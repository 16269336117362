import { HEADERS, KEYS } from '.';

export const REWARD_API = {
  getAllRewards: () => ({
    endPoint: '/api/v1/reward/all',
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  getRewardByProfileId: (id) => ({
    endPoint: `/api/v1/reward/${id}/list`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  insertReward: () => ({
    endPoint: '/api/v1/reward/create',
    headers: HEADERS.TOKEN_HEADER,
    method: 'POST',
  }),
  updateReward: () => ({
    endPoint: '/api/v1/reward/update',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  deleteReward: (id) => ({
    endPoint: `/api/v1/reward/delete/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'DELETE',
  }),
  updateRewardAccepted: () => ({
		endPoint: '/api/v1/formReward/updateScore_Accepted',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
};

export const REWARD_TYPE = {
  GET_ALL_REWARD_LOADING: 'GET_ALL_REWARD_LOADING',
  GET_ALL_REWARD_SUCCESS: 'GET_ALL_REWARD_SUCCESS',
  GET_ALL_REWARD_FAILURE: 'GET_ALL_REWARD_FAILURE',

  GET_REWARD_BY_PROFILE_ID_LOADING: 'GET_REWARD_BY_PROFILE_ID_LOADING',
  GET_REWARD_BY_PROFILE_ID_SUCCESS: 'GET_REWARD_BY_PROFILE_ID_SUCCESS',
  GET_REWARD_BY_PROFILE_ID_FAILURE: 'GET_REWARD_BY_PROFILE_ID_FAILURE',

  INSERT_REWARD_LOADING: 'INSERT_REWARD_LOADING',
  INSERT_REWARD_SUCCESS: 'INSERT_REWARD_SUCCESS',
  INSERT_REWARD_FAILURE: 'INSERT_REWARD_FAILURE',

  UPDATE_REWARD_LOADING: 'UPDATE_REWARD_LOADING',
  UPDATE_REWARD_SUCCESS: 'UPDATE_REWARD_SUCCESS',
  UPDATE_REWARD_FAILURE: 'UPDATE_REWARD_FAILURE',

  DELETE_REWARD_LOADING: 'DELETE_REWARD_LOADING',
  DELETE_REWARD_SUCCESS: 'DELETE_REWARD_SUCCESS',
  DELETE_REWARD_FAILURE: 'DELETE_REWARD_FAILURE',

  UPDATE_REWARD_ACCEPTED_LOADING: 'UPDATE_REWARD_ACCEPTED_LOADING',
  UPDATE_REWARD_ACCEPTED_SUCCESS: 'UPDATE_REWARD_ACCEPTED_SUCCESS',
  UPDATE_REWARD_ACCEPTED_FAILURE: 'UPDATE_REWARD_ACCEPTED_FAILURE',
};

export const REWARD_CONSTANTS = {
  typeOptions : [
    { key: 'o1', title: 'HCV Olympic Quốc tế', value: 'o1' },
    { key: 'o2', title: 'HCB Olympic Quốc tế', value: 'o2' },
    { key: 'o3', title: 'HCĐ Olympic Quốc tế', value: 'o3' },
    { key: 'g1', title: 'HCV/Giải Nhất Quốc tế', value: 'g1' },
    { key: 'g2', title: 'HCB/Giải Nhì Quốc tế', value: 'g2' },
    { key: 'g3-1', title: 'HCĐ/Giải ba Quốc tế', value: 'g3-1' },
    { key: 'g3-2', title: 'HCV/Giải nhất Quốc gia', value: 'g3-2' },
    { key: 'g4', title: 'HCB/Giải Nhì Quốc gia', value: 'g4' },
    { key: 'g5', title: 'HCĐ/Giải Ba Quốc gia', value: 'g5' },
  ],
  TYPE_OPTIONS: {
    // TODO: Chuyển về PTNS enums
    [KEYS.HTQT]: [
      { key: 'g1', title: 'HCV/Giải Nhất Quốc tế', value: 'g1' },
      { key: 'g2', title: 'HCB/Giải Nhì Quốc tế', value: 'g2' },
      { key: 'g3-1', title: 'HCĐ/Giải Ba Quốc tế', value: 'g3-1' },
    ],
    [KEYS.HTQG]: [
      { key: 'g3-2', title: 'HCV/Giải Nhất Quốc gia', value: 'g3-2' },
      { key: 'g4', title: 'HCB/Giải Nhì Quốc gia', value: 'g4' },
      { key: 'g5', title: 'HCĐ/Giải Ba Quốc gia', value: 'g5' },
    ],
    [KEYS.HTCS]: [
      { key: 'nstb-g1', title: 'HCV/Giải Nhất Cấp Tỉnh', value: 'nstb-g1' },
      { key: 'nstb-g2', title: 'HCB/Giải Nhì Cấp Tỉnh', value: 'nstb-g2' },
      { key: 'nstb-g3', title: 'HCĐ/Giải Ba Cấp Tỉnh', value: 'nstb-g3-1' },
    ],
    // 'nstb-n1', 'nstb-n2', 'nstb-n3', 'nstb-n4'
    [KEYS.NCKHCB]: [
      { key: 'nstb-n1', title: 'Giải Nhất cấp Bộ Giáo dục', value: 'nstb-n1' },
      { key: 'nstb-n2', title: 'Giải Nhì cấp Bộ Giáo dục', value: 'nstb-n2' },
      { key: 'nstb-n3', title: 'Giải Ba cấp Bộ Giáo dục', value: 'nstb-n3' },
      { key: 'nstb-n4', title: 'Giải Khuyến khích cấp Bộ Giáo dục', value: 'nstb-n4' },
    ],
    [KEYS.NCKHCT]: [
      { key: 'nstb-n5', title: 'Giải Nhất cấp Trường', value: 'nstb-n5' },
      { key: 'nstb-n6', title: 'Giải Nhì cấp Trường', value: 'nstb-n6' },
      { key: 'nstb-n7', title: 'Giải Ba cấp Trường', value: 'nstb-n7' },
    ]
  }
}


// [ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT]: rewards.filter((e) => ['g1', 'g2', 'g3-1'].find((type) => type === e.type)),
// [ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG]: rewards.filter((e) => ['g3-2', 'g4', 'g5'].find((type) => type === e.type)),
// [ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS]: rewards.filter((e) => ['nstb-g1', 'nstb-g2', 'nstb-g3'].find((type) => type === e.type)),
// [ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB]: rewards.filter((e) => ['nstb-n1', 'nstb-n2', 'nstb-n3', 'nstb-n4'].find((type) => type === e.type)),
// [ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT]: rewards.filter((e) => ['nstb-n5', 'nstb-n6', 'nstb-n7'].find((type) => type === e.type)),