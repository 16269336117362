import Interweave from 'interweave';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Divider, Typography } from '@material-ui/core';

import HosoAbout from '../components/about';
import HosoProfile from '../components/profile';
import KPTable from '../../../../components/KPTComponents/KPTable';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';

import { profileActions } from '../../../../actions';
import { hoSoColumnMap, hoSoDefaultColumns } from '../../../../utils/qcv/hoSo.column_table';
import { APP_SCENE, ARCHIVEMENT_FORM, ARCHIVEMENT_TITLE, ACHIEVEMENT_TYPE, KEYS } from '../../../../constants/enums';
import { capitalizeFirstLetter, lowercaseFirstLetter } from '../../../../utils';

const DEFAULT_HR_COUNTER_STATE = { master: 0, bachelor: 0 }

export default function HoSoViewAdmin(props) {
  const dispatch = useDispatch();

  const [pointHr, setPointHr] = useState({ ...DEFAULT_HR_COUNTER_STATE });
  const [counterHr, setCounterHr] = useState({ ...DEFAULT_HR_COUNTER_STATE });
  const [previewValue, setPreviewValue] = useState(null);

  const arrayPath = window.location.href.split('/');
  const urlId = parseInt(arrayPath.slice(arrayPath.length - 1));

  const profileId = useSelector((state) => state.profileReducer.profile?.id);
  const applicationList = useSelector((state) => state.formReducer.applicationList);

  useEffect(() => {
    if (applicationList.length === 0) dispatch(profileActions.getProfileList());
  }, []);

  const applicationDetail = useMemo(() => {
    const filtered = applicationList.find((e) => e.id === urlId);
    const detail = { ...filtered };

    if (filtered) {
      Object.keys(ACHIEVEMENT_TYPE).forEach((_key) => {
        const key = 'form' + capitalizeFirstLetter(ACHIEVEMENT_TYPE[_key]);
        detail[key] = detail[key].map((e) => ({
          ...e[ACHIEVEMENT_TYPE[_key]],
          note: e.note,
          score: e.score,
          accepted: e.accepted,
        }));
      });
  
      const keyHr = 'form' + capitalizeFirstLetter(ACHIEVEMENT_TYPE.HR_DEVELOPMENT);
      detail[keyHr + KEYS.DAO_TAO] = detail[keyHr].filter((item) => item.type === KEYS.DAO_TAO);
      detail[keyHr + KEYS.BOI_DUONG] = detail[keyHr].filter((item) => item.type === KEYS.BOI_DUONG);

      detail.process = JSON.parse(detail.process || '[]');
      detail.socialActivity = JSON.parse(detail.socialActivity || '[]');
      detail.communicationActivity = JSON.parse(detail.communicationActivity || '[]');

      const fileList = JSON.parse(detail.previewFile || '[]');
      detail.sumaryFile = fileList.find((file) => file.includes(('sumaryFile')));

      const currCounterHr = { ...DEFAULT_HR_COUNTER_STATE };
      detail[keyHr + KEYS.DAO_TAO].filter((form) => form.accepted).forEach((form) => {
        currCounterHr[form.academicLevel] += 1;
      });

      // TODO: Theo năm
      setPointHr({
        master: (currCounterHr.master > 0) ? (currCounterHr.master > 5 ? 3 : 2) : 0,
        bachelor: (currCounterHr.bachelor > 0) ? (currCounterHr.bachelor > 5 ? 3 : 2) : 0,
      });
      setCounterHr(currCounterHr);
    }

    return detail;
  }, [applicationList]);

  if (!props.isSuperAdmin && applicationDetail?.profileId !== profileId) return null;

  return (
    <>
      <Grid container style={{ padding: 32 }}>
        <HosoProfile applicationDetail={applicationDetail} />
        <HosoAbout applicationDetail={applicationDetail} />

        <Grid item xs={5} style={{ marginTop: 32, marginBottom: 8 }}>
          <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>
            QUÁ TRÌNH HỌC TẬP, CÔNG TÁC
          </Box>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12}>
          <KPTable
            nonBorder={false}
            columns={hoSoColumnMap[APP_SCENE].hocTapCongTac()}
            rows={applicationDetail?.process}
          />
        </Grid>
        <Grid item xs={5} style={{ marginTop: 32, marginBottom: 8 }}>
          <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>
            TÓM TẮT CÔNG TRÌNH TIÊU BIỂU
          </Box>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12}>
          <Interweave content={applicationDetail?.summary} />
        </Grid>
        <Grid item xs={5} style={{ marginTop: 32, marginBottom: 8 }}>
          <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>
            THÀNH TÍCH CÁ NHÂN
          </Box>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <Box display='flex'>
            <Box
              width={32}
              height={32}
              color='white'
              display='flex'
              bgcolor='black'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
            >
              A
            </Box>
            <Box flexGrow={1} paddingLeft={4} lineHeight='32px'>
              <Typography variant='h6'>
                <b>THÀNH TÍCH KHOA HỌC CÔNG NGHỆ</b>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {Object.keys(applicationDetail)
            .filter((key) => key.slice(0, 4) === 'form' && !!hoSoColumnMap[APP_SCENE]?.[key])
            .map((formKey, id) => {
              const key = lowercaseFirstLetter(formKey.replace('form', ''));
              return (
                <React.Fragment key={key}>
                  <Typography variant='h6' style={{ marginTop: id ? 32 : 8 }}>
                    <b>{id + 1}. {ARCHIVEMENT_TITLE[key]}</b>
                    <br />
                  </Typography>
                  {(key === ARCHIVEMENT_FORM.HR_DEVELOPMENT + KEYS.DAO_TAO && props.isSuperAdmin) && (
                    <>
                      <span style={{ fontSize: 16 }}>Hướng dẫn {counterHr.master} thạc sĩ: </span>
                      <span style={{ fontSize: 16, color: '#ee6064' }}>
                        +{pointHr.master}đ
                      </span>
                      <br />
                      <span style={{ fontSize: 16 }}>Hướng dẫn {counterHr.bachelor} cử nhân: </span>
                      <span style={{ fontSize: 16, color: '#ee6064' }}>
                        +{pointHr.bachelor}đ
                      </span>
                    </>
                  )}
                  <KPTable
                    nonBorder={false}
                    columns={props.isSuperAdmin ? [...hoSoColumnMap[APP_SCENE][formKey](), ...hoSoDefaultColumns] : [...hoSoColumnMap[APP_SCENE][formKey]()]}
                    rows={applicationDetail[formKey].filter((e) => e !== null) || []}
                  />
                  <Divider />
                </React.Fragment>
              );
            })
          }
        </Grid>
        <Grid item xs={12} style={{ marginTop: 64 }}>
          <Box display='flex'>
            <Box
              width={32}
              height={32}
              color='white'
              display='flex'
              bgcolor='black'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
            >
              B
            </Box>
            <Box flexGrow={1} paddingLeft={4} lineHeight='32px'>
              <Typography variant='h6'>
                <b>THÀNH TÍCH KHEN THƯỞNG (BẰNG KHEN, GIẤY KHEN...)</b>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <Grid item xs={12}>
            <KPTable
              nonBorder={false}
              columns={hoSoColumnMap[APP_SCENE].thanhTichBangKhen((value) => setPreviewValue(value))}
              rows={applicationDetail?.communicationActivity}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 64 }}>
          <Box display='flex'>
            <Box
              width={32}
              height={32}
              color='white'
              display='flex'
              bgcolor='black'
              borderRadius='50%'
              alignItems='center'
              justifyContent='center'
            >
              C
            </Box>
            <Box flexGrow={1} paddingLeft={4} lineHeight='32px'>
              <Typography variant='h6' style={{ marginBottom: 10 }}>
                <b>HOẠT ĐỘNG CỘNG ĐỒNG</b>
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 8 }}>
          <KPTable
            nonBorder={false}
            columns={hoSoColumnMap[APP_SCENE].hoatDongCongDong((value) => setPreviewValue(value))}
            rows={applicationDetail?.socialActivity}
          />
        </Grid>

      </Grid>

      <PreviewModal open={!!previewValue} url={previewValue} handleClose={() => setPreviewValue(null)} />
    </>
  );
}
