import { apiCall } from '../utils';
import { RESEARCH_API, RESEARCH_TYPE } from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const researchActions = {
  getAllResearch,
  getResearchByProfileId,
  insertResearch,
  updateResearch,
  deleteResearch,
  updateResearchAccepted,
};

function getAllResearch() {
  return async (dispatch) => {
    dispatch(loading());

    const api = RESEARCH_API.getAllResearch();
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy bài báo khoa học thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy bài báo khoa học thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: RESEARCH_TYPE.GET_ALL_RESEARCH_LOADING } }
  function success(payload) { return { type: RESEARCH_TYPE.GET_ALL_RESEARCH_SUCCESS, payload } }
  function failure(error) { return { type: RESEARCH_TYPE.GET_ALL_RESEARCH_FAILURE, error } }
}

function getResearchByProfileId(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = RESEARCH_API.getResearchByProfileId(id);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy bài báo khoa học thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy bài báo khoa học thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: RESEARCH_TYPE.GET_RESEARCH_BY_PROFILE_ID_LOADING } }
  function success(payload) { return { type: RESEARCH_TYPE.GET_RESEARCH_BY_PROFILE_ID_SUCCESS, payload } }
  function failure(error) { return { type: RESEARCH_TYPE.GET_RESEARCH_BY_PROFILE_ID_FAILURE, error } }
}

function insertResearch(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = RESEARCH_API.insertResearch();
    const { response, error } = await apiCall({
      ...api,
      payload: payload.map((e) => ({ ...e, publishYear: String(e.publishYear) })) 
    });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data[0]));
      dispatch(notification({ message: 'Thêm bài báo khoa học thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm bài báo khoa học thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: RESEARCH_TYPE.INSERT_RESEARCH_LOADING } }
  function success(payload) { return { type: RESEARCH_TYPE.INSERT_RESEARCH_SUCCESS, payload } }
  function failure(error) { return { type: RESEARCH_TYPE.INSERT_RESEARCH_FAILURE, error } }
}

function updateResearch(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = RESEARCH_API.updateResearch();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
			// dispatch(formActions.updateFocusFormByData('formScientificResearch', 'scientificResearch', response.data.data));
      dispatch(notification({ message: 'Chỉnh sửa bài báo khoa học thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Chỉnh sửa bài báo khoa học thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: RESEARCH_TYPE.UPDATE_RESEARCH_LOADING } }
  function success(payload) { return { type: RESEARCH_TYPE.UPDATE_RESEARCH_SUCCESS, payload } }
  function failure(error) { return { type: RESEARCH_TYPE.UPDATE_RESEARCH_FAILURE, error } }
}

function deleteResearch(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = RESEARCH_API.deleteResearch(id);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200) {
      dispatch(success(id));
      dispatch(notification({ message: 'Xoá bài báo khoa học thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Xoá bài báo khoa học thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: RESEARCH_TYPE.DELETE_RESEARCH_LOADING } }
  function success(id) { return { type: RESEARCH_TYPE.DELETE_RESEARCH_SUCCESS, id } }
  function failure(error) { return { type: RESEARCH_TYPE.DELETE_RESEARCH_FAILURE, error } }
}

function updateResearchAccepted(payload){
  return async(dispatch) => {
    dispatch(loading());

    const api = RESEARCH_API.updateResearchAccepted();
    const { response, error } = await apiCall({ ...api, payload });

      
    const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
			dispatch(formActions.updateFocusForm('formScientificResearch', response.data.data));
      dispatch(notification({ message: `${noti} bài báo khoa học thành công`, type: 'success' }));
			return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: `${noti} bài báo khoa học thất bại`, type: 'error' }));
    }
  };

  function loading() { return { type: RESEARCH_TYPE.UPDATE_RESEARCH_ACCEPTED_LOADING } }
  function success(payload) { return { type: RESEARCH_TYPE.UPDATE_RESEARCH_ACCEPTED_SUCCESS, payload } }
  function failure(error) { return { type: RESEARCH_TYPE.UPDATE_RESEARCH_ACCEPTED_FAILURE, error } }
}
