import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import { Grid, IconButton, Collapse, Typography } from '@material-ui/core';

import { PRIMARY_COLOR } from '../../../constants/enums';

function ThanhTichAlert(props) {
  const [open, setOpen] = useState(true);

  return (
    <Grid item xs={12}>
      <Collapse in={open}>
        <Alert
          severity="info"
          icon={false}
          action={!!props.closable &&
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpen(false)}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          style={{ fontSize: 16, border: `solid 1px ${PRIMARY_COLOR}40` }}
        >
          <Typography variant="h6"><b>Lưu ý: </b></Typography>
          <ul style={{
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}>
            {!!props.extraBefore && <li>{props.extraBefore}</li>}
            <li>
              <Typography variant="body1">
                Trong trường hợp minh chứng không phải là tiếng Anh
                hoặc tiếng Việt thì cần có văn bản dịch thuật minh
                chứng thành tích đó sang tiếng Anh hoặc tiếng Việt
                có xác nhận của cơ quan, đơn vị có thẩm quyền!
              </Typography>
            </li>
          </ul>
        </Alert>
      </Collapse>
    </Grid>
  );
}

export default ThanhTichAlert;
