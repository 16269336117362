export const jumpToReleventDiv = (id) => {
  const releventDiv = document.getElementById(id);
  if (releventDiv) {
    const offset = 96;
    const rect = releventDiv.getBoundingClientRect();
    const absoluteElementTop = rect.top + window.pageYOffset;
    const offsetPosition = absoluteElementTop - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}