import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import GiaiThuongForm from '../GiaiThuongForm/GiaiThuongForm';

import { dateFormatter } from '../../../../utils';
import { fileActions, rewardActions } from '../../../../actions';

export default function GiaiThuongInsert(props) {
  const dispatch = useDispatch();
  const profileId = useSelector((state) => state.profileReducer.profile?.id);

  const [values, setValues] = useState(null);

  const handleSubmit = () => {
    dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0])).then((response) => {
      dispatch(
        rewardActions.insertReward([
          {
            ...values,
            profileId,
            link: response,
            decisionDay: dateFormatter(values.decisionDay),
          },
        ]),
      );
    });
    setValues(null);
    props.onClose();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>Thêm giải thưởng</Typography>
          <IconButton onClick={props.onClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <GiaiThuongForm
          id='giai-thuong'
          values={values}
          setValues={setValues}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button variant='outlined' color='secondary' onClick={props.onClose}>Huỷ</Button>
        <Button form='giai-thuong' type='submit' variant='contained' color='secondary'>Xác nhận</Button>
      </DialogActions>
    </Dialog>
  );
}
