import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import BaiBaoQuocTeForm from "../BaiBaoQuocTeForm";
import BaiBaoTrongNuocForm from "../BaiBaoTrongNuocForm";
// import BaiBaoQuocTeKhacForm from "../BaiBaoQuocTeKhacForm";
import { fileActions, researchActions } from "../../../../actions";

const getType = (form) => {
  switch (form) {
    case "international":
      return null;
    case "other":
      return "q5";
    default:
      return "q6";
  }
};

function BaiBaoInsert(props) {
  const dispatch = useDispatch();

  const profileId = useSelector((state) => state.profileReducer.profile?.id);

  const initValues = {
    type: getType(props.form),
    filterType: props.form,
  };

  const [values, setValues] = useState(initValues);

  const handleChangeForm = (name, event, type) => {
    if (type === "int") {
      setValues({ ...values, [name]: Number(event.target.value) });
    } else if (type === "file") {
      setValues({ ...values, [name]: event });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleClose = () => {
    setValues(initValues);
    props.onClose();
  };

  const handleSubmit = () => {
    if (values?.previewFile?.[0]) {
      dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0]))
        .then((response) => dispatch(
          researchActions.insertResearch([{ ...values, profileId, preview: response }])
        ));
    } else {
      dispatch(researchActions.insertResearch([{ ...values, profileId }]));
    }
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={props.open}>
      <DialogTitle style={{ padding: "4px 12px 4px 24px" }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant="h5">Thêm bài báo</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {props.form === "international" && (
          <BaiBaoQuocTeForm
            id="bai-bao"
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
            handleChangeForm={handleChangeForm}
          />
        )}
        {/* {props.form === "other" && (
          <BaiBaoQuocTeKhacForm
            id="bai-bao"
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
            handleChangeForm={handleChangeForm}
          />
        )} */}
        {props.form === "domestic" && (
          <BaiBaoTrongNuocForm
            id="bai-bao"
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
            handleChangeForm={handleChangeForm}
          />
        )}
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px", }}>
        <Button variant="outlined" color="secondary" onClick={handleClose}>Huỷ</Button>
        <Button form="bai-bao" type="submit" variant="contained" color="secondary">Xác nhận</Button>
      </DialogActions>
    </Dialog>
  );
}

export default BaiBaoInsert;
