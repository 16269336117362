import { apiCall } from '../utils';
import { RESEARCHPROJECTS_API, RESEARCHPROJECTS_TYPE } from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const researchProjectActions = {
	getAllResearchProject,
	insertResearchProject,
	updateResearchProject,
	deleteResearchProject,
	updateResearchProjectAccepted,
	getResearchProjectByProfileId
}

function getAllResearchProject() {
	return async (dispatch) => {
		dispatch(loading());

		const api = RESEARCHPROJECTS_API.getAllResearchProjectList();
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy dự án nghiên cứu thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy dự án nghiên cứu thất bại', type: 'error' }));
		}
	}

	function loading() { return { type: RESEARCHPROJECTS_TYPE.GET_ALL_RESEARCH_PROJECT_LOADING } }
	function success(payload) { return { type: RESEARCHPROJECTS_TYPE.GET_ALL_RESEARCH_PROJECT_SUCCESS, payload } }
	function failure(error) { return { type: RESEARCHPROJECTS_TYPE.GET_ALL_RESEARCH_PROJECT_FAILURE, error } }
}

function getResearchProjectByProfileId(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = RESEARCHPROJECTS_API.getResearchProjectByProfileId(id);
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy dự án nghiên cứu thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy dự án nghiên cứu thất bại', type: 'error' }));
		}
	}

	function loading() { return { type: RESEARCHPROJECTS_TYPE.GET_RESEARCH_PROJECT_BY_PROFILE_ID_LOADING } }
	function success(payload) { 
		return { type: RESEARCHPROJECTS_TYPE.GET_RESEARCH_PROJECT_BY_PROFILE_ID_SUCCESS, payload } 
	}
	function failure(error) { return { type: RESEARCHPROJECTS_TYPE.GET_RESEARCH_PROJECT_BY_PROFILE_ID_FAILURE, error } }
}

function insertResearchProject(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = RESEARCHPROJECTS_API.insertResearchProject();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200) {
			dispatch(success(response.data.data[0]));
			dispatch(notification({ message: 'Thêm sáng chế thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Thêm sáng chế thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: RESEARCHPROJECTS_TYPE.INSERT_RESEARCHPROJECT_LOADING } }
	function success(payload) { return { type: RESEARCHPROJECTS_TYPE.INSERT_RESEARCHPROJECT_SUCCESS, payload } }
	function failure(error) { return { type: RESEARCHPROJECTS_TYPE.INSERT_RESEARCHPROJECT_FAILURE, error } }
}

function updateResearchProject(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = RESEARCHPROJECTS_API.updateResearchProject();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusFormByData('formResearchProject', 'researchProject', response.data.data));
			dispatch(notification({ message: 'Chỉnh sửa dự án nghiên cứu thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Chỉnh sửa dự án nghiên cứu thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_LOADING } }
	function success(payload) { return { type: RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_SUCCESS, payload } }
	function failure(error) { return { type: RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_FAILURE, error } }
}

function updateResearchProjectAccepted(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = RESEARCHPROJECTS_API.updateResearchProjectAccepted();
		const { response, error } = await apiCall({ ...api, payload });


		const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusForm('formResearchProject', response.data.data));
			dispatch(notification({ message:`${noti} dự án nghiên cứu thành công`,type:'success' }));
			return true;
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: `${noti} dự án nghiên cứu thất bại`, type: 'error' }));
		}
	};

	function loading() { return { type: RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_ACCEPTED_LOADING } }
	function success(payload) {return {type:RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_ACCEPTED_SUCCESS,payload}}
	function failure(error) { return { type: RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_ACCEPTED_FAILURE, error } }
}

function deleteResearchProject(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = RESEARCHPROJECTS_API.deleteResearchProject(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200) {
			dispatch(success(id));
			dispatch(notification({ message: 'Xoá dự án nghiên cứu thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Xoá dự án nghiên cứu thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: RESEARCHPROJECTS_TYPE.DELETE_RESEARCHPROJECT_LOADING } }
	function success(id) { return { type: RESEARCHPROJECTS_TYPE.DELETE_RESEARCHPROJECT_SUCCESS, id } }
	function failure(error) { return { type: RESEARCHPROJECTS_TYPE.DELETE_RESEARCHPROJECT_FAILURE, error } }
}