import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Grid, Select, MenuItem, Typography, Container, Box, Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core';

import { ROUTER } from '../../../constants';

import DuAn from '../../QCV/DuAnNghienCuu';
import BaiBao from '../../QCV/BaiBao';
import BaoCao from '../../QCV/BaoCao';
import DaoTao from '../../QCV/DaoTao';
import GiaiThuong from '../../QCV/GiaiThuong';
import DoanhNghiep from '../../QCV/DoanhNghiep';
import BangSangChe from '../../QCV/BangSangChe';
import SachChuyenKhao from '../../QCV/SachChuyenKhao';
import AppHeader from '../../../containers/AppLayout/AppHeader';
import AppFooter from '../../../containers/AppLayout/AppFooter/AppFooter';
import { WarningRounded } from '@material-ui/icons';


export const ROUTER_CONFIG_THANH_TICH_CA_NHAN = [
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.BANG_SANG_CHE, title: 'Bằng độc quyền sáng chế, giải pháp hữu ích', element: <BangSangChe /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.BAI_BAO_QUOC_TE, title: 'Bài báo khoa học đăng trên tạp chí quốc tế', element: <BaiBao form='international' /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.BAI_BAO_TRONG_NUOC, title: 'Bài báo khoa học đăng trên tạp chí trong nước', element: <BaiBao form='domestic' /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.BAO_CAO, title: 'Bài báo khoa học đăng trên hội thảo', element: <BaoCao /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.BANG_SACH, title: 'Sách chuyên khảo, chương sách', element: <SachChuyenKhao /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.DU_AN_NGHIEN_CUU, title: 'Chương trình, dự án, đề tài nghiên cứu khoa học', element: <DuAn /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.PHAT_TRIEN_NGUON_NHAN_LUC, title: 'Phát triển nguồn nhân lực', element: <DaoTao /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.DOANH_NGHIEP_CONG_NGHE, title: 'Sản phẩm khoa học công nghệ', element: <DoanhNghiep /> },
  { path: ROUTER.QCV.THANH_TICH_CA_NHAN.GIAI_THUONG, title: 'Giải thưởng, huy chương đã đạt được', element: <GiaiThuong /> },
];


function ThanhTichCaNhan() {
  const url = window.location.href;
  const navigate = useNavigate();
  const [value, setValue] = useState(ROUTER_CONFIG_THANH_TICH_CA_NHAN.find((e) => url.includes(e.path))?.path);
  const { profile, userId } = useSelector((state) => ({
    userId: state.userReducer.user?.id,
    profile: state.profileReducer.profile,
  }));

  document.body.style.backgroundColor = '#FFF';

  const { seasons, openSeasonId } = useSelector((state) => ({
    seasons: state.seasonReducer.seasonList,
    openSeasonId: state.seasonReducer.openSeasonId,
  }));

  const openSeason = useMemo(() => seasons.find((s) => s.id === openSeasonId), [seasons, openSeasonId]);
  
  const isNotAvalaible = useMemo(() => {
    if (!openSeason) return false;
    const { openDate, closeDate } = openSeason;
    const currDate = new Date();
    if (currDate < new Date(openDate)) return 'Chưa mở cổng đăng ký';
    if (currDate > new Date(closeDate)) return 'Đã hết thời hạn đăng ký';
    return null;
  }, [openSeason]);

  const outletComponent = () => {
    if (isNaN(userId) && !localStorage.getItem('refresh_token')) {
      return (
        <Box textAlign='center' marginTop={4}>
          <NavLink to={ROUTER.DANG_NHAP}>Đăng nhập để truy cập tính năng này</NavLink>
        </Box>
      );
    } else if (!isNaN(userId) && !profile) {
      return (
        <Box textAlign='center' marginTop={4}>
          <NavLink to={ROUTER.THONG_TIN_CA_NHAN}>Hoàn thiện thông tin cá nhân để truy cập tính năng này</NavLink>
        </Box>
      )
    } else {
      return (
        <Grid container spacing={2} justifyContent='flex-end' style={{ width: '100%', margin: 0 }}>
          <Grid item xs={12} md={3}>
            <Typography variant='h5'><b>Thành tích cá nhân</b></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              fullWidth
              size='small'
              value={value || ''}
              variant='outlined'
              SelectDisplayProps={{ style: { padding: 8 } }}
              MenuProps={{
                style: { left: window.innerWidth <= 768 ? -8 : 0 },
                getContentAnchorEl: null,
                MenuListProps: { style: { padding: 0 } },
                PaperProps: { style: { left: 8 } },
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
              }}
              onChange={(e) => {
                setValue(e.target.value);
                navigate(e.target.value);
              }}
            >
              {ROUTER_CONFIG_THANH_TICH_CA_NHAN.map((opt, id) => <MenuItem key={opt.path} value={opt.path}>
                {`${id + 1}. ${opt.title}`}
              </MenuItem>)}
            </Select>
          </Grid>
          <Outlet />
        </Grid>
      )
    }
  }

  return (
    <>
      <AppHeader />
      <Container maxWidth='lg' style={{ paddingTop: 96, paddingBottom: 32, minHeight: '100vh' }}>
        {outletComponent()}
      </Container>
      <AppFooter />
      
      <Dialog fullWidth maxWidth="xs" open={isNotAvalaible}>
        <DialogTitle style={{ padding: "4px 12px 4px 24px" }}>
          <Typography variant="h5"><b>Thông báo</b></Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" width="100%" >
            <WarningRounded style={{ margin: "auto", color: "#4da6ff", fontSize: 72 }} />
          </Box>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {isNotAvalaible}
            <br />
            <b>Giải thưởng Khoa học công nghệ <br /> Quả Cầu Vàng năm {openSeason?.year}</b>
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "8px 24px" }}>
          <Button
            fullWidth
            href={ROUTER.QCV.HOME}
            variant="contained"
            color="secondary"
          // onClick={() => setWarningOpen(false)}
          >
            Về trang chủ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ThanhTichCaNhan;
