import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Divider,
  Button,
  Box,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import DoanhNghiepForm from '../DoanhNghiepForm/DoanhNghiepForm';
import { fileActions, techCompanyActions } from '../../../../actions';


export default function DoanhNghiepInsert(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);
  
  const [values, setValues] = useState(null);

  const handleSubmit = () => {
    dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0]))
    .then((response) => {
      dispatch(techCompanyActions.insertTechCompany([
        {
          ...values,
          link: response,
          value: parseInt(values?.value?.toString()?.replaceAll('.', '')) || null,
          profileId,
        }
      ]));
    });
    setValues(null);
    props.onClose();
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
    >
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>Thêm sản phẩm ứng dụng thực tế</Typography>
          <IconButton onClick={props.onClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DoanhNghiepForm
          id='doanh-nghiep'
          values={values}
          setValues={setValues}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button variant='outlined' onClick={props.onClose}>Huỷ</Button>
        <Button form='doanh-nghiep' type='submit' variant='contained' color='secondary'>Xác nhận</Button>
      </DialogActions>
    </Dialog>
  );
}