import React, { useState,useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Card, Typography } from '@material-ui/core';

import { useScene } from '../../../hooks/useScene';
import { validEmail } from '../../../utils/validate';
import { userActions } from '../../../actions';
import { dateFormatter } from '../../../utils';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { ROUTER,FORM_CONSTANTS } from '../../../constants';


import Loading from '../../../components/CustomComponents/Loading';
import KPTextform from '../../../components/KPTComponents/KPTextform';
import EthnicOptions from '../../../constants/ethnic.json';
import theme from '../../../theme';

const TUTORIAL_STEP = [
  {
    title: 'Hoàn thiện thông tin',
    description: 'Hãy điền hết thông tin trong các ô, các trường thông tin yêu cầu phải chính xác, trung thực'
  },
  {
    title: 'Xác thực tài khoản',
    description: 'Sau khi đã hoàn thiện các trường thông tin, click vào nút ĐĂNG KÝ, mật khẩu sẽ được gửi về email của ứng viên'
  },
  {
    title: 'Đăng ký thành công',
    description: 'Với mật khẩu được gửi về email, ứng viên đã có thể sử dụng để truy cập vào hệ thống'
  },
];

function DangKy() {
  const scene = useScene();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileMode = useScreenSize();

  const [file, setFile] = useState('');
  const [values, setValues] = useState({});
  const [imagePreviewUrl, setImagePreviewUrl] = useState( '');
  
  const loading = useSelector(state => state.userReducer.loading);
  const refreshToken = localStorage.getItem('refresh_token');
  
  useEffect(() => {
    if (navigate && refreshToken) navigate(ROUTER.HOME);
  }, [navigate]);

  const textForm = [
    { key: 'name', width: isMobileMode ? 12 : 8, label: 'Họ và tên', type: 'uppercase', required: true },
    { key: 'birth', width: isMobileMode ? 12 : 4, label: 'Ngày sinh', type: 'date', required: true },
    { key: 'gender', width: isMobileMode ? 12 : 3, label: 'Giới tính', options: FORM_CONSTANTS.GENDER_OPTIONS, required: true },
    { key: 'ethnic', width: isMobileMode ? 12 : 3, label: 'Dân tộc', options: EthnicOptions.map((e) => ({ ...e, value: e.title })), required: true },
    { key: 'phone', width: isMobileMode ? 12 : 6, label: 'Số điện thoại', required: true },
    { key: 'email', width: 12, label: 'Email', required: true },
    { key: 'website', width: 12, label: 'Website/Facebook', required: true },
    { key: 'submit', width: 12, label: 'Đăng ký tài khoản', type: 'submit' },
  ];

  if (scene.isGiaiThuongNuSinh) {
    // Phần thưởng nữ sinh thì thôi cái Gender
    textForm.splice(2, 2, { ...textForm[3], width: isMobileMode ? 12 : 6 });
  }

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let readedFile = e.target.files[0];

    reader.onloadend = () => {
      if (readedFile && reader?.result) {
        setFile(readedFile);
        setImagePreviewUrl(reader.result);
      }
    }

    if (readedFile) reader.readAsDataURL(readedFile);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    for (const elm of textForm) {
      const elmValue = elm?.value || values?.[elm?.key];
      if (elm?.required && !elmValue) {
        setValues({ ...values, emptyKey: elm.key });
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label}`,
          type: 'error'
        }));
        return;
      }

      if (elm?.required && elm?.type === 'file' && elmValue.length !== 1) {
        setValues({ ...values, emptyKey: elm.key });
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label || 'File minh chứng'}`,
          type: 'error'
        }));
        return;
      }

      if (elm?.type === 'email' && !validEmail(elmValue)) {
        setValues({ ...values, emptyKey: elm.key });
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label}`,
          type: 'error'
        }));
        return;
      }
    }

    if (!file) {
      dispatch(userActions.notification({
        message: 'Không được để trống thông tin về: Ảnh chân dung',
        type: 'error'
      }));
      return;
    }

    dispatch(userActions.register(
      navigate,
      {
        ...values,
        name: values?.name.toUpperCase(),
        birth: dateFormatter(values?.birth),
        gender: values?.gender || 'female'
      },
      file
    ));
  }

  const topPart = isMobileMode ? textForm.slice(0, 3) : textForm.slice(0, 7);
  const botPart = isMobileMode ? textForm.slice(3) : textForm.slice(7);

  return (
    <Grid
      container
      alignContent='center'
      justifyContent='center'
      spacing={isMobileMode ? 1 : 5}
    >
      <Grid item xs={12} md={4} lg={3}>
        {TUTORIAL_STEP.map((e, id) => (
          <Box key={`tutor-${id}`} display='flex'>
            <Box display='flex' flexDirection='column'>
              <Box
                width={42}
                height={42}
                marginRight={4}
                display='flex'
                color='#ee6064'
                alignItems='center'
                justifyContent='center'
                borderRadius='50%'
                border='4px solid #ee6064'
              >
                <b style={{ fontSize: 24, fontFamily: 'SFProDisplay-Bold' }}>
                  {id + 1}
                </b>
              </Box>
              <Box width='1px' marginLeft='26px' flexGrow={1} borderLeft='4px dotted #cdcdcd' />
            </Box>
            <Box width='calc(100% - 80px)' marginBottom={4}>
              <Typography variant='h5' style={{ fontFamily: 'SFProDisplay-Bold' }}>
                <b>{e.title}</b>
              </Typography>
              <Typography variant='subtitle1' style={{ color: '#55626a', textAlign: 'justify' }}>
                {e.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Grid>
      <Grid item xs={12} md={8} lg={7}>
        <Card style={{ padding: 16, backgroundColor: '#E8F0FD' }}>
          <Typography variant='h4' style={{ textAlign: 'center', fontFamily: 'SFProDisplay-Bold' }}>
            <b>Đăng ký tài khoản</b>
          </Typography>
          <form className='dkhs-container' onSubmit={handleSubmit}>
            <Box display='flex'>
              <KPTextform
                rowMargin={4}
                component={Box}
                textForm={topPart}
                values={values}
                setValues={setValues}
              />
              <Box marginTop='4px' marginLeft={1}>
                <Typography
                  style={{ 
                    fontSize: 17,
                    fontWeight: 'bold',
                    color: '#031043',
                    paddingBottom: 4,
                  }}
                >
                  {isMobileMode ? 'Ảnh chân dung' : 'Ảnh chân dung (4x6)'}
                  <span style={{ color: theme.palette.secondary.main }}>*</span>
                  <span>:</span>
                </Typography>
                <div className='dkhs-idPhoto'>
                  {(imagePreviewUrl || values?.photoUrl) && (
                    <img
                      className='dkhs-photo'
                      src={imagePreviewUrl ? imagePreviewUrl : `${process.env.REACT_APP_DOMAIN}${values?.photoUrl}`}
                      alt='anh-chan-dung'
                    />
                  )}
                  <label className='dkhs-insertBtn' htmlFor='upload-photo'>
                    <div className='dkhs-insertIcon'>Click để thêm ảnh...</div>
                  </label>
                  <input
                    type='file'
                    id='upload-photo'
                    style={{ opacity: 0, zIndex: -1, position: 'absolute', width: '100%' }}
                    onChange={handleImageChange}
                  />
                </div>
              </Box>
            </Box>
            <KPTextform
              component={Box}
              textForm={botPart}
              values={values}
              rowMargin={4}
              setValues={setValues}
            />
          </form>
        </Card>
        <Typography style={{ marginTop: 8, textAlign: 'center' }}>
          {'Đã có tài khoản? '}
          <NavLink to={ROUTER.DANG_NHAP} style={{ color: 'blue' }}>Đăng nhập</NavLink>
        </Typography>
      </Grid>
      <Loading open={loading} />
    </Grid>
  );
}

export default DangKy;
