import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers';

const loggerMiddleware = createLogger({
  collapsed: true,
  predicate: (getState, action) => action.type !== 'NOTIFICATION',
  titleFormatter: (action) => action.type,
});

// eslint-disable-next-line no-undef
export const store =
  process.env.REACT_APP_BRANCH === 'development'
    ? createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware)))
    : createStore(rootReducer, applyMiddleware(thunkMiddleware));
