import { useState, useEffect } from 'react';
import { MOBILE_WIDTHS_SIZE } from '../constants/enums';

export const useScreenSize = () => {
  const [isMobileMode, setIsMobileMode] = useState(false);

  useEffect(() => {
    setIsMobileMode(window.innerWidth <= MOBILE_WIDTHS_SIZE);
  }, [window.innerWidth]);

  return isMobileMode;
}