import React from 'react';
import { Typography } from '@material-ui/core';

export default function QuyChe() {
  return (
    <div className='formal-text' style={{ padding: 8 }}>
      <Typography variant='h5'><strong>1. Đối tượng&nbsp;</strong></Typography>
      <p>
        Giải thưởng xét trao cho các cá nhân là công dân Việt Nam không 
        quá 35 tuổi (tính đến ngày 31/12 của năm xét trao giải) đang học 
        tập, nghiên cứu, làm việc ở trong hoặc ngoài nước.
      </p>
      <Typography variant='h5'><strong>2. Tiêu chuẩn</strong></Typography>
      <br/>
      <Typography variant='h6'><i><strong>2.1. Tiêu chuẩn chung&nbsp;</strong></i></Typography>
      <p>
        - Có thành tích xuất sắc trong học tập, nghiên cứu, làm việc; có 
        công trình nghiên cứu, giải pháp khoa học công nghệ nổi bật, có 
        giá trị khoa học cao; có ý tưởng sáng tạo được ứng dụng rộng rãi 
        trong thực tế, mang lại hiệu quả kinh tế, xã hội.
      </p>
      <p>
        - Có phẩm chất đạo đức, ý thức kỷ luật, chấp hành đường lối của Đảng, pháp 
        luật của Nhà nước Việt Nam và nước sở tại (đối với công dân Việt 
        Nam ở nước ngoài); có ý chí vươn lên trong học tập, công tác; có 
        uy tín và ảnh hưởng tốt trong thanh thiếu nhi và cộng đồng.
      </p>
      <p>
        - Ưu tiên, khuyến khích người dân tộc thiểu số, người khuyết tật; các cá nhân 
        vừa là nhà khoa học, nhà sáng chế vừa là lãnh đạo chủ chốt các tổ chức KHCN, 
        doanh nghiệp, tiên phong trong khởi nghiệp, đổi mới sáng tạo, chuyển đổi số, 
        tạo ra các giá trị sản phẩm hàng hoá, dịch vụ cao trên thị trường và có đóng 
        góp tích cực, tiêu biểu cho phát triển kinh tế, xã hội của đất nước.
      </p>
      <Typography variant='h6'><i><strong>2.2. Tiêu chuẩn trong lĩnh vực cụ thể</strong></i></Typography>
      <p>Cá nhân được trao tặng Giải thưởng phải đáp ứng ít nhất một trong những tiêu chuẩn sau đây:</p>
      <p><i>* Lĩnh vực Công nghệ thông tin, chuyển đổi số và tự động hóa&nbsp;</i></p>
      <p>
        - Là tác giả bằng độc quyền sáng chế, bằng độc quyền giải pháp hữu ích trong 
        lĩnh vực công nghệ thông tin hoặc sáng chế đã được áp dụng thành công trong thực tế.
      </p>
      <p>
        - Là tác giả các công trình nghiên cứu khoa học đã được đăng trên các tạp chí chuyên ngành uy 
        tín trong và ngoài nước, các báo cáo khoa học tại hội nghị khoa học chuyên ngành uy tín quốc gia, quốc tế.&nbsp;
      </p>
      <p>
        - Chủ trì, đồng chủ trì các đề tài nghiên cứu khoa học trong lĩnh vực công nghệ thông tin, 
        chuyển đổi số và tự động hóa đã nghiệm thu đạt yêu cầu. Ưu tiên nghiên cứu, phát triển và 
        ứng dụng công nghệ số: trí tuệ nhân tạo, internet vạn vật, công nghệ phân tích dữ liệu lớn, 
        công nghệ chuỗi khối; điện toán đám mây, điện toán lưới, điện toán biên, điện toán lượng tử; 
        công nghệ mạng thế hệ sau; thực tại ảo, thực tại tăng cường, thực tại trộn; công nghệ an ninh 
        mạng thông minh, tự khắc phục và thích ứng; bản sao số; công nghệ mô phỏng nhà máy sản xuất; 
        nông nghiệp chính xác; robot tự hành, robot cộng tác, phương tiện bay không người lái, phương 
        tiện tự hành dưới nước, điều khiển học kỹ thuật, điều khiển học tự động, cơ điện tử, đo lường 
        và cảm biếm, kỹ thuật robot,...
        </p>
        <p>
          - Là người sáng lập/điều hành dự án, doanh nghiệp khởi nghiệp công nghệ triển vọng thu hút 
          được đầu tư từ Nhà nước hoặc các Quỹ đầu tư có uy tín mang lại hình ảnh, thương hiệu Make in Vietnam.
        </p>
        <p>
          - Đoạt giải cao trong các kỳ thi công nghệ thông tin, tự động hóa quốc tế uy tín. Đạt các 
          giải thưởng/huy chương/bằng khen các cuộc thi uy tín cấp quốc gia trong lĩnh vực công nghệ 
          thông tin, chuyển đổi số và tự động hóa.
        </p>
        <p>
          - Là tác giả chủ biên các giáo trình, sách chuyên khảo, sách tham khảo chuyên ngành công nghệ 
          thông tin đã được các nhà xuất bản uy tín trong hoặc ngoài nước phát hành; có thành tích xuất 
          sắc trong tham gia đào tạo, bồi dưỡng nguồn nhân lực trong lĩnh vực công nghệ thông tin, chuyển 
          đổi số và tự động hóa.
        </p>
        <p><i>* Lĩnh vực Công nghệ y - dược</i></p>
        <p>
          - Là tác giả bằng độc quyền sáng chế, bằng độc quyền giải pháp hữu ích trong lĩnh vực công nghệ 
          y - dược hoặc các sáng chế đã được áp dụng triển khai rộng rãi trong dự phòng, chẩn đoán, điều 
          trị bệnh hiệu quả, giúp người bệnh nhanh chóng hồi phục, tiết kiệm chi phí hoặc giúp sàng lọc, 
          phát hiện sớm, cải thiện hiệu quả điều trị và nâng cao chất lượng cuộc sống.
        </p>
        <p>
          - Là tác giả các công trình nghiên cứu khoa học đã được đăng trên các tạp chí chuyên ngành uy tín 
          trong và ngoài nước; các báo cáo khoa học tại hội nghị khoa học chuyên ngành uy tín quốc gia, quốc tế.
        </p>
        <p>
          - Chủ trì, đồng chủ trì các đề tài nghiên cứu khoa học trong lĩnh vực công nghệ y 
          - dược đã nghiệm thu đạt yêu cầu.
        </p>
        <p>
          - Đoạt giải cao trong các cuộc thi quốc tế uy tín, đạt các giải thưởng/huy chương/bằng khen các cuộc 
          thi uy tín cấp quốc gia trong lĩnh vực công nghệ y - dược.
        </p>
        <p>
          - Là tác giả chủ biên các giáo trình, sách chuyên khảo, sách tham khảo hoặc chương sách chuyên ngành 
          công nghệ y - dược đã được các nhà xuất bản uy tín trong và ngoài nước phát hành; có thành tích xuất 
          sắc trong việc tham gia đào tạo, bồi dưỡng nguồn nhân lực trong lĩnh vực công nghệ y - dược.
        </p>
        <p><i>* Lĩnh vực Công nghệ sinh học</i></p>
        <p>
          - Là tác giả bằng độc quyền sáng chế, bằng độc quyền giải pháp hữu ích trong lĩnh vực công nghệ sinh 
          học hoặc sáng chế đã được áp dụng thành công trong thực tế.
        </p>
        <p>
          - Là tác giả các công trình nghiên cứu khoa học đã được đăng trên các tạp chí chuyên ngành uy tín trong 
          và ngoài nước; các báo cáo khoa học tại hội nghị khoa học chuyên ngành uy tín quốc gia, quốc tế.
        </p>
        <p>
          - Chủ trì, đồng chủ trì các đề tài nghiên cứu khoa học trong lĩnh vực công nghệ sinh học đã nghiệm thu 
          đạt yêu cầu. Ưu tiên các đề tài nghiên cứu, các giải pháp đã được ứng dụng có hiệu quả trong lĩnh vực 
          sinh học tổng hợp (kỹ thuật tạo mô động vật và nuôi cấy mô thực vật), công nghệ thần kinh, công nghệ 
          tế bào (tế bào gốc, tế bào động vật, tế bào thực vật), công nghệ enzyme và protein, công nghiệp thực 
          phẩm, công nghệ&nbsp; vi sinh, tin sinh học, nano sinh học, công nghệ sinh học phân tử, công nghệ gen 
          (di truyền và chọn giống), công nghệ giải trình tự gen thế hệ mới, chẩn đoán sinh học, vật liệu y sinh, 
          chip sinh học và cảm biến sinh học, nhiên liệu sinh học,…</p><p>- Đoạt giải cao trong các cuộc thi sinh 
          học quốc tế uy tín. Đạt các giải thưởng/huy chương/Bằng khen các cuộc thi uy tín quốc gia trong lĩnh vực 
          công nghệ sinh học.</p><p>- Là tác giả chủ biên các giáo trình, sách chuyên khảo, sách tham khảo chuyên 
          ngành công nghệ sinh học đã được các nhà xuất bản uy tín trong và ngoài nước phát hành; có thành tích 
          xuất sắc trong việc tham gia đào tạo, bồi dưỡng nguồn nhân lực trong lĩnh vực công nghệ sinh học.
        </p>
        <p><i>* Lĩnh vực Công nghệ môi trường</i></p>
        <p>
          - Là tác giả bằng độc quyền sáng chế, bằng độc quyền giải pháp hữu ích trong lĩnh vực công nghệ môi trường 
          hoặc sáng chế đã được áp dụng thành công trong thực tế.
        </p>
        <p>
          - Là tác giả các công trình nghiên cứu khoa học đã được đăng trên các tạp chí chuyên ngành uy tín trong và 
          ngoài nước; các báo cáo khoa học tại hội nghị khoa học chuyên ngành uy tín quốc gia, quốc tế.
        </p>
        <p>
          - Chủ trì, đồng chủ trì các đề tài nghiên cứu khoa học trong lĩnh vực công nghệ môi trường đã nghiệm thu 
          đạt yêu cầu. Ưu tiên các đề tài nghiên cứu ứng dụng công nghệ trong lĩnh vực môi trường: xỷ lý ô nhiễm
          &nbsp;môi trường,&nbsp;phục hồi và cải thiện môi trường; các giải pháp năng lượng tái tạo và ứng phó với 
          biến đổi khí hậu; các phát hiện, sáng kiến, giải pháp nhằm bảo tồn đa dạng sinh học, phát triển tài nguyên 
          thiên nhiên,...
        </p>
        <p>
          - Đoạt giải cao trong các cuộc thi quốc tế uy tín về môi trường. Đạt giải thưởng/huy chương/bằng khen các 
          cuộc thi uy tín cấp quốc gia trong lĩnh vực công nghệ môi trường.
        </p>
        <p>
          - Là tác giả chủ biên các giáo trình, sách chuyên khảo, sách tham khảo chuyên ngành công nghệ môi trường 
          đã được các nhà xuất bản uy tín trong và ngoài nước phát hành; có thành tích xuất sắc trong việc tham gia 
          đào tạo, bồi dưỡng nguồn nhân lực trong lĩnh vực công nghệ môi trường.
        </p>
        <p><i>* Lĩnh vực Công nghệ vật liệu mới</i></p>
        <p>
          - Là tác giả bằng độc quyền sáng chế, bằng độc quyền giải pháp hữu ích trong lĩnh vực công nghệ vật liệu 
          mới hoặc sáng chế đã được áp dụng thành công trong thực tế.
        </p>
        <p>
          - Là tác giả các công trình nghiên cứu khoa học đã được đăng trên các tạp chí chuyên ngành uy tín trong 
          và ngoài nước; các báo cáo khoa học tại Hội nghị khoa học chuyên ngành uy tín quốc gia, quốc tế.&nbsp;
        </p>
        <p>
          - Chủ trì, đồng chủ trì các đề tài nghiên cứu khoa học trong lĩnh vực công nghệ vật liệu mới đã nghiệm 
          thu đạt yêu cầu. Ưu tiên các đề tài nghiên cứu ứng dụng: các công nghệ chế tạo vật liệu có tính năng 
          đặc biệt (hợp kim, vật liệu polyme, vật liệu compozit, vật liệu nanocompozit, vật liệu xốp) sử dụng trong 
          công nghiệp quốc phòng, công nghiệp chế tạo, xây dựng, giao thông, xử lý ô nhiễm môi trường, chuyển hóa 
          năng lượng, chăm sóc sức khỏe,&nbsp;tạo ra các giá trị sản phẩm và dịch vụ cao trên thị trường; các công 
          nghệ chế tạo vật liệu điện tử và quang tử trong các mô - đun, thiết bị của hệ thống viễn thông, kỹ thuật 
          điện và tiết kiệm năng lượng, đặc biệt là vật liệu cáp quang; vật liệu chuyển hóa và tích trữ năng lượng; 
          vật liệu quang điện tử - quang tử và vật liệu biến hóa; công nghệ chế tạo vật liệu nano, thiết bị nano, 
          vật liệu y sinh tiên tiến sử dụng trong công nghiệp, nông nghiệp, xây dựng, giao thông, y - dược, bảo vệ 
          môi trường, quốc phòng, an ninh,…
        </p>
        <p>
          - Đoạt giải cao trong các cuộc thi quốc tế uy tín về vật liệu mới. Đạt giải thưởng/huy chương/bằng khen 
          các cuộc thi uy tín cấp quốc gia trong lĩnh vực công nghệ vật liệu mới.
        </p>
        <p>
          - Là tác giả chủ biên các giáo trình, sách chuyên khảo, sách tham khảo chuyên ngành công nghệ vật liệu 
          mới đã được các nhà xuất bản uy tín trong và ngoài nước phát hành; có thành tích xuất sắc trong việc 
          tham gia đào tạo, bồi dưỡng nguồn nhân lực trong lĩnh vực công nghệ vật liệu mới.
        </p>
        <Typography variant='h5'><strong>3. Hình thức giải thưởng</strong></Typography>
        <p>Mỗi cá nhân đạt Giải thưởng sẽ được nhận:</p>
        <p>- Huy hiệu “Tuổi trẻ Sáng tạo” của Ban Chấp hành Trung ương Đoàn TNCS Hồ Chí Minh;</p>
        <p>- Giấy chứng nhận của Bộ Khoa học và Công nghệ và Trung ương Đoàn TNCS Hồ Chí Minh;&nbsp;</p>
        <p>- Biểu trưng Giải thưởng;</p>
        <p>- Phần thưởng bằng tiền mặt tối thiểu 20 triệu đồng;</p>
        <p>- Các phần thưởng khác của đơn vị tài trợ, bảo trợ (nếu có).</p>
        <Typography variant='h5'><strong>4. Cơ cấu, số lượng Giải thưởng&nbsp;</strong></Typography>
        <p>
          Giải thưởng được trao hàng năm, căn cứ vào số lượng và chất lượng các hồ sơ đề cử, Hội đồng Giải 
          thưởng Khoa học Công nghệ Quả Cầu Vàng (gọi tắt Hội đồng Giải thưởng) sẽ đề xuất cụ thể số lượng 
          giải cho từng lĩnh vực, từng đối tượng (đào tạo, nghiên cứu, khởi nghiệp, sản xuất, kinh doanh,...) 
          trình Ban Bí thư Trung ương Đoàn quyết định, nhưng tối đa không quá 10 giải thưởng.
        </p><Typography variant='h5'><strong>5. Quyền lợi của cá nhân được nhận Giải thưởng</strong></Typography>
        <p>- Được nhận giải thưởng (theo Điều 6).</p>
        <p>
          - Được ghi danh và lưu hồ sơ cá nhân trong Hệ thống Cơ sở dữ liệu Tài năng trẻ Việt Nam, được giới 
          thiệu tham gia các sự kiện, diễn đàn tài năng trẻ hoặc một số hoạt động trong nước và quốc tế do 
          Trung ương Đoàn và các bộ, ngành tổ chức.
        </p>
        <p>
          - Được Trung tâm Phát triển Khoa học, Công nghệ và Tài năng trẻ, Quỹ Hỗ trợ Tài năng trẻ Việt Nam, 
          tổ chức Đoàn các cấp, các bộ, ban, ngành liên quan hỗ trợ trong học tập, nghiên cứu và công tác.
        </p>
        <p>- Mỗi cá nhân chỉ được nhận Giải thưởng một lần.</p>
        <Typography variant='h5'><strong>6. Nghĩa vụ của cá nhân được nhận Giải thưởng</strong></Typography>
        <p>
          - Tích cực tham gia, đóng góp nâng cao chất lượng, uy tín của Giải thưởng và là hạt nhân nòng cốt 
          trong các phong trào, các hoạt động tài năng trẻ của Đoàn Thanh niên các cấp.&nbsp;
        </p>
        <p>
          - Có trách nhiệm tham gia, đóng góp xây dựng các chủ trương, chính sách của Đảng, Nhà nước và của 
          Đoàn đối với công tác phát hiện, bồi dưỡng, phát huy Tài năng trẻ Việt Nam.&nbsp;&nbsp;
        </p>
        <Typography variant='h5'><strong>7. Quy định xử lý các trường hợp vi phạm</strong></Typography>
        <p>
          Sau khi nhận Giải thưởng, nếu phát hiện các nhân được nhận Giải thưởng vi phạm quy chế Giải thưởng 
          (làm giả tài liệu, khai báo không đúng thành tích, thành tích có dấu hiệu sai phạm về liêm chính khoa 
          học do cơ quan chuyên môn xác nhận, có hành vi gian lận trong quá trình bầu chọn trực tuyến hoặc gây 
          ảnh hưởng tới tính minh bạch và chất lượng của Giải thưởng...), đơn vị thường trực Giải thưởng sẽ đề 
          nghị Ban Bí thư Trung ương Đoàn ra quyết định tước bỏ danh hiệu Giải thưởng và thông báo công khai 
          trên các phương tiện thông tin đại chúng.
        </p>
        <Typography variant='h5'><strong>8. Công tác xét chọn Giải thưởng</strong></Typography>
        <br />
        <Typography variant='h6'><i><strong>8.1. Triển khai Giải thưởng</strong></i></Typography>
        <p>
          - Hàng năm, đơn vị Thường trực Giải thưởng có trách nhiệm gửi Quy chế và các văn bản hướng dẫn triển 
          khai tới các tỉnh, thành Đoàn, Đoàn trực thuộc; các bộ, ngành, các viện, trường học, cơ sở nghiên cứu, 
          doanh nghiệp uy tín trên phạm vi toàn quốc; Đại sứ quán, Ban Cán sự Đoàn TNCS Hồ Chí Minh, Hội Thanh 
          niên, Sinh viên Việt Nam ở nước ngoài và đăng trên Cổng thông tin điện tử Tài năng trẻ Quốc gia 
          (<a href="http://tainangviet.vn/">http://tainangviet.vn/</a>).
        </p>
        <p>
          - Báo Tiền phong, Báo Thanh niên, Trung tâm Truyền hình Thanh niên, Ban Phát thanh Thanh thiếu nhi 
          và các cơ quan báo chí của hệ thống Đoàn có trách nhiệm lên kế hoạch truyền thông cho giải thưởng.
        </p>
        <p>
          - Các cơ quan, đơn vị tìm kiếm, giới thiệu và hướng dẫn các cá nhân được giới thiệu đăng ký hồ sơ 
          tham dự Giải thưởng; đồng thời phối hợp với các cơ quan báo chí địa phương, ngành tổ chức tuyên 
          truyền về Giải thưởng và các gương mặt tài năng trẻ tham dự Giải thưởng.&nbsp;
        </p>
        <p>
          - Đơn vị thường trực Giải thưởng tiếp nhận, hướng dẫn hoàn thiện và tổng hợp hồ sơ đăng ký 
          tham gia Giải thưởng.
        </p>
        <Typography variant='h6'><i><strong>8.2. Đăng ký tham gia Giải thưởng&nbsp;&nbsp;</strong></i></Typography>
        <p>
          Ứng viên đăng ký tham gia Giải thưởng bằng hình thức trực tuyến trên Cổng thông tin điện tử Tài năng trẻ Quốc 
          gia (<a href="http://tainangviet.vn/">http://tainangviet.vn/</a>). 
          Các tài liệu, thông tin cần cung cấp trên hệ thống đăng ký gồm:
        </p>
        <p>
          - Công văn của cơ quan, đơn vị đề cử, giới thiệu (bản scan, có ký tên, đóng dấu). Cơ quan, đơn vị 
          đề cử, giới thiệu bao gồm: tỉnh, thành Đoàn, Đoàn trực thuộc; Trung ương Hội Liên hiệp Thanh niên 
          Việt Nam, Trung ương Hội Sinh viên Việt Nam; các bộ, ngành, các viện nghiên cứu, trường học, doanh 
          nghiệp, cơ quan báo chí; Đại sứ quán Việt Nam hoặc Ban cán sự Đoàn Thanh niên, Hội Thanh niên, Sinh 
          viên Việt Nam ở nước ngoài (đối với công dân Việt Nam ở nước ngoài); Đơn vị thường trực Giải thưởng 
          trực tiếp đề cử.&nbsp;
        </p>
        <p>
          - Kê khai thành tích cá nhân và đính kèm bản scan thành tích cá nhân có xác nhận của thủ trưởng cơ 
          quan, đơn vị quản lý trực tiếp (bản scan, có ký tên, đóng dấu).
        </p>
        <p>
          - Bản scan các tài liệu chứng minh về thành tích, kết quả nghiên cứu, áp dụng thực tiễn như: bằng 
          phát minh, sáng chế, giải pháp hữu ích; xác nhận, nhận xét, đánh giá về hiệu quả đạt được của các 
          đơn vị áp dụng công trình và các bản đề - mô (demo), ảnh tư liệu, các hình ảnh chứng minh thành 
          tích của cá nhân trong hoạt động và công tác của lĩnh vực đăng ký tham gia xét Giải thưởng.
        </p>
        <p>
          - Báo cáo tóm tắt công trình nghiên cứu, giải pháp công nghệ, sáng chế… trong lĩnh vực đăng ký xét 
          Giải thưởng đã được thẩm định, nghiệm thu đánh giá.
        </p>
        <p>
          - Video giới thiệu về cá nhân, các thành tích nổi bật, giá trị các nghiên cứu khoa học của ứng viên 
          (không quá 2 phút).
        </p>
        <p>- Ảnh chân dung ứng viên.</p>
        <Typography variant='h6'><i><strong>8.3. Hội đồng Giải thưởng&nbsp;</strong></i></Typography>
        <p>
          - Hội đồng Giải thưởng KHCN Quả Cầu Vàng (gọi tắt là Hội đồng Giải thưởng) là cơ quan tham mưu xét 
          chọn Giải thưởng do Ban Bí thư Trung ương Đoàn ra quyết định thành lập theo đề xuất của đơn vị thường 
          trực Giải thưởng.&nbsp;
        </p>
        <p>
          - Hội đồng có nhiệm vụ xét chọn các cá nhân có thành tích xuất sắc tiêu biểu trong từng lĩnh vực 
          trình Ban Bí thư Trung ương Đoàn xem xét quyết định trao tặng Giải thưởng.&nbsp;
        </p>
        <p>
          - Hội đồng bao gồm những cán bộ quản lý, chuyên gia có trình độ chuyên sâu về các lĩnh vực xét 
          giải thưởng và không đăng ký tham gia Giải thưởng năm đó. Hội đồng có cơ cấu như sau:
        </p>
        <p>+ Chủ tịch Hội đồng: Đại diện Ban Bí thư Trung ương Đoàn TNCS Hồ Chí Minh.</p>
        <p>
          + Các Phó Chủ tịch Hội đồng: Là đại diện lãnh đạo cấp vụ chuyên môn của Bộ Khoa học và Công 
          nghệ và Giám đốc Trung tâm Phát triển Khoa học, Công nghệ và Tài năng trẻ.&nbsp;
        </p>
        <p>
          + Các thành viên Hội đồng: Là đại diện các bộ, ngành liên quan, các chuyên gia, nhà khoa học, 
          nhà quản lý thuộc các lĩnh vực xét Giải thưởng và đại diện một số cơ quan truyền thông, nhà tài trợ.
        </p>
        <Typography variant='h6'><i><strong>8.4. Ban Thư ký Giải thưởng</strong></i></Typography>
        <p>
          - Ban Thư ký Giải thưởng là cơ quan tham mưu giúp việc cho Hội đồng Giải thưởng do Ban Bí thư Trung 
          ương Đoàn ra quyết định thành lập. Thành viên Ban Thư ký là các chuyên gia, nhà khoa học (không đăng 
          ký tham gia Giải thưởng của năm đó) có chuyên môn sâu trong lĩnh vực xét Giải thưởng do đơn vị thường 
          trực Giải thưởng đề xuất.
        </p>
        <p>
          - Ban Thư ký có nhiệm vụ phân loại, kiểm tra, rà soát hồ sơ và có báo 
          cáo đề xuất trình Hội đồng Giải thưởng xem xét. Nhiệm vụ cụ thể của các thành viên Ban thư ký do 
          Trưởng Ban thư ký phân công. Ban Thư ký có thể được chia thành các tổ chuyên môn theo các lĩnh vực 
          xét Giải thưởng.&nbsp;
        </p>
                        
        <Typography variant='h5' style={{ color: 'black', textAlign: 'end', fontFamily: "SFProDisplay-Bold" }}>
          <strong>ĐƠN VỊ THƯỜNG TRỰC GIẢI THƯỞNG</strong>
        </Typography>
    </div>
  );
}
