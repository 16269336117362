import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { getDoi } from '../../../../utils';
import { userActions } from '../../../../actions';
import { REPORTS_CONSTANTS } from '../../../../constants';
import { useScreenSize } from '../../../../hooks/useScreenSize';

const { TYPE_OPTIONS: { ALL } } = REPORTS_CONSTANTS;

export const reportForm = (values, mobileMode, disabled, doiEvents, typeOptions = ALL) => ([
  { key: 'link', width: 9, label: 'Link DOI', value: values?.link, disabled },
  {
    key: 'doi',
    width: 3,
    label: doiEvents.loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Nhập từ DOI',
    type: 'button',
    color: 'secondary',
    variant: 'contained',
    disabled,
    onClick: async () => {
      doiEvents.init();
      const doi = await getDoi(values?.link);
      if (doi.success) {
        doiEvents.onSuccess(doi);
      } else {
        doiEvents.onFailure(doi);
      }
    },
  },
  { key: 'title', width: 12, height: 1, label: 'Tên công bố', value: values?.title, disabled, required: true },
  { key: 'conferenceTitle', width: 12, height: 1, label: 'Tên hội thảo', value: values?.conferenceTitle, disabled, required: true },
  { key: 'publisher', width: 12, label: 'Nhà xuất bản', value: values?.publisher, disabled, required: true },
  { key: 'numberMain', width: 12, label: 'Số lượng tác giả chính (Tác giả đầu, tác giả liên hệ)', value: values?.numberMain, disabled, type: 'int', required: true },
  { key: 'publishYear', width: mobileMode ? 7 : 3, label: 'Năm xuất bản',type: 'int', value: values?.publishYear, disabled, required: true },
  { key: 'page', width: mobileMode ? 5 : 3, label: 'Trang', value: values?.page, disabled },
  { key: 'issn', width: mobileMode ? 5 : 3, label: 'ISSN/ISBN', value: values?.issn, disabled },
  { key: 'numberAuthor', width: mobileMode ? 7 : 3, label: 'Số tác giả', type: 'int', value: values?.numberAuthor, disabled, required: true },
  { key: 'author', width: 12, height: 1, label: 'Danh sách tác giả', value: values?.author, disabled, required: true },
  { key: 'role', width: mobileMode ? 7 : 6, label: 'Vai trò', value: values?.role, options: REPORTS_CONSTANTS.ROLE_OPTIONS, disabled, required: true },
  { key: 'type', width: mobileMode ? 5 : 6, label: 'Phân loại', value: values?.type, options: typeOptions, disabled, required: true },
  { key: 'description', width: 12, height: 1, label: 'Ghi chú', value: values?.description, disabled },
  {
    key: 'previewFile',
    width: 12,
    label: (
      <>
        <Typography variant='body1' style={{ fontSize: 17 }}>
          <b>Upload file minh chứng PDF</b>
        </Typography>
        <Typography variant='body2' color='secondary'>
          (Trong trường hợp không có link DOI
        </Typography>
        <Typography variant='body2' color='secondary'>
          hoặc cần upload minh chứng Báo cáo xuất sắc và Poster xuất sắc)
        </Typography>
      </>
    ),
    acceptedFiles: ["application/pdf"],
    type: 'file',
    value: values?.previewFile,
    disabled,
  }, 
]);

export default function BaoCaoForm(props) {
	const dispatch = useDispatch();
  const isMobileMode = useScreenSize();
  const { values, setValues, disabled } = props;
  const [loadingDoi, setLoadingDoi] = useState(false);

  const doiEvents = {
    loading: loadingDoi,
    init: () => setLoadingDoi(true),
    onSuccess: (doi) => {
      const { data: { info, journal } } = doi;
      setValues({
        ...values,
          conferenceTitle: journal?.type === 'conference and proceedings' ? journal?.title : null,
          type: journal?.coreCollection === 'Scopus' ? 'scopus' : journal?.sjrBestQuartile.toLowerCase(),
          link: info?.URL,
          title: info?.title,
          publisher: info?.publisher,
          publishYear: info?.issued['date-parts']?.[0]?.[0],
          page: info?.page,
          issn: info?.ISSN?.[0],
          numberAuthor: info?.author?.length,
          author: info?.author?.map((e) => (`${e.given} ${e.family}`)).join(', ')
      });
      setLoadingDoi(false);
      dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
    },
    onDoiFailure: (doi) => {
      setLoadingDoi(false);
      dispatch(userActions.notification({ message: doi.message, type: 'error' }));
    }
  };

  const textForm = reportForm(values, isMobileMode, disabled, doiEvents);

  return (
    <KPTextform {...props} textForm={textForm} />
  );
}