import { useState, useEffect } from 'react';
import { APP_SCENE, CATEGORY } from '../constants/enums';

export const useScene = () => {
  const [scene, setScene] = useState({
    isQuaCauVang: false,
    isKhueVanCac: false,
    isGiaiThuongNuSinh: false,
  });

  useEffect(() => {
    setScene({
      isQuaCauVang: APP_SCENE === CATEGORY.QCV,
      isKhueVanCac: APP_SCENE === CATEGORY.KVC,
      isGiaiThuongNuSinh: APP_SCENE === CATEGORY.GTNS,
    });
  }, [APP_SCENE]);

  return scene;
}