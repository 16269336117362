import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

function Loading(props) {
  return (
    <Backdrop open={props.open || false} style={{ zIndex: 2000 }}>
      <CircularProgress style={{ color: 'white' }} />
    </Backdrop>
  );
}

export default Loading;
