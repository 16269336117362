import { HEADERS } from '.';

export const RESEARCH_API = {
  getAllResearch: () => ({
    endPoint: '/api/v1/sci/all',
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  getResearchByProfileId: (id) => ({
    endPoint: `/api/v1/sci/${id}/researches`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  insertResearch: () => ({
    endPoint: '/api/v1/sci/create',
    headers: HEADERS.TOKEN_HEADER,
    method: 'POST',
  }),
  updateResearch: () => ({
    endPoint: '/api/v1/sci/update',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  deleteResearch: (id) => ({
    endPoint: `/api/v1/sci/delete/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'DELETE',
  }),
  updateResearchAccepted: () => ({
    endPoint: '/api/v1/formSciResearch/updateScore_Accepted',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
};

export const RESEARCH_TYPE = {
  GET_ALL_RESEARCH_LOADING: 'GET_ALL_RESEARCH_LOADING',
  GET_ALL_RESEARCH_SUCCESS: 'GET_ALL_RESEARCH_SUCCESS',
  GET_ALL_RESEARCH_FAILURE: 'GET_ALL_RESEARCH_FAILURE',

  GET_RESEARCH_BY_PROFILE_ID_LOADING: 'GET_RESEARCH_BY_PROFILE_ID_LOADING',
  GET_RESEARCH_BY_PROFILE_ID_SUCCESS: 'GET_RESEARCH_BY_PROFILE_ID_SUCCESS',
  GET_RESEARCH_BY_PROFILE_ID_FAILURE: 'GET_RESEARCH_BY_PROFILE_ID_FAILURE',

  INSERT_RESEARCH_LOADING: 'INSERT_RESEARCH_LOADING',
  INSERT_RESEARCH_SUCCESS: 'INSERT_RESEARCH_SUCCESS',
  INSERT_RESEARCH_FAILURE: 'INSERT_RESEARCH_FAILURE',

  UPDATE_RESEARCH_LOADING: 'UPDATE_RESEARCH_LOADING',
  UPDATE_RESEARCH_SUCCESS: 'UPDATE_RESEARCH_SUCCESS',
  UPDATE_RESEARCH_FAILURE: 'UPDATE_RESEARCH_FAILURE',

  DELETE_RESEARCH_LOADING: 'DELETE_RESEARCH_LOADING',
  DELETE_RESEARCH_SUCCESS: 'DELETE_RESEARCH_SUCCESS',
  DELETE_RESEARCH_FAILURE: 'DELETE_RESEARCH_FAILURE',

  UPDATE_RESEARCH_ACCEPTED_LOADING: 'UPDATE_RESEARCH_ACCEPTED_LOADING',
  UPDATE_RESEARCH_ACCEPTED_SUCCESS: 'UPDATE_RESEARCH_ACCEPTED_SUCCESS',
  UPDATE_RESEARCH_ACCEPTED_FAILURE: 'UPDATE_RESEARCH_ACCEPTED_FAILURE',
};

export const RESEARCH_CONSTANTS = {
  TYPE_OPTIONS: {
    INTERNATIONAL: [
      { key: 'q1', title: 'Q1', value: 'q1' },
      { key: 'q2', title: 'Q2', value: 'q2' },
      { key: 'q3', title: 'Q3', value: 'q3' },
      { key: 'q4', title: 'Q4', value: 'q4' },
      { key: 'scopus', title: 'Scopus', value: 'scopus' },
      { key: 'q5', title: 'Q5', value: 'q5' },
    ],
    DOMESTIC: [
      { key: 'q6', title: 'Q6', value: 'q6' },
    ]
  },
  ROLE_OPTIONS: [
    { key: 'main', title: 'Tác giả chính (Tác giả đầu, tác giả liên hệ)', value: 'main' },
    { key: 'member', title: 'Đồng tác giả', value: 'member' },
  ],
  typeOptionsQTK: [{ key: 'q5', title: 'Q5', value: 'q5' }],
};
