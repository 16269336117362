import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Grid,
	Paper,
	Typography,
} from '@material-ui/core';

import Loading from '../../../../components/CustomComponents/Loading';
import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { formActions } from '../../../../actions';

export default function HoSoNote(props) {
	const dispatch = useDispatch();

	// const role = useSelector(state => state.userReducer.user?.role);
	const loading = useSelector(state => state.formReducer.loading);
	// const applicationList = useSelector(state => state.formReducer.applicationList);
	const applicationDetail = useSelector(state => state.formReducer.focusForm);

	const [values, setValues] = useState({});
	
	const arrayPath = window.location.href.split('/');
	const urlId = parseInt(arrayPath.slice(arrayPath.length -1));
	// const applicationDetail = applicationList.find((e) => e.id === urlId);
	
	// Lưu giá trị
	useEffect(() => {
		if (applicationDetail) {
			var scoreB, scoreC, scoreF, scoreG = 0;

			if (applicationDetail?.gpa >= 3.6) {
				scoreB = 10;
			} else if (applicationDetail?.gpa >= 3.2) {
				scoreB = 7;
			}

			// if (applicationDetail?.majorCourseNumber >= 30) {
			// 	scoreC = 8;
			// } else if (applicationDetail?.majorCourseNumber >= 15) {
			// 	scoreC = 6;
			// } else {
			// 	scoreC = 4;
			// }

			if (applicationDetail?.profile.ethnic === 'Kinh') {
				scoreF = 0;
			} else {
				scoreF = 2;
			}

			if (applicationDetail?.bonus === 'khong') {
				scoreG = 0;
			} else {
				scoreG = 2;
			}

			setValues({
				...values,
				note: applicationDetail?.note,
				scoreA: applicationDetail?.scoreA,
				scoreB: applicationDetail?.scoreB || scoreB,
				scoreC: applicationDetail?.scoreC || scoreC,
				scoreD: applicationDetail?.scoreD,
				scoreE: applicationDetail?.scoreE,
				scoreF: applicationDetail?.scoreF || scoreF,
				scoreG: applicationDetail?.scoreG || scoreG,
			});
		}
	}, [applicationDetail]);


	const saveNote = () => dispatch(formActions.updateBCD({
		...values,
		id: urlId, 
		status: 'need_update',
	}));

	const handleSubmit = () => dispatch(formActions.updateBCD({
		...values,
		id: urlId, 
		status: 'approved',
	}));
	
	const textForm = [
		{ key: 'scoreA', width: 6, label: 'Điểm thành tích khoa học công nghệ', type: 'float', disabled: true, value: values?.scoreA },
		{ key: 'scoreD', width: 6, label: 'Điểm thành tích khen thưởng', type: 'float', value: values?.scoreD },
		{ key: 'scoreE', width: 6, label: 'Điểm thành tích hoạt động đoàn, hội', type: 'float', value: values?.scoreE },
		{ key: 'button-2', width: 6, label: 'Không duyệt hồ sơ', type: 'button', onClick: saveNote },
		{ key: 'submit', width: 6, label: 'Duyệt hồ sơ', type: 'submit' },
	];

	if (applicationDetail?.gpa >= 3.6) {
		textForm.splice(0, 0, {
			key: 'scoreB', width: 6, label: 'Điểm trung bình chung đạt trên 3.6 điểm trở lên', type: 'float', value: values?.scoreB
		});
	} else if (applicationDetail?.gpa >= 3.2) {
		textForm.splice(0, 0, {
			key: 'scoreB', width: 6, label: 'Điểm trung bình chung đạt từ 3.2 - 3.6 điểm', type: 'float', value: values?.scoreB
		});
	} else {
		textForm.splice(0, 0, {
			key: 'scoreB', width: 6, label: 'Điểm trung bình chung nhỏ hơn 3.2', type: 'float', value: values?.scoreB
		});
	}

	// if (applicationDetail?.majorCourseNumber >= 30) {
	// 	textForm.splice(0, 0, {
	// 		key: 'scoreC', width: 6, label: 'Đã học tập trên 30 tín chỉ chuyên ngành', type: 'float', value: values?.scoreC
	// 	});
	// } else if (applicationDetail?.majorCourseNumber >= 15) {
	// 	textForm.splice(0, 0, {
	// 		key: 'scoreC', width: 6, label: 'Đã học tập từ 15 - 30 tín chỉ chuyên ngành', type: 'float', value: values?.scoreC
	// 	});
	// } else {
	// 	textForm.splice(0, 0, {
	// 		key: 'scoreC', width: 6, label: 'Đã học tập dưới 15 tín chỉ chuyên ngành', type: 'float', value: values?.scoreC
	// 	});
	// }

	if (applicationDetail?.profile.ethnic === 'Kinh') {
		textForm.splice(4, 0, {
			key: 'scoreF', width: 6, label: `Ưu tiên (Dân tộc ${applicationDetail?.profile.ethnic})`, type: 'float', value: values?.scoreF
		});
	} else {
		textForm.splice(4, 0, {
			key: 'scoreF', width: 6, label: `Ưu tiên (Dân tộc ${applicationDetail?.profile.ethnic})`, type: 'float', value: values?.scoreF
		});
	}

	if (applicationDetail?.bonus === 'khong') {
		textForm.splice(5, 0, {
			key: 'scoreG', width: 6, label: `Ưu tiên nữ sinh viên khuyết tật (Không)`, type: 'float', value: values?.scoreG
		});
	} else {
		textForm.splice(5, 0, {
			key: 'scoreG', width: 6, label: `Ưu tiên nữ sinh viên khuyết tật (Có)`, type: 'float', value: values?.scoreG
		});
	}

	if (props.isSuperAdmin) return null;

	var score =
		applicationDetail?.scoreA +
		(values?.scoreB || 0) +
		(values?.scoreC || 0) +
		(values?.scoreD || 0) +
		(values?.scoreE || 0) +
		(values?.scoreF || 0) +
		(values?.scoreG || 0);
	score = Math.round(score * 100) / 100;

  if (loading) return null;

	return (
		<Paper style={{ marginTop: 12, marginBottom: 24, padding: 8 }}>
			<Grid container justifyContent='center' style={{ margin: 16, width: 'calc(100% - 32px)' }}>
				<Grid item xs={12} lg={3}>
					<Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
						<Typography variant='h1' style={{ color: '#ee6064' }}>
							<b>{score}</b>
						</Typography>
						<Typography variant='h5'>Điểm của ứng viên</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} lg={9}>
					<KPTextform
						textForm={textForm}
						handleSubmit={handleSubmit}
            values={values}
            setValues={setValues}
					/>
				</Grid>
				<Loading open={loading} />
			</Grid>
		</Paper>
	)
}