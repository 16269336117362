import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { RESEARCHPROJECTS_CONSTANTS } from '../../../../constants';
import { Box } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

export const researchProjectForm = (values, disabled) => ([
  { key: 'title', width: 12, height: 1, label: 'Tên đề tài', disabled, required: true },
  { key: 'start_at', width: 6, label: 'Thời gian thực hiện',type:'date', disabled, required: true },
  { key: 'finish_at', width: 6, label: 'Thời gian kết thúc',type:'date', disabled, required: true },
  { key: 'unit', width: 12, height: 1, label: 'Cơ quan, tổ chức chủ trì', disabled, required: true },
  {
    key: 'level',
    width: 12,
    label: 'Cấp đề tài',
    options: RESEARCHPROJECTS_CONSTANTS.LEVEL_OPTIONS,
    disabled,
    required: true,
    note: (
      <Box display="flex" alignItems="center">
        <InfoOutlined style={{ width: 16 }} />
        &nbsp;<b>Lưu ý:</b>&nbsp;Chỉ tính những đề tài đã được nghiệm thu!
      </Box>
    )
  },
  { key: 'numberMain', width: 6, label: 'Số tác giả chính', type: 'int', disabled, required: true },
  {
    key: 'type',
    width: 6,
    label: 'Vai trò',
    options: RESEARCHPROJECTS_CONSTANTS.TYPO_OPTIONS,
    disabled,
    required: values?.level !== 'domestic'
  },
  { key: 'previewFile', width: 12, type: 'file', value: values?.link, disabled, required: true },
]);

export default function DuAnForm(props) {
	const { values, disabled } = props;

  const textForm = researchProjectForm(values, disabled);
  // if (values?.level !== 'domestic') textForm.splice(6, 1);

  return <KPTextform {...props} textForm={textForm} />;
}
