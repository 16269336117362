import React from 'react';

import SangCheButton from '../../../containers/QCV/BangSangChe/SangcheButton';
import SangCheTable from '../../../containers/QCV/BangSangChe/SangCheTable';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';

export default function BangSangChe() {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  return (
    <>
      <SangCheButton filterType={filterType} />
      <ThanhTichAlert />
      <SangCheTable filterType={filterType} type='update' />
    </>
  );
}
