import { HEADERS } from '.';

export const PROFILE_API = {
  getProfileByUserId: (user_id) => ({
    endPoint: `/api/v1/profile/user/${user_id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  upsertProfile: () => ({
    endPoint: '/api/v1/profile/upsert',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  getProfileList: () => ({
    endPoint: '/api/v1/form/list',
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  getProfileFilterForm: () => ({
    endPoint: '/api/v1/form/filter',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  })
}

export const PROFILE_TYPE = {
  GET_PROFILE_BY_USER_ID_LOADING: 'GET_PROFILE_BY_USER_ID_LOADING',
  GET_PROFILE_BY_USER_ID_SUCCESS: 'GET_PROFILE_BY_USER_ID_SUCCESS',
  GET_PROFILE_BY_USER_ID_FAILURE: 'GET_PROFILE_BY_USER_ID_FAILURE',

  UPSERT_PROFILE_LOADING: 'UPSERT_PROFILE_LOADING',
  UPSERT_PROFILE_SUCCESS: 'UPSERT_PROFILE_SUCCESS',
  UPSERT_PROFILE_FAILURE: 'UPSERT_PROFILE_FAILURE',

  GET_PROFILE_LIST_LOADING: 'GET_PROFILE_LIST_LOADING',
  GET_PROFILE_LIST_SUCCESS: 'GET_PROFILE_LIST_SUCCESS',
  GET_PROFILE_LIST_FAILURE: 'GET_PROFILE_LIST_FAILURE',

  GET_PROFILE_FILTER_FORM_LOADING: 'GET_PROFILE_FILTER_FORM_LOADING',
  GET_PROFILE_FILTER_FORM_SUCCESS: 'GET_PROFILE_FILTER_FORM_SUCCESS',
  GET_PROFILE_FILTER_FORM_FAILURE: 'GET_PROFILE_FILTER_FORM_FAILURE',
};
