import React from 'react';
import { ACHIEVEMENT_TYPE, FORM_CONSTANTS, KEYS } from '../constants';

export const kvcAchievementFields = ({ achievementData, actions }) => [
  {
    index: '4',
    title: 'Thành tích cá nhân',
    children: [
      {
        index: '4.1',
        actions,
        type: ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL,
        title: (
          <>
            Bài báo khoa học thuộc ngành/liên ngành xét trao giải thưởng được đăng trên tạp chí quốc tế&nbsp;
            <span style={{ fontWeight: 'normal' }}>
              (chỉ khai các bài báo đăng tạp chí trong cơ sở dữ liệu của Scopus, 
              Web of Science hoặc được xuất bản bởi Nhà xuất bản của 500 trường 
              đại học hàng đầu thế giới theo xếp hạng của Times Higher Education 
              World University Rankings- THE)
            </span>
          </>
        ),
        data: achievementData?.[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
      },
      {
        index: '4.2',
        actions,
        type: ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL,
        title: (
          <>
            Bài báo khoa học thuộc ngành/liên ngành xét trao giải thưởng được in trong kỷ yếu hội thảo quốc tế&nbsp;
            <span style={{ fontWeight: 'normal' }}>
              (chỉ khai các bài báo đã xuất bản có ISBN và được tính trong cơ sở dữ liệu Scopus, web of science hay được xuất bản ở nước ngoài)
            </span>
          </>
        ),
        data: achievementData?.[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL],
      },
      {
        index: '4.3',
        actions,
        type: ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL,
        title: (
          <>
            Sách chuyên khảo, giáo trình, sách tham khảo, chương sách thuộc ngành/liên ngành xét trao giải thưởng được Nhà xuất bản uy tín trên thế giới xuất bản&nbsp;
            <span style={{ fontWeight: 'normal' }}>
              (chỉ khai các xuất bản thuộc các Nhà xuất bản có trong dữ liệu của Web of Sciences)
            </span>
          </>
        ),
        data: achievementData?.[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL],
      },
      {
        index: '4.4',
        actions,
        type: ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC,
        title: (
          <>
            Bài báo khoa học thuộc ngành/liên ngành xét trao giải thưởng được đăng trên tạp chí khoa học trong nước&nbsp;
            <span style={{ fontWeight: 'normal' }}>
              (chỉ khai các bài báo đăng trên tạp chí thuộc danh mục tạp chí được tính 0,75 đến 1 điểm cho ngành mà ứng viên đăng ký theo Hội đồng Giáo sư Nhà nước).
            </span>
          </>
        ),
        data: achievementData?.[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
      },
      {
        index: '4.5',
        actions,
        type: ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC,
        title: 'Báo cáo khoa học thuộc ngành/liên ngành xét trao giải thưởng được đăng trên hội thảo nghị cấp ngành hoặc cấp toàn quốc',
        data: achievementData?.[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC],
      },
      {
        index: '4.6',
        actions,
        type: ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC,
        title: 'Sách chuyên khảo, chương sách, giáo trình thuộc ngành/liên ngành xét trao giải thưởng được Nhà xuất bản uy tín trong nước xuất bản',
        data: achievementData?.[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC],
      },
      {
        index: '4.7',
        actions,
        type: ACHIEVEMENT_TYPE.RESEARCH_PROJECT,
        title: 'Chương trình, dự án, đề tài nghiên cứu khoa học cấp Nhà nước, cấp Bộ và tương đương đã nghiệm thu',
        data: achievementData?.[ACHIEVEMENT_TYPE.RESEARCH_PROJECT],
      },
    ]
  }
];

export const kvcActivityFields = ({
  isMobileMode,
  personalProcess,
  setPersonalProcess,
  otherAchievement,
  setOtherAchievement,
  personalAchievement,
  setPersonalAchievement,
  socialActivity,
  setSocialActivity,
}) => [
  {
    key: KEYS.PERSONAL_PROCESS,
    values: personalProcess,
    setValues: setPersonalProcess,
    label: "1. Quá trình học tập, công tác",
    fields: [
      { key: "startAt", width: isMobileMode ? 12 : 2, label: "Thời gian bắt đầu", type: "date", required: true },
      { key: "finishAt", width: isMobileMode ? 12 : 2, label: "Thời gian kết thúc", type: "date", required: true },
      { key: "department", width: isMobileMode ? 12 : 3, label: "Đơn vị công tác", required: true },
      { key: "works", width: isMobileMode ? 12 : 4, label: "Công việc đảm nhiệm", required: true },
    ],
  },
  {
    key: KEYS.OTHER_ACHIEVEMENT,
    values: otherAchievement,
    setValues: setOtherAchievement,
    label: "2. Thành tích khen thưởng (Bằng khen, giấy khen...)",
    fields: [
      { key: `${KEYS.OTHER_ACHIEVEMENT}Content`, width: isMobileMode ? 12 : 4, label: "Nội dung khen thưởng", required: true },
      { key: `${KEYS.OTHER_ACHIEVEMENT}Type`, width: isMobileMode ? 12 : 3, label: "Cấp khen thưởng", options: FORM_CONSTANTS.LEVEL_INPUT_OPTIONS, required: true },
      { key: `${KEYS.OTHER_ACHIEVEMENT}PreviewFile`, label: "Minh chứng:", width: isMobileMode ? 12 : 4, type: "file", required: true },
    ],
  },
  {
    key: "muc_3",
    title: '3. Hoạt động cộng đồng',
    children: [
      {
        key: KEYS.PERSONAL_ACHIEVEMENT,
        values: personalAchievement,
        setValues: setPersonalAchievement,
        label: '3.1. Hoạt động chia sẻ tri thức, tập huấn, đào tạo,...',
        fields: [
          { key: `${KEYS.PERSONAL_ACHIEVEMENT}Content`, width: isMobileMode ? 12 : 7, label: 'Hình thức và nội dung thành tích', required: true },
          { key: `${KEYS.PERSONAL_ACHIEVEMENT}PreviewFile`, width: isMobileMode ? 12 : 4, label: 'Minh chứng', type: 'file', required: true },
        ],
      },
      {
        key: KEYS.SOCIAL_ACTIVITY,
        values: socialActivity,
        setValues: setSocialActivity,
        label: '3.2. Hoạt động tình nguyện vì cộng đồng nói chung',
        fields: [
          { key: `${KEYS.SOCIAL_ACTIVITY}Content`, width: isMobileMode ? 12 : 7, label: 'Hình thức và nội dung thành tích', required: true },
          { key: `${KEYS.SOCIAL_ACTIVITY}PreviewFile`, width: isMobileMode ? 12 : 4, label: 'Minh chứng', type: 'file', required: true },
        ],
      },
    ]
  }
];