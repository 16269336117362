import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Chip, Button, Typography } from '@material-ui/core';

import KPTable from '../../../../components/KPTComponents/KPTable';

import BaiBaoUpdate from '../BaiBaoUpdate';
import PopupMenu from '../../../../components/CustomComponents/PopupMenu';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
import DuyetThanhTichModal from '../../../../components/CustomComponents/DuyetThanhTichModal';

import { researchActions } from '../../../../actions';
import { RESEARCH_CONSTANTS } from '../../../../constants';

function BaiBaoTable(props) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.researchReducer.loading);
  const totalResearchList = useSelector(state => state.researchReducer.totalResearchList);
  const personalResearchList = useSelector(state => state.researchReducer.personalResearchList);

  const [values, setValues] = useState(null);
  const [duyetThanhTich, setDuyetThanhTich] = useState(null);

  const [detail, setDetail] = useState(null);
  const [preview, setPreview] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(false);

  const openDeleteMenu = (e, id) => {
    setDeleteId(id);
    setDeleteMenuAnchor(e.currentTarget);
  }
  const closeDeleteMenu = () => {
    setDeleteId(null);
    setDeleteMenuAnchor(null);
  }

  const handleAccepted = (row = duyetThanhTich) => {
    dispatch(researchActions.updateResearchAccepted(
      [{
        id: row.formScientificResearchId,
        accepted: !row.thanhTichAccepted,
        score: values?.score || 0,
        note: values?.note || '',
      }]
    ));
    setDuyetThanhTich(null);
    setValues(null);
  }

  const handleDelete = () => {
    dispatch(researchActions.deleteResearch(deleteId));
    closeDeleteMenu();
  }
  
  const mobileMode = window.innerWidth <= 768;

  var tableRows = [];
  if (props?.rows) {
    tableRows = props?.rows;
  } else {
    tableRows = props.filterType === 'person' ? personalResearchList : totalResearchList;
  }
  tableRows = tableRows?.filter((e) => e?.filterType === props.form);

  var columns = [
    { title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
    {
      title: <b>Tiêu đề Bài báo</b>,
      dataIndex: 'title',
      width: props.type === 'update' ? '25%' : '30%',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    { title: <b>Tên tạp chí</b>, dataIndex: 'journalTitle', width: '12%' },
    { title: <b>NXB</b>, dataIndex: 'publisher', width: '12%' },
    { title: <b>Danh sách tác giả</b>, dataIndex: 'author', width: '20%' },
    {
      title: <b>Vai trò</b>,
      dataIndex: 'role',
      width: '20%',
      filters: [
        { text: 'Tác giả chính (hoặc tác giả liên hệ)', value: 'main' },
        { text: 'Đồng tác giả', value: 'member' }
      ],
      onFilter: (value, row) => row.role === value,
      render: (value) => value === 'main' ? 'Tác giả chính' : 'Đồng tác giả',
    },
    {
      title: <b>Tình trạng</b>,
      dataIndex: 'thanhTichAccepted',
      width: '15%',
      filters: [
        { text: 'Đã được duyệt', value: true },
        { text: 'Chưa được duyệt', value: false }
      ],
      onFilter: (value, row) => row.thanhTichAccepted === value,
      render: (value) => (
        <Chip
          size='small'
          variant='outlined'
          color={value ? 'primary' : 'default'}
          label={`${value ? 'Đã' : 'Chưa'} duyệt`}
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'cc-actions',
      width: '15%',
      render: (_, row) => (
        <>
          <Typography
            variant='body2'
            color='primary'
            className='actions-button'
            onClick={() => setDetail({...row, link: row?.link.includes('/storage/awardRegister/profile') ? undefined : row?.link })}
          >
            Chi tiết
          </Typography>
          {!(props.type === 'accept') && (
            <Typography
              variant='body2'
              color='secondary'
              className='actions-button'
              onClick={(e) => openDeleteMenu(e, row.id)}
            >
              Xoá
            </Typography>
          )}
          {row?.link.includes('doi') && (
            <a target='_blank' rel='noreferrer' href={row.link}>Link DOI</a>
          )}
          {row?.preview.includes('/storage/awardRegister/profile') && (
            <Typography variant='body2' className='actions-button' onClick={() => setPreview(row?.preview)}>Minh chứng</Typography>
          )}
          {(props.type === 'accept') && (
            <Typography
              variant='body2'
              color={row?.thanhTichAccepted ? 'secondary' : 'primary'}
              className='actions-button'
              onClick={() => {
                if (row?.thanhTichAccepted) handleAccepted(row); else setDuyetThanhTich(row);
              }}
            >
              {`${row?.thanhTichAccepted ? 'Huỷ xác' : 'Xác'} nhận`}
            </Typography>
          )}
        </>
      ),
    },
  ];

  if (props.type === 'accept') {
    columns = [
      { ...columns[0] },
      {
        title: <b>Thông tin thành tích</b>,
        dataIndex: 'id',
        width: '50%',
        render: (_, row) => (
          <>
            <Typography variant='body2'><b>Tên bài báo: </b>{row.title}</Typography>
            <Typography variant='body2'><b>Danh sách tác giả: </b>{row.author} {`(${row.numberAuthor})`}</Typography>
            <Typography variant='body2'><b>Nhà xuất bản: </b>{row.publisher}</Typography>
            <Typography variant='body2'><b>Tên tạp chí: </b>{row.journalTitle}</Typography>
            <Typography variant='body2'>
              <b>Xếp hạng: </b>
              {[
                ...RESEARCH_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
                ...RESEARCH_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
              ].find((e) => e.value === row.type)?.title}
            </Typography>
            <Typography variant='body2'>
              <b>Vai trò: </b>
              {row.role === 'main' ? 'Tác giả chính' : 'Đồng tác giả'}
            </Typography>
          </>
        ) 
      },
      { title: <b>Điểm</b>, dataIndex: 'score', width: '5%' },
      { title: <b>Ghi chú</b>, dataIndex: 'note', width: '15%' },
      { ...columns[6], width: '13%' },
      { ...columns[7], width: '12%' },
    ];
  }
  if (props.type === 'update') {
    if (mobileMode) {
      columns = [
        {...columns[1], title: <b>Tiêu đề</b>, width: '35%'},
        {...columns[3], width: '25%'},
        {...columns[5], width: '20%'},
        {...columns[7], width: '20%'},
      ];
    } else {
      columns = [
        {...columns[0], width: '5%'},
        {...columns[1], width: '25%'},
        {...columns[2], width: '15%'},
        {...columns[3], width: '10%'},
        {...columns[4], width: '25%'},
        {...columns[5], width: '10%'},
        {...columns[7], width: '10%'},
      ];
    }
  }

  return (
    <Grid item xs={12}>
      <KPTable
        nonBorder={props.type === 'accept'}
        columns={columns}
        rows={tableRows}
        loading={loading}
      />

      <BaiBaoUpdate form={props.form} detail={detail} onClose={() => setDetail(null)} />

      <PopupMenu
        position='center'
        anchorEl={deleteMenuAnchor}
        handleClose={closeDeleteMenu}
        render={
          <div style={{ padding: 8 }}>
            <Typography style={{ paddingBottom: 16 }}>Xác nhận xoá bài báo khoa học?</Typography>
            <Button variant='outlined' color='default' style={{ width: 'calc(50% - 4px)',fontSize:'12px' }} onClick={closeDeleteMenu}>Huỷ</Button>
            <Button variant='contained' color='secondary' style={{ marginLeft: 8, width: 'calc(50% - 4px)',fontSize:'12px' }} onClick={handleDelete}>Xác nhận</Button>
          </div>
        }
      />

      <PreviewModal
        open={!!preview}
        url={preview}
        handleClose={() => setPreview(null)}
      />

      <DuyetThanhTichModal
        type='formScientificResearch'
        info={duyetThanhTich}
        values={values}
        setValues={setValues}
        open={!!duyetThanhTich}
        handleClose={() => setDuyetThanhTich(null)}
        handleSubmit={handleAccepted}
      />
    </Grid>
  );
}

export default BaiBaoTable;
