import { apiCall } from '../utils';
import { SOLUTIONS_API, SOLUTIONS_TYPE } from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const solutionActions = {
  getAllSolutions,
  insertSolution,
  updateSolution,
  deleteSolution,
  updateSolutionAccepted,
  getSolutionsByProfileId,
};

function getAllSolutions() {
  return async (dispatch) => {
    dispatch(loading());

    const api = SOLUTIONS_API.getAllSolutionsList();
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy bảng sáng chế thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy bảng sáng chế thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: SOLUTIONS_TYPE.GET_ALL_SOLUTION_LOADING };
  }
  function success(payload) {
    return { type: SOLUTIONS_TYPE.GET_ALL_SOLUTION_SUCCESS, payload };
  }
  function failure(error) {
    return { type: SOLUTIONS_TYPE.GET_ALL_SOLUTION_FAILURE, error };
  }
}

function getSolutionsByProfileId(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = SOLUTIONS_API.getSolutionByProfileId(id);
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy bảng sáng chế thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy bảng sáng chế thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: SOLUTIONS_TYPE.GET_SOLUTION_BY_PROFILE_ID_LOADING };
  }
  function success(payload) {
    return { type: SOLUTIONS_TYPE.GET_SOLUTION_BY_PROFILE_ID_SUCCESS, payload };
  }
  function failure(error) {
    return { type: SOLUTIONS_TYPE.GET_SOLUTION_BY_PROFILE_ID_FAILURE, error };
  }
}

function insertSolution(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = SOLUTIONS_API.insertSolution();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data[0]));
      dispatch(notification({ message: 'Thêm sáng chế thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm sáng chế thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: SOLUTIONS_TYPE.INSERT_SOLUTION_LOADING };
  }
  function success(payload) {
    return { type: SOLUTIONS_TYPE.INSERT_SOLUTION_SUCCESS, payload };
  }
  function failure(error) {
    return { type: SOLUTIONS_TYPE.INSERT_SOLUTION_FAILURE, error };
  }
}

function updateSolution(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = SOLUTIONS_API.updateSolution();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      dispatch(formActions.updateFocusFormByData('formSolution', 'solution', response.data.data));
      dispatch(notification({ message: 'Chỉnh sửa bảng sáng chế thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Chỉnh sửa bảng sáng chế thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: SOLUTIONS_TYPE.UPDATE_SOLUTION_LOADING };
  }
  function success(payload) {
    return { type: SOLUTIONS_TYPE.UPDATE_SOLUTION_SUCCESS, payload };
  }
  function failure(error) {
    return { type: SOLUTIONS_TYPE.UPDATE_SOLUTION_FAILURE, error };
  }
}

function updateSolutionAccepted(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = SOLUTIONS_API.updateSolutionAccepted();
    const { response, error } = await apiCall({ ...api, payload });

    const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      dispatch(formActions.updateFocusForm('formSolution', response.data.data));
      dispatch(notification({ message: `${noti} sáng chế/đề tài khoa học thành công`, type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: `${noti} sáng chế/đề tài khoa học thất bại`, type: 'error' }));
    }
  };

  function loading() {
    return { type: SOLUTIONS_TYPE.UPDATE_SOLUTION_ACCEPTED_LOADING };
  }
  function success(payload) {
    return { type: SOLUTIONS_TYPE.UPDATE_SOLUTION_ACCEPTED_SUCCESS, payload };
  }
  function failure(error) {
    return { type: SOLUTIONS_TYPE.UPDATE_SOLUTION_ACCEPTED_FAILURE, error };
  }
}

function deleteSolution(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = SOLUTIONS_API.deleteSolution(id);
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200) {
      dispatch(success(id));
      dispatch(notification({ message: 'Xoá bảng sáng chế thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Xoá bảng sáng chế thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: SOLUTIONS_TYPE.DELETE_SOLUTION_LOADING };
  }
  function success(id) {
    return { type: SOLUTIONS_TYPE.DELETE_SOLUTION_SUCCESS, id };
  }
  function failure(error) {
    return { type: SOLUTIONS_TYPE.DELETE_SOLUTION_FAILURE, error };
  }
}
