/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';

import Loading from '../../../../components/CustomComponents/Loading';
import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { qcvEnums } from '../../../../constants/qcv.enums';
import { formActions } from '../../../../actions';

export default function HoSoNote() {
	const dispatch = useDispatch();

	const role = useSelector(state => state.userReducer.user?.role);
	const userId = useSelector(state => state.userReducer.user?.id);
	const loading = useSelector(state => state.formReducer.loading);
	const applicationList = useSelector(state => state.formReducer.applicationList);

	const [values, setValues] = useState({
		disabledScore: 0,
		ethnicMinorityScore: 0,
	});
	
	const arrayPath = window.location.href.split('/');
	const urlId = parseInt(arrayPath.slice(arrayPath.length -1));
	const applicationDetail = applicationList.find((e) => e.id === urlId);
	
	// Lưu giá trị
	useEffect(() => {
		if (applicationDetail) setValues({
			...values,
			note: applicationDetail?.note,
			scoreA: applicationDetail?.scoreA,
			scoreB: applicationDetail?.scoreB,
			scoreC: applicationDetail?.scoreC,
			scoreD: applicationDetail?.scoreD,
		});
	}, [applicationList]);

	const saveNote = () => dispatch(formActions.updateBCD({
		...values,
		id: urlId, 
		status: 'need_update',
	}));

	const handleSubmit = () => dispatch(formActions.updateBCD({
		...values,
		id: urlId, 
		status: 'approved',
	}));
	
	var textForm = [
		{ key: 'scoreB', width: 6, label: 'Điểm thành tích khen thưởng (Tối đa 8 điểm)', type: 'float', value: values?.scoreB },
		{ key: 'scoreC', width: 6, label: 'Điểm thành tích hoạt động cộng đồng (Tối đa 7 điểm)', type: 'float', value: values?.scoreC },
		{ key: 'scoreD', width: 12, label: 'Điểm ưu tiên (Người dân tộc thiểu số hoặc khuyết tật) (Tối đa 10 điểm)', type: 'float', value: values?.scoreD },
		{ key: 'note', width: 12, height: 10, label: 'Ghi chú của ban thư ký', value: values?.note },
		{ key: 'button-2', width: 6, label: 'Không duyệt hồ sơ', type: 'button', onClick: saveNote },
		{ key: 'submit', width: 6, label: 'Duyệt hồ sơ', type: 'submit' },
	];

	if (!role || role === 'candidate') {
		// Hồ sơ phải thuộc về người dùng đã đăng nhập và trong tình trạng cần phải sửa
		if (applicationDetail?.status !== 'need_update' || applicationDetail?.profile?.id !== userId) return null;
		else textForm = [{ key: 'note', width: 12, height: 10, value: values?.note, disabled: true }]
	}

  const score = useMemo(() => {
    if (applicationDetail) {
      var scoreA = 0;
      qcvEnums.THANH_TICH_LIST.forEach((key) => {
        applicationDetail[key]?.forEach((e) => scoreA += e?.score || 0);
      })
  
      var _score = scoreA
      + (values?.scoreB || applicationDetail?.scoreB || 0)
      + (values?.scoreC || applicationDetail?.scoreC || 0)
      + (values?.scoreD || applicationDetail?.scoreD || 0);
  
      return Math.round(_score * 100) / 100;
    }

    return null;
  }, [applicationDetail, values]);

	return (
		<Grid container justifyContent='center' style={{ margin: 16, width: 'calc(100% - 32px)' }}>
			<Grid item xs={12} lg={4}>
				<Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
					<Typography variant='h1' style={{ color: '#ee6064' }}>
						<b>{score}</b>
					</Typography>
					<Typography variant='h4'>Điểm của ứng viên</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} lg={8}>
				<KPTextform
					textForm={textForm}
					handleSubmit={handleSubmit}
					values={values}
					setValues={setValues}
				/>
			</Grid>
			<Loading open={loading} />
		</Grid>
	)
}