import React from "react";
import { Grid, Box } from "@material-ui/core"

import { fileRender } from "../../../../utils/photoRender";
import { FORM_CONSTANTS } from "../../../../constants";

const HosoAbout = ({ applicationDetail }) => {
  const { ACADEMIC_LEVEL_OPTIONS, POLITICAL_UNION_OPTIONS } = FORM_CONSTANTS;

  const aboutMeList = [
    { key: 'nominator', label: 'Đơn vị đề cử', value: applicationDetail?.nominator },
    { key: 'unit', label: 'Đơn vị công tác', value: applicationDetail?.unit },
    {
      key: 'academicLevel',
      label: 'Trình độ học vấn',
      value: ACADEMIC_LEVEL_OPTIONS.find((e) => e.value === applicationDetail?.academicLevel)?.title,
    },
    { key: 'academicRank', label: 'Học hàm/chức danh khoa học', value: applicationDetail?.academicRank },
    { key: 'languague', label: 'Ngoại ngữ', value: applicationDetail?.languague },
    {
      key: 'politicalUnion',
      label: 'Đoàn thể chính trị',
      value: POLITICAL_UNION_OPTIONS.find((e) => e.value === applicationDetail?.politicalUnion)?.title,
    },
    { key: 'gifted', label: 'Năng khiếu', value: applicationDetail?.gifted },
    { key: 'break' },
    {
      key: 'link-1',
      label: 'Link file công văn cơ quan, đơn vị đề cử',
      value: (
        <a target='_blank' rel='noreferrer' href={applicationDetail?.facultyPhone}>
          {applicationDetail?.facultyPhone}
        </a>
      ),
    },
    {
      key: 'link-2',
      label: 'Link video giới thiệu bản thân',
      value: (
        <a target='_blank' rel='noreferrer' href={applicationDetail?.videoUrl}>
          {applicationDetail?.videoUrl}
        </a>
      ),
    },
    {
      key: 'link-3',
      label: 'File giới thiệu công trình nghiên cứu, giải pháp công nghệ, sáng chế tiêu biểu nhất',
      value: (
        <a target='_blank' rel='noreferrer' href={fileRender(applicationDetail?.sumaryFile)}>
          Xem tại đây
        </a>
      ),
    },
  ];

  return (
    <>
      <Grid item xs={5} style={{ marginTop: 64, marginBottom: 8 }}>
        <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>GIỚI THIỆU CHUNG</Box>
      </Grid>
      <Grid item xs={7} />
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {aboutMeList.map((e, index) => (
            <Grid key={index} item xs={e.key.includes('link') ? 12 : 6}>
              {e.key !== 'break' && <span><b>{e.label}: </b>{e.value}</span>}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default HosoAbout;