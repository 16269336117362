import { HEADERS } from ".";

export const MONOGRAPHS_API = {
	getMonographAll: () => ({
		endPoint: `/api/v1/monograph/all`,
		headers: HEADERS.TOKEN_HEADER,
		method: 'GET',
	}),
	getMonographByProfileId: (id) => ({
		endPoint: `/api/v1/monograph/${id}/list`,
		headers: HEADERS.TOKEN_HEADER,
		method: 'GET',
	}),
	insertMonograph: () => ({
		endPoint: '/api/v1/monograph/create',
		headers: HEADERS.TOKEN_HEADER,
		method: 'POST',
	}),
	updateMonograph: () => ({
		endPoint: '/api/v1/monograph/update',
		headers: HEADERS.TOKEN_HEADER,
		method: 'PUT',
	}),
	deleteMonograph: (id) => ({
		endPoint: `/api/v1/monograph/delete/${id}`,
		headers: HEADERS.TOKEN_HEADER,
		method: 'DELETE',
	}),
	updateMonographAccepted:() => ({
		endPoint: '/api/v1/formMonograph/updateScore_Accepted',
		headers: HEADERS.TOKEN_HEADER,
		method: 'PUT',
	})
};

export const MONOGRAPHS_TYPE = {
	GET_ALL_MONOGRAPH_LOADING: 'GET_ALL_MONOGRAPH_LOADING',
	GET_ALL_MONOGRAPH_SUCCESS: 'GET_ALL_MONOGRAPH_SUCCESS',
	GET_ALL_MONOGRAPH_FAILURE: 'GET_ALL_MONOGRAPH_FAILURE',

	GET_MONOGRAPH_BY_PROFILE_ID_LOADING: 'GET_MONOGRAPH_BY_PROFILE_ID_LOADING',
	GET_MONOGRAPH_BY_PROFILE_ID_SUCCESS: 'GET_MONOGRAPH_BY_PROFILE_ID_SUCCESS',
	GET_MONOGRAPH_BY_PROFILE_ID_FAILURE: 'GET_MONOGRAPH_BY_PROFILE_ID_FAILURE',

	INSERT_MONOGRAPH_LOADING: 'INSERT_MONOGRAPH_LOADING',
	INSERT_MONOGRAPH_SUCCESS: 'INSERT_MONOGRAPH_SUCCESS',
	INSERT_MONOGRAPH_FAILURE: 'INSERT_MONOGRAPH_FAILURE',

	UPDATE_MONOGRAPH_LOADING: 'UPDATE_MONOGRAPH_LOADING',
	UPDATE_MONOGRAPH_SUCCESS: 'UPDATE_MONOGRAPH_SUCCESS',
	UPDATE_MONOGRAPH_FAILURE: 'UPDATE_MONOGRAPH_FAILURE',

	DELETE_MONOGRAPH_LOADING: 'DELETE_MONOGRAPH_LOADING',
	DELETE_MONOGRAPH_SUCCESS: 'DELETE_MONOGRAPH_SUCCESS',
	DELETE_MONOGRAPH_FAILURE: 'DELETE_MONOGRAPH_FAILURE',

	UPDATE_MONOGRAPH_ACCEPTED_LOADING: 'UPDATE_MONOGRAPH_ACCEPTED_LOADING',
	UPDATE_MONOGRAPH_ACCEPTED_SUCCESS: 'UPDATE_MONOGRAPH_ACCEPTED_SUCCESS',
	UPDATE_MONOGRAPH_ACCEPTED_FAILURE: 'UPDATE_MONOGRAPH_ACCEPTED_FAILURE',
}

export const MONOGRAPHS_CONSTANTS = {
	TYPE_OPTIONS: {
		INTERNATIONAL: [
			{ key: 's1', title: 'Sách chuyên khảo', value: 's1' },
			{ key: 's2', title: 'Sách giáo trình', value: 's2' },
			{ key: 's3', title: 'Sách tham khảo', value: 's3' },
			{ key: 's4', title: 'Chương sách (thuộc NXB uy tín trên thế giới)', value: 's4' }
		],
		DOMESTIC: [
			{ key: 's5', title: 'Sách chuyên khảo', value: 's5' },
			{ key: 's6', title: 'Sách giáo trình', value: 's6' },
			{ key: 's7', title: 'Sách tham khảo', value: 's7' },
			{ key: 's8', title: 'Chương sách (thuộc NXB uy tín trên thế giới)', value: 's8' }
		]
	},

	ROLE_OPTIONS : [
    { key: 'main', title: 'Tác giả chính (Tác giả đầu, tác giả liên hệ)', value: 'main' },
    { key: 'member', title: 'Đồng tác giả', value: 'member' },
  ],
}