import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Chip, Button, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

import KPTable from '../../../../components/KPTComponents/KPTable';
import PopupMenu from '../../../../components/CustomComponents/PopupMenu';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
import DuyetThanhTichModal from '../../../../components/CustomComponents/DuyetThanhTichModal';

import DaoTaoUpdate from '../DaoTaoUpdate';
import { hrDevelopmentActions } from '../../../../actions';
import { HR_DEVELOPMENT_CONSTANTS } from '../../../../constants';

export default function DaoTaoTable(props) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.hrDevelopmentReducer.loading);
  const totalHrDevelopmentList = useSelector(state => state.hrDevelopmentReducer.totalHrDevelopmentList);
  const personalHrDevelopmentList = useSelector(state => state.hrDevelopmentReducer?.personalHrDevelopmentList);
  const { roleOptions, levelOptions } = HR_DEVELOPMENT_CONSTANTS;
  
  const [values, setValues] = useState(null);
  const [duyetThanhTich, setDuyetThanhTich] = useState(null);

  const [detail, setDetail] = useState(null);
  const [preview, setPreview] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(false);
  const [list, setList] = useState(null)
  const [totalList, setTotalList] = useState(null);

  useEffect(() => {
    if (personalHrDevelopmentList) {
      setList(personalHrDevelopmentList.map(param => ({
        ...param,
        decisionDay: dayjs(param.decisionDay).format('DD/MM/YYYY')
      })))
    }
    if (totalHrDevelopmentList) {
      setTotalList(totalHrDevelopmentList.map(param => ({
        ...param,
        decisionDay: dayjs(param.decisionDay).format('DD/MM/YYYY')
      })))
    }
  }, [personalHrDevelopmentList, totalHrDevelopmentList]);

  const openDeleteMenu = (e, id) => {
    setDeleteId(id);
    setDeleteMenuAnchor(e.currentTarget);
  }

  const closeDeleteMenu = () => {
    setDeleteId(null);
    setDeleteMenuAnchor(null);
  }

  const handleAccepted = (row = duyetThanhTich) => {
    dispatch(hrDevelopmentActions.updateHrDevelopmentAccepted(
      [{
        id: row.formHrDevelopmentId,
        accepted: !row.thanhTichAccepted,
        score: values?.score || 0,
        note: values?.note || '',
      }]
    ));
    setDuyetThanhTich(null);
    setValues(null);
  }

  const saveNote = (row = duyetThanhTich) => {
    dispatch(hrDevelopmentActions.updateHrDevelopmentAccepted(
      [{
        id: row.formHrDevelopmentId,
        accepted: false,
        score: values?.score || 0,
        note: values?.note || '',
      }]
    ));
    setDuyetThanhTich(null);
    setValues(null);
  }

  const handleDelete = () => {
    dispatch(hrDevelopmentActions.deleteHrDevelopment(deleteId));
    closeDeleteMenu();
  }


  // const mobileMode = window.innerWidth <= 768;

  // const tableRows = props.filterType === 'person' ? list : totalList;
  var tableRows = [];
  if (props?.rows) {
    tableRows = props?.rows;
  } else {
    tableRows = props.filterType === 'person' ? list : totalList;
  }

  var columnsDaoTao = [
    { title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
    {
      title: <b>Tên học viên</b>,
      dataIndex: 'traineeName',
      width: '20%',
      sorter: (a, b) => a.traineeName.localeCompare(b.traineeName),
    },
    {
      title: <b>Cơ sở đào tạo</b>,
      dataIndex: 'unit',
      width: '15%'
    },
    {
      title: <b>Chương trình đào tạo</b>,
      dataIndex: 'academicLevel',
      width: '20%',
      render: (value) => levelOptions.find((e) => e.value === value)?.title,
    },
    {
      title: <b>Năm</b>,
      dataIndex: 'year',
      width: '10%'
    },
    {
      title: <b>Tình trạng</b>,
      dataIndex: 'thanhTichAccepted',
      width: '15%',
      filters: [
        { text: 'Đã được duyệt', value: true },
        { text: 'Chưa được duyệt', value: false }
      ],
      onFilter: (value, row) => row.thanhTichAccepted === value,
      render: (value) => (
        <Chip
          size='small'
          variant='outlined'
          color={value ? 'primary' : 'default'}
          label={`${value ? 'Đã' : 'Chưa'} duyệt`}
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'cc-actions',
      width: '15%',
      render: (_, row) => (
        <>
          <Typography variant='body2' color='primary' className='actions-button' onClick={() => setDetail(row)}>Chi tiết</Typography>
          {!(props.type === 'accept') && (
            <Typography
              variant='body2'
              color='secondary'
              className='actions-button'
              onClick={(e) => openDeleteMenu(e, row.id)}
            >
              Xoá
            </Typography>
          )}
          {/* <Typography variant='body2' color='secondary' className='actions-button' onClick={(e) => openDeleteMenu(e, row.id)}>Xoá</Typography> */}
          <Typography variant='body2' className='actions-button' onClick={() => setPreview(row?.link)}>Minh chứng</Typography>
          {(props.type === 'accept') && (
            <Typography
              variant='body2'
              color={row?.thanhTichAccepted ? 'secondary' : 'primary'}
              className='actions-button'
              onClick={() => {
                if (row?.thanhTichAccepted) handleAccepted(row); else {
                  setDuyetThanhTich(row);
                  setValues({
                    ...values,
                    note: row.note
                  })
                  console.log()
                }
              }}
            >
              {`${row?.thanhTichAccepted ? 'Huỷ xác' : 'Xác'} nhận`}
            </Typography>
          )}
        </>
      ),
    }
  ];
  const mobileMode = window.innerWidth <= 768;

  var columnsBoiDuong = [
    {
      title: <b>STT</b>,
      dataIndex: "id",
      width: "5%",
      render: (_, row, id) => id + 1,
    },
    {
      title: <b>Tên chương trình</b>,
      dataIndex: "programName",
      width: props.type === "update" ? "25%" : "30%",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: (
        <b>
          {mobileMode ? "Số lượng nhân lực" : "Số lượng nhân lực được đào tạo"}
        </b>
      ),
      dataIndex: "traineeNum",
      width: "20%",
    },
    {
      title: <b>Năm</b>,
      dataIndex: "year",
      width: "20%",
    },
    {
      title: <b>Tình trạng</b>,
      dataIndex: "accepted",
      width: "15%",
      filters: [
        { text: "Đã được duyệt", value: true },
        { text: "Chưa được duyệt", value: false },
      ],
      onFilter: (value, row) => row.role === value,
      render: (value) => (
        <Chip
          size="small"
          variant="outlined"
          color={value ? "primary" : "default"}
          label={`${value ? "Đã" : "Chưa"} duyệt`}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: "cc-actions",
      width: "15%",
      render: (_, row) => (
        <>
          <Typography
            variant="body2"
            color="primary"
            className="actions-button"
            onClick={() => setDetail(row)}
          >
            Chi tiết
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            className="actions-button"
            onClick={(e) => openDeleteMenu(e, row.id)}
          >
            Xoá
          </Typography>
          <Typography
            variant="body2"
            className="actions-button"
            onClick={() => setPreview(row?.link)}
          >
            Minh chứng
            {console.log(
              tableRows
                ?.filter((e) => e.type === props.tableType)
                // .find((e) => e.academicLevel === row.academicLevel).id ===
                // row.id
            )}
          </Typography>
          {props.type === "accept" &&
            row.type === "daoTao" &&
            (row.academicLevel === "doctor" ||
            tableRows
            ?.filter((e) => e.type === props.tableType)
            .find((e) => e.academicLevel === row.academicLevel)?.id === row?.id) && (
              <Typography
                variant="body2"
                color={row?.thanhTichAccepted ? "secondary" : "primary"}
                className="actions-button"
                onClick={() => {
                  if (row?.thanhTichAccepted) handleAccepted(row);
                  else setDuyetThanhTich(row);
                }}
              >
                {`${row?.thanhTichAccepted ? "Huỷ xác" : "Xác"} nhận`}
              </Typography>
            )}
        </>
      ),
    },
  ];

  if (props.type === 'accept') {
    columnsDaoTao = [
      { ...columnsDaoTao[0] },
      {
        title: <b>Thông tin thành tích</b>,
        dataIndex: 'id',
        width: '50%',
        render: (_, row) => (
          <>
            <Typography variant='body2'><b>Tên học viên: </b>{row.traineeName}</Typography>
            <Typography variant='body2'><b>Cơ sở đào tạo: </b>{row.unit}</Typography>
            <Typography variant='body2'>
              <b>Chương trình đào tạo: </b>
              {levelOptions.find((e) => e.value === row.academicLevel)?.title}
            </Typography>
            <Typography variant='body2'>
              <b>Vai trò: </b>
              {roleOptions.find((e) => e.value === row.role)?.title}
            </Typography>
          </>
        ) 
      },
      { title: <b>Điểm</b>, dataIndex: 'score', width: '5%' },
      { title: <b>Ghi chú</b>, dataIndex: 'note', width: '15%' },
      { ...columnsDaoTao[5], width: '13%' },
      { ...columnsDaoTao[6], width: '12%' },
    ];
    columnsBoiDuong = [
      { ...columnsBoiDuong[1], width: '35%', render: (value, row, id) => `${id + 1}. ${value}` },
      { ...columnsBoiDuong[2], width: '20%' },
      { title: <b>Điểm</b>, dataIndex: 'score', width: '10%' },
      { ...columnsBoiDuong[4], width: '20%' },
      { ...columnsBoiDuong[5], width: '15%' },
    ];
  }

  return (
    <Grid item xs={12}>
      <KPTable
        nonBorder={props.type === 'accept'}
        columns={props.tableType === 'daoTao' ? columnsDaoTao : columnsBoiDuong}
        rows={tableRows?.filter((e) => e.type === props.tableType)}
        loading={loading}
      />

      <DaoTaoUpdate detail={detail} tableType={props.tableType} onClose={() => setDetail(null)} />

      <PopupMenu
        position='center'
        anchorEl={deleteMenuAnchor}
        handleClose={closeDeleteMenu}
        render={
          <div style={{ padding: 8 }}>
            <Typography style={{ paddingBottom: 16 }}>Xác nhận xoá thông tin giải thưởng?</Typography>
            <Button variant='outlined' style={{ width: 'calc(50% - 4px)' }} onClick={closeDeleteMenu}>Huỷ</Button>
            <Button variant='contained' color='secondary' style={{ marginLeft: 8, width: 'calc(50% - 4px)' }} onClick={handleDelete}>Xác nhận</Button>
          </div>
        }
      />

      <PreviewModal
        open={!!preview}
        url={preview}
        handleClose={() => setPreview(null)}
      />

      <DuyetThanhTichModal
        type='formHrDevelopment'
        info={duyetThanhTich}
        values={values}
        setValues={setValues}
        open={!!duyetThanhTich}
        handleClose={() => setDuyetThanhTich(null)}
        handleSubmit={handleAccepted}
        saveNote={saveNote}
      />
    </Grid>
  );
}