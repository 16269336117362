import React from "react";
import { CATEGORY, FORM_CONSTANTS, KEYS } from "../../constants";
import { Button } from "@material-ui/core";
import { fileRender } from "../photoRender";

const {
  ROLE_OPTIONS,
  levelOptions,
  productionOptions,
  ALL_TYPE_OPTIONS,
} = FORM_CONSTANTS;

export const hoSoDefaultColumns = [
  { title: <b>Điểm</b>, dataIndex: 'score', width: '10%' },
  { title: <b>Ghi chú</b>, dataIndex: 'note', width: '20%' },
  {
    title: <b>Tác vụ</b>,
    dataIndex: 'link',
    width: '15%',
    render: (value, row) => (
      <a
        target='_blank'
        rel='noreferrer'
        href={value || fileRender(row?.preview || row?.link)}
      >
        Minh chứng
      </a>
    ),
  },
];

const _hoSoColumnMap = {
  formTechCompany: () => [
    { title: <b>Tên sản phẩm</b>, dataIndex: 'production', width: '30%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>Vai trò</b>, dataIndex: 'applicationRange', width: '25%', render: (value) => productionOptions.find((opt) => opt.value === value)?.title },
  ],
  formSolution: () => [
    { title: <b>Tên sản phẩm</b>, dataIndex: 'title', width: '30%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>Tác Giả</b>, dataIndex: 'author', width: '25%' },
  ],
  formResearchProject: () => [
    { title: <b>Tên sản phẩm</b>, dataIndex: 'title', width: '20%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>Cấp dự án</b>, dataIndex: 'level', width: '20%', render: (value) => levelOptions.find((opt) => opt.value === value)?.title },
    { title: <b>Vai trò</b>, dataIndex: 'type', width: '20%', render: (value) => ALL_TYPE_OPTIONS.find((opt) => opt.value === value)?.title },
  ],
  formReward: () => [
    { title: <b>Tên giải thưởng</b>, dataIndex: 'title', width: '20%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>PL</b>, dataIndex: 'type', width: '25%', render: (value) => ALL_TYPE_OPTIONS.find((opt) => opt.value === value)?.title },
  ],
  formMonograph: () => [
    { title: <b>Tên sách</b>, dataIndex: 'title', width: '40%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>PL</b>, dataIndex: 'type', width: '30%', render: (value) => ALL_TYPE_OPTIONS.find((opt) => opt.value === value)?.title },
    { title: <b>Vai trò</b>, dataIndex: 'role', width: '30%', render: (value) => ROLE_OPTIONS.find((opt) => opt.value === value)?.title },
  ],
  formHrDevelopmentdaoTao: () => [
    { title: <b>Tên học viên</b>, dataIndex: 'traineeName', width: '30%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>Chương trình đào tạo</b>, dataIndex: 'academicLevel', width: '25%', render: (value) => levelOptions.find((opt) => opt.value === value)?.title },
  ],
  formHrDevelopmentboiDuong: () => [
    { title: <b>Tên chương trình</b>, dataIndex: 'programName', width: '30%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>Số lượng nhân lực được bồi dưỡng</b>, dataIndex: 'traineeNum', width: '25%' },
  ],
  formScientificResearch: () => [
    { title: <b>Nội dung</b>, dataIndex: 'title', width: '25%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>PL</b>, dataIndex: 'type', width: '10%', render: (value) => ALL_TYPE_OPTIONS.find((opt) => opt.value === value)?.title },
    { title: <b>Số TG</b>, dataIndex: 'numberAuthor', width: '10%' },
    { title: <b>Vai trò</b>, dataIndex: 'role', width: '10%', render: (value) => ROLE_OPTIONS.find((opt) => opt.value === value)?.title },
  ],
  formScientificReport: () => [
    { title: <b>Nội dung</b>, dataIndex: 'title', width: '25%', render: (v, _, i) => `${i + 1}. ${v}` },
    { title: <b>PL</b>, dataIndex: 'type', width: '10%', render: (value) => ALL_TYPE_OPTIONS.find((opt) => opt.value === value)?.title },
    { title: <b>Số TG</b>, dataIndex: 'numberAuthor', width: '10%' },
    { title: <b>Vai trò</b>, dataIndex: 'role', width: '10%', render: (value) => ROLE_OPTIONS.find((opt) => opt.value === value)?.title },
  ],
  // ??
  hocTapCongTac: () => [
    { title: <b>Thời gian bắt đầu</b>, dataIndex: "startAt", width: "25%", },
    { title: <b>Thời gian kết thúc</b>, dataIndex: "finishAt", width: "25%" },
    { title: <b>Đơn vị công tác</b>, dataIndex: "department", width: "25%" },
    { title: <b>Công việc đảm nhiệm</b>, dataIndex: "works", width: "25%" },
  ],
  thanhTichBangKhen: (callback) => [
    { title: <b>Nội dung khen thưởng</b>, dataIndex: "achievementContent", width: "50%" },
    {
      title: <b>Cấp khen thưởng</b>,
      dataIndex: "typeOfAchievement",
      width: "30%",
      render: (value) => FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find((lvl) => lvl.value === value)?.title || "",
    },
    {
      title: <b>Minh Chứng</b>,
      dataIndex: 'achievementFile',
      width: '20%',
      render: (value) => (
        <Button color="primary" style={{ textTransform: 'none' }} onClick={() => callback(value)}>
          Chi tiết
        </Button>
      ),
    },
  ],
  hoatDongCongDong: (callback) => [
    {
      title: <b>Mô tả</b>,
      dataIndex: 'communityAchievement',
      width: '80%',
      render: (value) => value,
    },
    {
      title: <b>Minh Chứng</b>,
      dataIndex: 'communityAchievementFile',
      width: '20%',
      render: (value) => (
        <Button
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={() => callback(value)}
        >
          Chi tiết
        </Button>
      ),
    },
  ]
};

export const hoSoColumnMap = {
  QCV: _hoSoColumnMap,
  [CATEGORY.KVC]: {
    hocTapCongTac: _hoSoColumnMap.hocTapCongTac,
    thanhTichBangKhen: (callback) => [
      { title: <b>Nội dung khen thưởng</b>, dataIndex: `${KEYS.OTHER_ACHIEVEMENT}Content`, width: "50%" },
      {
        title: <b>Cấp khen thưởng</b>,
        dataIndex: `${KEYS.OTHER_ACHIEVEMENT}Type`,
        width: "30%",
        render: (value) => FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find((lvl) => lvl.value === value)?.title || "",
      },
      {
        title: <b>Minh Chứng</b>,
        dataIndex: `${KEYS.OTHER_ACHIEVEMENT}PreviewFile`,
        width: '20%',
        render: (value) => (
          <Button color="primary" style={{ textTransform: 'none' }} onClick={() => callback(value)}>
            Chi tiết
          </Button>
        ),
      },
    ],
    hoatDongChiaSeTriThuc: (callback) => [
      { title: <b>Mô tả</b>, dataIndex: `${KEYS.PERSONAL_ACHIEVEMENT}Content`, width: '80%' },
      {
        title: <b>Minh Chứng</b>,
        dataIndex: `${KEYS.PERSONAL_ACHIEVEMENT}PreviewFile`,
        width: '20%',
        render: (value) => (
          <Button
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => callback(value)}
          >
            Chi tiết
          </Button>
        ),
      },
    ],
    hoatDongCongDong: (callback) => [
      { title: <b>Mô tả</b>, dataIndex: `${KEYS.SOCIAL_ACTIVITY}Content`, width: '80%' },
      {
        title: <b>Minh Chứng</b>,
        dataIndex: `${KEYS.SOCIAL_ACTIVITY}PreviewFile`,
        width: '20%',
        render: (value) => (
          <Button
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => callback(value)}
          >
            Chi tiết
          </Button>
        ),
      },
    ],
    formScientificResearch: _hoSoColumnMap.formScientificResearch,
    formScientificReport: _hoSoColumnMap.formScientificReport,
    formMonograph: _hoSoColumnMap.formMonograph,
  },
  [CATEGORY.GTNS]: {
    thanhTichBangKhen: (callback) => [
      { title: <b>Nội dung khen thưởng</b>, dataIndex: `${KEYS.REWARD}Content`, width: "50%" },
      {
        title: <b>Cấp khen thưởng</b>,
        dataIndex: `${KEYS.REWARD}Type`,
        width: "30%",
        render: (value) => FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find((lvl) => lvl.value === value)?.title || "",
      },
      {
        title: <b>Minh Chứng</b>,
        dataIndex: `${KEYS.REWARD}PreviewFile`,
        width: '20%',
        render: (value) => (
          <Button color="primary" style={{ textTransform: 'none' }} onClick={() => callback(value)}>
            Chi tiết
          </Button>
        ),
      },
    ],
    thanhTichChuyenNganh: (callback) => [
      { title: <b>Hình thức và nội dung thành tích</b>, dataIndex: `${KEYS.MAJOR_ACHIEVEMENT}Content`, width: "50%" },
      {
        title: <b>Cấp thành tích</b>,
        dataIndex: `${KEYS.MAJOR_ACHIEVEMENT}Type`,
        width: "30%",
        render: (value) => FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find((lvl) => lvl.value === value)?.title || "",
      },
      {
        title: <b>Minh Chứng</b>,
        dataIndex: `${KEYS.MAJOR_ACHIEVEMENT}PreviewFile`,
        width: '20%',
        render: (value) => (
          <Button color="primary" style={{ textTransform: 'none' }} onClick={() => callback(value)}>
            Chi tiết
          </Button>
        ),
      },
    ],
    hoatDongCongDong: (callback) => [
      { title: <b>Mô tả</b>, dataIndex: `${KEYS.SOCIAL_ACTIVITY}Content`, width: '80%' },
      {
        title: <b>Minh Chứng</b>,
        dataIndex: `${KEYS.SOCIAL_ACTIVITY}PreviewFile`,
        width: '20%',
        render: (value) => (
          <Button
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => callback(value)}
          >
            Chi tiết
          </Button>
        ),
      },
    ],
  }
};