import { apiCall } from '../utils';
import { REPORTS_API, REPORTS_TYPE } from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const reportsActions = {
	getAllReport,
	getReportsByProfileId,
	insertReports,
	updateReport,
	deleteReport,
  updateReportAccepted,
}

function getAllReport() {
	return async (dispatch) => {
		dispatch(loading());

		const api = REPORTS_API.getAllReport();
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy báo cáo khoa học thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy báo cáo khoa học thất bại', type: 'error' }));
		}
	}

	function loading() { return { type: REPORTS_TYPE.GET_ALL_REPORT_LOADING } }
	function success(payload) { return { type: REPORTS_TYPE.GET_ALL_REPORT_SUCCESS, payload } }
	function failure(error) { return { type: REPORTS_TYPE.GET_ALL_REPORT_FAILURE, error } }
}


function getReportsByProfileId(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = REPORTS_API.getReportsByProfileId(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy báo cáo khoa học thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy báo cáo khoa học thất bại', type: 'error' }));
		}
	}

	function loading() { return { type: REPORTS_TYPE.GET_REPORTS_BY_PROFILE_ID_LOADING } }
	function success(payload) { return { type: REPORTS_TYPE.GET_REPORTS_BY_PROFILE_ID_SUCCESS, payload } }
	function failure(error) { return { type: REPORTS_TYPE.GET_REPORTS_BY_PROFILE_ID_FAILURE, error } }
}

function insertReports(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = REPORTS_API.insertReport();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data[0]));
			dispatch(notification({ message: 'Thêm báo cáo khoa học thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Thêm báo cáo khoa học thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: REPORTS_TYPE.INSERT_REPORTS_LOADING } }
	function success(payload) { return { type: REPORTS_TYPE.INSERT_REPORTS_SUCCESS, payload } }
	function failure(error) { return { type: REPORTS_TYPE.INSERT_REPORTS_FAILURE, error } }
}

function updateReport(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = REPORTS_API.updateReport();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(formActions.updateFocusFormByData('formScientificReport', 'scientificReport', response.data.data));
			dispatch(notification({ message: 'Chỉnh sửa báo cáo khoa học thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Chỉnh sửa báo cáo khoa học thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: REPORTS_TYPE.UPDATE_REPORT_LOADING } }
	function success(payload) { return { type: REPORTS_TYPE.UPDATE_REPORT_SUCCESS, payload } }
	function failure(error) { return { type: REPORTS_TYPE.UPDATE_REPORT_FAILURE, error } }
}

function deleteReport(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = REPORTS_API.deleteReport(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200) {
			dispatch(success(id));
			dispatch(notification({ message: 'Xoá báo cáo khoa học thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Xoá báo cáo khoa học thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: REPORTS_TYPE.DELETE_REPORT_LOADING } }
	function success(id) { return { type: REPORTS_TYPE.DELETE_REPORT_SUCCESS, id } }
	function failure(error) { return { type: REPORTS_TYPE.DELETE_REPORT_FAILURE, error } }
}

function updateReportAccepted(payload){
  return async(dispatch) => {
    dispatch(loading());

    const api = REPORTS_API.updateReportAccepted();
    const { response, error } = await apiCall({ ...api, payload });

    const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
			dispatch(formActions.updateFocusForm('formScientificReport', response.data.data));
      dispatch(notification({ message: `${noti} báo cáo khoa học thành công`, type: 'success' }));
			return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: `${noti} báo cáo khoa học thất bại`, type: 'error' }));
    }
  };

  function loading() { return { type: REPORTS_TYPE.UPDATE_REPORT_ACCEPTED_LOADING } }
  function success(payload) { return { type: REPORTS_TYPE.UPDATE_REPORT_ACCEPTED_SUCCESS, payload } }
  function failure(error) { return { type: REPORTS_TYPE.UPDATE_REPORT_ACCEPTED_FAILURE, error } }
}