import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
  Grid,
	Button,
	Select,
	MenuItem,
	InputLabel,
	Typography,
	FormControl,
	Divider,
	TextField,
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { scoreInformationActions } from '../../../../actions';

function BangTinhDiem() {
  const dispatch = useDispatch();

	const [selectedRow, setSelectedRow] = useState(null);
	const [seasonId, setSeasonId] = useState(null);

  const seasonList = useSelector((state) => state.seasonReducer.seasonList);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);

  const loadingScoreInformation = useSelector((state) => state.scoreInformationReducer.loading);
  const scoreInformationList = useSelector((state) => state.scoreInformationReducer.scoreInformationList);

	const handleReload = () => dispatch(scoreInformationActions.getScoreInformationBySeasonId(seasonId));
  const handleSubmit = (e) => {
    e.preventDefault();
		dispatch(scoreInformationActions.updateScoreInformation(selectedRow));
    setSelectedRow(null);
  };

  // Lấy dữ liệu bảng tính điểm
  useEffect(() => {
    if (seasonId) handleReload();
  }, [seasonId]);

	// Lấy năm tổ chức (năm hiện tại)
	useEffect(() => {
    if (openSeasonId) setSeasonId(openSeasonId);
  }, [openSeasonId]);

	return (
    <Grid container spacing={1} style={{ margin: 0, width: '100%' }}>
      <Grid item xs={5}>
        <Typography variant='h5' style={{ lineHeight: '42px' }}>
          <b>Bảng tính điểm</b>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <FormControl size='small' variant='outlined' fullWidth>
          <InputLabel>Năm tổ chức</InputLabel>
          <Select
            fullWidth
            value={seasonId || 0}
            MenuProps={{
              getContentAnchorEl: null,
              MenuListProps: { style: { padding: 0 } },
              PaperProps: { style: { left: 8 } },
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
            }}
            onChange={(e) => setSeasonId(e.target.value)}
            label='Năm tổ chức'
          >
            {seasonList.map((e) => (
              <MenuItem key={e.id} value={e.id}>
                {e.year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Box style={{ float: 'right', padding: '3px 0' }}>
          <Button variant='outlined' startIcon={<Replay />} onClick={handleReload}>
            Tải lại
          </Button>
        </Box>
      </Grid>

			<Grid item xs={12}><Divider /></Grid>

			<Grid item xs={12}>
				<KPTable
					loading={loadingScoreInformation}
					rows={scoreInformationList}
					columns={[
						{
							title: <b>STT</b>,
							dataIndex: 'stt',
							width: '10%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
                      type='int'
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												stt: parseInt(e.target.value)
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Mục</b>,
							dataIndex: 'code',
							width: '10%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												code: e.target.value
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Nội dung</b>,
							dataIndex: 'description',
							width: '60%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
                      type='int'
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												description: e.target.value
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Điểm</b>,
							dataIndex: 'value',
							width: '20%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
                      type='int'
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												value: parseFloat(e.target.value)
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Tác vụ</b>,
							dataIndex: 'actions',
							width: '10%',
							render: (_, row) => (
								(selectedRow?.id !== row?.id) ? (
									<Typography
										variant='body2'
										color={'primary'}
										className='actions-button'
										onClick={() => setSelectedRow(row)}
										// style={{ lineHeight: '41px' }}
									>
										Sửa
									</Typography>
								) : (
									<Box display='flex'>
										<Typography
											variant='body2'
											color='primary'
											className='actions-button'
											onClick={handleSubmit}
										>
											Lưu
										</Typography>
										<Typography
											variant='body2'
											color='secondary'
											className='actions-button'
											style={{ marginLeft: 8 }}
											onClick={() => setSelectedRow(null)}
										>
											Huỷ
										</Typography>
									</Box>
								)
							),
						},
					]}
				/>
			</Grid>
		</Grid>
	)
}

export default BangTinhDiem;
