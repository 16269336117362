import dayjs from 'dayjs';
import Interweave from 'interweave';
import React, { useEffect } from 'react';
import { Box, Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { formActions } from '../../../../actions';
import Loading from '../../../../components/CustomComponents/Loading';

function DanhSachThanhTichProfile(props) {
  const dispatch = useDispatch();
  const loadingForm = useSelector(state => state.formReducer.loading);
	const applicationList = useSelector(state => state.formReducer.applicationList);

	const form = applicationList.find((e) => e.id === props.formId);

	useEffect(() => {
		if (form) dispatch(formActions.setFocusForm(form));
	}, [form]);

	return (
		<Card style={{ padding: '4px 16px', minHeight: 'calc(100vh - 114px)' }}>
      <Box display='flex' justifyContent='center' padding={1}>
        <img
          // eslint-disable-next-line no-undef
          src={`${process.env.REACT_APP_DOMAIN}${form?.profile?.photoUrl}`}
          style={{ maxWidth: 120, maxHeight: 150, minHeight: 150, objectFit: 'cover' }}
        />
      </Box>
			<Box style={{ textAlign: 'center' }}><b>{form?.profile?.name}</b></Box>
      <Box style={{ textAlign: 'center' }}><b>Ngày sinh: </b>{dayjs(form?.profile?.birth).format('DD/MM/YYYY')}</Box>
      <Box marginTop={2}><b>Tóm tắt thành tích: </b></Box>
      <Box textAlign='justify' style={{ overflow: 'hidden' }}><Interweave content={form?.summary}/></Box>
      <Loading open={loadingForm} />
    </Card>
	)
}

export default DanhSachThanhTichProfile;
