import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Divider,
  Button,
  Box,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { dateFormatter } from '../../../../utils';
import SangCheForm from '../SangCheForm/SangCheForm';
import { fileActions, solutionActions } from '../../../../actions';

export default function SangCheInsert(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);

  const [values, setValues] = useState();

  const handleSubmit = () => {
    dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0]))
    .then((response) => {
      dispatch(solutionActions.insertSolution([
        { ...values, profileId, link: response, issueAt: dateFormatter(values.issueAt) }
      ]));
    });
    setValues(null);
    props.onClose();
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'><b>Thêm sáng chế/giải pháp</b></Typography>
          <IconButton onClick={props.onClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <SangCheForm
          id='sang-che'
          values={values}
          setValues={setValues}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button variant='outlined' onClick={props.onClose}>Huỷ</Button>
        <Button form='sang-che' type='submit' variant='contained' color='secondary'>Xác nhận</Button>
      </DialogActions>
    </Dialog>
  );
}

