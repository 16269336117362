import dayjs from 'dayjs';
import Interweave from 'interweave';
import React, { useState, useEffect, useMemo, Fragment } from 'react';
import {
  Box,
  Grid,
  Tooltip,
  Checkbox,
  IconButton,
  CardContent,
  FormControlLabel,
  Typography,
  Divider,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  CardHeader,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Close, ExpandMore, Edit, Home, Info, WarningRounded } from '@material-ui/icons';

import theme from '../../../../theme';
import KPTable from '../../../../components/KPTComponents/KPTable';
import Loading from '../../../../components/CustomComponents/Loading';
import KPTextform from '../../../../components/KPTComponents/KPTextform';
import InputActives from '../../../GTNS/ActivesField/InputActives';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
import exportProfile from '../../../../utils/kvc.exportProfile';
import ThanhTichAlert from '../../../AppLayout/ThanhTichAlert/ThanhTichAlert';
import fileCongVanMau from "../../../../assets/docs/KVC2024_Mẫu công văn giới thiệu.docx";
import ThanhTichInsert from '../../ThanhTichInsert/ThanhTichInsert';

import { useStyles } from "./styled";
import { columnRender } from '../../../../utils/nstb/columnRender';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { jumpToReleventDiv } from '../../../../components/KPTComponents/utils';
import { fileRender, photoRender } from '../../../../utils/photoRender';
import { capitalizeFirstLetter, getWordCount } from '../../../../utils';
import { fileActions, userActions, formActions } from '../../../../actions';
import { kvcAchievementFields, kvcActivityFields } from '../../../../utils';
import { ACHIEVEMENT_TYPE, CATEGORY, KEYS, SECONDARY_COLOR } from '../../../../constants/enums';
import { FORM_CONSTANTS, MONOGRAPHS_CONSTANTS, REPORTS_CONSTANTS, ROUTER } from '../../../../constants';

const ACTIONS = ['link', 'edit', 'delete'];

export default function DangKyHoSoUpdate() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

  const [files, setFiles] = useState({});
  const [dialog, setDialog] = useState(null);
  const [values, setValues] = useState(null);
  const [showCSKH, setShowCSKH] = useState(true);
  const [previewFile, setPreviewFile] = useState(null);
  const [acceptRules, setAcceptRules] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const [summaryText, setSummaryText] = useState([]);
  const [summaryTextModalProps, setSummaryTextModalProps] = useState(null);

  const [socialActivity, setSocialActivity] = useState([]);
  const [personalProcess, setPersonalProcess] = useState([]);
  const [otherAchievement, setOtherAchievement] = useState([])
  const [personalAchievement, setPersonalAchievement] = useState([])

  const profile = useSelector((state) => state.profileReducer.profile);
  const seasonList = useSelector(state => state.seasonReducer.seasonList);
  const application = useSelector((state) => state.formReducer.applicationList?.[0] || null);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);

  const loading = useSelector((state) => (
    state.userReducer.loading
    || state.formReducer.loading
    || state.profileReducer.loading
    || uploadingFiles
  ));

  // ---------------- ARCHIVEMENTS ---------------- //
  const achievementData = useSelector((state) => ({
    [ACHIEVEMENT_TYPE.MONOGRAPH]: state.monographReducer.personalMonographList,
    [ACHIEVEMENT_TYPE.RESEARCH_PROJECT]: state.researchProjectReducer.personalResearchProjectList,
    [ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT]: state.reportsReducer.personalReportList,
    [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH]: state.researchReducer.personalResearchList,
    [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.DOMESTIC),
    [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.INTERNATIONAL),
    [ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC]: state.reportsReducer.personalReportList.filter(
      (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
    ),
    [ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL]: state.reportsReducer.personalReportList.filter(
      (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
    ),
    [ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL]: state.monographReducer.personalMonographList.filter(
      (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
    ),
    [ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC]: state.monographReducer.personalMonographList.filter(
      (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
    ),
  }));

  const newFileLength = useMemo(() => {
    const len = {
      [KEYS.SOCIAL_ACTIVITY]: 0,
      [KEYS.PERSONAL_PROCESS]: 0,
      [KEYS.OTHER_ACHIEVEMENT]: 0,
    }
    Object.keys(files).forEach((key) => {
      if (key.includes(KEYS.SOCIAL_ACTIVITY)) len[KEYS.SOCIAL_ACTIVITY] = len[KEYS.SOCIAL_ACTIVITY] + 1;
      if (key.includes(KEYS.PERSONAL_PROCESS)) len[KEYS.PERSONAL_PROCESS] = len[KEYS.PERSONAL_PROCESS] + 1;
      if (key.includes(KEYS.OTHER_ACHIEVEMENT)) len[KEYS.OTHER_ACHIEVEMENT] = len[KEYS.OTHER_ACHIEVEMENT] + 1;
    });
    return len;
  }, [files]);

  const handleFileChange = (e, name) => setFiles({ ...files, [name]: e });

  const textForm = {
    base: [
      { key: 'name', width: isMobileMode ? 12 : 8, label: 'Họ và tên', value: profile?.name, required: true, disabled: true },
      {
        key: 'birth',
        width: isMobileMode ? 12 : 4,
        label: 'Ngày sinh',
        value: dayjs(profile?.birth).format('DD/MM/YYYY'),
        type: 'date',
        disabled: true,
        required: true,
      },
      { key: "gender", width: isMobileMode ? 6 : 3, label: "Giới tính", value: profile?.gender, options: FORM_CONSTANTS.GENDER_OPTIONS, disabled: true },
      { key: "ethnic", width: isMobileMode ? 6 : 3, label: "Dân tộc", value: profile?.ethnic, disabled: true },
      { key: 'phone', width: isMobileMode ? 12 : 6, label: 'Số điện thoại', value: profile?.phone, required: true, disabled: true },
      { key: 'email', width: 12, label: 'Email', value: profile?.email, required: true, disabled: true },
      { key: 'website', width: 12, height: 1, label: 'Website/Facebook', required: true, value: profile?.website, disabled: true },
    ],
    partI: [
      { key: "unit", width: isMobileMode ? 12 : 7, label: "Đơn vị công tác", required: true },
      {
        key: "field",
        width: isMobileMode ? 12 : 5,
        label: "Lĩnh vực",
        required: true,
        options: FORM_CONSTANTS.FIELD_XHNV_OPTIONS,
      },
      { key: "nominator", width: 12, label: "Đơn vị đề cử", required: true },
      {
        key: "facultyPhone",
        width: 12,
        label: 'Link file công văn cơ quan, đơn vị đề cử',
        labelRender: (
          <Typography className="kpt-textfield-label" style={{ display: "inline" }}>
            {`Link file công văn cơ quan, đơn vị đề cử (Tải file mẫu `}
            <a
              href={fileCongVanMau}
              style={{ color: SECONDARY_COLOR }}
              download={"File công văn mẫu.docx"}
            >
              tại đây
            </a>
            {")"}
          </Typography>
        ),
        required: true,
      },
    ],
    partII: [
      {
        key: "academicLevel",
        width: 6,
        label: "Trình độ học vấn",
        required: true,
        value: values?.academicLevel,
        options: FORM_CONSTANTS.ACADEMIC_LEVEL_OPTIONS,
      },
      {
        key: "academicRank",
        width: 6,
        label: isMobileMode ? "Học hàm/chức danh" : "Học hàm/chức danh khoa học",
        value: values?.academicRank,
      },
      {
        key: "languague",
        width: 6,
        label: "Ngoại ngữ",
        required: true,
        value: values?.languague,
      },
      {
        key: "politicalUnion",
        width: 6,
        label: "Đoàn thể chính trị",
        required: true,
        value: values?.politicalUnion,
        options: FORM_CONSTANTS.POLITICAL_UNION_OPTIONS,
      },
      {
        key: "gifted",
        width: 12,
        height: 2,
        label: "Năng khiếu",
        required: true,
        value: values?.gifted,
      },
      {
        key: "videoUrl",
        width: 12,
        label: "Link video giới thiệu bản thân",
        required: true,
        value: values?.videoUrl,
      },
      {
        key: "previewFileLabel",
        width: 12,
        value: true,
        render: (
          <Typography className="kpt-textfield-label">
            05 ảnh ứng viên tham gia các hoạt động học tập, nghiên cứu khoa học, hoạt động vì cộng đồng
            <span style={{ color: theme.palette.secondary.main }}>*</span>:
          </Typography>
        )
      },
      {
        key: "previewFile",
        width: 12,
        label: 'Upload tại đây',
        type: 'file',
        filesLimit: 5,
        acceptedFiles: ['image/*'],
        required: true,
      },
    ],
    summary: [
      {
        key: "summary",
        width: 12,
        height: 4,
        label: "Tự giới thiệu, đánh giá",
        type: "richText",
        required: true,
      },
      {
        key: "countWord",
        width: 12,
        value: true,
        render: <Typography variant="subtitle1">{`Số lượng từ: ${getWordCount(values?.summary || "")}`}</Typography>
      },
    ],
    summaryFile: [
      {
        key: 'sumaryFile',
        width: 12,
        label: 'Bản công trình tiêu biểu giới thiệu có xác nhận của cơ quan có thẩm quyền',
        labelRender: (
          <>
            <Typography variant='body1' style={{ fontSize: 17 }}>
              <b>Bản công trình tiêu biểu giới thiệu có xác nhận của cơ quan có thẩm quyền (bản scan, chữ ký, đóng dấu)</b>
            </Typography>
            <Typography variant='body1'>
              (Trích xuất Bản đăng ký tham gia Giải thưởng Khuê Văn Các để lấy xác nhận của đơn vị quản lý hoặc đơn vị giới thiệu&nbsp;
              <a onClick={async (event) => {
                event.stopPropagation();
                setUploadingFiles(true);
                const success = await handleSave('unsubmit');
                if (success) {
                  exportProfile(application);
                }
                setUploadingFiles(false);
              }} style={{ textDecoration: 'underline', color: SECONDARY_COLOR }}>
                tại đây
              </a>
              )
            </Typography>
          </>
        ),
        type: 'file',
        required: true,
      },
    ]
  }

  const topPart = isMobileMode ? textForm.base.slice(0, 4) : textForm.base;
  const botPart = isMobileMode ? textForm.base.slice(4, textForm.base.length) : [];

  const achievementFields = kvcAchievementFields({ achievementData, actions: ACTIONS });
  const activityFields = kvcActivityFields({
    isMobileMode,
    personalProcess,
    setPersonalProcess,
    otherAchievement,
    setOtherAchievement,
    personalAchievement,
    setPersonalAchievement,
    socialActivity,
    setSocialActivity,
  });

  const handleChangeActivityForm = (name, event, type, values, setValues) => {
    const keyList = name.split(".");
    const key = keyList[2];
    const index = parseInt(keyList[1]);

    const tmp = [...values];
    if(type === "date"){
      tmp.splice(index, 1, { ...tmp[index], [key]: event});
    } else if (type === "removeFile") {
      tmp.splice(index, 1, { ...tmp[index], [key]: undefined }); 
    } else {
      tmp.splice(index, 1, { ...tmp[index], [key]: event.target.value });
    }
    setValues(tmp);
  };

  const handleReload = (profileId) => {
    dispatch(formActions.getApplicationFormv2(profileId));
    dispatch(formActions.filterApplicationForm({
      category: CATEGORY.XHNV,
      seasonId: openSeasonId,
      profileId: profile.id,
    })).then((applications) => {
      if (applications.length > 0) {
        const application = applications[0];
        if (application.summary === "") application.summary = "[]";
        if (application.process === "") application.process = "[]";
        if (application.previewFile === "") application.previewFile = "[]";
        if (application.socialActivity === "") application.socialActivity = "[]";
        if (application.personalAchievement === "") application.personalAchievement = "[]";
        if (application.communicationActivity === "") application.communicationActivity = "[]";

        const fileList = JSON.parse(application?.previewFile);
        application.sumaryFile = fileList.filter((file) => file.includes(('sumaryFile'))).map((file) => fileRender(file));
        application.previewFile = fileList.filter((file) => file.includes(('previewFile'))).map((file) => photoRender(file));

        if (application) {
          setValues({ ...values, ...application });
          setSummaryText(JSON.parse(application.summary) || []);
          setSocialActivity(JSON.parse(application.socialActivity) || []);
          setPersonalProcess(JSON.parse(application.process) || []);
          setOtherAchievement(JSON.parse(application.communicationActivity) || []);
          setPersonalAchievement(JSON.parse(application.personalAchievement) || []);

          if (application?.status === "in_review") setWarningOpen(true);
        }
      } else {
        setValues({ ...values, summary: ' ' });
      }
    });
  }

  const upsertApplication = (status, uploadedFiles=[]) => {
    const parseJsonActivity = (key) => {
      let dataSource = [];
      if (key === KEYS.OTHER_ACHIEVEMENT) dataSource = [...otherAchievement];
      else if (key === KEYS.SOCIAL_ACTIVITY) dataSource = [...socialActivity];
      else if (key === KEYS.PERSONAL_PROCESS) dataSource = [...personalProcess];
      else if (key === KEYS.PERSONAL_ACHIEVEMENT) dataSource = [...personalAchievement];

      dataSource = dataSource
        .filter((data) => Object.values(data).length !== 0)
        .map((data, index) => ({
          ...data,
          [`${key}PreviewFile`]: uploadedFiles.find((file) => {
            const keys = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1].split('.');
            return keys[0] === key && parseInt(keys[1]) === index;
          }) || data?.[`${key}PreviewFile`] || "",
        }));
      
      return dataSource ? JSON.stringify(dataSource) : ''
    };

    const achivementFormData = {};
    [
      ACHIEVEMENT_TYPE.MONOGRAPH,
      ACHIEVEMENT_TYPE.RESEARCH_PROJECT,
      ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT,
      ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH,
    ].forEach((key) => {
      achivementFormData['form' + capitalizeFirstLetter(key)] = achievementData[key].map((e) => ({ [key + 'Id']: e.id }))
    });

    var previewFile = JSON.parse(application?.previewFile || '[]');
    const sumaryFiles = uploadedFiles?.filter((file) => file.includes('sumaryFile') || file.includes('previewFile'));
    if (sumaryFiles.length > 0) previewFile = sumaryFiles;

    const payload = {
      ...values,
      ...achivementFormData,
      status,
      seasonId: openSeasonId,
      category: CATEGORY.XHNV,
      profileId: profile.id,
      summary: JSON.stringify(summaryText),
      previewFile: JSON.stringify(previewFile),

      process: parseJsonActivity(KEYS.PERSONAL_PROCESS),
      communicationActivity: parseJsonActivity(KEYS.OTHER_ACHIEVEMENT),
      [KEYS.SOCIAL_ACTIVITY]: parseJsonActivity(KEYS.SOCIAL_ACTIVITY),
      [KEYS.PERSONAL_ACHIEVEMENT]: parseJsonActivity(KEYS.PERSONAL_ACHIEVEMENT),
    }

    // Hồ sơ rỗng thì tạo hồ sơ mới, không thì cập nhật đè lên hồ sơ cũ
    if (!application) {
      dispatch(formActions.insertApplicationForm(payload)).then((success) => {
        if (success && status === 'in_review') window.location.reload();
      });
    } else {
      dispatch(formActions.updateApplicationForm(payload)).then((success) => {
        if (success && status === 'in_review') window.location.reload();
      });
    }
  }

  const handleSave = async (status) => {
    const formFields = [
      ...textForm.partI,
      ...textForm.partII,
      ...textForm.summary
    ];

    for (const elm of formFields) {
      if (status === "unsubmit" && elm.key === "sumaryFile") continue;

      const value = elm?.value || values?.[elm.key];
    
      if (!elm.disabled && elm.required && !value) {
        setValues({ ...values, emptyKey: elm.key });
        jumpToReleventDiv(elm.key);
        dispatch(
          userActions.notification({
            message: `Không được để trống thông tin về: ${elm?.renderLabel || elm?.label}`,
            type: 'error',
          }),
        );
        return;
      }
    }

    if (summaryText.length === 0) {
      jumpToReleventDiv('summary-all');
      dispatch(
        userActions.notification({
          message: "Không được để trống thông tin về: Bản công trình tiêu biểu giới thiệu có xác nhận của cơ quan có thẩm quyền",
          type: 'error',
        }),
      );
      return;
    }

    if (!profile?.id) {
      dispatch(userActions.notification({ message: "Lỗi profile ID, vui lòng tải lại trang!", type: 'error' }));
      return false;
    }

    const uploadFiles = {...files};
    values.previewFile?.forEach((value, index) => uploadFiles[`previewFile.${index}`] = value);

    if (Object.keys(uploadFiles).length > 0) {
      setUploadingFiles(true);
      dispatch(fileActions.importMultiFileQCV(profile.id, uploadFiles)).then((uploadedFiles) => {
        setUploadingFiles(false);
        if (!uploadedFiles) {
          dispatch(userActions.notification({ message: "Lỗi upload files, vui lòng tải lại trang!", type: 'error' }));
          return;
        }
        upsertApplication(status, uploadedFiles)
      });
    } else {
      upsertApplication(status);
    }

    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave('in_review');
  };

  const openSeason = useMemo(() => seasonList.find((s) => s.id === openSeasonId), [seasonList, openSeasonId]);

  const isNotAvalaible = useMemo(() => {
    if (!openSeason) return false;
    const { openDate, closeDate } = openSeason;
    const currDate = new Date();
    if (currDate < new Date(openDate)) return 'Chưa mở cổng đăng ký';
    if (currDate > new Date(closeDate)) return 'Đã hết thời hạn đăng ký';
    return null;
  }, [openSeason]);

  useEffect(() => {
    if (values?.sumaryFile?.[0]) {
      setFiles({ ...files, sumaryFile: values?.sumaryFile?.[0] });
    }
  }, [values?.sumaryFile]);
  
  useEffect(() => {
    if (profile?.id) handleReload(profile?.id);
  }, [profile]);

  return (
    <>
      <Card style={{ width: '100%', marginTop: 64 }}>
        <Box height={6} bgcolor='#233871' />
        <Box padding={2}>
          <Typography variant='h4' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
            <b>Đăng ký hồ sơ</b>
          </Typography>
        </Box>
      </Card>

      <Card style={{ width: '100%', marginTop: 32, display: showCSKH ? 'block' : 'none' }}>
        <CardHeader style={{ paddingRight: 4, paddingTop: 2, paddingBottom: 2 }} title={
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant="h6">
              <b>Hỗ trợ đăng ký Giải thưởng Khuê Văn Các</b>
            </Typography>
            <IconButton onClick={() => setShowCSKH(false)}><Close /></IconButton>
          </Box>
          }
        />
        <Divider />
        <CardContent style={{ paddingTop: 2, paddingBottom: 2 }}>
          <ul>
            <li>Hỗ trợ nội dung giải thưởng: Mr Hùng (<a href='tel:0936441976'>0936.441.976</a>)</li>
            <li style={{ marginTop: 4 }}>Hỗ trợ kỹ thuật hệ thống: Mr Kiên (<a href='http://zalo.me/0373699131'>037 3699131</a>)</li>
          </ul>
        </CardContent>
      </Card>

      <form className='dkhs-container' id='form-update' defaultValue={values} onSubmit={handleSubmit}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Box display='flex'>
              <Box flexGrow={1} marginTop='4px'>
                <KPTextform component='div' textForm={topPart} />
              </Box>
              <Box marginTop='4px' marginLeft={1}>
                <Typography className='kpt-textfield-label'>
                  {isMobileMode ? 'Ảnh chân dung' : 'Ảnh chân dung (4x6)'}
                  <span style={{ color: theme.palette.secondary.main }}>*</span>
                  {':'}
                </Typography>
                <Box>
                  <Card className='dkhs-photoContainer'>
                    <img alt='anh-chan-dung' className='dkhs-photo' src={photoRender(profile?.photoUrl)} />
                  </Card>
                </Box>
              </Box>
            </Box>
            <Box flexGrow={1} marginTop='4px'>
              <KPTextform component='div' textForm={botPart} />
            </Box>
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 32 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.partI}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 32 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.partII}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 96 }}>
          <Box height={6} bgcolor='#233871' />
          <Box padding={2}>
            <Typography variant='h5' style={{ textAlign: 'center' }}>
              <b>THÀNH TÍCH TRONG HỌC TẬP VÀ CÔNG TÁC</b>
            </Typography>
          </Box>
        </Card>

        {activityFields.map((active) => (
          <Card key={active.key} style={{ width: '100%', marginTop: 32 }}>
            {active?.children ? (
              <>
                <Box padding='8px 16px' display='flex' alignItems='center'>
                  <Typography variant='h6' style={{ flexGrow: 1 }}>
                    <b>{active.title}</b>
                  </Typography>
                </Box>
                <Divider />
                {active.children.map((e) => (
                  <Fragment key={e.key}>
                    <InputActives
                      id={e.key}
                      {...e}
                      newFileLength={newFileLength[e.key]}
                      setPreview={setPreviewFile}
                      handleFileChange={handleFileChange}
                      handleChangeForm={handleChangeActivityForm}
                    />
                    <Divider />
                  </Fragment>
                ))}
              </>
            ) : (
            <InputActives
              id={active.key}
              {...active}
              newFileLength={newFileLength[active.key]}
              setPreview={setPreviewFile}
              handleFileChange={handleFileChange}
              handleChangeForm={handleChangeActivityForm}
            />
            )}
          </Card>
        ))}

        {achievementFields.map((e) => (
          <Card key={e.index} style={{ width: '100%', marginTop: 32 }}>
            <Box padding={e.type ? '2px 4px 2px 16px' : '8px 16px'} display='flex' alignItems='center'>
              <Typography variant='h6' style={{ flexGrow: 1 }}>
                <b>{e.index}. {e.title}</b>
              </Typography>
              {e.type && (
                <>
                  <Tooltip arrow title={<ThanhTichAlert closable={false} />}><Info style={{ cursor: "pointer" }} /></Tooltip>
                  <IconButton color='primary' onClick={() => setDialog({ ...e, actionType: 'insert', data: {} })}><Add /></IconButton>
                </>
              )}
            </Box>
            {e.type ? (
              <>
                <Divider />
                <KPTable nonBorder={true} columns={columnRender(e, setDialog, setPreviewFile)} rows={e.data || []} />
              </>
            ) : (
              <>
                {e.children.map((item) => (
                  <>
                    <Divider />
                    <Box padding='2px 4px 2px 16px' display='flex' alignItems='center'>
                      <Typography variant='h6' align="justify" style={{ flexGrow: 1, marginRight: 32 }}>
                        <b>{item?.index}. {item?.title}</b>
                      </Typography>
                      <Tooltip arrow title={<ThanhTichAlert closable={false} />}>
                        <Info style={{ cursor: "pointer" }} />
                      </Tooltip>
                      <IconButton color='primary' onClick={() => setDialog({ ...item, actionType: 'insert', data: {} })}><Add /></IconButton>
                    </Box>
                    <Divider />
                    <KPTable nonBorder={true} columns={columnRender(item, setDialog, setPreviewFile)} rows={item.data || []} />
                  </>
                ))}
              </>
            )}
          </Card>
        ))}

        <Card id='summary-all' style={{ width: '100%', marginTop: 64 }}>
          <Box padding='2px 4px 2px 16px' display='flex' alignItems='center'>
            <Typography variant='h6'>
              <b>4.8. Ứng viên tự giới thiệu từ 1 đến 3 công trình nghiên cứu khoa học tiêu biểu nhất của bản thân tham gia xét giải thưởng đã được công bố</b>
            </Typography>
          </Box>
          <Divider />
          <CardContent>
            <Typography align='justify' className="kpt-textfield-label">
              Ứng viên phải là tác giả độc lập hoặc tác giả chính (chủ biên, hoặc tác giả thứ nhất, 
              hoặc tác giả liên hệ) của công trình. Trong bản giới thiệu, ứng viên cần làm nổi bật 
              các điểm sau đây:
              <br />
              <i style={{ fontSize: 16, fontWeight: 100 }}>
                <span>
                  (1) Tóm tắt quá trình đào tạo, công tác, thành tích nghiên cứu của ứng viên; 
                </span><br/>
                <span>
                  (2) Tên công trình nghiên cứu, mục tiêu, phương pháp và tính mới trong nghiên cứu (ý tưởng mới, tính sáng tạo, tính đột phá…)
                </span><br/>
                <span>
                  (3) Vai trò của ứng viên là tác giả độc lập hay tác giả chính (chủ biên, hay tác giả 
                  thứ nhất, tác giả liên hệ). Vai trò đóng góp của ứng viên đối với kết quả nghiên cứu 
                  (nếu công trình có nhiều hơn một tác giả)
                </span><br/>
                <span>
                  (4) Giá trị khoa học: uy tín tạp chí/nhà xuất bản, yếu tố tác động (Impact factor), số lượng trích dẫn (citation index).…
                </span><br/>
                <span>
                  (5) Giá trị thực tiễn: đóng góp vào sự phát triển khoa học, ứng dụng của công trình 
                  trong thực tiễn, ảnh hưởng của công trình đến cộng đồng và xã hội. (Nếu công trình 
                  đã ứng dụng thực tiễn thì có xác nhận của cơ quan/tổ chức sử dụng kết quả nghiên cứu).
                </span><br/>
              </i>
              <span style={{ fontSize: 16, fontWeight: 100 }}>
                <b>Yêu cầu: </b>Bản giới thiệu công trình có độ dài từ 1500 đến 2000 từ
              </span><br/>
              <span style={{ fontSize: 16, fontWeight: 100 }}>
                (<b>Lưu ý: </b>Trong trường hợp đơn vị giới thiệu không ký xác nhận hay không đủ thầm quyền xác 
                nhận công trình nghiên cứu tiêu biểu của ứng viên khai trong cùng bản đăng ký này, thì 
                ứng viên có thể in riêng phần giới thiệu công trình tiêu biểu để xin xác nhận của cơ 
                quan/tổ chức có thẩm quyền về chuyên môn/quản lý/sử dụng công trình này và đình kèm 
                trên hệ thống)
              </span>
            </Typography>
            {summaryText.map((summary, index) => (
              <Box key={index} marginBottom={2}>
                <Accordion component={Card} classes={{ root: classes.accordionRoot }}>
                  <AccordionSummary expandIcon={<ExpandMore />} classes={{
                    root: classes.accordionSummaryRoot,
                    content: classes.accordionSummaryContent,
                    expanded: classes.accordionSummaryExpanded,
                  }}>
                    <Box width='30%'>
                      <Typography><b>Công trình tiêu biểu {index + 1}</b></Typography>
                    </Box>
                    <Box width='70%'>
                      <Typography color='textSecondary'>
                        Click để hiển thị chi tiết/chỉnh sửa
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Interweave content={summary} />
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions>
                    <Button size='small' color='secondary' onClick={() => {
                      setSummaryText(summaryText.filter((_, id) => id != index));
                    }}>
                      Xóa
                    </Button>
                    <Button size='small' color='primary' onClick={() => {
                      setSummaryTextModalProps({
                        index,
                        values: { summary },
                        type: "update"
                      });
                    }}>
                      Chỉnh sửa
                    </Button>
                  </AccordionActions>
                </Accordion>
              </Box>
            ))}
            <Box display="flex" alignItems="center" justifyContent='center'>
              <Tooltip
                title="Tối đa 3 đánh giá"
              >
                <span>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={summaryText.length >= 3}
                    onClick={() => {
                      setSummaryTextModalProps({
                        index: summaryText.length,
                        values: { summary: " " },
                        type: "insert"
                      });
                    }}
                    style={{ margin: 'auto', marginTop: 8 }}
                  >
                    Thêm công trình tiêu biểu
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Dialog fullWidth maxWidth="md" open={!!summaryTextModalProps}>
              <DialogTitle style={{ padding: "4px 12px 4px 24px" }}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant="h5"><b>Tự giới thiệu, đánh giá</b></Typography>
                  <IconButton onClick={() => setSummaryTextModalProps(null)}><Close /></IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                {summaryTextModalProps && (
                  <KPTextform
                    component={Box}
                    textForm={textForm.summary}
                    values={summaryTextModalProps?.values}
                    handleChangeForm={(_, event) => setSummaryTextModalProps({
                      ...summaryTextModalProps,
                      values: { summary: event || ' ' }
                    })}
                  />
                )}
              </DialogContent>
              <DialogActions style={{ padding: "8px 24px" }}>
                <Button variant="outlined" onClick={() => setSummaryTextModalProps(null)}>
                  Hủy
                </Button>
                <Button color="secondary" variant="contained" onClick={() => {
                  const { values, index, type } = summaryTextModalProps;
                  if (type === "insert") {
                    setSummaryText([...summaryText, values.summary]);
                  } else {
                    setSummaryText([...summaryText].splice(index, 1, values.summary));
                  }
                  setSummaryTextModalProps(null);
                }}>
                  Thêm
                </Button>
              </DialogActions>
            </Dialog>
            {/* <KPTextform
              component={Box}
              textForm={textForm.summary}
              values={values}
              setValues={setValues}
            /> */}
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 64 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.summaryFile}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 16 }}>
          <CardContent>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  <i>
                    <b>Lưu ý: </b>Công văn giới thiệu, bảng điểm, bằng khen, giấy khen, giấy chứng nhận hay các văn
                    bản có liên quan làm minh chứng phải được scan bản gốc hoặc có chữ ký, đóng dấu đỏ của đơn vị chủ
                    quản/cơ quan, tổ chức có thẩm quyền.
                  </i>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color='secondary' checked={acceptRules} onChange={() => setAcceptRules(!acceptRules)} />}
                  label={
                    <Typography variant='subtitle1'>
                      <b style={{ fontFamily: 'SFProDisplay' }}>
                        Tôi xin hoàn toàn chịu trách nhiệm trước pháp luật, Ban tổ chức Giải thưởng về tính chính xác
                        và các thông tin đã khai trong hồ sơ đăng ký trực tuyến tham gia xét giải thưởng
                      </b>
                    </Typography>
                  }
                  style={{ marginTop: 48 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth disabled={!acceptRules} variant='outlined' onClick={() => handleSave('unsubmit')}>
                  Lưu bản nháp
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={!acceptRules}
                  form='form-update'
                  type='submit'
                  color='secondary'
                  variant='contained'
                >
                  Nộp hồ sơ
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>

      <ThanhTichInsert dialog={dialog} setDialog={setDialog} />


      <Loading open={loading} />
      <PreviewModal open={!!previewFile} url={previewFile} handleClose={() => setPreviewFile(null)} />

      <Dialog fullWidth maxWidth="xs" open={!loading && (isNotAvalaible || warningOpen)}>
        <DialogTitle style={{ padding: "4px 12px 4px 24px" }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant="h5"><b>Thông báo</b></Typography>
            {!isNotAvalaible && (
              // Chưa đóng cổng thì có thể tắt
              <IconButton onClick={() => setWarningOpen(false)}><Close /></IconButton>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" width="100%" marginBottom={4}>
            <WarningRounded style={{ margin: "auto", color: "#4da6ff", fontSize: 72 }} />
          </Box>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {isNotAvalaible ? (
              <>
                {isNotAvalaible}
                <br />
                <b>Giải thưởng Khuê Văn Các năm {openSeason?.year}</b>
                </>
            ) : (
              <b>{application?.status === "in_review" && "Hồ sơ đã được nộp trên hệ thống!"}</b>
            )}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "8px 24px" }}>
          {isNotAvalaible ? (
            <Button fullWidth color="secondary" variant="contained" href={ROUTER.HOME}>
              Về trang chủ
            </Button>
          ) : (
            <>
              {application?.status === "in_review" && (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      href={ROUTER.QCV.HOME}
                      variant="outlined"
                      startIcon={<Home />}
                      onClick={() => setWarningOpen(false)}
                    >
                      Trang chủ
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      startIcon={<Edit />}
                      style={{ float: "right" }}
                      onClick={() => setWarningOpen(false)}
                    >
                      Chỉnh sửa hồ sơ
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
