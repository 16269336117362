import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Paper, CircularProgress, Box } from '@material-ui/core';

import { APP_SCENE, ROLE } from '../../../constants';

import HoSoNote from '../../../containers/KVC/HoSo/HoSoNote';
import HoSoDetail from '../../../containers/KVC/HoSo/HoSoDetail';

export default function HoSo() {
	const role = useSelector(state => state.userReducer.user?.role);
	const loading = useSelector(state => state.formReducer.loading);

	const isSuperAdmin = useMemo(() => {
    const adminRole = APP_SCENE.toLowerCase() + '_admin_';
    return role === ROLE.SUPER_ADMIN || role?.includes(adminRole);
  }, [role]);

	if (!role || loading) {
    return (
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        height='calc(100vh - 64px)'
      >
        <CircularProgress />
      </Box>
    );
  }

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Paper className='paper' style={{ marginTop: 32, marginBottom: 16 }}>
				<HoSoDetail isSuperAdmin={isSuperAdmin} />
			</Paper>
      {isSuperAdmin && (
        <Paper className='paper' style={{ margin: '32px 0' }}>
          <HoSoNote />
        </Paper>
      )}
		</Box>
	)
}