import { PROFILE_TYPE } from '../constants';

const initialState = {
  profile: null,
  loading: false,
}

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_TYPE.GET_PROFILE_BY_USER_ID_LOADING:
    case PROFILE_TYPE.UPSERT_PROFILE_LOADING:
      return { ...state, loading: true };

    case PROFILE_TYPE.GET_PROFILE_BY_USER_ID_FAILURE:
    case PROFILE_TYPE.UPSERT_PROFILE_FAILURE:
      return { ...state, loading: false };

    case PROFILE_TYPE.GET_PROFILE_BY_USER_ID_SUCCESS:
      return { ...state, profile: action.profile, loading: false };
  
    case PROFILE_TYPE.UPSERT_PROFILE_SUCCESS:
      return { ...state, profile: action.profile, loading: false };

    default:
      return state
  }
}
