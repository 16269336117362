import { FORM_TYPE, HR_DEVELOPMENT_TYPE } from "../constants";

const initialState = {
  loading: false,
  personalHrDevelopmentList: [],
  totalHrDevelopmentList: [],
};

const sortFunc = (a, b) => a.id - b.id;

export function hrDevelopmentReducer(state = initialState, action) {
  switch (action.type) {
    case HR_DEVELOPMENT_TYPE.GET_ALL_HR_DEVELOPMENT_LOADING:
    case HR_DEVELOPMENT_TYPE.GET_HR_DEVELOPMENT_BY_PROFILE_ID_LOADING:
    case HR_DEVELOPMENT_TYPE.INSERT_HR_DEVELOPMENT_LOADING:
    case HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_LOADING:
    case HR_DEVELOPMENT_TYPE.DELETE_HR_DEVELOPMENT_LOADING:
    case HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_ACCEPTED_LOADING:
      return {...state, loading: true };

    case HR_DEVELOPMENT_TYPE.GET_ALL_HR_DEVELOPMENT_FAILURE:
    case HR_DEVELOPMENT_TYPE.GET_HR_DEVELOPMENT_BY_PROFILE_ID_FAILURE:
    case HR_DEVELOPMENT_TYPE.INSERT_HR_DEVELOPMENT_FAILURE:
    case HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_FAILURE:
    case HR_DEVELOPMENT_TYPE.DELETE_HR_DEVELOPMENT_FAILURE:
    case HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_ACCEPTED_FAILURE:
      return { ...state, loading: false };

    case HR_DEVELOPMENT_TYPE.GET_ALL_HR_DEVELOPMENT_SUCCESS:
      return {
        ...state,
        totalHrDevelopmentList: action.payload.sort(sortFunc),
        loading: false,
      };

    case HR_DEVELOPMENT_TYPE.GET_HR_DEVELOPMENT_BY_PROFILE_ID_SUCCESS:
      return {
        ...state,
        personalHrDevelopmentList: action.payload.sort(sortFunc),
        loading: false,
      };

    case HR_DEVELOPMENT_TYPE.INSERT_HR_DEVELOPMENT_SUCCESS:
      return {
        ...state,
        totalHrDevelopmentList: [
          ...(state.personalHrDevelopmentList || []),
          action.payload,
        ].sort(sortFunc),
        personalHrDevelopmentList: [
          ...(state.personalHrDevelopmentList || []),
          action.payload,
        ].sort(sortFunc),
        loading: false,
      };

    case HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_SUCCESS:
      return {
        ...state,
        totalHrDevelopmentList: state.totalHrDevelopmentList
          ? [
              ...state.totalHrDevelopmentList.filter(
                (e) => e.id !== action.payload.id
              ),
              action.payload,
            ].sort(sortFunc)
          : null,
        personalHrDevelopmentList: state.personalHrDevelopmentList
          ? [
              ...state.personalHrDevelopmentList.filter(
                (e) => e.id !== action.payload.id
              ),
              action.payload,
            ].sort(sortFunc)
          : null,
        loading: false,
      };

    case HR_DEVELOPMENT_TYPE.DELETE_HR_DEVELOPMENT_SUCCESS:
      return {
        ...state,
        totalHrDevelopmentList: state.totalHrDevelopmentList
          ?.filter((e) => e.id !== action.id)
          .sort(sortFunc),
        personalHrDevelopmentList: state.personalHrDevelopmentList
          ?.filter((e) => e.id !== action.id)
          .sort(sortFunc),
        loading: false,
      };

    case HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_ACCEPTED_SUCCESS:
      return {
        ...state,
        totalHrDevelopmentList: state.totalHrDevelopmentList
          ? [
              ...state.totalHrDevelopmentList.filter(
                // (e) => e.id !== action.payload.id
                (e) => action.payload.find((elm) => e.id === elm)
              ),
              action.payload,
            ].sort(sortFunc)
          : null,
        personalHrDevelopmentList: state.personalHrDevelopmentList
          ? [
              ...state.personalHrDevelopmentList.filter(
                // (e) => e.id !== action.payload.id
                (e) => action.payload.find((elm) => e.id === elm)
              ),
              action.payload,
            ].sort(sortFunc)
          : null,
        loading: false,
      };

    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        personalHrDevelopmentList: action.payload.hrDevelopments.sort(sortFunc)
      }

    default:
      return state;
  }
}
