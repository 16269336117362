import { HEADERS } from ".";

export const SCORE_INFORMATION_API = {
  getAllScoreInformation: () => ({
    endPoint: '/api/v1/qcvInfo/all',
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  getScoreInformationBySeasonId: (id) => ({
    endPoint: `/api/v1/qcvInfo/ss/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  updateScoreInformation: () => ({
    endPoint: '/api/v1/qcvInfo/update',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
};

export const SCORE_INFORMATION_TYPE = {
  GET_ALL_SCORE_INFORMATION_LOADING: 'GET_ALL_SCORE_INFORMATION_LOADING',
  GET_ALL_SCORE_INFORMATION_SUCCESS: 'GET_ALL_SCORE_INFORMATION_SUCCESS',
  GET_ALL_SCORE_INFORMATION_FAILURE: 'GET_ALL_SCORE_INFORMATION_FAILURE',

  UPDATE_SCORE_INFORMATION_LOADING: 'UPDATE_SCORE_INFORMATION_LOADING',
  UPDATE_SCORE_INFORMATION_SUCCESS: 'UPDATE_SCORE_INFORMATION_SUCCESS',
  UPDATE_SCORE_INFORMATION_FAILURE: 'UPDATE_SCORE_INFORMATION_FAILURE',
}