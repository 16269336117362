import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROLE, ROUTER } from '../../../constants';

import DanhSachHoSoTable from '../../../containers/GTNS/DanhSachHoSo/DanhSachHoSoTable';

function DanhSachHoSo() {
  const navigate = useNavigate();
	const role = useSelector(state => state.userReducer.user?.role);

  useEffect(() => {
    const hasPermission = !!role && (role === ROLE.SUPER_ADMIN || role === ROLE.ADMIN_GTNS);
    if (!hasPermission) navigate(ROUTER.PAGE_401);
  }, [role]);

	return (
		<Box padding='0 8px' margin='32px 0' minHeight='calc(100vh - 256px - 128px)'>
			<DanhSachHoSoTable />
		</Box>
	)
}

export default DanhSachHoSo;
