import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Typography,
	IconButton,
	Divider,
	Button,
  Box,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import DuAnForm from '../DuAnForm';
import { dateFormatter } from '../../../../utils';
import { fileActions, researchProjectActions } from '../../../../actions';

export default function DuAnUpdate(props) {
	const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);

	const [values, setValues] = useState(null);
	const [disabled, setDisabled] = useState(true);

	const handleSubmit = () => {
		if (values?.previewFile?.[0]) {
			dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0]))
			.then((response) => {
				dispatch(researchProjectActions.updateResearchProject({
					...values,
					link: response,
					start_at: dateFormatter(values.start_at),
					finish_at: dateFormatter(values.finish_at),
				}));
			});
    } else {
      dispatch(researchProjectActions.updateResearchProject({
				...values,
				start_at: dateFormatter(values.start_at),
				finish_at: dateFormatter(values.finish_at),
			}));
    }
		props.onClose();
	}

	const handleEdit = () => { setDisabled(false) };

	const handleClose = () => {
		if (disabled) props.onClose();
		else setDisabled(true);
	}

	useEffect(() => {
		setDisabled(true);
		setValues({
			...props.detail,
			start_at: props.detail?.start_at? dayjs(props.detail?.start_at).format('DD/MM/YYYY') : null,
			finish_at: props.detail?.finish_at? dayjs(props.detail?.finish_at).format('DD/MM/YYYY') : null,
		});
	}, [props.detail]);

	return (
		<Dialog fullWidth maxWidth='sm' open={!!(props.detail)}>
			<DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography variant='h5'>Chi tiết đề tài/dự án</Typography>
					<IconButton onClick={props.onClose}><Close /></IconButton>
				</Box>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<DuAnForm
					id='du-an'
					values={values}
					disabled={disabled}
					setValues={setValues}
					handleSubmit={handleSubmit}
				/>
			</DialogContent>
			<DialogActions style={{ padding: '8px 24px' }}>
				<Button variant='outlined' onClick={handleClose}>Huỷ</Button>
				{disabled ? (
					<Button variant='outlined' color='secondary' onClick={handleEdit}>Chỉnh sửa</Button>
				) : (
					<Button variant='contained' color='secondary' onClick={handleSubmit}>Xác nhận</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}