"use strict";

module.exports = {
  mainContentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml",
  headerContentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.header+xml",
  footerContentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.footer+xml",
  slideContentType: "application/vnd.openxmlformats-officedocument.presentationml.slide+xml",
  mainWithMacroContentType: "application/vnd.ms-word.document.macroEnabled.main+xml",
  mainTemplateContentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.template.main+xml",
  mainTemplateWithMacroContentType: "application/vnd.ms-word.template.macroEnabledTemplate.main+xml"
};