import { SOLUTIONS_TYPE, FORM_TYPE } from '../constants';

const initialState = {
  loading: false,
  personalSolutionList: [],
  totalSolutions: [],
};

const sortFunc = (a, b) => a.id - b.id;

export function solutionReducer(state = initialState, action) {
  switch (action.type) {
    case SOLUTIONS_TYPE.GET_ALL_SOLUTION_LOADING:
    case SOLUTIONS_TYPE.GET_SOLUTION_BY_PROFILE_ID_LOADING:
    case SOLUTIONS_TYPE.INSERT_SOLUTION_LOADING:
    case SOLUTIONS_TYPE.UPDATE_SOLUTION_LOADING:
    case SOLUTIONS_TYPE.DELETE_SOLUTION_LOADING:
      return { ...state, loading: true };

    case SOLUTIONS_TYPE.GET_ALL_SOLUTION_FAILURE:
    case SOLUTIONS_TYPE.GET_SOLUTION_BY_PROFILE_ID_FAILURE:
    case SOLUTIONS_TYPE.INSERT_SOLUTION_FAILURE:
    case SOLUTIONS_TYPE.UPDATE_SOLUTION_FAILURE:
    case SOLUTIONS_TYPE.DELETE_SOLUTION_FAILURE:
      return { ...state, loading: false };

    case SOLUTIONS_TYPE.GET_ALL_SOLUTION_SUCCESS:
      return { ...state, totalSolutions: action.payload.sort(sortFunc), loading: false };

    case SOLUTIONS_TYPE.GET_SOLUTION_BY_PROFILE_ID_SUCCESS:
      return { ...state, personalSolutionList: action.payload.sort(sortFunc), loading: false };

    case SOLUTIONS_TYPE.INSERT_SOLUTION_SUCCESS:
      return {
        ...state,
        totalSolutions: [...state.personalSolutionList, action.payload].sort(sortFunc),
        personalSolutionList: [...state.personalSolutionList, action.payload].sort(sortFunc),
        loading: false,
      };

    case SOLUTIONS_TYPE.UPDATE_SOLUTION_SUCCESS:
      return {
        ...state,
        totalSolutions: state.totalSolutions
          ? [...state.totalSolutions.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        personalSolutionList: state.personalSolutionList
          ? [...state.personalSolutionList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        loading: false,
      };

    case SOLUTIONS_TYPE.DELETE_SOLUTION_SUCCESS:
      return {
        ...state,
        totalSolutions: state.personalSolutionList?.filter((e) => e.id !== action.id).sort(sortFunc),
        personalSolutionList: state.personalSolutionList.filter((e) => e.id !== action.id).sort(sortFunc),
        loading: false,
      };

    case SOLUTIONS_TYPE.UPDATE_SOLUTION_ACCEPTED_SUCCESS:
      return {
        ...state,
        // totalSolutions: state.totalSolutions ? [
        //     ...state.totalSolutions.filter((e) => e.id !== action.payload.id),
        //     action.payload
        //   ].sort(sortFunc) : null,
        // personalSolutionList: state.personalSolutionList ? [
        //   ...state.personalSolutionList?.filter((e) => e.id !== action.payload.id),
        //   action.payload
        // ].sort(sortFunc) : null,
        // update
        loading: false,
      };

    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        personalSolutionList: action.payload?.solutions.sort(sortFunc),
      };

    default:
      return state;
  }
}
