import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  logo30: {
    height: 0,
    [theme.breakpoints.up('lg')]: {
      marginLeft: (w) => `calc((100vw - 960px) / 4 - ${w / 2}px)`,
    },
  },
  frame30: {
    bottom: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  }
}));