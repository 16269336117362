import { HEADERS } from ".";

export const TECH_COMPANY_API = {
  getAllTechCompanyList: () => ({
    endPoint: "/api/v1/techCompany/all",
    headers: HEADERS.TOKEN_HEADER,
    method: "GET",
  }),
  getTechCompanyByProfileId: (id) => ({
    endPoint: `/api/v1/techCompany/${id}/list`,
    headers: HEADERS.TOKEN_HEADER,
    method: "GET",
  }),
  insertTechCompany: () => ({
    endPoint: "/api/v1/techCompany/create",
    headers: HEADERS.TOKEN_HEADER,
    method: "POST",
  }),
  updateTechCompany: () => ({
    endPoint: "/api/v1/techCompany/update",
    headers: HEADERS.TOKEN_HEADER,
    method: "PUT",
  }),
  deleteTechCompany: (id) => ({
    endPoint: `/api/v1/techCompany/delete/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: "DELETE",
  }),
  updateTechCompanyAccepted: () => ({
    endPoint: "/api/v1/formTechCompany/updateScore_Accepted",
    headers: HEADERS.TOKEN_HEADER,
    method: "PUT",
  }),
};

export const TECH_COMPANY_TYPE = {
  GET_ALL_TECH_COMPANY_LOADING: "GET_ALL_TECH_COMPANY_LOADING",
  GET_ALL_TECH_COMPANY_SUCCESS: "GET_ALL_TECH_COMPANY_SUCCESS",
  GET_ALL_TECH_COMPANY_FAILURE: "GET_ALL_TECH_COMPANY_FAILURE",

  GET_TECH_COMPANY_BY_PROFILE_ID_LOADING:
    "GET_TECH_COMPANY_BY_PROFILE_ID_LOADING",
  GET_TECH_COMPANY_BY_PROFILE_ID_SUCCESS:
    "GET_TECH_COMPANY_BY_PROFILE_ID_SUCCESS",
  GET_TECH_COMPANY_BY_PROFILE_ID_FAILURE:
    "GET_TECH_COMPANY_BY_PROFILE_ID_FAILURE",

  INSERT_TECH_COMPANY_LOADING: "INSERT_TECH_COMPANY_LOADING",
  INSERT_TECH_COMPANY_SUCCESS: "INSERT_TECH_COMPANY_SUCCESS",
  INSERT_TECH_COMPANY_FAILURE: "INSERT_TECH_COMPANY_FAILURE",

  UPDATE_TECH_COMPANY_LOADING: "UPDATE_TECH_COMPANY_LOADING",
  UPDATE_TECH_COMPANY_SUCCESS: "UPDATE_TECH_COMPANY_SUCCESS",
  UPDATE_TECH_COMPANY_FAILURE: "UPDATE_TECH_COMPANY_FAILURE",

  DELETE_TECH_COMPANY_LOADING: "DELETE_TECH_COMPANY_LOADING",
  DELETE_TECH_COMPANY_SUCCESS: "DELETE_TECH_COMPANY_SUCCESS",
  DELETE_TECH_COMPANY_FAILURE: "DELETE_TECH_COMPANY_FAILURE",

  UPDATE_TECH_COMPANY_ACCEPTED_LOADING: "UPDATE_TECH_COMPANY_ACCEPTED_LOADING",
  UPDATE_TECH_COMPANY_ACCEPTED_SUCCESS: "UPDATE_TECH_COMPANY_ACCEPTED_SUCCESS",
  UPDATE_TECH_COMPANY_ACCEPTED_FAILURE: "UPDATE_TECH_COMPANY_ACCEPTED_FAILURE",
};

export const TECH_COMPANY_CONSTANTS = {
  roleOptions: [
    { key: "founder", title: "Sáng lập/Chủ doanh nghiệp", value: "founder" },
    { key: "manager", title: "Quản lý", value: "manager" },
  ],

  productionOptions: [
    { key: "international", title: "Quốc tế", value: "international" },
    { key: "domestic", title: "Quốc gia", value: "domestic" },
    { key: "local", title: "Địa phương/Đơn vị", value: "local" },
  ],
};
