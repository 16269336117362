import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import BaiBaoQuocTeForm from '../BaiBaoQuocTeForm';
import BaiBaoTrongNuocForm from '../BaiBaoTrongNuocForm';
import BaiBaoQuocTeKhacForm from '../BaiBaoQuocTeKhacForm';

import { fileActions, researchActions } from '../../../../actions';

function BaiBaoUpdate(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);

  const [values, setValues] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const handleEdit = () => setDisabled(false);

  const handleSubmit = () => {
    if (values?.previewFile?.[0]) {
      dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0]))
      .then((response) => dispatch(researchActions.updateResearch({ ...values, preview: response })));
    } else {
      dispatch(researchActions.updateResearch(values));
    }
    props.onClose();
  }
  
  const handleClose = () => {
    if (disabled) props.onClose();
    else setDisabled(true);
  }

  useEffect(() => {
    setDisabled(true);
    setValues(props.detail);
  }, [props.detail]);

  return (
    <Dialog fullWidth maxWidth='sm' open={!!(props.detail)}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>Chi tiết bài báo</Typography>
          <IconButton onClick={props.onClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {props.form === 'international' && (
          <BaiBaoQuocTeForm
            id='bai-bao'
            values={values}
            disabled={disabled}
            setValues={setValues}
            handleSubmit={handleSubmit}
          />
        )}
        {props.form === 'other' && (
          <BaiBaoQuocTeKhacForm
            id='bai-bao'
            values={values}
            disabled={disabled}
            setValues={setValues}
            handleSubmit={handleSubmit}
          />
        )}
        {props.form === 'domestic' && (
          <BaiBaoTrongNuocForm
            id='bai-bao'
            values={values}
            disabled={disabled}
            setValues={setValues}
            handleSubmit={handleSubmit}
          />
        )}
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button variant='outlined' color='secondary' onClick={handleClose}>Huỷ</Button>
        {disabled ? (
          <Button variant='contained' color='default' onClick={handleEdit}>Chỉnh sửa</Button>
          ) : (
          <Button form='bai-bao' variant='contained' color='secondary' onClick={handleSubmit}>Xác nhận</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default BaiBaoUpdate;
