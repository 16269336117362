import React from "react";
import { Box, Typography } from "@material-ui/core";

import img401 from "../../assets/image/401.png";

export default function GioiThieu() {
  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height='calc(100vh - 64px)'
    >
      <img alt='404' src={img401} style={{ height: 384 }} />
      <Typography variant='h5' color='primary' style={{ marginTop: 16 }}>
        <b>Bạn không có quyền truy cập vào đường dẫn này!</b>
      </Typography>
		</Box>
  );
}
