import React from "react";
import { Box, Hidden } from "@material-ui/core";

import { useStyles } from "./styled";

import cytast30 from "../../../assets/image/home/cytast30.jpg";
import frame30 from "../../../assets/image/home/frame-30.png";

const LOGO_30_WIDTH = 200;
const LOGO_30_HEIGHT = 519 / 549 * 200;
const LOGO_30_SCALE = LOGO_30_WIDTH / 549;

function Logo30() {
  const classes = useStyles(LOGO_30_WIDTH);

  return (
    <Hidden smDown>
      <Box
        className={classes.logo30}
        position='sticky'
        height={0}
        bottom={128}
      >
        <Box position='relative' width={549 * LOGO_30_SCALE} height={519 * LOGO_30_SCALE}>
          {/* <video loop muted autoPlay src={video30} style={{
            width: `calc(100% - ${96 * LOGO_30_SCALE}px)`,
            height: `calc(100% - ${56 * LOGO_30_SCALE}px)`,
            top: -LOGO_30_HEIGHT,
            bottom: 0,
            left: 48 * LOGO_30_SCALE,
            right: 48 * LOGO_30_SCALE,
            position: 'absolute',
          }} /> */}
          <img src={cytast30} draggable={false} style={{
            width: `calc(100% - ${96 * LOGO_30_SCALE + 2}px)`,
            height: `calc(100% - ${56 * LOGO_30_SCALE + 4}px)`,
            top: -LOGO_30_HEIGHT,
            bottom: 0,
            left: 48 * LOGO_30_SCALE,
            right: 48 * LOGO_30_SCALE,
            position: 'absolute',
          }} />
          <img className={classes.frame30} src={frame30} draggable={false} style={{ top: -LOGO_30_HEIGHT }} />
        </Box>
      </Box>
    </Hidden>
  );
}

export default Logo30;
