import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import BaoCaoForm from "../BaoCaoForm/BaoCaoForm";
import { fileActions, reportsActions } from "../../../../actions";

export default function BaoCaoInsert(props) {
  const dispatch = useDispatch();
  const profileId = useSelector((state) => state.profileReducer.profile?.id);

  const [values, setValues] = useState(null);

  const handleChangeForm = (name, event, type) => {
    if (type === "date") {
      setValues({ ...values, [name]: event });
    } else if (type === "richText") {
      setValues({ ...values, [name]: event || " " });
    } else if (type === "file") {
      setValues({ ...values, [name]: event });
    } else if (type === "int") {
      setValues({ ...values, [name]: parseInt(event.target.value) });
    } else if (type === "float") {
      setValues({ ...values, [name]: parseFloat(event.target.value) });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleSubmit = () => {
    if (values?.previewFile?.[0]) {
      dispatch(
        fileActions.importPreviewFile(profileId, values?.previewFile[0])
      ).then((response) =>
        dispatch(
          reportsActions.insertReports([
            {
              ...values,
              profileId,
              preview: response,
            },
          ])
        )
      );
    } else {
      dispatch(reportsActions.insertReports([{ ...values, profileId }]));
    }
    setValues(null);
    props.onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
    >
      <DialogTitle style={{ padding: "4px 12px 4px 24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Thêm báo cáo</Typography>
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <BaoCaoForm
          id="bao-cao"
          values={values}
          setValues={setValues}
          handleSubmit={handleSubmit}
          handleChangeForm={handleChangeForm}
        />
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px" }}>
        <Button
          variant="outlined"
          onClick={props.onClose}
        >
          Huỷ
        </Button>
        <Button
          form="bao-cao"
          type="submit"
          variant="contained"
          color="secondary"
        >
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
}
