import React from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

function PreviewModal(props) {
  return (
    <Dialog fullWidth maxWidth='md' open={props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>File minh chứng</Typography>
          <IconButton onClick={props.handleClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {props.url && (
          <iframe
            frameBorder={0}
            src={`${process.env.REACT_APP_DOMAIN}${props.url}`}
            style={{ minHeight: '75vh', width: 'calc(100% - 4px)', overflow: 'auto' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          target='_blank'
          rel='noreferrer'
          href={props.url ? `${process.env.REACT_APP_DOMAIN}${props.url}` : null}
          variant='outlined'
        >
          Mở bản gốc
        </Button>
        <Button onClick={props.handleClose} color='secondary' variant='contained'>Đóng</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewModal;
