/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {
	Grid,
	Typography,
	Card,
	CardMedia,
	CardActionArea,
	CardContent,
} from '@material-ui/core';

import { ROUTER, FORM_CONSTANTS } from '../../../../constants';
import { formActions } from '../../../../actions';
import noImage from '../../../../assets/image/app/no-image.png';
import { useNavigate } from 'react-router-dom';

export default function DanhSachHoSoGrid() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);
	const applicationFilter = useSelector(state => state.formReducer.applicationList);
	const { fielQCVdOptions } = FORM_CONSTANTS;

	// useEffect(() => {
	// 	console.log('loi')
	// 	dispatch(formActions.filterApplicationForm({
	// 		seasonId: openSeasonId,
	// 		category:'qcv',
	// 		status: ['approved']
	// 	}));
	// }, []);

	return (
		<Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
			{applicationFilter?.map((e) => (
				<Grid key={e?.id} item xs={12} sm={4} md={3} style={{ marginTop: 32 }}>
					<Card style={{ border: 'none' }}>
						<CardActionArea onClick={() => navigate(`${ROUTER.QCV.HO_SO}/${e.id}`)}>
							<CardMedia
								component='img'
								style={{ width: 120, height: 180, margin: 'auto' }}
								src={e?.profile?.photoUrl ? `${process.env.REACT_APP_DOMAIN}${e?.profile?.photoUrl}` : noImage}
							/>
							<CardContent>
							<Typography variant='subtitle1' style={{ color: '#ee6064',textAlign: 'center' }}><b>{e.profile?.name}</b></Typography>
							<Typography variant='subtitle2' style={{ color: '#55626a', textAlign: 'center' }}>
								Lĩnh vực: {fielQCVdOptions.find((field) => field.value === e?.field)?.title}
							</Typography>
								
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			))}
		</Grid>
	)
}
