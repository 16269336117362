/* eslint-disable max-len */
import dayjs from "dayjs";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import Docxtemplater from "docxtemplater";

import { saveAs } from "file-saver";

import sample from "../assets/docs/KVC2024_Mẫu Đăng ký tham gia giải thưởng.docx";
import HTMLModule from "./docxtemplater-html-module/js";

import {
  FORM_CONSTANTS,
  REPORTS_CONSTANTS,
  RESEARCH_CONSTANTS,
  MONOGRAPHS_CONSTANTS,
  RESEARCHPROJECTS_CONSTANTS,
} from "../constants";
import { KEYS } from "../constants/enums";

const loadFile = (url, callback) => PizZipUtils.getBinaryContent(url, callback);

const parseJson = (value) => {
  try {
    return JSON.parse(value || "[]");
  } catch (e) {
    return [];
  }
}

const cleanArray = (arr, keys) => arr.map((item) => {
  const cleanedItem = {};
  keys.forEach((key) => cleanedItem[key] = item?.[key] || "");
  return cleanedItem;
});

const checkField = (field, formField) => field === formField ? "☒" : "☐";
const previewCell = (domain, value) => `<a href="${domain || ''}${value || ''}">Minh chứng</a>`;

const exportProfile = (form) => {
  const domain = process.env.REACT_APP_DOMAIN;

  loadFile(sample, (error, content) => {
    if (error) throw error;

    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      linebreaks: true,
      paragraphLoop: true,
      modules: [new HTMLModule({})],
    });

    // Đoạn này phải làm cẩn thận, để né những trường hợp undefined
    const process = cleanArray(parseJson(form?.process), ['works', 'startAt', 'finishAt', 'department']);
    const communicationActivity = cleanArray(
      parseJson(form?.communicationActivity).map((item) => ({
        ...item,
        achievementFile: previewCell(domain, item?.achievementFile),
        typeOfAchievement: FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find(l => l.value === item.otherAchievementType)?.title,
      })),
      ['otherAchievementContent', 'typeOfAchievement', 'achievementFile']
    );
    const socialActivity = cleanArray(
      parseJson(form?.socialActivity).map((item) => ({
        ...item,
        socialActivityPreviewFile: previewCell(domain, item?.socialActivityPreviewFile),
      })),
      ['socialActivityContent', 'socialActivityPreviewFile']
    );
    const socialTrainingActivity = cleanArray(
      parseJson(form?.personalAchievement).map((item) => ({
        ...item,
        personalAchievementPreviewFile: previewCell(domain, item?.personalAchievementPreviewFile),
      })),
      ['personalAchievementContent', 'personalAchievementPreviewFile']
    );

    console.log('process', form?.process);
    console.log('communicationActivity', form?.communicationActivity);
    console.log('socialActivity', form?.socialActivity);
    console.log('socialTrainingActivity', form?.personalAchievement);

    const formScientificResearch = cleanArray(
      form.formScientificResearch.map((item) => ({
        ...item,
        ...item.scientificResearch,
        type: [
          ...RESEARCH_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
          ...RESEARCH_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
        ].find((t) => t.value === item.scientificResearch.type)?.title,
        link: item.scientificResearch.link
          ? previewCell(null, item.scientificResearch.link)
          : previewCell(domain, item.scientificResearch.preview),
        role: RESEARCH_CONSTANTS.ROLE_OPTIONS.find((r) => r.value === item.scientificResearch.role)?.title,
      })),
      Object.keys(form.formScientificResearch?.[0]?.scientificResearch || {})
    );

    console.log(form);

    const formScientificReport = cleanArray(
      form.formScientificReport.map((item) => ({
        ...item,
        ...item.scientificReport,
        type: REPORTS_CONSTANTS.TYPE_OPTIONS.ALL.find((t) => t.value === item.scientificReport.type)?.title,
        link: item.scientificReport.link
          ? previewCell(null, item.scientificReport.link)
          : previewCell(domain, item.scientificReport.preview),
        role: REPORTS_CONSTANTS.ROLE_OPTIONS.find((r) => r.value === item.scientificReport.role)?.title,
      })),
      Object.keys(form.formScientificReport?.[0]?.scientificReport || {})
    );


    const formResearchProject = cleanArray(
      form.formResearchProject.map((item) => ({
        ...item,
        ...item.researchProject,
        link: previewCell(domain, item.researchProject.link),
        type: RESEARCHPROJECTS_CONSTANTS.TYPO_OPTIONS.find((r) => r.value === item.researchProject.type)?.title,
        level: RESEARCHPROJECTS_CONSTANTS.LEVEL_OPTIONS.find((r) => r.value === item.researchProject.level)?.title,
      })),
      Object.keys(form.formResearchProject?.[0]?.researchProject || {})
    );

    const formMonograph = cleanArray(
      form.formMonograph.map((item) => ({
        ...item,
        ...item.monograph,
        link: previewCell(domain, item.monograph.link),
        type: [
          ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
          ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
        ].find((r) => r.value === item.monograph.type)?.title,
        role: MONOGRAPHS_CONSTANTS.ROLE_OPTIONS.find((r) => r.value === item.monograph.role)?.title,
      })),
      Object.keys(form.formMonograph?.[0]?.monograph || {})
    );

    const formScientificReportQuocte = formScientificReport.filter(item => REPORTS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((t) => t.title === item.type));
    const formScientificReportQuocgia = formScientificReport.filter(item => REPORTS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((t) => t.title === item.type));

    const formScientificResearchQuocte = formScientificResearch.filter(item => item.filterType === KEYS.INTERNATIONAL);
    const formScientificResearchQuocgia = formScientificResearch.filter(item => item.filterType === KEYS.DOMESTIC);

    var summary = '';
    JSON.parse(form.summary).forEach((s) => summary += s + '<br/>')

    const data = {
      ...form,
      ...form.profile,

      birth: dayjs(form.profile.birth).format("DD/MM/YYYY"),
      gender: FORM_CONSTANTS.GENDER_OPTIONS.find(item => item.value === form.profile.gender)?.title || "",
      academicLevel: FORM_CONSTANTS.ACADEMIC_LEVEL_OPTIONS.find(item => item.value === form.academicLevel)?.title || "",

      summary,

      process,
      socialActivity,
      communicationActivity,
      socialTrainingActivity,
      processTitle: process.length > 0,
      socialActivityTitle: socialActivity.length > 0,
      communicationActivityTitle: communicationActivity.length > 0,
      socialTrainingActivityTitle: socialTrainingActivity.length > 0,
      
      formScientificResearchQuocte,
      formScientificResearchQuocteTitle: formScientificResearchQuocte.length > 0,

      formScientificReportQuocte,
      formScientificReportQuocteTitle: formScientificReportQuocte.length > 0,

      formScientificResearchQuocgia,
      formScientificResearchQuocgiaTitle: formScientificResearchQuocgia.length > 0,

      formScientificReportQuocgia,
      formScientificReportQuocgiaTitle: formScientificReportQuocgia.length > 0,

      formMonograph,
      formResearchProject,
      formScientificReport,
      formMonographTitle: formMonograph.length > 0,
      formResearchProjectTitle: formResearchProject.length > 0,
      formScientificReportTitle: formScientificReport.length > 0,

      isLaw: checkField("luat", form.field),
      isEdu: checkField("giao_duc", form.field),
      isEco: checkField("kinh_te", form.field),
      isVhnt: checkField("van_hoa", form.field),
      isThctxhh: checkField("triet_hoc", form.field),
      isShkchdthnh: checkField("su_hoc", form.field),
    }

    console.log(data);
    doc.render(data);

    const blob = doc.getZip().generate({
      type: "blob",
      mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    saveAs(blob, "File Chi tiết hồ sơ.docx");
  });
}


export default exportProfile;