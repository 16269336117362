import React from 'react';
import { Popover } from '@material-ui/core';

function PopupMenu(props) {
  const {
    render,
    anchorEl,
    position,
    containerStyle,
    handleClose,
  } = props;

  return (
    <Popover
      keepMounted
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: position || 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: position || 'center' }}
      PaperProps={{
        style: { ...containerStyle }
      }}
    >
      {render}
    </Popover>
  );
}

export default PopupMenu;
