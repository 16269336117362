import dayjs from 'dayjs';
import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import KPTextform from '../../KPTComponents/KPTextform';

function DuyetThanhTichModal(props) {
  const [scoreInformation, setScoreInformation] = useState(null);

  const scoreInformationList = useSelector(state => 
    state.scoreInformationReducer.scoreInformationList?.filter((e) => e.type === 'qcv')
  );

  const code = useMemo(() => {
    switch (props.type) {
      case 'formSolution':
        if (props.info?.type === 'sc') return '1.1';
        else if (props.info?.type === 'scqg') return '1.2';
        else if (props.info?.type === 'gp') return '1.3';
        break;
      case 'formScientificResearch':
        if (props.info?.type === 'q1') return '2.1';
        else if (props.info?.type === 'q2') return '2.2';
        // else if (props.info?.type === 'q3') return '2.3';
        // else if (props.info?.type === 'q4' || props.info?.type === 'scopus') return '2.4';
        else if (props.info?.type === 'q6') return '2.3';
        break;
      case 'formScientificReport':
        if (props.info?.type === 'q1') return '3.1';
        else if (props.info?.type === 'q2') return '3.2';
        // else if (props.info?.type === 'q3') return '3.3';
        // else if (props.info?.type === 'q4') return '3.4';
        else if (props.info?.type === 'b1') return '3.3';
        else if (props.info?.type === 'b2-1') return '3.4';
        // else if (props.info?.type === 'b2-2') return '3.7';
        // else if (props.info?.type === 'b3') return '3.8';
        break;
      case 'formMonograph':
        if (props.info?.type === 's1' && props.info?.issn !== null) return '5.1';
        // else if (props.info?.type === 's1' && props.info?.issn === null) return '5.1-b';
        else if (props.info?.type === 's2') return '5.2';
        else if (props.info?.type === 's3') return '5.3';
        else if (props.info?.type === 's4') return '5.4';
        break;
      case 'formResearchProject':
        if (props.info?.level === 'domestic' && props.info?.type === 'd1') return '4.1';
        // else if (props.info?.level === 'domestic' && props.info?.type === 'd2') return '4.1-b';
        // else if (props.info?.level === 'domestic' && props.info?.type === 'd3') return '4.1-c';
        else if (props.info?.level === 'ministry' && props.info?.type === 'd1') return '4.2';
        // else if (props.info?.level === 'school' && props.info?.type === 'd1') return '4.3';
        else if (props.info?.level === 'vn' && props.info?.type === 'd1') return '4.3';
        break;
      case 'formReward':
        if (props.info?.type === 'o1') return '7.1-a';
        else if (props.info?.type === 'o2') return '7.1-b';
        else if (props.info?.type === 'o3') return '7.1-c';
        else if (props.info?.type === 'g1') return '7.2-a';
        else if (props.info?.type === 'g2') return '7.2-b';
        else if (props.info?.type === 'g3-1') return '7.2-c';
        else if (props.info?.type === 'g3-2') return '7.2-d';
        else if (props.info?.type === 'g4') return '7.2-e';
        else if (props.info?.type === 'g5') return '7.2-f';
        break;
      // case 'formHrDevelopment':
      //   if (props.info?.type === 'daoTao' && props.info?.academicLevel === 'doctor') {
      //     const roleLower = props.info?.role.toLowerCase();
      //     if (roleLower === 'other') {
      //       return '8.1-b';
      //     } else if (roleLower === 'main') {
      //       return '8.1-a';
      //     } else {
      //       return null;
      //     }
      //   }
        // else if (props.info?.type === 'daoTao' && props.info?.academicLevel === 'master') return '8.2';
        // else if (props.info?.type === 'daoTao' && props.info?.academicLevel === 'bachelor') return '8.3';
        // else if (props.info?.type === 'boiDuong' && props.info?.traineeNum > 500) return '7-2a';
        // else if (props.info?.type === 'boiDuong' && 200 <= props.info?.traineeNum && props.info?.traineeNum <= 500) return '7-2b';
        // else if (props.info?.type === 'boiDuong' && props.info?.traineeNum < 200) return '7-2c';
        // break;
      case 'formTechCompany':
        if (props.info?.applicationRange === 'international') return '6.1';
        else if (props.info?.applicationRange === 'domestic') return '6.2';
        else if (props.info?.applicationRange === 'local') return '6.3';
        break;
      default:
        break;
    }
  }, [props.info, props.type]);


  useEffect(() => {
    if (code) {
      var info;
      var bonus = null;
      const minBonusYear = dayjs().year() - 3 + 1;
      const sInfo = scoreInformationList?.find((e) => e?.code == code);

      if (props.type === 'formScientificResearch' || props.type === 'formScientificReport' || props.type === 'formMonograph') {
        const diemChiaDeu = (sInfo?.value / 2) / props.info?.numberAuthor;
        const diemTgChinh = (props.info?.role === 'main') ? (sInfo?.value / 2) / props.info?.numberMain : 0;
        const tong = diemChiaDeu + diemTgChinh;
        if (props.info?.publishYear >= minBonusYear) {
          bonus = {
            goc: tong,
            text: <>Năm xuất bản của thành tích là <b>{props.info?.publishYear}</b>, ứng viên được cộng thêm <b>10%</b> điểm của nội dung đánh giá.</>,
            diem: tong * 0.1
          }
        }
        info = {
          ...sInfo,
          bonus,
          value: Math.round((tong + (bonus?.diem || 0)) * 100) / 100
        };
      } else if (props.type === 'formSolution') {
        var numberAuthor = 1;
        if (props.info?.author.includes(' and ')) numberAuthor = 2;
        else if (props.info?.author.includes(';')) numberAuthor = props.info?.author.split(';').length;
        else if (props.info?.author.includes(',')) numberAuthor = props.info?.author.split(',').length;
        else if (props.info?.author.includes('-')) numberAuthor = props.info?.author.split('-').length;
        const tong = sInfo?.value / numberAuthor;
        console.log(props.info)
        if (parseInt(props.info?.issueAt.split('/')?.slice(-1)?.[0]) >= minBonusYear) {
          bonus = {
            goc: tong,
            text: <>Ngày cấp của thành tích là <b>{props.info?.issueAt}</b>, ứng viên được cộng thêm <b>10%</b> điểm của nội dung đánh giá.</>,
            diem: tong * 0.1
          }
        }
        info = {
          ...sInfo,
          bonus,
          numberAuthor,
          value: Math.round((tong + (bonus?.diem || 0)) * 100) / 100
        };
      } else if (props.type === 'formResearchProject') {
        if (props.info?.type === 'd1' || props.info.level !== 'domestic') {
          const tong = sInfo?.value / props.info?.numberMain;
          if (dayjs(props.info?.start_at).year() >= minBonusYear) {
            bonus = {
              goc: tong,
              text: <>Thời gian kết thúc của thành tích là <b>{dayjs(props.info?.start_at).format("DD/MM/YYYY")}</b>, ứng viên được cộng thêm <b>10%</b> điểm của nội dung đánh giá.</>,
              diem: tong * 0.1
            }
          }
          info = {
            ...sInfo,
            bonus,
            value: Math.round((tong + (bonus?.diem || 0)) * 100) / 100
          };
        } else {
          if (props.info?.level === 'domestic') {
            if (props.info?.type === 'd2') {
              info = { ...sInfo };
            } else if (props.info?.type === 'd3') {
              info = { ...sInfo };
            }
          }
        }
      } else if (props.type === 'formTechCompany') {
        const tong = sInfo?.value;
        if (props.info?.publicYear >= minBonusYear) {
          bonus = {
            goc: tong,
            text: <>Năm xuất bản của thành tích là <b>{props.info?.publicYear}</b>, ứng viên được cộng thêm <b>10%</b> điểm của nội dung đánh giá.</>,
            diem: tong * 0.1
          }
        }
        info = {
          ...sInfo,
          bonus,
          value: Math.round((tong + (bonus?.diem || 0)) * 100) / 100
        };
      } else if (props.type === 'formReward') {
        const tong = sInfo?.value;
        if (parseInt(props.info?.decisionDay.split('/')?.slice(-1)?.[0]) >= minBonusYear) {
          bonus = {
            goc: tong,
            text: <>Ngày quyết định khen thưởng là <b>{props.info?.decisionDay}</b>, ứng viên được cộng thêm <b>10%</b> điểm của nội dung đánh giá.</>,
            diem: tong * 0.1
          }
        }
        info = {
          ...sInfo,
          bonus,
          value: Math.round((tong + (bonus?.diem || 0)) * 100) / 100
        };
      }

      setScoreInformation(info);
    }
  }, [code]);

  useEffect(() => {
    props.setValues({
      ...props.values,
      score: scoreInformation?.value || 0,
    });
  }, [scoreInformation]);

  const textForm = [
    { key: 'score', width: 12, label: 'Điểm', value: props.values?.score, type: 'float', required: true },
    { key: 'note', width: 12, height: 3, label: 'Ghi chú của thư ký (Trong trường hợp sửa điểm)', value: props.values?.note},
    { key: 'submit', width: 12, label: 'Duyệt thành tích', type: 'submit' },
  ];

  return (
    <Dialog fullWidth maxWidth='xs' open={props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>Duyệt thành tích</Typography>
          <IconButton onClick={() => {
              props.handleClose();
              props.setValues(null);
              setScoreInformation(null);
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 16 }}>
        <Typography style={{ marginBottom: 32, fontSize: 17 }}>
          {(!scoreInformation?.description || scoreInformationList?.value) ? (
            (props.type === 'formHrDevelopment')
              ? 'Hội đồng thư ký xét duyệt xem thông tin đã chính xác chưa, hệ thống sẽ tự tính điểm thành tích.'
              : 'Thành tích không thuộc hạng mục nào, hội đồng thư ký tự cho điểm.' 
          ) : (
            <>
              {'Thành tích thuộc hạng mục '}
              <b>{scoreInformation?.description}</b>
              {', với số điểm tối đa là: '}
              <b>{scoreInformationList?.find((e) => e?.code === code)?.value} điểm.</b>
              <br /><br />

              {(props.type === 'formScientificResearch' || props.type === 'formScientificReport' || props.type === 'formMonograph') && (
                <>
                  {'Thành tích có '}
                  <b>{props.info?.numberAuthor}</b>
                  {' tác giả tham gia (Với '}
                  <b>{props.info?.numberMain}</b>
                  {' tác giả chính (hoặc tác giả liên hệ)). Trong đó, ứng viên'}
                  {' là '}
                  <b>{props.info?.role === 'main' ? 'Tác giả chính (hoặc tác giả liên hệ)' : 'Đồng tác giả'}</b>
                  {' nên được hưởng '}
                  <b>{props.info?.role === 'main' 
                  ? `1/2 x 1/${props.info?.numberMain} + 1/2 x 1/${props.info?.numberAuthor} = ${
                    Math.round((((1 / 2) / props.info?.numberMain + (1 / 2) / props.info?.numberAuthor)) * 100) / 100
                  }` 
                  : `1/2 x 1/${props.info?.numberAuthor} = 1/${2 * props.info?.numberAuthor}`}</b>
                  {' tổng số điểm.'}
                  <br /><br />
                </>
              )}

              {props.type === 'formSolution' && (
                <>
                  {'Thành tích có '}
                  <b>{scoreInformation?.numberAuthor}</b>
                  {' tác giả tham gia nên ứng viên được hưởng '}
                  <b>{`1/${scoreInformation?.numberAuthor} `}</b>
                  {'tổng số điểm.'} 
                  <br /><br />
                </>
              )}

              {(props.type === 'formResearchProject' && props.info?.type === 'd1' && props.info?.numberMain > 1) && (
                <>
                  {'Chương trình, dự án, đề tài có '}
                  <b>{props.info?.numberMain}</b>
                  {' chủ trì nên ứng viên được hưởng '}
                  <b>{`1/${props.info?.numberMain} `}</b>
                  {'tổng số điểm.'} 
                  <br /><br />
                </>
              )}

              {scoreInformation?.bonus && (
                <>
                  Ứng viên được: <b>{scoreInformation?.bonus?.goc} điểm.</b>
                  <br /><br />
                  {scoreInformation?.bonus?.text}
                  <br /><br />
                </>
              )}

              {'Vậy ứng viên được tối đa: '}
              <b>{scoreInformation?.value} điểm.</b>
            </>
          )}
        </Typography>
        <KPTextform
          textForm={
            (!((!scoreInformation?.description || scoreInformationList?.value) && props.type === 'formHrDevelopment'))
            ? textForm
            : [
              { key: 'note', width: 12, height: 3, label: 'Ghi chú của thư ký (Trong trường hợp sửa điểm)', value: props.values?.note},
              {
                key: 'save-note',
                width: 6,
                label: 'Lưu G/C (Không duyệt)',
                type: 'button',
                color: 'default',
                variant: 'outlined',
                onClick: () => props.saveNote(),
              },
              { key: 'submit', width: 6, label: 'Duyệt thành tích', type: 'submit' },
            ]
          }
          values={props.values}
          setValues={props.setValues}
          handleSubmit={props.handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DuyetThanhTichModal;
