/* eslint-disable */
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Grid, Typography } from '@material-ui/core';

import './ThongTinCaNhan.css';

import Loading from '../../../components/CustomComponents/Loading';
import KPTextfield from '../../../components/KPTComponents/KPTextfield';
import KPTimepicker from '../../../components/KPTComponents/KPTimepicker';

import { dateFormatter } from '../../../utils';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { FORM_CONSTANTS } from "../../../constants"
import { fileActions, profileActions, userActions } from '../../../actions';
import KPTextform from '../../../components/KPTComponents/KPTextform';
import theme from '../../../theme';
import EthnicOptions from '../../../constants/ethnic.json';

function ThongTinCaNhan() {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

  const [file, setFile] = useState('');
  const [values, setValues] = useState({ gender: 'male' });
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const user = useSelector(state => state.userReducer.user);
  const loading = useSelector(state => state.profileReducer.loading);
  const profile = useSelector(state => state.profileReducer.profile);

  const userId = useMemo(() => user?.id, [user]);
  const username = useMemo(() => user?.username, [user]);

  const textForm = [
    { key: 'name', width: isMobileMode ? 12 : 8, label: 'Họ và tên', type: 'uppercase', required: true },
    { key: 'birth', width: isMobileMode ? 12 : 4, label: 'Ngày sinh', type: 'date', required: true },
    { key: 'gender', width: isMobileMode ? 12 : 3, label: 'Giới tính', options: FORM_CONSTANTS.GENDER_OPTIONS, required: true },
    { key: 'ethnic', width: isMobileMode ? 12 : 3, label: 'Dân tộc', options: EthnicOptions.map((e) => ({ ...e, value: e.title })), required: true },
    { key: 'phone', width: isMobileMode ? 12 : 6, label: 'Số điện thoại', required: true },
    { key: 'email', width: 12, label: 'Email', value: username, disabled: true, required: true },
    { key: 'website', width: 12, label: 'Website/Facebook', required: true },
    { key: 'submit', width: 12, label: 'Lưu thông tin cá nhân', type: 'submit' },
  ];


  useEffect(() => {
    // Lấy được thông tin cá nhân thì nhét vào values để hiện lên màn hình
    const birth = profile?.birth? dayjs(profile?.birth).format('DD/MM/YYYY') : null;
    setValues({ ...values, ...profile, birth })
  }, [profile]);

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let readedFile = e.target.files[0];

    reader.onloadend = () => {
      if (readedFile && reader?.result) {
        setFile(readedFile);
        setImagePreviewUrl(reader.result);
      }
    }

    if (readedFile) reader.readAsDataURL(readedFile);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const elm of textForm) {
      const elmValue = elm?.value || values?.[elm?.key];
      if (elm?.required && !elmValue) {
        setValues({ ...values, emptyKey: elm.key });
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label}`,
          type: 'error'
        }));
        return;
      }
    }

    const profilePayload = {
      ...values,
      email: username,
      userId: values?.userId || userId,
      birth: dateFormatter(values?.birth),
      name: values?.name.toUpperCase(),
    }

    if (file) {
      if (values?.id) {
        dispatch(fileActions.importFile(values?.id, file, profilePayload));
      } else {
        dispatch(profileActions.upsertProfile(profilePayload))
          .then((payload) => {
            dispatch(fileActions.importFile(payload.id, file, payload));
          });
      }
    } else {
      dispatch(profileActions.upsertProfile(profilePayload));
    }
  }

  const topPart = isMobileMode ? textForm.slice(0, 3) : textForm.slice(0, 7);
  const botPart = isMobileMode ? textForm.slice(3) : textForm.slice(7);

  return (
    <Grid
      container
      alignContent='center'
      justifyContent='center'
      style={{ minHeight: 'calc(100vh - 96px)' }}
    >
      <Grid className='dkhs-container' item xs={12} md={9} lg={8} style={{ margin: '32px 0' }}>
        <Card style={{ padding: 16, backgroundColor: '#E8F0FD' }}>
          <Typography variant='h4' style={{ textAlign: 'center' }}><b>Thông tin cá nhân</b></Typography>
          <form className='dkhs-container' onSubmit={handleSubmit}>
            <Box display='flex'>
              <KPTextform
                component={Box}
                textForm={topPart}
                values={values}
                rowMargin={4}
                setValues={setValues}
              />
              <Box marginTop='4px' marginLeft={1}>
                <Typography
                  style={{ 
                    fontSize: 17,
                    fontWeight: 'bold',
                    color: '#031043',
                    paddingBottom: 4,
                  }}
                >
                  {isMobileMode ? 'Ảnh chân dung' : 'Ảnh chân dung (4x6)'}
                  <span style={{ color: theme.palette.secondary.main }}>*</span>
                  <span>:</span>
                </Typography>
                <div className='dkhs-idPhoto'>
                  {(imagePreviewUrl || values?.photoUrl) && (
                    <img
                      className='dkhs-photo'
                      src={imagePreviewUrl ? imagePreviewUrl : `${process.env.REACT_APP_DOMAIN}${values?.photoUrl}`}
                      alt='anh-chan-dung'
                    />
                  )}
                  <label className='dkhs-insertBtn' htmlFor='upload-photo'>
                    <div className='dkhs-insertIcon'>Click để thêm ảnh...</div>
                  </label>
                  <input
                    type='file'
                    id='upload-photo'
                    style={{ opacity: 0, zIndex: -1, position: 'absolute', width: '100%' }}
                    onChange={handleImageChange}
                  />
                </div>
              </Box>
            </Box>
            <KPTextform
              component={Box}
              textForm={botPart}
              values={values}
              rowMargin={4}
              setValues={setValues}
            />
            <Loading open={loading} />
          </form>
        </Card> 
      </Grid>
    </Grid>
  );
}

export default ThongTinCaNhan;
