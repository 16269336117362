import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function QuyChe() {
  return (
    <Box marginTop={4} className="formal-text">
      <b>1. Đối tượng</b>
      <p>Giải thưởng xét trao cho các cá nhân là công dân Việt Nam không quá 35 tuổi (tính đến
        ngày 31/12 của năm xét trao Giải thưởng) đang học tập, nghiên cứu và làm việc ở trong và ngoài nước thuộc lĩnh
        vực khoa học xã hội và nhân văn, bao gồm các ngành và liên ngành sau:</p>
      <p>[1] Ngành Luật học</p>
      <p>[2] Ngành Giáo dục học</p>
      <p>[3] Ngành Kinh tế học</p>
      <p>[4] Liên ngành Văn hóa - Nghệ thuật</p>
      <p>[5] Liên ngành Triết học - Chính trị học - Xã hội học</p>
      <p>[6] Liên ngành Sử học - Khảo cổ học - Dân tộc học - Nhân học</p>
      <b>2. Tiêu chuẩn</b>
      <p></p>
      <b>2.1. Tiêu chuẩn chung</b>
      <p>- Chấp hành tốt chủ trương, đường lối của Đảng, pháp luật của Nhà nước; có đạo đức
        tốt, có uy tín trong tập thể nơi công tác hoặc nơi sinh sống.</p>
      <p>- Tác giả/đồng tác giả của các công trình khoa học có giá trị, đóng góp tích cực vào
        sự phát triển khoa học xã hội và nhân văn, ứng dụng kết quả các công trình nghiên cứu vào thực tiễn, có sức lan
        tỏa giá trị tốt đẹp đến thanh thiếu nhi và xã hội.</p>
      <p>- Đối với cá nhân người Việt Nam đang học tập, nghiên cứu và làm việc ở nước ngoài,
        các công trình khoa học đăng ký tham gia xét giải thưởng phải là các công trình nghiên cứu giải quyết các vấn đề
        thực tiễn của Việt Nam hay có tính ứng dụng tại Việt Nam.</p>
      <p>- Chưa từng vi phạm về đạo đức, liêm chính khoa học (theo đánh giá của cơ quan, tổ
        chức có thẩm quyền).</p>
      <b>2.2. Tiêu chuẩn cụ thể</b>
      <p>Cá nhân được trao giải thưởng phải đáp ứng tối thiểu một trong những tiêu chuẩn sau
        đây:</p>
      <p>* Thành tích khoa học (thuộc ngành/liên ngành được nêu ở trên)</p>
      <p>- Tác giả/đồng tác giả bài báo khoa học đăng trên tạp chí quốc tế/quốc gia.
    </p>
      <p>- Tác giả/đồng tác giả bài báo khoa học đăng trên kỷ yếu hội thảo quốc tế/quốc gia.
    </p>
      <p>- Chủ trì/chủ nhiệm các chương trình, dự án, đề tài nghiên cứu khoa học.</p>
      <p>- Tác giả/đồng tác giả sách chuyên khảo, giáo trình, chương sách</p>
      <p>- Tác giả/đồng tác giả công trình nghiên cứu khoa học tiêu biểu khác.</p>
      <p>* Thành tích khen thưởng: được nhận hình thức khen thưởng cấp Nhà nước, cấp Bộ và
        tương đương.</p>
      <p>* Thành tích cộng đồng: Tích cực tình nguyện tham gia các hoạt động chia sẻ tri thức,
        đào tạo, bồi dưỡng phát triển nhân lực lĩnh vực khoa học xã hội và nhân văn; tham gia các hoạt động tình nguyện
        vì cộng đồng.</p>
      <b>3. Hình thức giải thưởng</b>
      <p>Mỗi cá nhân đạt Giải thưởng sẽ được nhận:</p>
      <p>- Huy hiệu “Tuổi trẻ Sáng tạo” của Ban Chấp hành Trung ương Đoàn TNCS Hồ Chí
        Minh;</p>
      <p>- Giấy chứng nhận của Trung ương Đoàn TNCS Hồ Chí Minh;</p>
      <p>- Biểu trưng Giải thưởng;</p>
      <p>- Phần thưởng bằng tiền mặt;</p>
      <p>- Các phần thưởng khác của đơn vị tài trợ, bảo trợ (nếu có).</p>
      <b>4. Số lượng cá nhân được trao Giải thưởng</b>
      <p>Giải thưởng được tổ chức 2 năm 1 lần. Mỗi lần trao Giải thưởng cho tối đa 10 cá nhân tiêu
        biểu.</p>
      <b>5. Quyền lợi của cá nhân được nhận Giải thưởng</b>
      <p>- Được nhận giải thưởng.</p>
      <p>- Được ghi danh và lưu hồ sơ cá nhân trong Hệ thống Cơ sở dữ liệu Tài năng trẻ Việt
        Nam, được giới thiệu tham gia các sự kiện, diễn đàn tài năng trẻ hoặc một số hoạt động trong nước và quốc tế do
        Trung ương Đoàn và các bộ, ngành tổ chức.</p>
      <p>- Được Trung tâm Phát triển Khoa học, Công nghệ và Tài năng trẻ, Quỹ Hỗ trợ Tài năng
        trẻ Việt Nam, tổ chức Đoàn các cấp, các bộ, ban, ngành liên quan hỗ trợ trong học tập, nghiên cứu và công
        tác.</p>
      <p>- Mỗi cá nhân chỉ được nhận Giải thưởng một lần.</p>
      <b>6. Nghĩa vụ của cá nhân được nhận Giải thưởng</b>
      <p>- Tích cực tham gia đóng góp nâng cao chất lượng, uy tín của Giải thưởng và là hạt
        nhân nòng cốt trong các phong trào học tập, nghiên cứu khoa học trong lĩnh vực khoa học xã hội và nhân văn, các
        hoạt động tài năng trẻ của Đoàn Thanh niên các cấp.</p>
      <p>- Có trách nhiệm tham gia đóng góp xây dựng các chủ trương, chính sách của Đảng, Nhà
        nước và của Đoàn Thanh niên đối với công tác phát hiện, đào tạo bồi dưỡng, phát huy và trọng dụng tài năng trẻ
        Việt Nam. &nbsp;</p>
      <b>7. Quy định xử lý các trường hợp vi phạm</b>
      <p>Sau khi nhận giải thưởng, nếu phát hiện cá nhân được nhận giải thưởng vi phạm quy chế
        giải thưởng (làm giả tài liệu, khai báo không đúng thành tích, có dấu hiệu vi phạm về liêm chính khoa học do cơ
        quan chuyên môn xác nhận, có hành vi gian lận gây ảnh hưởng tới chất lượng của giải thưởng...), đơn vị thường
        trực giải thưởng sẽ đề xuất Ban Bí thư Trung ương Đoàn quyết định tước danh hiệu giải thưởng và thông báo công
        khai trên các phương tiện thông tin đại chúng.</p>
      <b>8. Công tác xét chọn Giải thưởng</b>
      <p className="c10"></p>
      <b>8.1. Triển khai Giải thưởng</b>
      <p>- Trung ương Đoàn ban hành kế hoạch và các văn bản hướng dẫn triển khai Giải thưởng
        tới các tỉnh, thành Đoàn, Đoàn trực thuộc; các cơ quan, tổ chức có liên quan; đăng tải trên thông tin trên Cổng
        thông tin điện tử Tài năng trẻ Quốc gia (http://tainangviet.vn/) và các kênh thông tin của Trung ương
        Đoàn.</p>
      <p>- Báo Tiền phong, Báo Thanh niên, Trung tâm Truyền hình Thanh niên và các cơ quan báo
        chí của hệ thống tổ chức Đoàn TNCS Hồ Chí Minh có trách nhiệm truyền thông cho giải thưởng.</p>
      <p>- Các cơ quan, tổ chức tìm kiếm, giới thiệu các cá nhân tiêu biểu đăng ký hồ sơ tham
        gia giải thưởng; đồng thời phối hợp với các cơ quan báo chí địa phương, ngành tổ chức tuyên truyền về giải
        thưởng và các tài năng trẻ đạt giải thưởng.</p>
      <p>- Đơn vị thường trực tiếp nhận, hướng dẫn hoàn thiện và tổng hợp hồ sơ đăng ký tham
        gia xét trao giải thưởng.</p>
      <b>8.2. Đăng ký tham gia Giải thưởng &nbsp;</b>
      <p>Các ứng viên đăng ký tham gia xét trao giải thưởng qua Cổng đăng ký trực tuyến “Giải
        thưởng Khuê Văn Các” tại địa chỉ: <a href="http://khxhnv.tainangviet.vn/">http://khxhnv.tainangviet.vn/</a>. Các thông tin cá nhân, thành tích khoa học,
        công trình nghiên cứu, tài liệu minh chứng của ứng viên tham gia xét giải thưởng kê khai và cung cấp trên hệ
        thống đăng ký trực tuyến của giải thưởng phải được dịch sang Tiếng Việt hoặc Tiếng Anh gồm:</p>
      <p>- Công văn của cơ quan, tổ chức giới thiệu (bản scan, có ký tên, đóng dấu được đính
        kèm trên hệ thống đăng ký trực tuyến). Cơ quan, tổ chức được giới thiệu bao gồm: tỉnh, thành Đoàn, Đoàn trực
        thuộc; Trung ương Hội Liên hiệp Thanh niên Việt Nam, Trung ương Hội Sinh viên Việt Nam; các bộ, ngành, các viện
        nghiên cứu, trường học, doanh nghiệp, cơ quan báo chí; Đại sứ quán, Hội Thanh niên, Sinh viên Việt Nam ở nước
        ngoài (đối với các ứng viên đang học tập, nghiên cứu và làm việc ở nước ngoài).</p>
      <p>- Thông tin và các thành tích cá nhân kê khai phải đảm bảo tính pháp lý có xác nhận
        của thủ trưởng cơ quan, tổ chức quản lý trực tiếp, các đơn vị chuyên môn có thẩm quyền xác nhận (bản scan, có ký
        tên, đóng dấu và được đính kèm trên hệ thống đăng ký trực tuyến).</p>
      <p>- Bản scan các tài liệu chứng minh về thành tích khoa học, các hình thức khen thưởng,
        tham gia tình nguyện vì cộng đồng (được đính kèm trên hệ thống đăng ký trực tuyến).</p>
      <p>- Ứng viên tự giới thiệu từ 01 đến 03 công trình khoa học tiêu biểu của mình đã công
        bố thuộc ngành/liên ngành xét trao giải thưởng. Ứng viên phải là tác giả độc lập hoặc tác giả chính (chủ biên,
        hoặc tác giả thứ nhất, hoặc tác giả liên hệ) của công trình. Bản giới thiệu công trình có độ dài từ 1500 đến
        2000 từ, font Times New Roman, size 14. Nếu công trình có ứng dụng trong thực tiễn thì có giấy xác nhận của cơ
        quan/tổ chức sử dụng kết quả nghiên cứu (bản scan, có ký tên, đóng dấu, được đính kèm trên hệ thống đăng ký trực
        tuyến giải thưởng). Bản giới thiệu, ứng viên cần làm nổi bật các điểm sau đây:</p>
      <p>(1) Tóm tắt quá trình đào tạo, công tác, thành tích nghiên cứu của ứng viên;
    </p>
      <p>(2) Tên công trình nghiên cứu, mục tiêu, phương pháp và tính mới trong nghiên cứu (ý
        tưởng mới, tính sáng tạo, tính đột phá…)</p>
      <p>(3) Vai trò của ứng viên là tác giả độc lập hay tác giả chính (chủ biên, hay tác giả
        thứ nhất, tác giả liên hệ). Vai trò đóng góp của ứng viên đối với kết quả nghiên cứu (nếu công trình có nhiều
        hơn một tác giả)</p>
      <p>(4) Giá trị khoa học: uy tín tạp chí/nhà xuất bản, yếu tố tác động (Impact factor), số
        lượng trích dẫn (citation index).…</p>
      <p>(5) Giá trị thực tiễn: đóng góp vào sự phát triển khoa học, ứng dụng của công trình
        trong thực tiễn, ảnh hưởng của công trình đến cộng đồng và xã hội. (Nếu công trình đã ứng dụng thực tiễn thì có
        xác nhận của cơ quan/tổ chức sử dụng kết quả nghiên cứu).</p>
      <p>- 01 ảnh chân dung và 05 ảnh ứng viên tham gia các hoạt động học tập, nghiên cứu khoa
        học, hoạt động vì cộng đồng.</p>
      <p>- Video giới thiệu về cá nhân, các thành tích nổi bật, giá trị các nghiên cứu khoa học
        của ứng viên (không quá 2 phút).</p>
      <b>8.3. Hội đồng Giải thưởng</b>
      <p>- Hội đồng Giải thưởng Khuê Văn Các (gọi tắt là Hội đồng Giải thưởng) là cơ quan tham
        mưu xét chọn giải thưởng do Ban Bí thư Trung ương Đoàn ra quyết định thành lập theo đề xuất của đơn vị thường
        trực giải thưởng.</p>
      <p>- Hội đồng Giải thưởng có nhiệm vụ lựa chọn các ứng viên tiêu biểu để đề xuất Ban Bí
        thư Trung ương Đoàn xem xét quyết định trao tặng giải thưởng.</p>
      <p>- Hội đồng Giải thưởng bao gồm những cán bộ quản lý, chuyên gia, nhà khoa học có trình
        độ chuyên sâu trong các ngành/liên ngành xét trao giải thưởng và không phải là ứng viên đăng ký xét trao giải
        thưởng năm đó. Hội đồng Giải thưởng có cơ cấu như sau:</p>
      <p>+ Chủ tịch Hội đồng Giải thưởng: Là đại diện Ban Bí thư Trung ương Đoàn TNCS Hồ Chí
        Minh.</p>
      <p>+ Các Phó Chủ tịch Hội đồng Giải thưởng: Là đại diện bộ, ngành liên quan hoặc cá nhân
        tiêu biểu, uy tín trong lĩnh vực xét trao giải thưởng và Giám đốc Trung tâm Phát triển Khoa học, Công nghệ và
        Tài năng trẻ.</p>
      <p>+ Các thành viên Hội đồng: Là đại diện các bộ, ngành liên quan, các chuyên gia, nhà
        khoa học, nhà quản lý thuộc các ngành/liên ngành xét trao Giải thưởng và đại diện một số cơ quan truyền thông,
        nhà tài trợ.</p>
      <b>8.4. Ban Thư ký Giải thưởng</b>
      <p>- Ban Thư ký Giải thưởng là cơ quan tham mưu giúp việc cho Hội đồng Giải thưởng do Ban
        Bí thư Trung ương Đoàn ra quyết định thành lập. Thành viên Ban Thư ký là các chuyên gia, nhà khoa học (không
        phải là ứng viên đăng ký tham gia xét trao giải thưởng của năm đó) có chuyên môn sâu trong ngành/liên ngành xét
        giải thưởng do đơn vị thường trực giải thưởng đề xuất.</p>
      <p>- Ban Thư ký có nhiệm vụ phân loại, kiểm tra, rà soát hồ sơ và có báo cáo đề xuất
        trình Hội đồng giải thưởng xem xét. Nhiệm vụ cụ thể của các thành viên Ban thư ký do Trưởng Ban thư ký phân
        công. Ban Thư ký có thể được chia thành các tổ chuyên môn theo các lĩnh vực xét giải thưởng.</p>
      <Typography variant='h5' style={{ color: 'black', textAlign: 'end', fontFamily: "SFProDisplay-Bold" }}>
        <b>ĐƠN VỊ THƯỜNG TRỰC GIẢI THƯỞNG</b>
      </Typography>
    </Box>
  );
}