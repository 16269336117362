import React from 'react';

import { CATEGORY, ROUTER } from '../../constants';

import Home from '../Home';
import Page401 from '../Page401';
import TongQuan from '../GioiThieu';

import DangKy from '../../containers/AppLayout/DangKy';
import DangNhap from '../../containers/AppLayout/DangNhap';
import DoiMatKhau from '../../containers/AppLayout/DoiMatKhau';
import ThongTinCaNhan from '../../containers/AppLayout/ThongTinCaNhan';
import HuongDanSuDung from '../../containers/QCV/HuongDanSuDung/HuongDanSuDung';
import QuanLyNamToChuc from '../../containers/AppLayout/QuanLyNamToChuc';
import QuanLyNguoiDung from '../../containers/AppLayout/QuanLyNguoiDung';
import DanhSachHoSoCaNhan from '../../containers/AppLayout/DanhSachHoSo';

import HoSoQCV from '../QCV/HoSo';
import GioiThieuQCV from '../QCV/GioiThieu';
import DangKyHoSoQCV from '../QCV/DangKyHoSo';
import CauHinhHeThong from '../QCV/CauHinhHeThong';
import DanhSachHoSoQCV from '../QCV/DanhSachHoSo';
import DanhSachThanhTich from '../QCV/DanhSachThanhTich';

import HoSoKVC from '../KVC/HoSo';
import GioiThieuKVC from '../KVC/GioiThieu';
import DangKyHoSoKVC from '../KVC/DangKyHoSo';
import DanhSachHoSoKVC from '../KVC/DanhSachHoSo';

import HoSoGTNS from '../GTNS/HoSo';
import GioiThieuGTNS from '../GTNS/GioiThieu';
import DangKyHoSoGTNS from '../GTNS/DangKyHoSo';
import DanhSachHoSoGTNS from '../GTNS/DanhSachHoSo';
import KetQuaBinhChon from '../../containers/QCV/BinhChon/KetQuaBinhChon';

import BinhChonV2 from '../QCV/BinhChonV2/BinhChonV2';

const ROUTER_CONFIG = {
  BASE: [
    { path: ROUTER.HOME, element: <Home />, disableHeader: true, maxWidth: 'xl', authRequired: false },
    { path: ROUTER.DANG_KY, element: <DangKy />, disableHeader: true, maxWidth: 'xl', authRequired: false },
    { path: ROUTER.DANG_NHAP, element: <DangNhap />, disableHeader: true, maxWidth: 'md', authRequired: false },
    { path: ROUTER.TONG_QUAN, element: <TongQuan />, disableHeader: true, maxWidth: 'lg', authRequired: false },
    { path: ROUTER.DOI_MAT_KHAU, element: <DoiMatKhau />, maxWidth: 'md' },
    { path: ROUTER.THONG_TIN_CA_NHAN, element: <ThongTinCaNhan /> },
    { path: ROUTER.PAGE_401, element: <Page401 />, maxWidth: 'lg' },
    { path: ROUTER.DANH_SACH_HO_SO_CA_NHAN, element: <DanhSachHoSoCaNhan />, maxWidth: 'lg' },
    { path: ROUTER.QCV.HUONG_DAN_SU_DUNG, element: <HuongDanSuDung />, maxWidth: 'md', bgColor: '#E8F0FD' },
    { path: ROUTER.BINH_CHON, element: <BinhChonV2 /> },
  ],
  ADMIN: [
    { path: ROUTER.CAU_HINH, element: <CauHinhHeThong />, maxWidth: 'lg', adminRoleRequired: true },
    { path: ROUTER.QUAN_LY_NGUOI_DUNG, element: <QuanLyNguoiDung />, maxWidth: 'lg', adminRoleRequired: true },
    { path: ROUTER.QUAN_LY_NAM_TO_CHUC, element: <QuanLyNamToChuc />, maxWidth: 'md', adminRoleRequired: true },
    { path: ROUTER.QCV.KET_QUA_BINH_CHON, element: <KetQuaBinhChon />, maxWidth: 'xl', adminRoleRequired: true },
  ],
  [CATEGORY.QCV]: [
    { path: ROUTER.GIOI_THIEU, element: <GioiThieuQCV />, authRequired: false },
    { path: ROUTER.DANG_KY_HO_SO, element: <DangKyHoSoQCV />, maxWidth: 'md', bgColor: '#E8F0FD' },
    { path: ROUTER.QCV.DUYET_THANH_TICH + '/:id', element: <DanhSachThanhTich />, maxWidth: 'lg', exact: false },
    { path: ROUTER.HO_SO + '/:id', element: <HoSoQCV />, maxWidth: 'lg', exact: false, bgColor: '#E8F0FD' },
    { path: ROUTER.DANH_SACH_HO_SO, element: <DanhSachHoSoQCV />, maxWidth: 'lg' },
  ],
  [CATEGORY.KVC]: [
    { path: ROUTER.GIOI_THIEU, element: <GioiThieuKVC />, authRequired: false },
    { path: ROUTER.DANG_KY_HO_SO, element: <DangKyHoSoKVC />, maxWidth: 'md', bgColor: '#E8F0FD' },
    { path: ROUTER.HO_SO + '/:id', element: <HoSoKVC />, maxWidth: 'lg', exact: false, bgColor: '#E8F0FD' },
    { path: ROUTER.DANH_SACH_HO_SO, element: <DanhSachHoSoKVC />, maxWidth: 'lg' },
  ],
  [CATEGORY.GTNS]: [
    { path: ROUTER.GIOI_THIEU, element: <GioiThieuGTNS />, authRequired: false },
    { path: ROUTER.DANG_KY_HO_SO, element: <DangKyHoSoGTNS />, maxWidth: 'lg', bgColor: '#E8F0FD' },
    { path: ROUTER.HO_SO + '/:id', element: <HoSoGTNS />, maxWidth: 'lg', exact: false, bgColor: '#E8F0FD' },
    { path: ROUTER.DANH_SACH_HO_SO, element: <DanhSachHoSoGTNS />, maxWidth: 'lg' },
  ]
}

export default ROUTER_CONFIG;
