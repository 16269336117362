import { RESEARCH_TYPE, FORM_TYPE } from '../constants';

const initialState = {
  loading: false,
  totalResearchList: [],
  personalResearchList: [],
}

const sortFunc = (a, b) => a.id - b.id;

export function researchReducer(state = initialState, action) {
  switch (action.type) {
    case RESEARCH_TYPE.GET_ALL_RESEARCH_LOADING:
    case RESEARCH_TYPE.GET_RESEARCH_BY_PROFILE_ID_LOADING:
    case RESEARCH_TYPE.INSERT_RESEARCH_LOADING:
    case RESEARCH_TYPE.UPDATE_RESEARCH_LOADING:
    case RESEARCH_TYPE.DELETE_RESEARCH_LOADING:
    case RESEARCH_TYPE.UPDATE_RESEARCH_ACCEPTED_LOADING:
      return { ...state, loading: true };

    case RESEARCH_TYPE.GET_ALL_RESEARCH_FAILURE:
    case RESEARCH_TYPE.GET_RESEARCH_BY_PROFILE_ID_FAILURE:
    case RESEARCH_TYPE.INSERT_RESEARCH_FAILURE:
    case RESEARCH_TYPE.UPDATE_RESEARCH_FAILURE:
    case RESEARCH_TYPE.DELETE_RESEARCH_FAILURE:
    case RESEARCH_TYPE.UPDATE_RESEARCH_ACCEPTED_FAILURE:
      return { ...state, loading: false };

    case RESEARCH_TYPE.GET_ALL_RESEARCH_SUCCESS:
      return { ...state, totalResearchList: action.payload.sort(sortFunc), loading: false };

    case RESEARCH_TYPE.GET_RESEARCH_BY_PROFILE_ID_SUCCESS:
      return { ...state, personalResearchList: action.payload.sort(sortFunc), loading: false };

    case RESEARCH_TYPE.INSERT_RESEARCH_SUCCESS:
      return {
        ...state,
        totalResearchList: [...state.personalResearchList, action.payload].sort(sortFunc),
        personalResearchList: [...state.personalResearchList, action.payload].sort(sortFunc),
        loading: false
      };

    case RESEARCH_TYPE.UPDATE_RESEARCH_SUCCESS:
      return {
        ...state,
        totalResearchList: state.totalResearchList ? [
          ...state.totalResearchList.filter((e) => e.id !== action.payload.id),
          action.payload
        ].sort(sortFunc) : null,
        personalResearchList: state.personalResearchList ? [
          ...state.personalResearchList.filter((e) => e.id !== action.payload.id),
          action.payload
        ].sort(sortFunc) : null,
        loading: false
      };

    case RESEARCH_TYPE.DELETE_RESEARCH_SUCCESS:
      return {
        ...state,
        totalResearchList: state.personalResearchList.filter((e) => e.id !== action.id).sort(sortFunc),
        personalResearchList: state.personalResearchList.filter((e) => e.id !== action.id).sort(sortFunc),
        loading: false
      };

    case RESEARCH_TYPE.UPDATE_RESEARCH_ACCEPTED_SUCCESS:
      return {
        ...state,
        totalResearchList: state.totalResearchList ? [
          ...state.totalResearchList.filter((e) => e.id !== action.payload.id),
          action.payload
        ].sort(sortFunc) : null,
        personalResearchList: state.personalResearchList ? [
          ...state.personalResearchList.filter((e) => e.id !== action.payload.id),
          action.payload
        ].sort(sortFunc) : null,
        loading: false
      };

    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        personalResearchList: action.payload.scientificResearches.sort(sortFunc)
      }
    default:
      return state;
  }
}
