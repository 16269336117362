import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, Typography } from '@material-ui/core';

import { userActions } from '../../../actions';

import Loading from '../../../components/CustomComponents/Loading';
import KPTextform from '../../../components/KPTComponents/KPTextform';

function DoiMatKhau() {
  const [values, setValues] = useState({
    passwordOld: '',
    passwordNew1: '',
    passwordNew2: '',
    showPasswordOld: false,
    showPasswordNew1: false,
    showPasswordNew2: false,
  });

  const dispatch = useDispatch();
  const userId = useSelector(state => state.userReducer.user?.id);
  const loading = useSelector(state => state.userReducer.loading);

  const textForm = [
    { key: 'passwordOld', width: 12, label: 'Mật khẩu hiện tại', type: values?.showPasswordOld ? 'passwordText' : 'password', required: true },
    { key: 'passwordNew1', width: 12, label: 'Mật khẩu mới', type: values?.showPasswordNew1 ? 'passwordText' : 'password', required: true },
    { key: 'passwordNew2', width: 12, label: 'Xác nhận mật khẩu mới', type: values?.showPasswordNew2 ? 'passwordText' : 'password', required: true },
    { key: 'submit', width: 12, label: 'Đổi mật khẩu', type: 'submit', disabled: !values?.passwordNew1 || (values?.passwordNew1 !== values?.passwordNew2) },
  ];

  const handleChangeForm = (name, event) => {
    if (name === 'visibleOld') {
      setValues({ ...values, showPasswordOld: !values?.showPasswordOld });
    } else if (name === 'visibleNew1') {
      setValues({ ...values, showPasswordNew1: !values?.showPasswordNew1 });
    } else if (name === 'visibleNew2') {
      setValues({ ...values, showPasswordNew2: !values?.showPasswordNew2 });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  }

  const handleSubmit = () => {
    dispatch(userActions.changePassword({
      userID: userId,
      oldPassword: values?.passwordOld,
      newPassword: values?.passwordNew1,
    }));
    setValues(null);
  }

  return (
    <Grid
      container
      alignContent='center'
      justifyContent='center'
      style={{ minHeight: 'calc(100vh - 96px)' }}
    >
      <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Card style={{ padding: 16, backgroundColor: '#E8F0FD' }}>
          <Typography variant='h5' style={{ textAlign: 'center' }}><b>Đổi mật khẩu</b></Typography>
          <KPTextform
            textForm={textForm}
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
            handleChangeForm={handleChangeForm}
          />
        </Card>
        <Loading open={loading} />
      </Grid>
    </Grid>
  );
}

export default DoiMatKhau;
