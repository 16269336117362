/* eslint-disable max-len */
import { Card, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import { ARCHIVEMENT_TITLE, ACHIEVEMENT_TYPE, KEYS } from '../../../constants/enums';

import DanhSachThanhTichTable from '../../../containers/QCV/DanhSachThanhTich/DanhSachThanhTichTable';
import DanhSachThanhTichButton from '../../../containers/QCV/DanhSachThanhTich/DanhSachThanhTichButton';
import DanhSachThanhTichProfile from '../../../containers/QCV/DanhSachThanhTich/DanhSachThanhTichProfile';
// import { ROUTER_CONFIG_DUYET_THANH_TICH_CA_NHAN } from '../DuyetThanhTichCaNhan/DuyetThanhTichCaNhan';

const THANH_TICH_LIST = [
	{ key: ACHIEVEMENT_TYPE.SOLUTION, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.SOLUTION] },
	{ key: ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL] },
	{ key: ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC] },
	{ key: ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT] },
	{ key: ACHIEVEMENT_TYPE.MONOGRAPH, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.MONOGRAPH] },
	{ key: ACHIEVEMENT_TYPE.RESEARCH_PROJECT, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.RESEARCH_PROJECT] },
	{ key: ACHIEVEMENT_TYPE.REWARD, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.REWARD] },
	// { key: ACHIEVEMENT_TYPE.HR_DEVELOPMENT, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.HR_DEVELOPMENT + KEYS.DAO_TAO] },
	{ key: ACHIEVEMENT_TYPE.TECH_COMPANY, title: ARCHIVEMENT_TITLE[ACHIEVEMENT_TYPE.TECH_COMPANY] },
];

function DanhSachThanhTich() {
	const [value, setValue] = useState(THANH_TICH_LIST[0].key);

	const arrayPath = window.location.href.split('/');
	const formId = parseInt(arrayPath.slice(arrayPath.length -1));

	return (
    <Grid container spacing={2} style={{ marginTop: 32 }}>
      <Grid item xs={3}>
        <DanhSachThanhTichProfile formId={formId} />
      </Grid>
      <Grid item xs={9}>
        <Card style={{ padding: 8, minHeight: 'calc(100vh - 124px)' }}>
          <DanhSachThanhTichButton
            value={value}
            setValue={setValue}
            formId={formId}
            thanhTichList={THANH_TICH_LIST}
          />
          <DanhSachThanhTichTable value={value} formId={formId} />
        </Card>
      </Grid>
    </Grid>
	)
}

export default DanhSachThanhTich;
