import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from 'react';

import { useScene } from '../../../hooks/useScene';
import { autoScore } from './utils';
import { ACHIEVEMENT_TYPE, CATEGORY, KEYS } from "../../../constants";
import { monographActions, reportsActions, researchActions, researchProjectActions, rewardActions, solutionActions } from "../../../actions";

import KPTextform from '../../KPTComponents/KPTextform';

const TEXT_FORM = [
  { key: 'maxScore', width: 6, label: 'Điểm CTKH', type: 'float' },
  { key: 'score', width: 6, label: 'Điểm tính cho ứng viên', type: 'float', disabled: true, required: true },
  { key: 'note', width: 12, height: 3, label: 'Ghi chú của thư ký (Trong trường hợp sửa điểm)' },
  { key: 'submit', width: 12, label: 'Duyệt thành tích', type: 'submit' },
];

function DuyetThanhTichModalV2(props) {
  const scene = useScene();
  const dispatch = useDispatch();

  const { info, scoreInformationList, handleClose } = props;

  const [values, setValues] = useState(null);
  const [scoreInformation, setScoreInformation] = useState(null);

  useEffect(() => {
    if (info) {
      if (info.thanhTichAccepted) {
        handleAccept();
      } else {
        const found = autoScore(scene, info, scoreInformationList, values?.maxScore);
        setValues({
          ...values || {},
          score: found?.value || 0,
          maxScore: values?.maxScore || found?.maxScore || 0,
        });
        setScoreInformation(found);
      }
    }
  }, [info, values?.maxScore, scoreInformationList]);

  const closeModel = () => {
    handleClose();
    setTimeout(() => {
      setValues(null);
      setScoreInformation(null);
    }, 500);
  }

  const handleAccept = () => {
    switch (info.renderType) {
      case ACHIEVEMENT_TYPE.SOLUTION:
      case ACHIEVEMENT_TYPE.SOLUTION + CATEGORY.GTNS:
        dispatch(solutionActions.updateSolutionAccepted(
          [{
            id: info.formSolutionId,
            accepted: !info.thanhTichAccepted,
            score: info.thanhTichAccepted ? 0 : (values?.score || 0),
            maxScore: info.thanhTichAccepted ? 0 : (values?.maxScore || 0),
            note: values?.note || '',
          }]
        ));
        break;
      
      case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT:
      case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
      case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
        dispatch(reportsActions.updateReportAccepted(
          [{
            id: info.formScientificReportId,
            accepted: !info.thanhTichAccepted,
            score: info.thanhTichAccepted ? 0 : (values?.score || 0),
            maxScore: info.thanhTichAccepted ? 0 : (values?.maxScore || 0),
            note: values?.note || '',
          }]
        ));
        break;

      case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH:
      case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
      case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
        dispatch(researchActions.updateResearchAccepted(
          [{
            id: info.formScientificResearchId,
            accepted: !info.thanhTichAccepted,
            score: info.thanhTichAccepted ? 0 : (values?.score || 0),
            note: values?.note || '',
          }]
        ));
        break;
      
      case ACHIEVEMENT_TYPE.RESEARCH_PROJECT:
        dispatch(researchProjectActions.updateResearchProjectAccepted(
          [{
            id: info.formResearchProjectId,
            accepted: !info.thanhTichAccepted,
            score: values?.score || 0,
            note: values?.note || '',
          }]
        ));
        break;

      case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT:
        dispatch(rewardActions.updateRewardAccepted(
          [{
            id: info.formRewardId,
            accepted: !info.thanhTichAccepted,
            score: info.thanhTichAccepted ? 0 : (values?.score || 0),
            note: values?.note || '',
          }]
        ));
        break;
      
      case ACHIEVEMENT_TYPE.MONOGRAPH:
      case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
      case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
        dispatch(monographActions.updateMonographAccepted(
          [{
            id: info.formMonographId,
            accepted: !info.thanhTichAccepted,
            score: values?.score || 0,
            note: values?.note || '',
          }]
        ));
        break;

      default:
        break;
    }
    closeModel();
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={!!props.info}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>Duyệt thành tích</Typography>
          <IconButton onClick={closeModel}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 16 }}>
        <Box mb={2}>
          {scoreInformation?.text?.map((e, id) => <React.Fragment key={`text_${id}`}>{e}</React.Fragment>)}
        </Box>
        <KPTextform
          values={values}
          setValues={setValues}
          textForm={TEXT_FORM}
          handleSubmit={handleAccept}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DuyetThanhTichModalV2;
