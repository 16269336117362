import dayjs from 'dayjs';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { qcvEnums } from '../constants/qcv.enums';
import { FORM_CONSTANTS } from '../constants/form.constants';
import { convertHtmlToRichText } from './dateFormatter';

const setCellValue = (cell, properties) => {
  try {
    for (const item in properties) cell[item] = properties[item];
  } catch (e) {
    console.log(e)
  }
}

const convertNumToString= (num) => {
  var _num = num.toString();
  while (_num.lengh < 2) _num = '0' + _num;
  return num;
}

const getValue = (s) => s[0].toLowerCase() + s.slice(1);

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');


export const exportQCVToXlsx = (formList, field, fieldName) => {
  const workbook = new ExcelJS.Workbook();
  workbook.properties.date1904 = true;
  workbook.views = [{
    x: 0, y: 0, width: 10000, height: 20000,
    firstSheet: 0, activeTab: 0, visibility: 'visible'
  }];

  const worksheet = workbook.addWorksheet(fieldName);
  worksheet.columns = qcvEnums.EXPORT_COLUMNS;

  // Header
  worksheet.mergeCells('A1:H1');
  setCellValue(worksheet.getCell('A1'), {
    font: { size: 14, bold: true },
    alignment: { horizontal: 'center' },
    value: 'HỒ SƠ XÉT CHỌN GIẢI THƯỞNG KHCN QUẢ CẦU VÀNG 2024'
  });

  worksheet.addRow({ name: `LĨNH VỰC: ${fieldName}` }, 'i');
  for (var i = 2; i <= 5; i++) worksheet.mergeCells(`A${i}:H${i}`);

  worksheet.addRow(qcvEnums.EXPORT_COLUMNS.map((e) => e.title));

  formList.map((form) => {
    const startRow = worksheet.rowCount;
    
    var numMaster = 0;
    var numBachelor = 0;
    form?.formHrDevelopment?.forEach((hrForm) => {
      if (hrForm.accepted && hrForm.hrDevelopment.type === "daoTao" && hrForm.hrDevelopment.academicLevel === "master") {
        numMaster++;
      } if (hrForm.accepted && hrForm.hrDevelopment.type === "daoTao" && hrForm.hrDevelopment.academicLevel === "bachelor") {
        numBachelor++;
      }
    });
  
    const pointMaster = (numMaster > 0) ? (numMaster > 5 ? 3 : 2) : 0;
    const pointBachelor = (numBachelor > 0) ? (numBachelor > 10 ? 3 : 2) : 0;
  
    worksheet.addRow({
      name: `${field.toUpperCase()}${convertNumToString(form.realID)} - ${form.profile.name}`,
      score: form?.scoreA + form?.scoreB + form?.scoreC + form?.scoreD + pointMaster + pointBachelor,
      linkMinhChung: ''
    });
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    worksheet.mergeCells(`G${worksheet.rowCount}:H${worksheet.rowCount}`);

    ["A", "F", "G"].forEach((key) => {
      setCellValue(worksheet.getCell(`${key}${worksheet.rowCount}`), {
        font: { bold: true },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' },
          bgColor: { argb: 'FFFF00' }
        }
      })
    });
  
    worksheet.addRow({
      name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
      title: 'A. Thành tích khoa học công nghệ',
      score: form.scoreA + pointMaster + pointBachelor,
    },);

    worksheet.getCell(`C${worksheet.rowCount}`).style = {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' }, bgColor: { argb: 'FFFFFF' } },
      font: { bold: true, color: { argb: 'FFFF0000' } },
    }

    qcvEnums.THANH_TICH_LIST?.forEach((key) => {
      const value = getValue(key.replace('form', ''));

      var list = form[key]?.map((e) => ({
        ...e?.[value],
        note: e?.note || '',
        score: e?.score || 0,
        accepted: e?.accepted,
        preview: e?.[value]?.preview?.includes('/storage/awardRegister/') ? `${process.env.REACT_APP_DOMAIN}${e?.[value]?.preview}` : e?.[value]?.preview,
        link: e?.[value]?.link?.includes('/storage/awardRegister/') ? `${process.env.REACT_APP_DOMAIN}${e?.[value]?.link}` : e?.[value]?.link,
      }));

      if (list.length > 0) {
        var rows = [];
        switch (value) {
          case 'solution':
            var patentNum = 0, patentVnNum = 0, solutionNum = 0, score = 0;
            list.forEach((e) => {
              patentNum += (e.type === 'sc') ? 1 : 0;
              patentVnNum += (e.type === 'scqg') ? 1 : 0;
              solutionNum += (e.type === 'gp') ? 1 : 0;
              score += e.score;
            });

            rows = [
              {
                name: '',
                title: `${list.length} bằng độc quyền sáng chế, giải pháp hữu ích. Trong đó có ` +
                `${patentNum > 0 ? ` ${patentNum} bằng sáng chế quốc tế` : ''}` +
                `${patentVnNum > 0 ? ` ${patentVnNum} bằng độc quyền sáng chế quốc gia` : ''}` +
                `${solutionNum > 0 ? ` ${solutionNum} bằng độc quyền giải pháp hữu ích quốc gia` : ''}`,
                type: '', numberAuthor: '', isMain: '', score: Math.round(score * 100) / 100, linkMinhChung: '', note: '',
              },
              ...list.map((e) => ({
                ...e,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: '',
                // numberAuthor: calcAuthorNumber(e?.author),
                numberAuthor: 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'scientificResearch':
            var q1Num = 0, q1MainNum = 0, q2Num = 0, q2MainNum = 0;
            var q3Num = 0, q3MainNum = 0, q4Num = 0, q4MainNum = 0;
            var q6Num = 0, q6MainNum = 0;
            score = 0;
            list.forEach((e) => {
              q1Num += (e.type === 'q1') ? 1 : 0;
              q2Num += (e.type === 'q2') ? 1 : 0;
              q3Num += (e.type === 'q3') ? 1 : 0;
              q4Num += ((e.type === 'q4') ? 1 : 0) + ((e.type === 'scopus') ? 1 : 0);
              q6Num += (e.type === 'q6') ? 1 : 0;
              q1MainNum += (e.type === 'q1' && e.role === 'main') ? 1 : 0;
              q2MainNum += (e.type === 'q2' && e.role === 'main') ? 1 : 0;
              q3MainNum += (e.type === 'q3' && e.role === 'main') ? 1 : 0;
              q4MainNum += ((e.type === 'q4' || e.type === 'scopus') && e.role === 'main') ? 1 : 0;
              q6MainNum += (e.type === 'q6' && e.role === 'main') ? 1 : 0;
              score += e.score;
            });
            rows = [
              {
                name: '',
                title: 
                  (`${q1Num + q2Num + q3Num + q4Num > 0 ? `${q1Num + q2Num + q3Num + q4Num} bài báo khoa học đã công bố trên tạp chí khoa học quốc tế. Trong đó:` : ''}`
                  + `${q1Num > 0 ? ` ${q1Num} bài báo thuộc danh mục Q1 (${q1MainNum} bài là tác giả chính),` : ''}`
                  + `${q2Num > 0 ? ` ${q2Num} bài báo thuộc danh mục Q2 (${q2MainNum} bài là tác giả chính),` : ''}`
                  + `${q3Num > 0 ? ` ${q3Num} bài báo thuộc danh mục Q3 (${q3MainNum} bài là tác giả chính),` : ''}`
                  + `${q4Num > 0 ? ` ${q4Num} bài báo thuộc danh mục Q4/Scopus (${q4MainNum} bài là tác giả chính),` : ''}`).replace(/.$/,'.')
                  + `${q6Num > 0 ? ` ${q6Num} bài báo khoa học đã công bố trên tạp chí khoa học trong nước (${q6MainNum} bài là tác giả chính).` : ''}`,
                type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: e.role === 'main' ? 1 : 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'scientificReport':
            q1Num = 0, q1MainNum = 0, q2Num = 0, q2MainNum = 0;
            q3Num = 0, q3MainNum = 0, q4Num = 0, q4MainNum = 0;
            var b1Num = 0, b1MainNum = 0, b21Num = 0, b21MainNum = 0;
            var b3Num = 0, b3MainNum = 0, b22Num = 0, b22MainNum = 0;
            score = 0;
            
            list.forEach((e) => {
              q1Num += (e.type === 'q1') ? 1 : 0;
              q2Num += (e.type === 'q2') ? 1 : 0;
              q3Num += (e.type === 'q3') ? 1 : 0;
              q4Num += (e.type === 'q4') ? 1 : 0;
              b1Num += (e.type === 'b1') ? 1 : 0;
              b21Num += (e.type === 'b2-1') ? 1 : 0;
              b22Num += (e.type === 'b2-2') ? 1 : 0;
              b3Num += (e.type === 'b3') ? 1 : 0;
              q1MainNum += (e.type === 'q1' && e.role === 'main') ? 1 : 0;
              q2MainNum += (e.type === 'q2' && e.role === 'main') ? 1 : 0;
              q3MainNum += (e.type === 'q3' && e.role === 'main') ? 1 : 0;
              q4MainNum += (e.type === 'q4' && e.role === 'main') ? 1 : 0;
              b1MainNum += (e.type === 'b1' && e.role === 'main') ? 1 : 0;
              b21MainNum += (e.type === 'b2-1' && e.role === 'main') ? 1 : 0;
              b22MainNum += (e.type === 'b2-2' && e.role === 'main') ? 1 : 0;
              b3MainNum += (e.type === 'b3' && e.role === 'main') ? 1 : 0;
              score += e.score;
            });
            rows = [
              {
                name: '',
                title: 
                  (`${q1Num + q2Num + q3Num + q4Num + b1Num + b21Num + b22Num + b3Num > 0 ? 
                    `${q1Num + q2Num + q3Num + q4Num + b1Num + b21Num + b22Num + b3Num} bài báo khoa học đã công bố trên hội thảo khoa học quốc tế. Trong đó:` : ''}`
                  + `${q1Num > 0 ? ` ${q1Num} bài báo đăng trên hội thảo quốc tế Q1 (${q1MainNum} bài là tác giả chính),` : ''}`
                  + `${q2Num > 0 ? ` ${q2Num} bài báo đăng trên hội thảo quốc tế Q2 (${q2MainNum} bài là tác giả chính),` : ''}`
                  + `${q3Num > 0 ? ` ${q3Num} bài báo đăng trên hội thảo quốc tế Q3 (${q3MainNum} bài là tác giả chính),` : ''}`
                  + `${q4Num > 0 ? ` ${q4Num} bài báo đăng trên hội thảo quốc tế Q4 (${q4MainNum} bài là tác giả chính),` : ''}`).replace(/.$/,'.')
                  + `${b1Num > 0 ? ` ${b1Num} báo cáo khoa học xuất sắc tại hội thảo/hội nghị quốc tế (Oral presentation) (${b1MainNum} bài là tác giả chính),` : ''}`
                  + `${b21Num > 0 ? ` ${b21Num} poster khoa học xuất sắc tại hội thảo quốc tế (Poster presentation) (${b21MainNum} bài là tác giả chính),` : ''}`
                  + `${b22Num > 0 ? ` ${b22Num} báo cáo khoa học đăng toàn văn trong kỷ yếu hội thảo/hội nghị quốc tế (${b22MainNum} bài là tác giả chính),` : ''}`
                  + `${b3Num > 0 ? ` ${b3Num} báo cáo khoa học đăng toàn văn trong kỷ yếu hội thảo/hội nghị quốc gia (${b3MainNum} bài là tác giả chính),` : ''}`,
                type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: e.role === 'main' ? 1 : 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'researchProject':
            var domesticNum = 0, domesticMainNum = 0, ministryNum = 0, ministryMainNum = 0;
            var schoolNum = 0, schoolMainNum = 0, vnNum = 0, vnMainNum = 0;
            score = 0;
            list.forEach((e) => {
              domesticNum += (e.level === 'domestic') ? 1 : 0;
              ministryNum += (e.level === 'ministry') ? 1 : 0;
              schoolNum += (e.level === 'school') ? 1 : 0;
              vnNum += (e.level === 'vn') ? 1 : 0;
              domesticMainNum += (e.level === 'domestic' && e.type === 'd1') ? 1 : 0;
              ministryMainNum += (e.level === 'ministry' && e.type === 'd1') ? 1 : 0;
              schoolMainNum += (e.level === 'school' && e.type === 'd1') ? 1 : 0;
              vnMainNum += (e.level === 'vn' && e.type === 'd1') ? 1 : 0;
              score += e.score;
            });
            rows = [
              {
                name: '',
                title:
                  (`Chủ trì ${list.length} chương trình, dự án, đề tài nghiên cứu khoa học. Trong đó:`
                  + `${domesticNum > 0 ? ` ${domesticNum} đề tài cấp Nhà nước và tương đương đã nghiệm thu đạt yêu cầu (chủ nhiệm ${domesticMainNum} đề tài),` : ''}`
                  + `${ministryNum > 0 ? ` ${ministryNum} đề tài cấp Bộ và tương đương đã nghiệm thu đạt yêu cầu (chủ nhiệm ${ministryMainNum} đề tài),` : ''}`
                  + `${schoolNum > 0 ? ` ${schoolNum} đề tài cấp Cơ sở đã nghiệm thu đạt yêu cầu (chủ nhiệm ${schoolMainNum} đề tài),` : ''}`
                  + `${vnNum > 0 ? ` ${vnNum} đề tài hợp tác quốc tế đã nghiệm thu đạt yêu cầu (chủ nhiệm ${vnMainNum} đề tài),` : ''}`).replace(/.$/,'.'),
                type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                // type: calcProjectType(e?.level),
                type: '',
                isMain: e.type === 'd1' ? 1 : 0,
                linkMinhChung: e?.link || e?.preview || '',
                numberAuthor: '',
              }))
            ];
            break;
          case 'monograph':
            var s1Num = 0, s2Num = 0, s3Num = 0, s4Num = 0;
            score = 0;
            list.forEach((e) => {
              s1Num += (e.type === 's1') ? 1 : 0;
              s2Num += (e.type === 's2') ? 1 : 0;
              s3Num += (e.type === 's3') ? 1 : 0;
              s4Num += (e.type === 's4') ? 1 : 0;
              score += e.score;
            });
            rows = [
              {
                name: '',
                title:
                  (`Tác giả của ${list.length} sách chuyên khảo, chương sách thuộc lĩnh vực xét thưởng. Trong đó:`
                  + `${s1Num > 0 ? ` ${s1Num} sách chuyên khảo được NXB uy tín phát hàn,` : ''}`
                  + `${s2Num > 0 ? ` ${s2Num} sách giáo trình được NXB uy tín phát hành,` : ''}`
                  + `${s3Num > 0 ? ` ${s3Num} sách tham khảo được NXB uy tín phát hành,` : ''}`
                  + `${s4Num > 0 ? ` ${s4Num} chương sách phục vụ đào tạo được NXB uy tín trên thế giới xuất bản (Chuẩn ISSN).` : ''}`).replace(/.$/,'.'),
                type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: e?.role === 'main' ? 1 : 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'techCompany':
            var internationalNum = 0, localNum = 0;
            domesticNum = 0
            score = 0;
            list.forEach((e) => {
              internationalNum += (e.applicationRange === 'international') ? 1 : 0;
              domesticNum += (e.applicationRange === 'domestic') ? 1 : 0;
              localNum += (e.applicationRange === 'local') ? 1 : 0;
              score += e.score;
            });
            rows = [
              {
                name: '',
                title:
                  (`Có ${list.length} sản phẩm khoa học công nghệ. Trong đó:`
                  + `${internationalNum > 0 ? ` ${internationalNum} sản phẩm được ứng dụng thực tiễn phạm vi quốc tế,` : ''}`
                  + `${domesticNum > 0 ? ` ${domesticNum} sản phẩm được ứng dụng phạm vị quốc gia,` : ''}`
                  + `${localNum > 0 ? ` ${localNum} sản phẩm được ứng dụng phạm vị địa phương/đơn vị,` : ''}`).replace(/.$/,'.'),
                type: '', numberAuthor: '', isMain: '', score: Math.round(score * 100) / 100, linkMinhChung: '', note: ''
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.production}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: '',
                linkMinhChung: e?.link || e?.preview || '',
                numberAuthor: '',
              }))
            ];
            break;
          case 'reward':
            var g1Num = 0, g2Num = 0;
            var g31Num = 0, g32Num = 0, g4Num = 0;
            var g5Num = 0;
            score = 0;
            list.forEach((e) => {
              g1Num += (e.type === 'g1') ? 1 : 0;
              g2Num += (e.type === 'g2') ? 1 : 0;
              g31Num += (e.type === 'g3-1') ? 1 : 0;
              g32Num += (e.type === 'g3-2') ? 1 : 0;
              g4Num += (e.type === 'g4') ? 1 : 0;
              g5Num += (e.type === 'g5') ? 1 : 0;
              score += e.score;
            });
            rows = [
              {
                name: '',
                title: 
                  (`Đã đạt được ${list.length} giải thưởng, huy chương. Trong đó:`
                  + `${g1Num > 0 ? ` ${g1Num} giải thưởng thuộc danh mục G1,` : ''}`
                  + `${g2Num > 0 ? ` ${g2Num} giải thưởng thuộc danh mục G2,` : ''}`
                  + `${g31Num > 0 ? ` ${g31Num} giải thưởng thuộc danh mục G3-1,` : ''}`
                  + `${g32Num > 0 ? ` ${g32Num} giải thưởng thuộc danh mục G3-2,` : ''}`
                  + `${g4Num > 0 ? ` ${g4Num} giải thưởng thuộc danh mục G4,` : ''}`
                  + `${g5Num > 0 ? ` ${g5Num} giải thưởng thuộc danh mục G5,` : ''}`).replace(/.$/,'.'),
                type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: '',
                numberAuthor: '',
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'hrDevelopment':
            var doctorNum = 0, masterNum = 0, bachelorNum = 0;
            g5Num = 0;
            score = 0;
            list.forEach((e) => {
              // doctorNum += (e.type === 'daoTao' && e.academicLevel === 'doctor') ? 1 : 0;
              masterNum += (e.type === 'daoTao' && e.academicLevel === 'master') ? 1 : 0;
              bachelorNum += (e.type === 'daoTao' && e.academicLevel === 'bachelor') ? 1 : 0;
              if (e.type === 'daoTao' && e.academicLevel === 'doctor') {
                doctorNum += 1;
                score += e.score;
              }
            });
            score += pointMaster + pointBachelor;
            rows = [
              {
                name: '',
                title: 
                  (`Đào tạo ${doctorNum + masterNum + bachelorNum} học viên. Trong đó:`
                  + `${doctorNum > 0 ? ` ${doctorNum} tiến sĩ,` : ''}`
                  + `${masterNum > 0 ? ` ${masterNum} thạc sĩ,` : ''}`
                  + `${bachelorNum > 0 ? ` ${bachelorNum} cử nhân,` : ''}`).replace(/.$/,'.'),
                type: '', numberAuthor: '', isMain: '',linkMinhChung: '', note: '',
                score: Math.round(score * 100) / 100,
              },
              ...list.filter((e) => e.type === 'daoTao').map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.traineeName}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.academicLevel],
                isMain: e.role === 'main' ? 1 : 0,
                numberAuthor: '',
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          default:
            break;
        }

        worksheet.addRows(rows);

        worksheet.getCell(`C${worksheet.rowCount - rows.length + 1}`).style = {
          font: { bold: true, color: { argb: 'FFFF0000' } },
        }
      }
    });

    const activityReward = JSON.parse(form?.communicationActivity || '[]');
    const socialActivity = JSON.parse(form?.socialActivity || '[]');

    var rows = [
      {
        name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
        title: 'B. Thành tích khen thưởng',
        score: form.scoreB,
      },
      ...activityReward.map((e) => ({
        ...e,
        note: '',
        name: '',
        title: e.achievementContent,
        type: FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find((lvl) => lvl.value === e.typeOfAchievement)?.title,
        isMain: '',
        numberAuthor: '',
        linkMinhChung:
          e?.achievementFile?.includes('/storage/awardRegister/')
            ? `${process.env.REACT_APP_DOMAIN}${e?.achievementFile}`
            : e?.achievementFile,
      }))
    ];

    worksheet.addRows(rows);

    worksheet.getCell(`C${worksheet.rowCount - rows.length + 1}`).style = {
      font: { bold: true, color: { argb: 'FFFF0000' } },
      alignment: { wrapText: true },
    }

    rows = [
      {
        name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
        title: 'C. Hoạt động cộng đồng',
        score: form.scoreC,
      },
      ...socialActivity.map((e) => ({
        ...e,
        note: '',
        name: '',
        title: e.communityAchievement,
        type: '',
        isMain: '',
        numberAuthor: '',
        linkMinhChung:
          e?.communityAchievementFile?.includes('/storage/awardRegister/')
            ? `${process.env.REACT_APP_DOMAIN}${e?.communityAchievementFile}`
            : e?.communityAchievementFile,
      }))
    ];

    worksheet.addRows(rows);

    worksheet.getCell(`B${worksheet.rowCount - rows.length + 1}`).style = {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' }, bgColor: { argb: 'FFFFFF' } },
      font: { bold: true, color: { argb: 'FFFF0000' } },
      alignment: { wrapText: true },
    }

    worksheet.addRow({
      name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
      title: 'D. Tổng điểm ưu tiên',
      score: form.scoreD,
    });

    worksheet.getCell(`B${worksheet.rowCount}`).style = {
      font: { bold: true, color: { argb: 'FFFF0000' } },
      alignment: { wrapText: true },
      border: qcvEnums.FULL_BORDER
    }

    worksheet.getCell(`A${startRow + 2}`).value = {
      richText: [
        { font: { bold: true }, text: 'Ngày sinh: '}, { text: dayjs(form?.profile?.birth).format('DD/MM/YYYY') },
        { font: { bold: true }, text: '\nGiới tính: '}, { text: form?.profile?.gender === 'male' ? 'Nam' : 'Nữ' },
        { font: { bold: true }, text: '\nDân tộc: '}, { text: form?.profile?.ethnic },
        { font: { bold: true }, text: '\nĐơn vị đề cử: '}, { text: form?.nominator },
        { font: { bold: true }, text: '\nĐơn vị công tác: '}, { text: form?.unit },
        { font: { bold: true }, text: '\nTrình độ học vấn: '},
        { text: FORM_CONSTANTS.ACADEMIC_LEVEL_OPTIONS.find((e) => e.value === form?.academicLevel)?.title || "" },
        { font: { bold: true }, text: '\nHọc hàm/chức danh khoa học: '}, { text: `${form?.academicRank} ` },
        { font: { bold: true }, text: '\nNgoại ngữ: '}, { text: form?.languague },
        { font: { bold: true }, text: '\nĐoàn thể chính trị: ' },
        { text: FORM_CONSTANTS.POLITICAL_UNION_OPTIONS.find((e) => e.value === form?.politicalUnion)?.title || "" },
        { font: { bold: true }, text: '\nNăng khiếu: '}, { text: form?.gifted },
        { font: { bold: true }, text: '\nĐT: '}, { text: form?.profile?.phone },
        { font: { bold: true }, text: '\nEmail: '}, { text: form?.profile?.email }, { text: '\n'},
        { font: { bold: true }, text: '\nWebsite/facebook: \n'}, { text: form?.profile?.website },
        { font: { bold: true }, text: '\nLink file công văn đề cử: \n'}, { text: form?.facultyPhone },
        { font: { bold: true }, text: '\nLink video giới thiệu bản thân: \n'}, { text: form?.videoUrl }, { text: '\n'},
      ]
    };
    worksheet.getCell(`A${startRow + 2}`).style = {
      alignment: { vertical: 'top' },
    }

    worksheet.getCell(`B${startRow + 2}`).value = {
      richText: convertHtmlToRichText(form.summary)
    };
    worksheet.getCell(`B${startRow + 2}`).style = {
      alignment: { vertical: 'top' },
    }
  
    worksheet.mergeCells(`A${startRow + 2}:A${worksheet.rowCount}`);
    worksheet.mergeCells(`B${startRow + 2}:B${worksheet.rowCount}`);
  });


  for (var row = 6; row <= worksheet.actualRowCount; row++) {
    for (var col = 0; col < worksheet.actualColumnCount; col++) {
      const cell = worksheet.getCell(`${alphabet[col]}${row}`);
      setCellValue(cell, {
        font: { size: 10, ... cell.font },
        alignment: { wrapText: true, vertical: 'middle', ... cell.alignment },
        border: qcvEnums.FULL_BORDER
      });
    }
  }


  workbook.xlsx.writeBuffer().then(function(buffer) {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), 'QCV.xlsx');
  });
}
