import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import DaoTaoForm from '../DaoTaoForm/DaoTaoForm';
import { fileActions, hrDevelopmentActions } from '../../../../actions';

export default function DaoTaoInsert(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);
  
  const [values, setValues] = useState(null);

  const handleSubmit = () => {
    dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0]))
    .then((response) => {
      dispatch(hrDevelopmentActions.insertHrDevelopment({
        ...values,
        link: response,
        type: props?.type,
        profileId,
      }));
    });
    setValues(null);
    props.onClose();
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>
            {`Đóng góp ${props?.type === 'daoTao' ? 'đào tạo' : 'bồi dưỡng'} nhân lực`}
          </Typography>
          <IconButton onClick={props.onClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DaoTaoForm
          id='dao-tao'
          values={values}
          type={props?.type}
          setValues={setValues}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button variant='outlined' onClick={props.onClose}>Huỷ</Button>
        <Button form='dao-tao' type='submit' variant='contained' color='secondary'>Xác nhận</Button>
      </DialogActions>
    </Dialog>
  );
}