import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import { useStyles } from "./styled";

import ThuHa from '../../../assets/image/face-list/ThuHa.jpg';
import BaoSon from '../../../assets/image/face-list/PGS-TS-Pham-Bao-Son.jpg';
import KimHoa from '../../../assets/image/face-list/KimHoa.jpg';
import AnhTien from '../../../assets/image/face-list/AnhTien.jpg';
import VanCanh from '../../../assets/image/face-list/PGS-TS-Le-Van-Canh.jpg';
import NhaTram from '../../../assets/image/face-list/NhaTram.jpg';
import AnhChien from '../../../assets/image/face-list/TS-Pham-Anh-Chien.jpg';
import YenThanh from '../../../assets/image/face-list/YenThanh.jpg';
import TuanDung from '../../../assets/image/face-list/TuanDung.jpg';
import DaoVanTu from '../../../assets/image/face-list/DaoVanTu.jpg';
import DaoSyDuc from '../../../assets/image/face-list/DaoSyDuc.jpg';
import HoangMinh from '../../../assets/image/face-list/PGS-TS-Hoang-Minh.jpg';
import Buitheduy from '../../../assets/image/face-list/PGS-TS-Bui-The-Duy.jpg';
import QuangTuan from '../../../assets/image/face-list/TS-Tran-Quang-Tuan.jpg';
import MinhCuong from '../../../assets/image/face-list/TS-Hoang-Minh-Cuong.jpg';
import MinhTriet from '../../../assets/image/face-list/PGS-TS-Tran-Minh-Triet.jpg';
import SharkBinh from '../../../assets/image/face-list/shark-binh.jpg';
import TaHaiTung from '../../../assets/image/face-list/TaHaiTung.jpg';
import DamThiLan from '../../../assets/image/face-list/DamThiLan.jpg';
import NgoQuocDuy from '../../../assets/image/face-list/NgoQuocDuy.jpg';
import TongVanHai from '../../../assets/image/face-list/TongVanHai.jpg';
import TrungNghia from '../../../assets/image/face-list/TrungNghia.jpg';
import HongPhuong from '../../../assets/image/face-list/HongPhuong.jpg';
import LeHoangTan from '../../../assets/image/face-list/LeHoangTan.jpg';
import HoXuanVinh from '../../../assets/image/face-list/HoXuanVinh.jpg';
import DangDucHuy from '../../../assets/image/face-list/DangDucHuy.jpg';
import DaoVietHang from '../../../assets/image/face-list/DaoVietHang.jpg';
import HoangVanSam from '../../../assets/image/face-list/HoangVanSam.jpg';
import TruongThang from '../../../assets/image/face-list/TS-Nguyen-Truong-Thang.jpg';
import NgoMinhLong from '../../../assets/image/face-list/NgoMinhLong.jpg';
import VoThanhSang from '../../../assets/image/face-list/VoThanhSang.jpg';
import PhamHuyHieu from '../../../assets/image/face-list/PhamHuyHieu.jpg';
import NguyenDaiHai from '../../../assets/image/face-list/NguyenDaiHai.jpg';
import VongBinhLong from '../../../assets/image/face-list/VongBinhLong.jpg';
import TranXuanBach from '../../../assets/image/face-list/TranXuanBach.jpg';
import NguyenNhuSon from '../../../assets/image/face-list/NguyenNhuSon.jpg';
import Nguyenhuydung from '../../../assets/image/face-list/Nguyenhuydung.jpg';
import DinhNgocThanh from '../../../assets/image/face-list/DinhNgocThanh.jpg';
import NguyenThaiSon from '../../../assets/image/face-list/NguyenThaiSon.jpg';
import TranDinhPhong from '../../../assets/image/face-list/TranDinhPhong.jpg';
import NguyenTheTrung from '../../../assets/image/face-list/NguyenTheTrung.jpg';
import NguyenThienTao from '../../../assets/image/face-list/NguyenThienTao.jpg';
import TruongQuocPhong from '../../../assets/image/face-list/TruongQuocPhong.jpg';
import NguyenTrungKien from '../../../assets/image/face-list/NguyenTrungKien.jpg';
import TrinhKieuTheLoan from '../../../assets/image/face-list/TrinhKieuTheLoan.jpg';
import NguyenHoangChinh from '../../../assets/image/face-list/NguyenHoangChinh.jpg';

const LIST_FACE = [
  {
    title: "Công tác tại các cơ quan bộ ngành, địa phương",
    list: [
      {
        name: 'QCV2006 - PGS. TS. Bùi Thế Duy',
        img: Buitheduy,
        text: 'Thứ trưởng Bộ Khoa học và Công nghệ',
      },
      {
        name: 'QCV2010 - NGUYỄN HUY DŨNG',
        img: Nguyenhuydung,
        text: 'Thứ trưởng Bộ Thông tin và Truyền thông',
      },
      {
        name: 'QCV2003 - PGS.TS. Hoàng Minh',
        img: HoangMinh,
        text: 'Thứ trưởng Bộ Khoa học và Công nghệ',
      },
    ],
  },
  {
    title: null,
    list: [
      {
        name: 'QCV2008 - TS. Trần Quang Tuấn',
        img: QuangTuan,
        text: 'Phó Cục trưởng Cục năng lượng nguyên tử, Bộ Khoa học và Công nghệ',
      },
      {
        name: 'QCV2004 - PGS.TS. Nguyễn Như Sơn',
        img: NguyenNhuSon,
        text: 'Giám đốc Trung tâm Công nghệ thông tin và chuyển đổi số, Bộ Kế hoạch và Đầu tư',
      },
      {
        name: 'QCV2003 - TS. Phạm Anh Chiến',
        img: AnhChien,
        text: 'Phó giám đốc Trung tâm sản xuất và phát triển nội dung số Đài truyền hình Việt Nam (VTV Digital)',
      },
      {
        name: 'QCV2004 - TS. Hoàng Minh Cường',
        img: MinhCuong,
        text: 'Phó Chủ tịch Ủy ban Nhân dân Thành phố Hải Phòng',
      },
      {
        name: 'QCV2009 - Nguyễn Thị Kim Hoa',
        img: KimHoa,
        text: 'Phó Ban Khoa Giáo, Đài truyền hình Việt Nam',
      },
      {
        name: 'QCV2013 - TS. BS. Nguyễn Thị Thu Hà',
        img: ThuHa,
        text: 'Phó giám đốc Bệnh viện phụ sản Trung ương',
      },
      {
        name: 'QCV2011 - Ngô Minh Long',
        img: NgoMinhLong,
        text: 'Giám đốc Sở Nông nghiệp và Phát triển Nông thôn tỉnh Hậu Giang',
      },
    ]
  },
  {
    title: "Công tác tại các cơ sở giáo dục, viện nghiên cứu",
    list: [
      {
        name: 'QCV2007 - PGS. TS. Phạm Bảo Sơn',
        img: BaoSon,
        text: 'Phó Giám đốc Đại học Quốc gia Hà Nội',
      },
      {
        name: 'QCV2008 - TS. Nguyễn Trường Thắng',
        img: TruongThang,
        text: 'Viện trưởng Viện Công nghệ thông tin, Viện Hàn lâm Khoa học và Công nghệ Việt Nam',
      },
      {
        name: 'QCV2008 - PGS.TS. Phùng Trung Nghĩa',
        img: TrungNghia,
        text: 'Hiệu trưởng trường Đại học Công nghệ thông tin và Truyền thông, Đại học Thái Nguyên',
      },
      {
        name: 'QCV2013 - PGS.TS. Tạ Hải Tùng',
        img: TaHaiTung,
        text: 'Hiệu trưởng Trường Công nghệ Thông tin và Truyền thông, Đại học Bách khoa Hà Nội',
      },
      {
        name: 'QCV2009 - PGS. TS. Trần Minh Triết',
        img: MinhTriet,
        text: 'Phó Hiệu trưởng Trường Đại học Khoa học Tự nhiên, Đại học Quốc gia TP. Hồ Chí Minh',
      },
      {
        name: 'QCV2011 - PGS.TS. Cao Tuấn Dũng',
        img: TuanDung,
        text: 'Phó Hiệu trưởng, Trường Công nghệ Thông tin & Truyền thông, Đại học Bách Khoa Hà Nội',
      },
      {
        name: 'QCV2013 - GS.TS. Lê Văn Cảnh',
        img: VanCanh,
        text: 'Phó hiệu trưởng Trường Đại học Quốc tế, Đại học Quốc gia Thành phố Hồ Chí Minh',
      },
      {
        name: 'QCV2016 - PGS.TS. Trần Đình Phong',
        img: TranDinhPhong,
        text: 'Phó Hiệu trưởng Trường Đại học Khoa học và Công nghệ Hà Nội',
      },
      {
        name: 'QCV2018 - GS.TS. Nguyễn Đại hải',
        img: NguyenDaiHai,
        text: 'Phó Viện trường Viện Công nghệ hóa học, Viện Hàn lâm Khoa học và Công nghệ Việt Nam',
      },
      {
        name: 'QCV2004 - Nguyễn Trung Kiên',
        img: NguyenTrungKien,
        text: 'Viện trưởng Viện công nghệ thông tin và Truyền Thông, Học viện Công nghệ Bưu chính Viên thông',
      },
      {
        name: 'QCV2011 - GS.TS. Hoàng Văn Sâm',
        img: HoangVanSam,
        text: 'Trưởng Phòng Hợp tác Quốc Tế, Trường Đại học Lâm Nghiệp',
      },
      {
        name: 'QCV2012 - TS. Tống Văn Hải',
        img: TongVanHai,
        text: 'Phó Viện trưởng Viện Nghiên cứu Công nghệ Hỗ trợ Nông nghiệp, Học viện Nông nghiệp Việt Nam',
      },
      {
        name: 'QCV2014 - PGS.TS. Trương Quốc Phong',
        img: TruongQuocPhong,
        text: 'Phó Viện trưởng, Viện Công nghệ Sinh học và Công nghệ Thực phẩm, Đại học Bách khoa Hà Nội',
      },
      {
        name: 'QCV2017 - GS.TS. Trần Xuân Bách',
        img: TranXuanBach,
        text: 'Phó Chủ tịch Hội thầy thuốc trẻ Việt Nam Phó Trưởng Bộ môn Kinh tế Y tế, Viện Đào tạo Y học Dự phòng và Y tế công cộng, Trường Đại học Y Hà Nội',
      },
      {
        name: 'QCV2020 - PGS.TS. Võ Thanh Sang',
        img: VoThanhSang,
        text: 'Phó Viện trưởng Viện Sinh học Nông nghiệp tiên tiến, Trường Đại học Nguyễn Tất Thành',
      },
      {
        name: 'QCV2015 - PGS.TS. Nguyễn Thiên Tạo',
        img: NguyenThienTao,
        text: 'Chánh văn phòng, Trưởng nhóm Nghiên cứu, Viện Nghiên cứu Hệ gen, Viện Hàn lâm KHCN Việt Nam',
      },
      {
        name: 'QCV2015 - PGS.TS. Nguyễn Thái Sơn',
        img: NguyenThaiSon,
        text: 'Viện trưởng viện phát triển nguồn lực, Trường Đại học Trà Vinh',
      },
      {
        name: 'QCV2018 - PGS.TS. Vòng Bính Long',
        img: VongBinhLong,
        text: 'Phó trưởng Khoa Kỹ thuật Y sinh, Trường Dại học Quốc tế, Đại học Quốc gia TP. Hồ Chí Minh',
      },
      {
        name: 'QCV2018 - PGS.TS. Đào Sỹ Đức',
        img: DaoSyDuc,
        text: 'Phó trưởng Khoa Hóa học, Trường Đại học Khoa học Tự nhiên, Đại học Quốc gia Hà Nội',
      },
      {
        name: 'QCV2020 - TS.BS. Đào Văn Tú',
        img: DaoVanTu,
        text: 'Giám đốc Trung tâm nghiên cứu lâm sàng, Bệnh viện K Hà Nội',
      },
      {
        name: 'QCV2020 - PGS.TS. Đoàn Lê Hoàng Tân',
        img: LeHoangTan,
        text: 'Phó Giám đốc, Trung tâm Nghiên cứu Vật liệu Cấu trúc Nano và Phân tử, Đại học Quốc gia TP. Hồ Chí Minh',
      },
      {
        name: 'QCV2021 - PGS.TS.BS. Đào Việt Hằng',
        img: DaoVietHang,
        text: 'Chủ tịch mạng lưới Tri thức trẻ Việt Nam toàn cầu, Phó Giám đốc Trung tâm Nội soi, Bệnh viện Đại học Y Hà Nội',
      },
      {
        name: 'QCV2023 - TS.BS. Ngô Quốc Duy',
        img: NgoQuocDuy,
        text: 'Phó trưởng Khoa ngoại Đầu cổ, Bệnh viện K',
      },
      {
        name: 'QCV2023 - TS. Phạm Huy Hiệu',
        img: PhamHuyHieu,
        text: 'Phó Giám đốc Trung tâm Nghiên cứu Sức khoẻ Thông minh VinUni-Illinois, Trường Đại học VinUni'
      },
      // {
      //   name: 'QCV2010 - ThS. Nguyễn Đồng Long',
      //   img: DongLong,
      //   text: 'Chủ tịch Hội Nghệ sĩ trẻ Hà Nội',
      // },
    ],
  },
  {
    title: "Lãnh đạo chủ chốt các doanh nghiệp khoa học công nghệ",
    list: [
      {
        name: 'QCV2003 - Nguyễn Hòa Bình',
        img: SharkBinh,
        text: 'Chủ tịch HĐQT Tập đoàn NextTech',
      },
      {
        name: 'QCV2007 - Nguyễn Thế Trung',
        img: NguyenTheTrung,
        text: 'Chủ tịch Hội đồng quản trị, Tổng Giám đốc Công ty Cổ phần Công nghệ DTT'
      },
      {
        name: 'QCV2010 - Lê Nguyễn Hồng Phương',
        img: HongPhuong,
        text: 'Chủ tịch Hội đồng quản trị, Tổng giám đốc Công ty CP BIT Group',
      },
      {
        name: 'QCV2011 - Tô Thị Nhã Trầm',
        img: NhaTram,
        text: 'Giám đốc Công ty cổ phần Công nghệ Sinh học Cây giống Việt Nam',
      },
      {
        name: 'QCV2013 - Đàm Thị Lan',
        img: DamThiLan,
        text: 'Giám đốc Công ty CP Năng lượng và Môi trường Bách khoa Hà Nội',
      },
      {
        name: 'QCV2013 - Lê Yên Thanh',
        img: YenThanh,
        text: 'Tổng Giảm đốc Phenikaa MaaS Technology JSC',
      },
      {
        name: 'QCV2015 - Lê Anh Tiến',
        img: AnhTien,
        text: 'Đồng sáng lập và CEO Công ty cổ phần Công nghệ Chatbot Việt Nam',
      },
      {
        name: 'QCV2021 - Hồ Xuân Vinh',
        img: HoXuanVinh,
        text: 'Phó Giám đốc Công ty TNHH Hồ Hoàn Cầu',
      },
    ]
  },
  {
    title: "Công tác tại các cơ sở giáo dục, viện nghiên cứu ở nước ngoài",
    list: [
      {
        name: 'QCV2018 - TS. Trịnh Kiều Thế Loan',
        img: TrinhKieuTheLoan,
        text: 'Trợ lý Giáo sư Khoa Công nghệ Sinh học Nano, Trường Đại học Gachon, Hàn Quốc',
      },
      {
        name: 'QCV2019 - TS. Đinh Ngọc Thạnh',
        img: DinhNgocThanh,
        text: 'Giáo sư tập sự, Khoa công nghệ thông tin - Truyền Thông, Đại học Soongsil, Hàn Quốc',
      },
      {
        name: 'QCV2020 - TS. Nguyễn Hoàng Chinh',
        img: NguyenHoangChinh,
        text: 'Đại học Deakin, Australia',
      },
      {
        name: 'QCV2020 - TS. Đặng Đức Huy',
        img: DangDucHuy,
        text: 'Giáo sư trợ lý, Đại học Trent, Canada',
      },
    ]
  }
];

export default function SuccessfulFace() {
  const classes = useStyles();
  return (
    <>
      {LIST_FACE.map((item, index) => (
        <Box key={`item.${index}`} marginY={4}>
          <Typography variant='h5' style={{ textAlign: 'center' }}><b>{item.title}</b></Typography>
          <Grid container spacing={2} justifyContent='center' style={{ marginTop: 4 }}>
            {item.list.map((param, index) => (
              <Grid key={index} item xs={12} sm={4} md={3}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <img className={classes.image} alt={param.title} src={param.img} />
                  <Box width={360} marginTop={2}>
                    <Typography className={classes.subtitle1} variant='subtitle1'>
                      <b>{param.name.split(' - ')[1]}</b>
                    </Typography>
                  </Box>
                  <Typography className={classes.subtitle1} variant='subtitle1'>
                    <b>({param.name.split(' - ')[0]})</b>
                  </Typography>
                  <Typography className={classes.subtitle2} variant='subtitle2'>
                    {param.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </>
  );
}
