import dayjs from 'dayjs';

import {
  hrDevelopmentActions,
  monographActions,
  reportsActions,
  researchActions,
  researchProjectActions,
  rewardActions,
  solutionActions,
  techCompanyActions,
} from '../actions'

export const thanhTichList = [
	'formSolution',
	'formScientificResearch',
	'formScientificReport',
	'formResearchProject',
	'formMonograph',
	'formTechCompany',
	'formReward',
	'formHrDevelopment',
];

const getValue = (s) => s[0].toLowerCase() + s.slice(1);

export const autoScore = (form, scoreInformationList, dispatch) => {
  // const dispatch = useDispatch();

  var finalObj = {};
  thanhTichList.forEach((thanhTich) => {
    const listInfo = form?.[thanhTich];

    const finalList = [];
    listInfo.forEach((infoObj) => {
      const value = getValue(thanhTich.replace('form', ''));
      const info = infoObj?.[value];

      var code;
      switch (thanhTich) {
        case 'formSolution':
          if (info?.type === 'sc') code = '1-1';
          else if (info?.type === 'scqg') code = '1.2';
          else if (info?.type === 'gp') code = '1.3';
          break;
        case 'formScientificResearch':
          if (info?.type === 'q1') code = '2.1';
          else if (info?.type === 'q2') code = '2.2';
          else if (info?.type === 'q3') code = '2.3';
          else if (info?.type === 'q4' || info?.type === 'scopus') code = '2.4';
          else if (info?.type === 'q6') code = '2.5';
          break;
        case 'formScientificReport':
          if (info?.type === 'q1') code = '3.1';
          else if (info?.type === 'q2') code = '3.2';
          else if (info?.type === 'q3') code = '3.3';
          else if (info?.type === 'q4') code = '3.4';
          else if (info?.type === 'b1') code = '3.5';
          else if (info?.type === 'b2-1') code = '3.6';
          else if (info?.type === 'b2-2') code = '3.7';
          else if (info?.type === 'b3') code = '3.8';
          break;
        case 'formMonograph':
          if (info?.type === 's1' && info?.issn !== null) code = '5.1a';
          else if (info?.type === 's1' && info?.issn === null) code = '5.1b';
          else if (info?.type === 's2') code = '5.2';
          else if (info?.type === 's3') code = '5.3';
          else if (info?.type === 's4') code = '5.4';
          break;
        case 'formResearchProject':
          if (info?.level === 'domestic' && info?.type === 'd1') code = '4.1a';
          else if (info?.level === 'domestic' && info?.type === 'd2') code = '4.1b';
          else if (info?.level === 'domestic' && info?.type === 'd3') code = '4.1c';
          else if (info?.level === 'ministry') code = '4.2';
          else if (info?.level === 'school') code = '4.3';
          else if (info?.level === 'vn') code = '4.4';
          break;
        case 'formReward':
          if (info?.type === 'g1') code = (dayjs(form?.profile?.birth).year() >= 1998) ? '7.1-a' : '7.2-a';
          else if (info?.type === 'g2') code = (dayjs(form?.profile?.birth).year() >= 1998) ? '7.1-b' : '7.2-b';
          else if (info?.type === 'g3-1') code = (dayjs(form?.profile?.birth).year() >= 1998) ? '7.1-c' : '7.2-c';
          else if (info?.type === 'g3-2') code = '7.2-d';
          else if (info?.type === 'g4') code = '7.2-e';
          else if (info?.type === 'g5') code = '7.2-f';
          break;
        case 'formHrDevelopment':
          if (info?.type === 'daoTao' && info?.academicLevel === 'doctor') {
            const roleLower = info?.role.toLowerCase();
            if (roleLower.includes('đồng') || roleLower.includes('co') || roleLower.includes('phụ')) {
              code = '8.1-b';
            } else {
              code = '8.1-a';
            }
          }
          else if (info?.type === 'daoTao' && info?.academicLevel === 'master') code = '8.2';
          else if (info?.type === 'daoTao' && info?.academicLevel === 'bachelor') code = '8.3';
          break;
        case 'formTechCompany':
          if (info?.applicationRange === 'international') code = '6.1';
          else if (info?.applicationRange === 'domestic') code = '6.2';
          else if (info?.applicationRange === 'local') code = '6.3';
          break;
        default:
          break;
      }

      if (code) {
        var infoScore;
        const sInfo = scoreInformationList?.find((e) => e?.code == code);
  
        if (thanhTich === 'formScientificResearch' || thanhTich === 'formScientificReport' || thanhTich === 'formMonograph') {
          // const diemChiaDeu = sInfo?.value / 2 / (info?.numberAuthor);
          // const diemTgChinh = (info?.role === 'main') ? sInfo?.value / 2 : 0;
          // var tong = diemChiaDeu + diemTgChinh;
          // if (info?.role === 'main') tong = tong / info?.numberMain;
          // infoScore = { ...sInfo, value: Math.round(tong * 100) / 100 };

          const diemChiaDeu = (sInfo?.value / 2) / info?.numberAuthor;
          const diemTgChinh = (info?.role === 'main') ? (sInfo?.value / 2) / info?.numberMain : 0;
          var tong = diemChiaDeu + diemTgChinh;
          infoScore = { ...sInfo, value: Math.round(tong * 100) / 100 };
        } else if (thanhTich === 'formSolution') {
          var numberAuthor = 1;
          if (info?.author.includes(' and ')) numberAuthor = 2;
          else if (info?.author.includes(';')) numberAuthor = info?.author.split(';').length;
          else if (info?.author.includes(',')) numberAuthor = info?.author.split(',').length;
          else if (info?.author.includes('-')) numberAuthor = info?.author.split('-').length;
  
          infoScore = { ...sInfo, numberAuthor, value: Math.round((sInfo?.value / numberAuthor) * 100) / 100 };
        } else if (thanhTich === 'formResearchProject') {
          if (info?.type === 'd1') {
            infoScore = { ...sInfo, value: Math.round((sInfo?.value / info?.numberMain) * 100) / 100 };
          }
        } else {
          infoScore = {...sInfo};
        }
      }

      if (code) {
        finalList.push({
          ...infoObj,
          score: infoScore.value,
          accepted: false,
        });infoObj
      }
      // console.log(infoScore, code)
    });
    // console.log(thanhTich, finalList);
    finalObj = { ...finalObj, [thanhTich]: finalList };
  });

  dispatch(researchActions.updateResearchAccepted(finalObj.formScientificResearch)).then(() => {
    dispatch(reportsActions.updateReportAccepted(finalObj.formScientificReport)).then(() => {
      dispatch(solutionActions.updateSolutionAccepted(finalObj.formSolution)).then(() => {
        dispatch(researchProjectActions.updateResearchProjectAccepted(finalObj.formResearchProject)).then(() => {
          dispatch(monographActions.updateMonographAccepted(finalObj.formMonograph)).then(() => {
            dispatch(techCompanyActions.updateTechCompanyAccepted(finalObj.formTechCompany)).then(() => {
              dispatch(rewardActions.updateRewardAccepted(finalObj.formReward)).then(() => {
                dispatch(hrDevelopmentActions.updateHrDevelopmentAccepted(finalObj.formHrDevelopment)).then(() => {
                  console.log(finalObj);
                });
              });
            });
          });
        });
      });
    });
  });
}
