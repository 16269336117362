const EXPORT_COLUMNS = [
  { key: 'name', title: 'THÔNG TIN CÁ NHÂN', width: 50 },
  { key: 'title', title: 'THÀNH TÍCH NỔI BẬT', width: 70 },
  { key: 'type', title: 'PL', width: 10 },
  { key: 'numberAuthor', title: 'SỐ TG', width: 5 },
  { key: 'isMain', title: 'TG CHÍNH', width: 7 },
  { key: 'score', title: 'ĐIỂM', width: 7 },
  { key: 'linkMinhChung', title: 'MINH CHỨNG (link file, website,…)', width: 20 },
  { key: 'note', title: 'GHI CHÚ', width: 30 },
];

const THANH_TICH_LIST = [
  'formSolution',
  'formScientificResearch',
  'formScientificReport',
  'formStudentResearch',
  'formScientificReward',
  'formStudentReward'
];

export const GTNS_ENUMS = {
  EXPORT_COLUMNS,
  THANH_TICH_LIST,
}
