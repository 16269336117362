import { FORM_TYPE, MONOGRAPHS_TYPE } from '../constants';

const initialState = {
  loading: false,
  personalMonographList: [],
  totalMonographList: [],
};

const sortFunc = (a, b) => a.id - b.id;

export function monographReducer(state = initialState, action) {
  switch (action.type) {
    case MONOGRAPHS_TYPE.GET_ALL_MONOGRAPH_LOADING:
    case MONOGRAPHS_TYPE.GET_MONOGRAPH_BY_PROFILE_ID_LOADING:
    case MONOGRAPHS_TYPE.INSERT_MONOGRAPH_LOADING:
    case MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_LOADING:
    case MONOGRAPHS_TYPE.DELETE_MONOGRAPH_LOADING:
    case MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_ACCEPTED_LOADING:
      return { ...state, loading: true };

    case MONOGRAPHS_TYPE.GET_ALL_MONOGRAPH_FAILURE:
    case MONOGRAPHS_TYPE.GET_MONOGRAPH_BY_PROFILE_ID_FAILURE:
    case MONOGRAPHS_TYPE.INSERT_MONOGRAPH_FAILURE:
    case MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_FAILURE:
    case MONOGRAPHS_TYPE.DELETE_MONOGRAPH_FAILURE:
    case MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_ACCEPTED_FAILURE:
      return { ...state, loading: false };

    case MONOGRAPHS_TYPE.GET_ALL_MONOGRAPH_SUCCESS:
      return { ...state, totalMonographList: action.payload.sort(sortFunc), loading: false };

    case MONOGRAPHS_TYPE.GET_MONOGRAPH_BY_PROFILE_ID_SUCCESS:
      return { ...state, personalMonographList: action.payload.sort(sortFunc), loading: false };

    case MONOGRAPHS_TYPE.INSERT_MONOGRAPH_SUCCESS:
      return {
        ...state,
        totalMonographList: [...state.personalMonographList, action.payload].sort(sortFunc),
        personalMonographList: [...state.personalMonographList, action.payload].sort(sortFunc),
        loading: false,
      };

    case MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_SUCCESS:
      return {
        ...state,
        totalMonographList: state.totalMonographList
          ? [...state.totalMonographList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        personalMonographList: state.personalMonographList
          ? [...state.personalMonographList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        loading: false,
      };

    case MONOGRAPHS_TYPE.DELETE_MONOGRAPH_SUCCESS:
      return {
        ...state,
        totalMonographList: state.totalMonographList?.filter((e) => e.id !== action.id).sort(sortFunc),
        personalMonographList: state.personalMonographList.filter((e) => e.id !== action.id).sort(sortFunc),
        loading: false,
      };

    case MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_ACCEPTED_SUCCESS:
      return {
        ...state,
        // totalMonographList: state.totalMonographList ? [
        //   ...state.totalMonographList?.filter((e) => e.id !== action.payload.id),
        //   action.payload
        // ].sort(sortFunc) : null,
        // personalMonographList: state.personalMonographList ? [
        //   ...state.personalMonographList?.filter((e) => e.id !== action.payload.id),
        //   action.payload
        // ].sort(sortFunc) : null,
        loading: false,
      };

    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        personalMonographList: action.payload.monographs.sort(sortFunc)
      }

    default:
      return state;
  }
}
