import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import theme from './theme';
import AppLayout from './components/AppLayout';

import { store } from './helpers';

import './index.css';

const App = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={2} style={{ pointerEvents: 'all' }}>
        <ThemeProvider theme={theme}>
          <AppLayout />
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
