/* eslint-disable */
import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Container,
  Divider,
} from "@material-ui/core";

import { ROUTER } from "../../../constants";
import { useScreenSize } from "../../../hooks/useScreenSize";

import tut from "../../../assets/image/home/tutorial-icon.png";
import backdrop from "../../../assets/image/home/backdrop.jpg";
import logoDoan from "../../../assets/image/home/logoDoan.png";
import paperIcon from "../../../assets/image/home/paper-icon.png";
import bannerTitle from "../../../assets/image/home/xhnv-banner-title.png";
import registerIcon from "../../../assets/image/home/register-icon.png";
import QuyChe from "../QuyChe";
import Logo30 from "../../../containers/AppLayout/Logo30";

const topicList = [
  {
    key: "thanh-tích",
    title: "Quy chế xét thưởng",
    containerColor: "#F5F1EE",
    imgSource: paperIcon,
    to: "#quy-che",
    description:
      "Theo Quy chế Giải thưởng Khuê Văn Các ban hành theo Quyết định số 601 -QĐ/TWĐTN-KHCN ngày 10/07/2024 của Ban Bí thư Trung ương Đoàn"
  },
  {
    key: "dang-ky",
    title: "Đăng ký",
    containerColor: "#EFEEF5",
    imgSource: registerIcon,
    to: ROUTER.DANG_KY_HO_SO,
    description:
      "Đăng ký tham gia hệ thống bằng việc hoàn thiện hồ sơ đăng ký, khai các thành tích cá nhân và chờ hệ thống xét duyệt.",
  },
  {
    key: "binh-chọn",
    title: "Hướng dẫn đăng ký",
    containerColor: "#F4F5EE",
    imgSource: tut,
    to: ROUTER.QCV.HUONG_DAN_SU_DUNG,
    description: "Vui lòng ấn vào Chi tiết bên dưới để xem hướng dẫn sử dụng",
  },
];

function GioiThieuXHNV() {
  const isMobileMode = useScreenSize();

  return (
    <>
      <Box style={{
        backgroundImage: `url(${backdrop})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <Container maxWidth="md">
          <Box
            paddingY={6}
            display="flex"
            alignItems="center"
            flexDirection={isMobileMode ? "column" : "row"}
          >
            <Box display="flex">
              <img
                src={logoDoan}
                alt="logo-doan"
                style={{ minWidth: 64, width: "calc(100vw / 20)" }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{ flexGrow: 1 }}
            >
              <img
                src={bannerTitle}
                alt="title"
                style={{
                  marginLeft: isMobileMode ? 0 : 16,
                  width: isMobileMode ? "100%" : "calc(100% - 100vw / 30)",
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box className="formal-text">
          <p><span>Thực hiện Quyết định số 1314/QĐ-TTg ngày 01/11/2022 của Thủ tướng Chính phủ về việc
            phê duyệt Đề án </span><i>"Phát hiện, bồi dưỡng và phát huy tài năng trẻ Việt Nam giai đoạn
              2022-2030", </i><span>Trung ương Đoàn TNCS Hồ Chí Minh xây dựng Giải thưởng Khuê Văn Các, đây
                là giải thưởng đầu tiên ở Việt Nam có quy mô quốc gia và phạm vi xét chọn toàn cầu dành cho các nhà khoa học
                trẻ Việt Nam trong lĩnh vực khoa học xã hội và nhân văn. Trung tâm Phát triển Khoa học, Công nghệ và Tài
                năng trẻ trực thuộc Trung ương Đoàn TNCS Hồ Chí Minh là đơn vị thường trực giải thưởng.</span></p>
          <p><span>Giải thưởng Khuê Văn Các là phần thưởng cao quý của Trung ương Đoàn TNCS Hồ Chí Minh
            chủ trì phối hợp với các bộ, ngành liên quan định kỳ 2 năm tổ chức 1 lần xét trao tối đa cho 10 nhà khoa học
            trẻ xuất sắc đang học tập, nghiên cứu, làm việc ở trong hoặc nước ngoài, không quá 35 tuổi (tính đến ngày
            31/12 của năm xét trao giải) trong lĩnh vực khoa học xã hội và nhân văn thuộc 06 ngành/liên ngành: </span>
          </p>
          <p><span>[1] Luật học</span></p>
          <p><span>[2] Giáo dục học</span></p>
          <p><span>[3] Kinh tế học</span></p>
          <p><span>[4] Liên ngành Văn hóa - Nghệ thuật</span></p>
          <p><span>[5] Liên ngành Triết học - Chính trị - Xã hội học </span></p>
          <p><span>[6] Liên ngành Sử học - Khảo cổ học - Dân tộc học - Nhân học. </span></p>
          <p><span>Giải thưởng Khuê Văn Các ra đời là hiện thực hóa chủ trương của Đảng, Nhà nước về
            phát triển nguồn nhân lực chất lượng cao và triển khai thực hiện Đề án </span><i>"Phát
              hiện, bồi dưỡng và phát huy tài năng trẻ Việt Nam giai đoạn 2022-2030"</i><span>&nbsp;của Thủ
                tướng Chính phủ nhằm mục đích:</span></p>
          <p><span>- Phát hiện, tôn vinh, xây dựng đội ngũ chuyên gia, nhà khoa học trẻ giỏi, tài năng
            trong lĩnh vực khoa học xã hội và nhân văn.</span></p>
          <p><span>- Tạo động lực, truyền cảm hứng và thúc đẩy phong trào thi đua học tập, nghiên cứu
            khoa học, ứng dụng các thành tựu khoa học xã hội và nhân văn trong thanh thiếu nhi.</span></p>
          <p><span>- Huy động và phát huy mọi nguồn lực xã hội đối với công tác phát triển nguồn nhân
            lực chất lượng cao trong lĩnh vực khoa học xã hội và nhân văn phục vụ sự nghiệp xây dựng và bảo vệ Tổ quốc,
            góp phần phát triển đất nước Việt Nam phồn vinh, hạnh phúc.</span></p>
          <p class="c2"><span></span></p>
        </Box>
        <Grid container spacing={4}>
          {topicList.map((e) => (
            <Grid key={e.key} item xs={12} md={4} style={{ marginBottom: 32 }}>
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  display="flex"
                  paddingY={4}
                  justifyContent="center"
                  alignItems="center"
                  bgcolor={e.containerColor}
                >
                  <CardMedia
                    src={e.imgSource}
                    component="img"
                    style={{ width: '45%' }}
                  />
                </Box>
                <CardContent style={{ flexGrow: 1, textAlign: "justify" }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginTop: 24,
                      marginBottom: 8,
                      textAlign: "center",
                    }}
                  >
                    <b>{e.title}</b>
                  </Typography>
                  <Box style={{ color: "#55626a", lineHeight: "1.68421053em" }}>
                    {e.description}
                  </Box>
                </CardContent>
                <CardActions>
                  <a href={e.to} style={{ margin: "auto", color: "#ee6064" }}>
                    <b>Chi tiết</b>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Divider
          id="quy-che"
          style={{
            width: 64,
            height: 4,
            backgroundColor: "#ee6064",
            margin: "64px auto 16px",
          }}
        />
        <Typography variant="h4" style={{ textAlign: "center", fontFamily: "WorkSans-ExtraBold" }}>
          <strong>QUY CHẾ XÉT THƯỞNG</strong>
        </Typography>
        <Typography
          variant="h6"
          style={{ textAlign: "center", color: "#55626a" }}
        >
          <i>
            (Theo Quy chế Giải thưởng Khuê Văn Các ban hành theo Quyết định số
            601 -QĐ/TWĐTN-KHCN ngày 10/07/2024 của Ban Bí thư Trung ương Đoàn)
          </i>
        </Typography>
        <QuyChe />
      </Container>
      <Logo30 />
    </>
  );
}

export default GioiThieuXHNV;
