import React from "react";
import dayjs from "dayjs";

import { Box, Grid, Typography } from "@material-ui/core";
import { APP_SCENE, FORM_CONSTANTS } from "../../../../constants";

import AnhChanDung from "../../../../components/CustomComponents/AnhChanDung";


const HosoProfile = ({ applicationDetail }) => {
  const profileList = [
    { key: 'birthday', label: 'Ngày sinh', value: dayjs(applicationDetail?.profile?.birth).format('DD/MM/YYYY') },
    { key: 'ethnic', label: 'Dân tộc', value: applicationDetail?.profile?.ethnic },
    { key: 'gender', label: 'Giới tính', value: FORM_CONSTANTS.GENDER_OPTIONS.find((e) => e.value === applicationDetail?.profile?.gender)?.title },
    { key: 'phone', label: 'Điện thoại', value: applicationDetail?.profile?.phone },
    { key: 'email', label: 'Email', value: applicationDetail?.profile?.email },
    {
      key: 'facebook',
      label: 'Website/facebook',
      value: (
        <a target='_blank' rel='noreferrer' href={applicationDetail?.profile?.website}>
          {applicationDetail?.profile?.website}
        </a>
      ),
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Box display='flex'>
          <Box display='flex'>
            <AnhChanDung width='180px' src={applicationDetail?.profile?.photoUrl} />
          </Box>
          <Box display='flex' flexDirection='column' justifyContent='space-between' width='100%' pl={4}>
            <Box>
              <Typography variant='h3' className='name'>
                <b>{applicationDetail?.profile?.name}</b>
              </Typography>
              <Typography variant='h6'>
                <b>Nhóm ngành: </b>
                {FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE].find((e) => e.value === applicationDetail?.field)?.title}
              </Typography>
            </Box>
            <Box mb={2}>
              {profileList.map((e, index) => (
                <Typography key={index}>
                  <b>{e.label}: </b><span>{e.value}</span>
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
        <Box bgcolor='black' width='100%' height={4} />
      </Grid>
    </>
  )
}

export default HosoProfile;