import React from 'react';

import { InfoOutlined } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { useScreenSize } from '../../../../hooks/useScreenSize';
import { REWARD_CONSTANTS } from '../../../../constants';

export const rewardForm = (values, mobileMode, disabled, typeOptions) => ([
  {
    key: 'title',
    width: 12,
    height: 1,
    label: 'Tên giải thưởng',
    value: values?.title,
    disabled,
    required: true,
    note: (
      <Box display="flex" alignItems="center">
        <InfoOutlined style={{ width: 16 }} />
        &nbsp;<b>Lưu ý:</b>&nbsp;
        Không gồm những thành tích đã khai ở các tiêu chuẩn trên
      </Box>
    )
  },
  { key: 'author', width: 12, height: 1, label: 'Danh sách đoạt giải', value: values?.author, disabled, required: true },
  { key: 'type', width: 12, label: 'Loại giải thưởng', value: values?.type, options: typeOptions || REWARD_CONSTANTS.typeOptions, disabled, required: true },
  { key: 'code', width: 6, label: mobileMode ? 'Số quyết định':'Số quyết định khen thưởng', value: values?.code, disabled, required: true},
  { key: 'decisionDay', width: 6 , label:mobileMode ? 'Ngày quyết định':'Ngày quyết định khen thưởng',type: 'date',value: values?.decisionDay, disabled, required: true },
  { key: 'unit', width: 12, height: 1, label: 'Cơ quan, tổ chức ra quyết định khen thưởng', value: values?.unit, disabled, required: true },
  {
    key: 'previewFile',
    width: 12,
    value: values?.previewFile,
    type: 'file',
    acceptedFiles: ["image/*", "application/pdf"],
    label: (
      <>
        <Typography variant='body1' style={{ fontSize: 17 }}>
          <b>Upload file minh chứng tại đây</b>
        </Typography>
        <Typography variant='body2' color='secondary'>
          (Hình ảnh/ file PDF bằng khen/ giấy khen)
        </Typography>
      </>
    ),
    disabled, required: true
  },
]);

export default function GiaiThuongForm(props) {
  const isMobileMode = useScreenSize();
  const textForm = rewardForm(props.values, isMobileMode, props.disabled)

  return (
    <KPTextform {...props} textForm={textForm} />
  );
}