/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';

import Loading from '../../../../components/CustomComponents/Loading';
import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { qcvEnums } from '../../../../constants/qcv.enums';
import { formActions } from '../../../../actions';
import { ACHIEVEMENT_TYPE, KEYS, MONOGRAPHS_CONSTANTS, REPORTS_CONSTANTS, SECONDARY_COLOR } from '../../../../constants';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../../../../utils';

export default function HoSoNote() {
	const dispatch = useDispatch();

	const role = useSelector(state => state.userReducer.user?.role);
	const userId = useSelector(state => state.userReducer.user?.id);
	const loading = useSelector(state => state.formReducer.loading);
	// const applicationList = useSelector(state => state.formReducer.applicationList);
	const focusForm = useSelector(state => state.formReducer.focusForm);

	const [values, setValues] = useState({
		disabledScore: 0,
		ethnicMinorityScore: 0,
	});
	
	const arrayPath = window.location.href.split('/');
	const urlId = parseInt(arrayPath.slice(arrayPath.length -1));
	// const applicationDetail = applicationList.find((e) => e.id === urlId);
	
	// Lưu giá trị
  const applicationDetail = useMemo(() => {
    const detail = { ...focusForm };
    if (focusForm) {
      // TODO: Quy về 1 mối
      Object.keys(ACHIEVEMENT_TYPE).forEach((key) => {
        const _key = 'form' + capitalizeFirstLetter(ACHIEVEMENT_TYPE[key]);
        detail[ACHIEVEMENT_TYPE[key]] = detail[_key]
          .map((e) => ({
            ...e[ACHIEVEMENT_TYPE[key]],
            note: e.note,
            score: e.score,
            accepted: e.accepted,

            [`${_key}Id`]: e.id,
            thanhTichAccepted: e.accepted,
          }))
          .sort((a, b) => a.id - b.id);
      })

      detail.formResearchProject = detail.formResearchProject.map((e) => ({
        ...e,
        // TODO: Sửa backend cái json này
        start_at: dayjs(e.start_at).format('DD/MM/YYYY'),
        finish_at: dayjs(e.finish_at).format('DD/MM/YYYY'),
      }));

      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT].filter(
        (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT].filter(
        (e) => REPORTS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.MONOGRAPH].filter(
        (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.MONOGRAPH].filter(
        (e) => MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL.find((option) => e.type === option.value)
      );
      detail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT] = detail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT].map((e) => ({
        ...e,
        start_at: dayjs(e.start_at).format('DD/MM/YYYY'),
        finish_at: dayjs(e.finish_at).format('DD/MM/YYYY'),
      }));
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.DOMESTIC);
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.INTERNATIONAL);
  
      detail.summary = JSON.parse(detail.summary || '[]');
      detail.process = JSON.parse(detail.process || '[]');
      detail.socialActivity = JSON.parse(detail.socialActivity || '[]');
      detail.personalAchievement = JSON.parse(detail.personalAchievement || '[]');
      detail.communicationActivity = JSON.parse(detail.communicationActivity || '[]');

      const fileList = JSON.parse(detail.previewFile || '[]');
      detail.sumaryFile = fileList.find((file) => file.includes(('sumaryFile')));
      detail.previewFile = fileList.filter((file) => file.includes(('previewFile')));
    } else {
      return null;
    }
    return detail;
  }, [focusForm]);

	useEffect(() => {
    console.log(applicationDetail);
		if (applicationDetail) setValues({
			...values,
			note: applicationDetail?.note,
			scoreA: Math.round(applicationDetail?.scoreA * 100) / 100,
			scoreB: Math.round(applicationDetail?.scoreB * 100) / 100,
			scoreC: Math.round(applicationDetail?.scoreC * 100) / 100,
			scoreD: Math.round(applicationDetail?.scoreD * 100) / 100,
      [KEYS.INTERNATIONAL]: [
        ...applicationDetail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
        ...applicationDetail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL],
        ...applicationDetail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL]
      ].reduce((total, item) => total + item.score, 0),
      [KEYS.DOMESTIC]: [
        ...applicationDetail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
        ...applicationDetail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC],
        ...applicationDetail[ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC],
        ...applicationDetail[ACHIEVEMENT_TYPE.RESEARCH_PROJECT]
      ].reduce((total, item) => total + item.score, 0),
		});
	}, [applicationDetail]);

	const saveNote = () => dispatch(formActions.updateBCD({
		...values,
		id: urlId, 
		status: 'need_update',
	}));

	const handleSubmit = () => dispatch(formActions.updateBCD({
		...values,
		id: urlId, 
		status: 'approved',
	}));

	var textForm = [
		{ key: 'cc_1', width: 12, render: <Typography variant="h6"><b>A. THÀNH TÍCH KHOA HỌC </b>(Tối đa 100 điểm)</Typography> },
		{ key: 'scoreD', width: 12, label: '1. Điểm đánh giá định tính (Công trình tiêu biểu) (Tối đa 50 điểm)', type: 'float', value: values?.scoreD },
		{ key: 'scoreA', width: 12, label: '2. Điểm thành tích định lượng (Tối đa 50 điểm, nếu tổng số các công bố quốc tế và trong nước nhiều hơn 50 điểm)', type: 'float', disabled: true, value: values?.scoreA },
		{ key: KEYS.INTERNATIONAL, width: 6, labelRender: <Typography variant="body1">Công trình công bố quốc tế:</Typography>, type: 'float', disabled: true, value: values[KEYS.INTERNATIONAL] || 0 },
		{ key: KEYS.DOMESTIC, width: 6, labelRender: <Typography variant="body1">Công trình công bố trong nước:</Typography>, type: 'float', disabled: true, value: values[KEYS.DOMESTIC] || 0 },
		{ key: 'cc_4', width: 12, render: <Typography variant="h6"><b>B. THÀNH TÍCH KHEN THƯỞNG </b>(Tối đa 15 điểm)</Typography> },
		{
      key: 'scoreB',
      width: 12,
      label: '1. Thành tích khen thưởng (Tối đa 10 điểm)',
      type: 'float',
      value: values?.scoreB,
      note: (
        <>
          <Box><b>1: </b>Được nhận hình thức khen thưởng cấp <b>Nhà nước</b> (Tối đa <b>10 điểm</b>)</Box>
          <Box><b>2: </b>Được nhận Bằng khen <b>Bộ, ban, ngành, tỉnh</b> (Tối đa <b>05 điểm</b>)</Box>
        </>
      )
    },
		{
      key: 'scoreC',
      width: 12,
      label: 'Hoạt động cộng đồng (Tối đa 5 điểm)',
      type: 'float',
      value: values?.scoreC,
      note: (
        <>
          <Box><b>1: </b>Tham gia các hoạt động chia sẻ tri thức, tập huấn, đào tạo... trong ngành/liên ngành xét trao Giải thưởng (Tối đa <b>3 điểm</b>)</Box>
          <Box><b>2: </b>Tham gia các hoạt động tình nguyện vì cộng đồng nói chung (Tối đa <b>2 điểm</b>)</Box>
        </>
      )
    },
		{ key: 'note', width: 12, height: 6, label: 'Ghi chú của ban thư ký', value: values?.note },
		{ key: 'button-2', width: 6, label: 'Không duyệt hồ sơ', type: 'button', onClick: saveNote },
		{ key: 'submit', width: 6, label: 'Duyệt hồ sơ', type: 'submit' },
	];

	if (applicationDetail && !role || role === 'candidate') {
		// Hồ sơ phải thuộc về người dùng đã đăng nhập và trong tình trạng cần phải sửa
		if (applicationDetail?.status !== 'need_update' || applicationDetail?.profile?.id !== userId) return null;
		else textForm = [{ key: 'note', width: 12, height: 10, value: values?.note, disabled: true }]
	}

  const score = useMemo(() => {
    if (applicationDetail) {
      var scoreA = 0;
      qcvEnums.THANH_TICH_LIST.forEach((key) => {
        applicationDetail[key]?.forEach((e) => scoreA += e?.score || 0);
      })
  
      var _score = scoreA + (values?.scoreB || 0) + (values?.scoreC || 0) + (values?.scoreD || 0);
  
      return Math.round(_score * 100) / 100;
    }

    return null;
  }, [values, applicationDetail]);

	return (
		<Grid container justifyContent='center' style={{ margin: 16, width: 'calc(100% - 32px)' }}>
			<Grid item xs={12} lg={4}>
				<Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
					<Typography variant='h1' style={{ color: SECONDARY_COLOR, fontFamily: "WorkSans-ExtraBold" }}>
						<b>{score}</b>
					</Typography>
					<Typography variant='h5'>Điểm của ứng viên</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} lg={8}>
				<KPTextform
					textForm={textForm}
					handleSubmit={handleSubmit}
					values={values}
					setValues={setValues}
				/>
			</Grid>
			<Loading open={loading} />
		</Grid>
	)
}