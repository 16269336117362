import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import SangCheInsert from '../SangCheInsert';

import { solutionActions } from '../../../../actions';

export default function SangCheButton(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);
  const personalSolutionList = useSelector(state => state.solutionReducer.personalSolutionList);
  const totalSolutions = useSelector(state => state.solutionReducer.totalSolutions);

  const [inserting, setInserting] = useState(false);

  const handleClick = () => setInserting(true);
  const handleClose = () => setInserting(false);
  const handleReload = () => {
    if (props.filterType === 'person') {
      dispatch(solutionActions.getSolutionsByProfileId(profileId));
    } else {
      dispatch(solutionActions.getAllSolutions());
    }
  }

  useEffect(() => {
    if (!isNaN(profileId) && personalSolutionList.length === 0) handleReload();
  }, [profileId]);

  useEffect(() => {
    if (props.filterType === 'all' && !isNaN(totalSolutions) && totalSolutions.length === 0) handleReload();
  }, []);

  return (
    <>
      <Grid item xs={12} md={3}>
        <div style={{ float: 'right' }}>
          <Button variant='outlined' startIcon={<Replay />} style={{ backgroundColor: 'white' }} onClick={handleReload}>Tải lại</Button>
          {props.filterType === 'person' && (
            <Button variant='contained' color='secondary' startIcon={<Add />} style={{ marginLeft: 8 }} onClick={handleClick}>Thêm</Button>
          )}
        </div>
      </Grid>


      <SangCheInsert open={inserting} onClose={handleClose} />
    </>
  );
}
