import { createTheme } from '@material-ui/core/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from './constants/enums';

const theme = createTheme({
  palette: {
    primary: { main: PRIMARY_COLOR },
    secondary: { main: SECONDARY_COLOR }
  },
  typography: { fontFamily: 'SFProDisplay' },
  overrides: {
    MuiCard: {
      root: {
        border: `1px solid rgba(0,0,0,0.125)`,
        boxShadow: 'none'
      }
    }
  }
});

export default theme;
