/* eslint-disable max-len */
import dayjs from "dayjs";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import Docxtemplater from "docxtemplater";

import { saveAs } from "file-saver";

import sample from "../assets/docs/QCV2024_Ho_so.docx";
import HTMLModule from "../utils/docxtemplater-html-module";

import {
  FORM_CONSTANTS,
  REWARD_CONSTANTS,
  REPORTS_CONSTANTS,
  RESEARCH_CONSTANTS,
  SOLUTIONS_CONSTANTS,
  MONOGRAPHS_CONSTANTS,
  HR_DEVELOPMENT_CONSTANTS,
  RESEARCHPROJECTS_CONSTANTS,
} from "../constants";
import { KEYS } from "../constants/enums";

const loadFile = (url, callback) => PizZipUtils.getBinaryContent(url, callback);

const parseJson = (value) => {
  try {
    return JSON.parse(value || "[]");
  } catch (e) {
    return [];
  }
}

const cleanArray = (arr, keys) => arr.map((item) => {
  const cleanedItem = {};
  keys.forEach((key) => cleanedItem[key] = item?.[key] || "");
  return cleanedItem;
});

const checkField = (field, formField) => field === formField ? "☒" : "☐";
const previewCell = (domain, value) => `<a href="${domain || ''}${value || ''}">Minh chứng</a>`;

const exportProfile = (form) => {
  const domain = process.env.REACT_APP_DOMAIN;

  loadFile(sample, (error, content) => {
    if (error) throw error;

    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      linebreaks: true,
      paragraphLoop: true,
      modules: [new HTMLModule({})],
    });

    // Đoạn này phải làm cẩn thận, để né những trường hợp undefined
    const process = cleanArray(parseJson(form?.process), ['works', 'startAt', 'finishAt', 'department']);
    const communicationActivity = cleanArray(
      parseJson(form?.communicationActivity).map((item) => ({
        ...item,
        achievementFile: previewCell(domain, item?.achievementFile),
        typeOfAchievement: FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find(l => l.value === item.typeOfAchievement)?.title,
      })),
      ['achievementContent', 'typeOfAchievement', 'achievementFile']
    );
    const socialActivity = cleanArray(
      parseJson(form?.socialActivity).map((item) => ({
        ...item,
        communityAchievementFile: previewCell(domain, item?.communityAchievementFile),
      })),
      ['communityAchievement', 'communityAchievementFile']
    );

    const formSolution = cleanArray(
      form.formSolution.map((s) => ({
        ...s,
        ...s.solution,
        link: previewCell(domain, s.solution.link),
        type: SOLUTIONS_CONSTANTS.TYPE_OPTIONS.find((t) => t.value === s.type)?.title
      })),
      Object.keys(form.formSolution?.[0]?.solution || {})
    );

    console.log(form.formScientificResearch);

    const formScientificResearch = cleanArray(
      form.formScientificResearch.map((item) => ({
        ...item,
        ...item.scientificResearch,
        type: [
          ...RESEARCH_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
          ...RESEARCH_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
        ].find((t) => t.value === item.scientificResearch?.type)?.title,
        link: item.scientificResearch.link
          ? previewCell(null, item.scientificResearch.link)
          : previewCell(domain, item.scientificResearch.preview),
        role: RESEARCH_CONSTANTS.ROLE_OPTIONS.find((r) => r.value === item.scientificResearch.role)?.title,
      })),
      Object.keys(form.formScientificResearch?.[0]?.scientificResearch || {})
    );

    const formScientificReport = cleanArray(
      form.formScientificReport.map((item) => ({
        ...item,
        ...item.scientificReport,
        type: REPORTS_CONSTANTS.TYPE_OPTIONS.ALL.find((t) => t.value === item.scientificReport.type)?.title,
        link: item.scientificReport.link
          ? previewCell(null, item.scientificReport.link)
          : previewCell(domain, item.scientificReport.preview),
        role: REPORTS_CONSTANTS.ROLE_OPTIONS.find((r) => r.value === item.scientificReport.role)?.title,
      })),
      Object.keys(form.formScientificReport?.[0]?.scientificReport || {})
    );

    const formResearchProject = cleanArray(
      form.formResearchProject.map((item) => ({
        ...item,
        ...item.researchProject,
        link: previewCell(domain, item.researchProject.link),
        type: RESEARCHPROJECTS_CONSTANTS.TYPO_OPTIONS.find((r) => r.value === item.researchProject.type)?.title,
        level: RESEARCHPROJECTS_CONSTANTS.LEVEL_OPTIONS.find((r) => r.value === item.researchProject.level)?.title,
      })),
      Object.keys(form.formResearchProject?.[0]?.researchProject || {})
    );

    const formReward = cleanArray(
      form.formReward.map((item) => ({
        ...item,
        ...item.reward,
        link: previewCell(domain, item.reward.link),
        type: REWARD_CONSTANTS.typeOptions.find((r) => r.value === item.reward.type)?.title,
      })),
      Object.keys(form.formReward?.[0]?.reward || {})
    );

    const formMonograph = cleanArray(
      form.formMonograph.map((item) => ({
        ...item,
        ...item.monograph,
        link: previewCell(domain, item.monograph.link),
        type: [
          ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
          ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
        ].find((r) => r.value === item.monograph.type)?.title,
        role: MONOGRAPHS_CONSTANTS.ROLE_OPTIONS.find((r) => r.value === item.monograph.role)?.title,
      })),
      Object.keys(form.formMonograph?.[0]?.monograph || {})
    );

    const formHrDevelopment = cleanArray(
      form.formHrDevelopment.map((item) => ({
        ...item,
        ...item.hrDevelopment,
        link: previewCell(domain, item.hrDevelopment.link),
        academicLevel: HR_DEVELOPMENT_CONSTANTS.levelOptions.find((r) => r.value === item.hrDevelopment.academicLevel)?.title,
      })),
      Object.keys(form.formHrDevelopment?.[0]?.hrDevelopment || {})
    );

    const formTechCompany = cleanArray(
      form.formTechCompany.map((item) => ({
        ...item,
        ...item.techCompany,
        value: `${item.techCompany.value?.toString().replace(/\D/g,'')?.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.')} VNĐ`,
        link: previewCell(domain, item.techCompany.link),
      })),
      Object.keys(form.formTechCompany?.[0]?.techCompany || {})
    );

    const formHrDevelopmentDaotao = formHrDevelopment.filter(item => item.type === KEYS.DAO_TAO);
    const formHrDevelopmentBoiduong = formHrDevelopment.filter(item => item.type === KEYS.BOI_DUONG);
    const formScientificResearchQuocte = formScientificResearch.filter(item => item.filterType === KEYS.INTERNATIONAL);
    const formScientificResearchQuocgia = formScientificResearch.filter(item => item.filterType === KEYS.DOMESTIC);

    const data = {
      ...form,
      ...form.profile,

      birth: dayjs(form.profile.birth).format("DD/MM/YYYY"),
      gender: FORM_CONSTANTS.GENDER_OPTIONS.find(item => item.value === form.profile.gender)?.title || "",
      academicLevel: FORM_CONSTANTS.ACADEMIC_LEVEL_OPTIONS.find(item => item.value === form.academicLevel)?.title || "",

      process,
      socialActivity,
      communicationActivity,
      processTitle: process.length > 0,
      socialActivityTitle: socialActivity.length > 0,
      communicationActivityTitle: communicationActivity.length > 0,

      formReward,
      formSolution,
      formMonograph,
      formTechCompany,
      formResearchProject,
      formScientificReport,
      formHrDevelopmentDaotao,
      formHrDevelopmentBoiduong,
      formScientificResearchQuocte,
      formScientificResearchQuocgia,
      formRewardTitle: formReward.length > 0,
      formSolutionTitle: formSolution.length > 0,
      formMonographTitle: formMonograph.length > 0,
      formTechCompanyTitle: formTechCompany.length > 0,
      formResearchProjectTitle: formResearchProject.length > 0,
      formScientificReportTitle: formScientificReport.length > 0,
      formHrDevelopmentDaotaoTitle: formHrDevelopmentDaotao.length > 0,
      formHrDevelopmentBoiduongTitle: formHrDevelopmentBoiduong.length > 0,
      formScientificResearchQuocteTitle: formScientificResearchQuocte.length > 0,
      formScientificResearchQuocgiaTitle: formScientificResearchQuocgia.length > 0,

      isCntt: checkField("cntt", form.field),
      isCnyd: checkField("cnyd", form.field),
      isCnsh: checkField("cnsh", form.field),
      isCnmt: checkField("cnmt", form.field),
      isCnvlm: checkField("cnvlm", form.field),

      summary: form.summary.replaceAll('<figure class="table">', '').replaceAll('</figure>', '')
    };

    console.log(data);
    doc.render(data);

    const blob = doc.getZip().generate({
      type: "blob",
      mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    saveAs(blob, "File Chi tiết hồ sơ.docx");
  });
}


export default exportProfile;