import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';

import { HEADERS,RESEARCH_CONSTANTS } from '../../../../constants';
import { userActions } from '../../../../actions';
import KPTextform from '../../../../components/KPTComponents/KPTextform';

function BaiBaoQuocTeKhacForm(props) {
  const dispatch = useDispatch();
  const { values, setValues, disabled } = props;
  const [loadingDoi, setLoadingDoi] = useState(false);
  const {typeOptionsQTK, roleOptions} = RESEARCH_CONSTANTS;

  const getDoi = async () => {
    var doi = values?.link;

    const type1 = 'https://doi.org/';
    const type2 = 'doi.org/';
    if (doi.substr(0, type1.length) === type1) {
      doi = values?.link;
    } else if (doi.substr(0, type2.length) === type2) {
      doi = `https://${doi}`;
    } else {
      doi = `https://doi.org/${doi}`;
    }

    setLoadingDoi(true);
    let response = null, error = null;

    try {
      response = await axios({ url: doi, method: 'GET', headers: HEADERS.DOI_HEADER });
    } catch (e) {
      error = e.request;
    }

    if (!error && response?.status === 200) {
      let responseJournalByISSN = null, errorJournalByISSN = null;

      try {
        responseJournalByISSN = await axios({
          url: 'https://nckh.phenikaa-uni.edu.vn/api/v1/award/journal/filter?page=1&pageSize=10',
          method: 'PUT',
          data: {
            year: new Date().getFullYear(),
            issn: response?.data?.ISSN?.[0]?.split('-')?.join(''),
          },
          headers: HEADERS.DEFAULT_HEADER
        });
      } catch (e) {
        errorJournalByISSN = e.request;
      }

      if (!errorJournalByISSN && responseJournalByISSN?.data.data && responseJournalByISSN?.status === 200) {
        setValues({
          ...values,
          journalTitle: responseJournalByISSN?.data?.data?.[0]?.type === 'journal' ? responseJournalByISSN?.data?.data?.[0]?.title : null,
          link: response?.data?.URL,
          title: response?.data?.title,
          publisher: response?.data?.publisher,
          publishYear: String(response?.data?.issued['date-parts']?.[0]?.[0]),
          page: response?.data?.page,
          issn: response?.data?.ISSN?.[0],
          numberAuthor: response?.data?.author?.length,
          author: response?.data?.author?.map((e) => (`${e.given} ${e.family}`)).join(', ')
        });
        setLoadingDoi(false);
        dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
      } else {
        let responseJournalByTitle = null, errorJournalByTitle = null;

        try {
          responseJournalByTitle = await axios({
            url: 'https://nckh.phenikaa-uni.edu.vn/api/v1/award/journal/filter?page=1&pageSize=10',
            method: 'PUT',
            data: {
              year: new Date().getFullYear(),
              title: response?.data?.['container-title'].replace(/[&\\#,+()$~%.'":*?<>{}]/g, ''),
            },
            headers: HEADERS.DEFAULT_HEADER
          });
        } catch (e) {
          errorJournalByTitle = e.request;
        }

        if (!errorJournalByTitle && responseJournalByTitle?.status === 200) {
          setValues({
            ...values,
            journalTitle: responseJournalByTitle?.data?.data?.[0]?.type === 'journal' ? responseJournalByTitle?.data?.data?.[0]?.title : null,
            link: response?.data?.URL,
            title: response?.data?.title,
            publisher: response?.data?.publisher,
            publishYear: String(response?.data?.issued['date-parts']?.[0]?.[0]),
            page: response?.data?.page,
            issn: response?.data?.ISSN?.[0],
            numberAuthor: response?.data?.author?.length,
            author: response?.data?.author?.map((e) => (`${e.given} ${e.family}`)).join(', ')
          });
          setLoadingDoi(false);
          dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
        } else {
          setLoadingDoi(false);
          dispatch(userActions.notification({ message: 'Link DOI bị lỗi', type: 'error' }));
        }
      }
    } else {
      setLoadingDoi(false);
      dispatch(userActions.notification({ message: 'Link DOI bị lỗi', type: 'error' }));
    }
  }

  const mobileMode = window.innerWidth <= 768;

  const textForm = [
    { key: 'link', width: 9, label: 'Link DOI', value: values?.link, disabled },
    {
      key: 'doi',
      width: 3,
      label: loadingDoi ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Nhập từ DOI',
      type: 'button',
      color: 'secondary',
      variant: 'contained',
      disabled,
      onClick: getDoi
    },
    { key: 'title', width: 12, height: 1, label: 'Tên công bố', value: values?.title, disabled, required: true },
    { key: 'journalTitle', width: 12, height: 1, label: 'Tên tạp chí quốc tế', value: values?.journalTitle, disabled, required: true },
    { key: 'publisher', width: 12, height: 1, label: 'Nhà xuất bản', value: values?.publisher, disabled, required: true },
    { key: 'numberMain', width: 12, label: 'Số lượng tác giả chính (Tác giả đầu, tác giả liên hệ)', value: values?.numberMain, disabled, type: 'int', required: true },
    { key: 'publishYear', width: mobileMode ? 7 : 3, label: 'Năm xuất bản', value: values?.publishYear, disabled, required: true },
    { key: 'page', width: mobileMode ? 5 : 3, label: 'Trang', value: values?.page, disabled },
    { key: 'issn', width: mobileMode ? 5 : 3, label: 'ISSN/ISBN', value: values?.issn, disabled },
    { key: 'numberAuthor', width: mobileMode ? 7 : 3, label: 'Số tác giả', type: 'int', value: values?.numberAuthor, disabled, required: true },
    { key: 'author', width: 12, height: 1, label: 'Danh sách tác giả', value: values?.author, disabled, required: true },
    { key: 'role', width: mobileMode ? 7 : 6, label: 'Vai trò', value: values?.role, options: roleOptions, disabled, required: true },
    { key: 'type', width: mobileMode ? 5 : 6, label: 'Phân loại', value: values?.type, options: typeOptionsQTK, disabled: true, required: true },
    { key: 'description', width: 12, height: 1, label: 'Ghi chú', value: values?.description, disabled },
    {
      key: 'previewFile',
      width: 12,
      label: (
        <>
          <Typography variant='body1' style={{ fontSize: 17 }}>
            <b>Upload file minh chứng tại đây</b>
          </Typography>
          <Typography variant='body2' color='secondary'>
            (Trong trường hợp không có link DOI)
          </Typography>
        </>
      ),
      value: values?.previewFile,
      type: 'file',
      disabled,
    },
   ];

  return (
    <KPTextform {...props} textForm={textForm} />
  );
}

export default BaiBaoQuocTeKhacForm;
