import React from 'react';
import Interweave from 'interweave';
import { useSelector } from 'react-redux';

export default function QuyChe() {
  const seasonList = useSelector((state) => state.seasonReducer.seasonList);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);

  const openSeason = seasonList.find((s) => s.id === openSeasonId);

  return (
    <div className='formal-text' style={{ padding: 8 }}>
      <Interweave content={openSeason?.sheRegulation} />
    </div>
  );
}
