import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, Typography } from '@material-ui/core';

import tnt from '../../assets/image/home/logo-tai-nang-tre.png';
import intro from '../../assets/image/tnv/intro.mp4';
import styles from './index.module.css';
import { ROUTER } from '../../constants'; 
import { useScreenSize } from '../../hooks/useScreenSize';

const SLOGAN = 'Phát huy tinh thần dân tộc - Thực hiện trách nhiệm quốc gia - Chung tay kết nối toàn cầu';

function Home() {
  const navigate = useNavigate();
  const isMobileMode = useScreenSize();
  const refreshToken = localStorage.getItem('refresh_token');

  const [textLength, setTextLength] = useState(-10);

  const anim = setTimeout(() => {
    if (textLength < SLOGAN.length) setTextLength(textLength + 1);
    else clearTimeout(anim);
  }, 35);

  useEffect(() => {
    if (refreshToken) navigate(ROUTER.GIOI_THIEU);
  }, [refreshToken]);

  if (refreshToken) return null;

  return (
    <Grid
      container
      spacing={4}
      alignContent="center"
      justifyContent="center"
      style={{ minHeight: 'calc(100vh - 32px)' }}
    >
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <img alt='logo' src={tnt} style={{ height: 180 }} />
    </Grid>
    <Grid item xs={12}>
      <Box color='#fff'>
        <Typography variant={isMobileMode ? 'h4' : 'h3'} className={styles.title}><b>TÀI NĂNG TRẺ VIỆT NAM</b></Typography>
        <Typography variant={isMobileMode ? 'h5' : 'h4'} className={styles.textChay}>
          {textLength > 0 ? SLOGAN.substr(0, textLength) : <>&nbsp;</>}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={10} md={2}>
      <Button className={styles.button} href={ROUTER.DANG_KY} fullWidth variant='contained' color='secondary'>Đăng ký</Button>
    </Grid>
    <Grid item xs={10} md={2}>
      <Button className={styles.button} href={ROUTER.DANG_NHAP} fullWidth variant='contained' color='secondary'>Đăng nhập</Button>
    </Grid>
    <video className={styles.background} loop muted autoPlay src={intro} />
    <Box className={styles.mask} />
    </Grid>
  );
}

export default Home;
