import { combineReducers } from 'redux';

import { formReducer } from './form.reducer';
import { voteReducer } from './vote.reducer';
import { userReducer } from './user.reducer';
import { seasonReducer } from './season.reducer';
import { profileReducer } from './profile.reducer';

import { rewardReducer } from './qcv.reward.reducer';
import { reportsReducer } from './qcv.reports.reducer.';
import { researchReducer } from './qcv.research.reducer';
import { solutionReducer } from './qcv.solution.reducer';
import { monographReducer } from './qcv.monograph.reducer';
import { techCompanyReducer } from './qcv.techCompany.reducer';
import { hrDevelopmentReducer } from './qcv.hrDevelopment.reducer';
import { researchProjectReducer } from './qcv.researchProject.reducer';
import { scoreInformationReducer } from './qcv.scoreInformation.reducer';

const rootReducer = combineReducers({
  formReducer,
  voteReducer,
  userReducer,
  seasonReducer,
  profileReducer,

  rewardReducer,
  reportsReducer,
  solutionReducer,
  researchReducer,
  monographReducer,
  techCompanyReducer,
  hrDevelopmentReducer,
  researchProjectReducer,
  scoreInformationReducer,
});

export default (state, action) => rootReducer(action.type === 'LOGOUT' ? undefined : state, action);