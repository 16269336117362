import dayjs from "dayjs";
import React, { Fragment } from "react";

import {
  Card,
  Grid,
  Button,
  Divider,
  Typography,
  CardContent,
} from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import KPTextform from "../../../components/KPTComponents/KPTextform";

import { dateFormatter } from '../../../utils';
import { seasonActions } from "../../../actions";
import { useScreenSize } from "../../../hooks/useScreenSize";

export default function QuanLyNamToChuc() {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

  const [values, setValues] = React.useState(null);
  const [isChange, setIsChange] = React.useState(false);

  const seasonList = useSelector((state) => state.seasonReducer.seasonList);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);
  const loading = useSelector((state) => state.seasonReducer.loading);
  const seasonYear = seasonList
    ? seasonList.map((e) => ({ key: e?.id, title: e?.year, value: e?.year }))
    : null;

  const handleReload = () => {
    dispatch(seasonActions.getSeasonList());
  };

  const handelUpdate = (type) => {
    if (type === 'year') {
      let payload = seasonList.find((e) => e.id === openSeasonId);
      dispatch(seasonActions.updateSeason({ ...payload, status: "close" }));
      payload = seasonList.find((e) => e.year === values?.year);
      dispatch(seasonActions.updateSeason({ ...payload, status: "open" }));
    } else if (type === 'date') {
      let payload = {
        ...values,
        openDate: dateFormatter(values?.openDate),
        closeDate: dateFormatter(values?.closeDate),
        regOpenAt: dateFormatter(values?.regOpenAt),
        regCloseAt: dateFormatter(values?.regCloseAt),
      }
      dispatch(seasonActions.updateSeason(payload));
      setIsChange(false);
    }
  };

  const changeValues = (val) => {
    setValues(val);
    setIsChange(true);
  }

  React.useEffect(() => {
    if (!seasonList) handleReload();
  }, []);

  React.useEffect(() => {
    if (openSeasonId) {
      setValues({ year: seasonList.find((e) => e.id === openSeasonId).year });
      const time = seasonList.find((e) => e.id === openSeasonId);
      // eslint-disable-next-line no-unused-vars
      setValues({
        ...time,
        openDate: time?.openDate ? dayjs(time?.openDate).format('DD-MM-YYYY').replaceAll('-', '/') : null,
        closeDate: time?.closeDate ? dayjs(time?.closeDate).format('DD-MM-YYYY').replaceAll('-', '/') : null,
        regOpenAt: time?.regOpenAt ? dayjs(time?.regOpenAt).format('DD-MM-YYYY').replaceAll('-', '/') : null,
        regCloseAt: time?.regCloseAt ? dayjs(time?.regCloseAt).format('DD-MM-YYYY').replaceAll('-', '/') : null
      });
    }
  }, [openSeasonId]);

  const works = [
    {
      key: "dangky",
      label: "Đăng ký giải thưởng",
      time: [
        {
          key: "openDate",
          label: isMobileMode ? "Bắt đầu" : "Thời gian bắt đầu",
          type: "date",
          value: values?.openDate,
          width: 6,
        },
        {
          key: "closeDate",
          label: isMobileMode ? "Kết thúc" : "Thời gian kết thúc",
          type: "date",
          value: values?.closeDate,
          width: 6,
        },
      ],
    },
    {
      key: "chamgiai",
      label: "Chấm giải thưởng",
      time: [
        {
          key: "cgStartAt",
          label: isMobileMode ? "Bắt đầu" : "Thời gian bắt đầu",
          value: values?.cgStartAt,
          type: "date",
          width: 6,
        },
        {
          key: "cgFinish",
          label: isMobileMode ? "Kết thúc" : "Thời gian kết thúc",
          value: values?.cgFinishAt,
          type: "date",
          width: 6,
        },
      ],
    },
    {
      key: "mocong",
      label: "Mở cổng bình chọn",
      time: [
        {
          key: "regOpenAt",
          label: isMobileMode ? "Bắt đầu" : "Thời gian bắt đầu",
          value: values?.regOpenAt,
          type: "date",
          width: 6,
        },
        {
          key: "regCloseAt",
          label: isMobileMode ? "Kết thúc" : "Thời gian kết thúc",
          value: values?.regCloseAt,
          type: "date",
          width: 6,
        },
      ],
    },
  ];

  const yearForm = [{
    key: "year",
    width: 12,
    label: "Năm tổ chức giải thưởng",
    options: seasonYear,
    value: values?.year
  }];

  return (
    <Card style={{ marginTop: 108, marginBottom: 108 }}>
      <CardContent>
        <Grid container spacing={2} justifyContent='center' alignItems='flex-end'>
          <Grid item xs={6}>
            <KPTextform textForm={yearForm} values={values} setValues={changeValues} />
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" onClick={handleReload} style={{ width: '100%' }}><Replay /></Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              color="secondary"
              variant="contained"
              disabled={openSeasonId === seasonList.find((e) => e.year === values?.year)?.id}
              onClick={() => handelUpdate('year')}
              style={{ width: '100%' }}
            >
              Đặt làm năm tổ chức
            </Button>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          {works.map((elm) => (
            <Fragment key={elm.key}>
              <Grid item xs={3}>
                <Typography><b>{elm.label}</b></Typography>
              </Grid>
              <Grid item xs={9} key={elm.key}>
                <KPTextform textForm={elm.time} values={values} setValues={changeValues} />
              </Grid>
            </Fragment>
          ))}
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <Button
              disabled={!isChange}
              color='primary'
              loading={loading}
              variant="contained"
              style={{ width: '100%' }}
              onClick={() => handelUpdate('date')}
            >
              Lưu thay đổi
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
