import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import DuAnInsert from '../DuAnInsert';

import { researchProjectActions } from '../../../../actions';

export default function DuAnButton(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);
  const researchProjectList = useSelector(state => state.researchProjectReducer.researchProjectList);
  const totalResearchProjectList = useSelector(state => state.researchProjectReducer.totalResearchProjectList);

  const [inserting, setInserting] = useState(false);

  const handleClick = () => setInserting(true);
  const handleClose = () => setInserting(false);
  const handleReload = () => {
    if (props.filterType === 'person') {
      dispatch(researchProjectActions.getResearchProjectByProfileId(profileId));
    } else {
      dispatch(researchProjectActions.getAllResearchProject());
    }
  }

  useEffect(() => {
    if (props.filterType === 'person' && !isNaN(profileId) && researchProjectList.length === 0) handleReload();
  }, [profileId]);

  useEffect(() => {
    if (props.filterType === 'all' && totalResearchProjectList.length === 0) handleReload();
  }, []);

  return (
    <>
      <Grid item xs={12} md={3}>
        <div style={{ float: 'right' }}>
          <Button variant='outlined' startIcon={<Replay />} style={{ backgroundColor: 'white' }} onClick={handleReload}>Tải lại</Button>
          {props.filterType === 'person' && (
            <Button variant='contained' color='secondary' startIcon={<Add />} style={{ marginLeft: 8 }} onClick={handleClick}>Thêm</Button>
          )}
        </div>
      </Grid>


      <DuAnInsert open={inserting} onClose={handleClose} />
    </>
  );
}
