/* eslint-disable */
import React from 'react';
import { Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { qcvEnums } from '../../constants/qcv.enums';
import { ACHIEVEMENT_TYPE, KEYS } from '../../constants/enums';
import { monographActions, reportsActions, researchActions, researchProjectActions, rewardActions, solutionActions } from '../../actions';
import { FORM_CONSTANTS, MONOGRAPHS_CONSTANTS, REPORTS_CONSTANTS, RESEARCH_CONSTANTS, RESEARCHPROJECTS_CONSTANTS, REWARD_CONSTANTS } from '../../constants';

export const columnRender = (e, setDialog, setPreviewFile, setDuyetThanhTich=null) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    switch (e.type) {
      case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
      case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
        return monographActions.deleteMonograph(id);
      case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT:
      case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
      case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
        return reportsActions.deleteReport(id);
      case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
      case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
        return researchActions.deleteResearch(id);
      case ACHIEVEMENT_TYPE.RESEARCH_PROJECT:
        return researchProjectActions.deleteResearchProject(id);
      case ACHIEVEMENT_TYPE.REWARD:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB:
      case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT:
        return rewardActions.deleteReward(id);
      case ACHIEVEMENT_TYPE.SOLUTION:
        return solutionActions.deleteSolution(id);
      default: break;
    }
  }

  const handleAccepted = (row) => {
    dispatch(researchActions.updateResearchAccepted(
      [{
        id: row.formScientificResearchId,
        accepted: !row.thanhTichAccepted,
        score: values?.score || 0,
        note: values?.note || '',
      }]
    ));
    setDuyetThanhTich(null);
    setValues(null);
  }

  const actionButton = (type, row) => {
    switch (type) {
      case 'link':
        return (
          <>
            {(row?.link?.includes('/storage/awardRegister/profile') ||
              row?.preview?.includes('/storage/awardRegister/profile')) && (
              <Link
                variant='body2'
                style={{ display: 'block' }}
                className='actions-button'
                onClick={() =>  setPreviewFile(row?.link || row?.preview)}
              >
                Minh chứng
              </Link>
            )}
            {row?.link?.includes('doi') && <a target='_blank' rel='noreferrer' href={row.link}>Link DOI</a>}
          </>
        );
      case 'edit':
      case 'detail':
        return (
          <Link
            color='primary'
            variant='body2'
            style={{ display: 'block' }}
            className='actions-button'
            onClick={() => setDialog({
              data: row,
              type: e.type,
              title: e.title,
              actionType: type
            })}
          >
            Chi tiết
          </Link>
        );
      case 'delete':
        return (
          <Link
            className='actions-button'
            color='error'
            style={{ display: 'block' }}
            onClick={() => dispatch(handleDelete(row.id)) }
          >
            Xóa
          </Link>
        );
      case 'score':
        return (
          <Link
            className='actions-button'
            style={{ display: 'block' }}
            onClick={() => {
              if (row.accepted) handleAccepted(row); else setDuyetThanhTich(row);
            }}
          >
            {`${row.accepted ? 'Huỷ xác' : 'Xác'} nhận`}
          </Link>
        );
      default:
        return null;
    }
  }

  var columns;
  switch (e.type) {
    case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '25%',
          render: (value) => [
            ...REPORTS_CONSTANTS.TYPE_OPTIONS.ALL,
            ...RESEARCH_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
            ...RESEARCH_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
            ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
            ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
            ...RESEARCHPROJECTS_CONSTANTS.TYPO_OPTIONS,
          ].find((e) => e.value === value)?.title,
        },
        { title: <b>Số tác giả</b>, dataIndex: 'numberAuthor', width: '15%' },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'role',
          width: '30%',
          render: (value) => RESEARCH_CONSTANTS.ROLE_OPTIONS.find((e) => e.value === value)?.title
        },
      ];
      break;
    
    case ACHIEVEMENT_TYPE.RESEARCH_PROJECT:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'level',
          width: '25%',
          render: (value) => RESEARCHPROJECTS_CONSTANTS.LEVEL_OPTIONS.find((e) => e.value === value)?.title,
        },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'type',
          width: '30%',
          render: (value) => RESEARCHPROJECTS_CONSTANTS.TYPO_OPTIONS.find((e) => e.value === value)?.title
        },
      ];
      break;

    case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '55%',
          render: (value) => REWARD_CONSTANTS.TYPE_OPTIONS[e.type.replace(ACHIEVEMENT_TYPE.REWARD, '')].find((e) => e.value === value)?.title,
        },
      ];
      break;

    case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '55%',
          render: (value) => REWARD_CONSTANTS.TYPE_OPTIONS[e.type.replace(ACHIEVEMENT_TYPE.REWARD, '')].find((e) => e.value === value)?.title,
        },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'role',
          width: '30%',
          render: (value) => FORM_CONSTANTS.ROLE_OPTIONS.slice(0, 2).find((e) => e.value === value)?.title,
        },
      ];
      break;
  
    default:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '20%',
          render: (value) => qcvEnums.TYPE_OPTIONS?.[value],
        },
        { title: <b>Số tác giả</b>, dataIndex: 'numberAuthor', width: '15%' },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'role',
          width: '20%',
          render: (value) => {
            if (value === 'main' && (e?.type === 'research' || e?.type === 'report'))
              return 'Tác giả chính (hoặc tác giả liên hệ)';
            // return roleOptions.find((e) => e.value === value)?.title;
          },
        },
      ];
  }

  if (setDuyetThanhTich) {
    columns = columns.concat([
      { title: <b>Điểm</b>, dataIndex: 'score', width: '5%' },
      { title: <b>Ghi chú</b>, dataIndex: 'note', width: '15%' },
    ]);
  }

  columns.push({
    title: <b>Tác vụ</b>,
    width: '15%',
    dataIndex: 'link',
    render: (value, row) => <>{e.actions.map((action) => actionButton(action, row))}</>
  });

  return columns;
};
