import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Chip, Button, Typography } from '@material-ui/core';

import KPTable from '../../../../components/KPTComponents/KPTable';
import PopupMenu from '../../../../components/CustomComponents/PopupMenu';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
import DuyetThanhTichModal from '../../../../components/CustomComponents/DuyetThanhTichModal';

import SangCheUpdate from '../SangCheUpdate';
import { solutionActions } from '../../../../actions';
import { SOLUTIONS_CONSTANTS } from '../../../../constants';

export default function SangCheTable(props) {
  const dispatch = useDispatch();

  const { TYPE_OPTIONS } = SOLUTIONS_CONSTANTS;

  const loading = useSelector(state => state.solutionReducer.loading);
  const totalSolutions = useSelector(state => state.solutionReducer.totalSolutions);
  const personalSolutionList = useSelector(state => state.solutionReducer.personalSolutionList);

  const [values, setValues] = useState(null);

  const [list, setList] = useState(null);
  const [detail, setDetail] = useState(null);
  const [preview, setPreview] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [totalList, setTotalList] = useState(null);
  const [duyetThanhTich, setDuyetThanhTich] = useState(null);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(false);

  useEffect(() => {
    if (personalSolutionList) {
      setList(personalSolutionList.map(param => ({
        ...param,
        issueAt: dayjs(param.issueAt).format('DD/MM/YYYY')
      })));
    }
    if (totalSolutions) {
      setTotalList(totalSolutions.map(param => ({
        ...param,
        issueAt: dayjs(param.issueAt).format('DD/MM/YYYY')
      })));
    }
  }, [personalSolutionList, totalSolutions]);

  const numberAuthorCounter = (author) => {
    var numberAuthor = 1;
    if (author.includes(' and ')) numberAuthor = 2;
    else if (author.includes(';')) numberAuthor = author.split(';').length;
    else if (author.includes(',')) numberAuthor = author.split(',').length;
    else if (author.includes('-')) numberAuthor = author.split('-').length;
    return numberAuthor;
  }

  const openDeleteMenu = (e, id) => {
    setDeleteId(id);
    setDeleteMenuAnchor(e.currentTarget);
  }
  const closeDeleteMenu = () => {
    setDeleteId(null);
    setDeleteMenuAnchor(null);
  }

  const handleDelete = () => {
    dispatch(solutionActions.deleteSolution(deleteId));
    closeDeleteMenu();
  }

  const handleAccepted = (row = duyetThanhTich) => {
    dispatch(solutionActions.updateSolutionAccepted([{
      id: row.formSolutionId,
      accepted: !row.thanhTichAccepted,
      score: values?.score || 0,
      note: values?.note || '',
    }]));
    setDuyetThanhTich(null);
    setValues(null);
  }

  const mobileMode = window.innerWidth <= 768;
  
  var tableRows = [];
  if (props?.rows) {
    tableRows = props?.rows.map((param) => ({ ...param, issueAt: dayjs(param.issueAt).format('DD/MM/YYYY') }));
  } else {
    tableRows = props.filterType === 'person' ? list : totalList;
  }

  var columns = [
    { title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
    { title: <b>Tên sản phẩm</b>, dataIndex: 'title', width: props.type === 'update' ? '25%' : '30%', sorter: (a, b) => a.title.localeCompare(b.title) },
    { title: <b>Tác Giả</b>, dataIndex: 'author', width: '20%' },
    {
      title: <b>Phân loại</b>,
      dataIndex: 'type',
      width: '20%',
      render: (value) => TYPE_OPTIONS.find((e) => e.value === value)?.title,
    },
    {
      title: <b>Tình trạng</b>,
      dataIndex: 'thanhTichAccepted',
      width: '15%',
      filters: [
        { text: 'Đã được duyệt', value: true },
        { text: 'Chưa được duyệt', value: false }
      ],
      onFilter: (value, row) => row.thanhTichAccepted === value,
      render: (value) => (
        <Chip
          size='small'
          variant='outlined'
          color={value ? 'primary' : 'default'}
          label={`${value ? 'Đã' : 'Chưa'} duyệt`}
          style={{ width: '100%' }}
        />
      )
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'cc-actions',
      width: '15%',
      render: (_, row) => (
        <>
          <Typography variant='body2' color='primary' className='actions-button' onClick={() => setDetail(row)}>Chi tiết</Typography>
          {!(props.type === 'accept') && (
            <Typography
              variant='body2'
              color='secondary'
              className='actions-button'
              onClick={(e) => openDeleteMenu(e, row.id)}
            >
              Xoá
            </Typography>
          )}
          <Typography variant='body2' className='actions-button' onClick={() => setPreview(row?.link)}>Minh chứng</Typography>
          {(props.type === 'accept') && (
            <Typography
              variant='body2'
              color={row?.thanhTichAccepted ? 'secondary' : 'primary'}
              className='actions-button'
              onClick={() => {
                if (row?.thanhTichAccepted) handleAccepted(row); else setDuyetThanhTich(row);
              }}
            >
              {`${row?.thanhTichAccepted ? 'Huỷ xác' : 'Xác'} nhận`}
            </Typography>
          )}
        </>
      ),
    },
  ];

  if (props.type === 'accept') {
    columns = [
      { ...columns[0] },
      {
        title: <b>Thông tin thành tích</b>,
        dataIndex: 'title',
        width: '50%',
        render: (_, row) => (
          <>
            <Typography variant='body2'><b>Tên sản phẩm: </b>{row.title}</Typography>
            <Typography variant='body2'>
              <b>Danh sách tác giả: </b>
              {row.author}
              {` (${numberAuthorCounter(row.author)})`}
            </Typography>
            <Typography variant='body2'>
              <b>Phân loại: </b>
              {TYPE_OPTIONS.find((e) => e.value === row.type)?.title}
            </Typography>
          </>
        ) 
      },
      { title: <b>Điểm</b>, dataIndex: 'score', width: '5%' },
      { title: <b>Ghi chú</b>, dataIndex: 'note', width: '15%' },
      { ...columns[4], width: '13%' },
      { ...columns[5], width: '12%' },
    ];
  }

  if (props.type === 'update') {
    if (mobileMode) {
      columns = [
        {...columns[1], title: <b>Tiêu đề</b>, width: '45%'},
        {...columns[3], width: '30%'},
        {...columns[5], width: '25%'},
      ];
    } else {
      columns = [
        {...columns[0], width: '5%'},
        {...columns[1], width: '25%'},
        {...columns[2], width: '25%'},
        {...columns[3], width: '15%'},
        {...columns[4], width: '15%'},
        {...columns[5], width: '15%'},
      ];
    }
  }

  return (
    <Grid item xs={12}>
      <KPTable
        nonBorder={props.type === 'accept'}
        columns={columns}
        rows={tableRows}
        loading={loading}
      />

      <SangCheUpdate detail={detail} onClose={() => setDetail(null)} />

      <PopupMenu
        position='center'
        anchorEl={deleteMenuAnchor}
        handleClose={closeDeleteMenu}
        render={
          <div style={{ padding: 8 }}>
            <Typography style={{ paddingBottom: 16 }}>Xác nhận xoá sáng chế/giải pháp?</Typography>
            <Button variant='outlined' style={{ width: 'calc(50% - 4px)' }} onClick={closeDeleteMenu}>Huỷ</Button>
            <Button variant='contained' color='secondary' style={{ marginLeft: 8, width: 'calc(50% - 4px)' }} onClick={handleDelete}>Xác nhận</Button>
          </div>
        }
      />

      <PreviewModal
        open={!!preview}
        url={preview}
        handleClose={() => setPreview(null)}
      />

      <DuyetThanhTichModal
        type='formSolution'
        info={duyetThanhTich}
        values={values}
        setValues={setValues}
        open={!!duyetThanhTich}
        handleClose={() => setDuyetThanhTich(null)}
        handleSubmit={handleAccepted}
      />
    </Grid>
  );
}