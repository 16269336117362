import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { getDoi } from '../../../../utils';
import { userActions } from '../../../../actions';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { SECONDARY_COLOR } from '../../../../constants/enums';
import { RESEARCH_CONSTANTS } from '../../../../constants';


export const domesticResearchForm = (values, mobileMode, disabled, doiEvents) => ([
  { key: 'link', width: 9, label: 'Link DOI', value: values?.link, disabled },
  {
    key: 'doi',
    width: 3,
    label: doiEvents.loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Nhập từ DOI',
    type: 'button',
    color: 'secondary',
    variant: 'contained',
    disabled,
    onClick: async () => {
      doiEvents.init();
      const doi = await getDoi(values?.link);
      if (doi.success) {
        doiEvents.onSuccess(doi);
      } else {
        doiEvents.onFailure(doi);
      }
    },
  },
  {
    key: 'title',
    width: 12,
    height: 1,
    label: 'Tên công bố',
    value: values?.title,
    disabled, required: true,
    note: (
      <Typography variant='subtitle1' style={{ color: SECONDARY_COLOR }}>
        <b>Lưu ý: </b>
        Xin vui lòng chỉ khai bài báo được đăng trên tạp chí trong nước thuộc danh mục tính điểm của Hội đồng GSNN!
      </Typography>
    ),
  },
  { key: 'journalTitle', width: 12, height: 1, label: 'Tên tạp chí trong nước', value: values?.journalTitle, disabled, required: true },
  { key: 'publisher', width: 12, height: 1, label: 'Nhà xuất bản', value: values?.publisher, disabled, required: true },
  { key: 'numberMain', width: 12, label: 'Số lượng tác giả chính (Tác giả đầu, tác giả liên hệ)', value: values?.numberMain, disabled, type: 'int', required: true },
  { key: 'publishYear', width: mobileMode ? 7 : 3, label: 'Năm xuất bản', value: values?.publishYear, disabled, required: true },
  { key: 'page', width: mobileMode ? 5 : 3, label: 'Trang', value: values?.page, disabled },
  { key: 'issn', width: mobileMode ? 5 : 3, label: 'ISSN/ISBN', value: values?.issn, disabled },
  { key: 'numberAuthor', width: mobileMode ? 7 : 3, label: 'Số tác giả', type: 'int', value: values?.numberAuthor, disabled, required: true },
  { key: 'author', width: 12, height: 1, label: 'Danh sách tác giả', value: values?.author, disabled, required: true },
  { key: 'role', width: mobileMode ? 7 : 6, label: 'Vai trò', value: values?.role, options: RESEARCH_CONSTANTS.ROLE_OPTIONS, disabled, required: true },
  { key: 'type', width: mobileMode ? 5 : 6, label: 'Phân loại', value: values?.type, options: RESEARCH_CONSTANTS.TYPE_OPTIONS.DOMESTIC, disabled, required: true },
  { key: 'description', width: 12, height: 1, label: 'Ghi chú', value: values?.description, disabled },
  {
    key: 'previewFile',
    width: 12,
    label: (
      <>
        <Typography variant='body1' style={{ fontSize: 17 }}>
          <b>Upload file minh chứng PDF</b>
        </Typography>
        <Typography variant='body2' color='secondary'>
          (Trong trường hợp không có link DOI)
        </Typography>
      </>
    ),
    acceptedFiles: ["application/pdf"],
    value: values?.previewFile,
    type: 'file',
    disabled,
  },
]);


function BaiBaoTrongNuocForm(props) {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();
  const { values, setValues, disabled } = props;
  const [loadingDoi, setLoadingDoi] = useState(false);

  const doiEvents = {
    loading: loadingDoi,
    init: () => setLoadingDoi(true),
    onSuccess: (doi) => {
      const { data: { info, journal } } = doi;
      setValues({
        ...values,
        journalTitle: journal?.type === 'journal' ? journal?.title : null,
        type: journal?.coreCollection === 'Scopus' ? 'scopus' : journal?.sjrBestQuartile.toLowerCase(),
        link: info?.URL || info?.doi,
        title: info?.title || info?.titleEn,
        publisher: info?.publisher || info?.publisherId,
        publishYear: String(info?.issued?.['date-parts']?.[0]?.[0] || info?.pubYear),
        page: info?.page,
        issn: info?.ISSN?.[0] || info?.journal?.issn,
        numberAuthor: info?.author?.length || info?.contributorList?.length,
        author: info?.author
          ? info?.author?.map((e) => (`${e.given} ${e.family}`)).join(', ')
          : info?.contributorList?.map((e) => (e.surnameEn || `${e.surname}${e.givenName ? ` ${e.givenName}` : ''}`)).join('; ')
      });
      setLoadingDoi(false);
      dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
    },
    onFailure: (doi) => {
      setLoadingDoi(false);
      dispatch(userActions.notification({ message: doi.message, type: 'error' }));
    }
  };

  const textForm = domesticResearchForm(values, isMobileMode, disabled, doiEvents);

  return <KPTextform {...props} textForm={textForm} />
}

export default BaiBaoTrongNuocForm;
