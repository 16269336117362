import React from 'react';

import DoanhNghiepTable from '../../../containers/QCV/DoanhNghiep/DoanhNghiepTable/DoanhNghiepTable'
import DoanhNghiepButton from '../../../containers/QCV/DoanhNghiep/DoanhNghiepButton/DoanhNghiepButton';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';

export default function DoanhNghiep() {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  return (
    <>
      <DoanhNghiepButton filterType={filterType} />
      <ThanhTichAlert />
      <DoanhNghiepTable filterType={filterType} type='update' />
    </>
  );
}