import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { SOLUTIONS_CONSTANTS } from '../../../../constants';
import { Typography } from '@material-ui/core';

export const solutionForm = (values, mobileMode, disabled, typeOptions=SOLUTIONS_CONSTANTS.TYPE_OPTIONS) => ([
  { key: 'title', width: 12, height: 1, label: 'Tên sáng chế/giải pháp', value: values?.title, disabled, required: true },
  { key: 'author', width: 12, height: 1, label: 'Tác giả', value: values?.author, disabled, required: true},
  { key: 'issueBy', width: 12, height: 1, label: 'Nơi cấp', value: values?.issueBy, disabled, required: true },
  { key: 'issueAt', width: 5, label: 'Ngày cấp', type: 'date', value: values?.issueAt, disabled, required: true },
  { key: 'type', width: 7, label: 'Phân loại', value: values?.type, options: typeOptions, disabled, required: true },
  {
    key: 'previewFile',
    disabled,
    required: true,
    width: 12,
    type: 'file',
    value: values?.previewFile,
    label: (
      <>
        <Typography variant='body1' style={{ fontSize: 17 }}>
          <b>Upload file minh chứng tại đây</b>
        </Typography>
        <Typography variant='body2' color='secondary'>
          Hình ảnh/ file PDF bằng hoặc quyết định được cấp
        </Typography>
        <Typography variant='body2' color='secondary'>
          (Có dấu xác nhận của cơ quan có thẩm quyền)
        </Typography>
      </>
    ),
    acceptedFiles: ["image/*", "application/pdf"],
  },
]);

export default function SangCheForm(props) {
  const textForm = solutionForm(props.values, null, props.disabled);
  return <KPTextform {...props} textForm={textForm} />;
}
