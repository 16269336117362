import { apiCall } from '../utils';
import { FILE_API, FILE_TYPE } from '../constants';

import { userActions, profileActions } from '.';
const { notification } = userActions;

export const fileActions = {
  importFile,
  importPreviewFile,
  importMultiFile,
  importMultiFileQCV,
};

function importFile(id, file, profilePayload){
  return async(dispatch) => {
    dispatch(loading());
  
    const formData = new FormData();
    formData.id = id;
    formData.append('id', id);
    formData.append('file', file);

    const api = FILE_API.importFile(id);
    const { response, error } = await apiCall({ ...api, payload: formData });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success());
      dispatch(profileActions.upsertProfile({
        ...profilePayload,
        photoUrl: response.data.data,
      }));
      // dispatch(notification({ message: 'Thêm ảnh chân dung thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm ảnh chân dung thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FILE_TYPE.IMPORT_FILE_LOADING } }
  function success() { return { type: FILE_TYPE.IMPORT_FILE_SUCCESS } }
  function failure(error) { return { type: FILE_TYPE.IMPORT_FILE_FAILURE, error } }
}

function importPreviewFile(id, file){
  return async(dispatch) => {
    dispatch(loading());
  
    const formData = new FormData();
    formData.id = id;
    formData.append('id', id);
    formData.append('file', file, new Date().getTime() + file.name);

    const api = FILE_API.importFile(id);
    const { response, error } = await apiCall({ ...api, payload: formData });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success());
      // dispatch(notification({ message: 'Thêm minh chứng thành công', type: 'success' }));
      return response.data.data;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm minh chứng thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FILE_TYPE.IMPORT_FILE_LOADING } }
  function success() { return { type: FILE_TYPE.IMPORT_FILE_SUCCESS } }
  function failure(error) { return { type: FILE_TYPE.IMPORT_FILE_FAILURE, error } }
}


function importMultiFile(id, file){
  return async(dispatch) => {
    dispatch(loading());

    const formData = new FormData();
    formData.append('file', file.gpa, 'ptns_gpa_' + new Date().getTime() + '_' + file.gpa.name);
    formData.append('file', file.majorGpa, 'ptns_major_gpa_' + new Date().getTime() + '_' + file.majorGpa.name);
    file.majorAchievementFile.forEach((e) => {
      formData.append('file', e, 'ptns_major_achievement_' + new Date().getTime() + '_' + e.name);
    });
    file.reward?.forEach((e) => {
      formData.append('file', e, 'ptns_reward_' + new Date().getTime() + '_' + e.name);
    });
    file.socialActivityFile?.forEach((e) => {
      formData.append('file', e, 'ptns_social_activity_' + new Date().getTime() + '_' + e.name);
    });

    const api = FILE_API.importMultiFile(id);
    const { response, error } = await apiCall({ ...api, payload: formData });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success());
      return response.data.data;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm minh chứng thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FILE_TYPE.IMPORT_FILE_LOADING } }
  function success() { return { type: FILE_TYPE.IMPORT_FILE_SUCCESS } }
  function failure(error) { return { type: FILE_TYPE.IMPORT_FILE_FAILURE, error } }
}

function importMultiFileQCV(id, file){
  return async(dispatch) => {
    dispatch(loading());

    const formData = new FormData();
    for (const property in file) {
      if (file[property] instanceof File) {
        var name = file[property].name;
        if (name.includes(`${property}.`)) {
          name = name.replace(`${property}.`, '');
          name = name.split('_')[1];
        }
        formData.append('file', file[property], property + '.' + new Date().getTime() + '_' + name);
      }
    }

    const api = FILE_API.importMultiFile(id);
    const { response, error } = await apiCall({ ...api, payload: formData });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success());
      return response.data.data || [];
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm minh chứng thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FILE_TYPE.IMPORT_FILE_LOADING } }
  function success() { return { type: FILE_TYPE.IMPORT_FILE_SUCCESS } }
  function failure(error) { return { type: FILE_TYPE.IMPORT_FILE_FAILURE, error } }
}