import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import BaiBaoInsert from '../BaiBaoInsert';

import { researchActions } from '../../../../actions';

function BaiBaoButton(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);
  const totalResearchList = useSelector(state => state.researchReducer.totalResearchList);
  const personalResearchList = useSelector(state => state.researchReducer.personalResearchList);

  const [inserting, setInserting] = useState(false);

  const handleClick = () => setInserting(true);
  const handleClose = () => setInserting(false);
  const handleReload = () => {
    if (props.filterType === 'person') {
      dispatch(researchActions.getResearchByProfileId(profileId));
    } else {
      dispatch(researchActions.getAllResearch());
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (props.filterType === 'person' && !isNaN(profileId) && personalResearchList.length === 0) handleReload();
    }, 1000);
    if (props.filterType === 'person' && !isNaN(profileId) && personalResearchList.length === 0) handleReload();
  }, [profileId]);

  useEffect(() => {
    if (props.filterType === 'all' && totalResearchList.length === 0) handleReload();
  }, []);

  return (
    <>
      <Grid item xs={12} md={3}>
        <div style={{ float: 'right' }}>
          <Button variant='outlined' startIcon={<Replay />} style={{ backgroundColor: 'white' }} onClick={handleReload}>Tải lại</Button>
          {props.filterType === 'person' && (
            <Button variant='contained' color='secondary' startIcon={<Add />} style={{ marginLeft: 8 }} onClick={handleClick}>Thêm</Button>
          )}
        </div>
      </Grid>


      <BaiBaoInsert form={props.form} open={inserting} onClose={handleClose} />
    </>
  );
}

export default BaiBaoButton;
