import { SEASON_TYPE } from '../constants';
import { APP_SCENE } from '../constants/enums';

const initialState = {
  seasonList: [],
  openSeasonId: null,
}

export function seasonReducer(state = initialState, action) {
  switch (action.type) {
    case SEASON_TYPE.GET_SEASON_LIST_LOADING:
    case SEASON_TYPE.UPDATE_SEASON_LOADING:
      return { ...state, loading: true };

    case SEASON_TYPE.GET_SEASON_LIST_FAILURE:
    case SEASON_TYPE.UPDATE_SEASON_FAILURE:
      return { ...state, loading: false };

    case SEASON_TYPE.GET_SEASON_LIST_SUCCESS:
      return {
        ...state,
        seasonList: [...action.payload.filter((e) => e.category === APP_SCENE)],
        openSeasonId: action.payload.find((e) => e.status === 'open' && e.category === APP_SCENE)?.id,
        loading: false
      };

    case SEASON_TYPE.UPDATE_SEASON_SUCCESS:
      return {
        ...state,
        loading: false,
        openSeasonId: action.payload.id,
        seasonList: [...state.seasonList.filter(e => e.id !== action.payload.id), action.payload]
      }

    default:
      return state;
  }
}
