import { REWARD_TYPE, FORM_TYPE } from '../constants';

const initialState = {
  loading: false,
  totalRewardList: [],
  personalRewardList: [],
}

const sortFunc = (a, b) => a.id - b.id;

export function rewardReducer(state = initialState, action) {
  switch (action.type) {
    case REWARD_TYPE.GET_ALL_REWARD_LOADING:
    case REWARD_TYPE.GET_REWARD_BY_PROFILE_ID_LOADING:
    case REWARD_TYPE.INSERT_REWARD_LOADING:
    case REWARD_TYPE.UPDATE_REWARD_LOADING:
    case REWARD_TYPE.DELETE_REWARD_LOADING:
    case REWARD_TYPE.UPDATE_REWARD_ACCEPTED_LOADING:
      return { ...state, loading: true };

    case REWARD_TYPE.GET_ALL_REWARD_FAILURE:
    case REWARD_TYPE.GET_REWARD_BY_PROFILE_ID_FAILURE:
    case REWARD_TYPE.INSERT_REWARD_FAILURE:
    case REWARD_TYPE.UPDATE_REWARD_FAILURE:
    case REWARD_TYPE.DELETE_REWARD_FAILURE:
    case REWARD_TYPE.UPDATE_REWARD_ACCEPTED_FAILURE:
      return { ...state, loading: false };

    case REWARD_TYPE.GET_ALL_REWARD_SUCCESS:
      return { ...state, totalRewardList: action.payload.sort(sortFunc), loading: false };

    case REWARD_TYPE.GET_REWARD_BY_PROFILE_ID_SUCCESS:
      return { ...state, personalRewardList: action.payload.sort(sortFunc), loading: false };

    case REWARD_TYPE.INSERT_REWARD_SUCCESS:
      return {
        ...state,
        totalRewardList: state.personalRewardList?
          [...state.personalRewardList, action.payload].sort(sortFunc):
          [action.payload],
        personalRewardList: state.personalRewardList?
          [...state.personalRewardList, action.payload].sort(sortFunc):
          [action.payload],
        loading: false
      };

    case REWARD_TYPE.UPDATE_REWARD_SUCCESS:
      return {
        ...state,
        totalRewardList: state.totalRewardList ? [
          ...state.totalRewardList.filter((e) => e.id !== action.payload.id),
          action.payload
        ].sort(sortFunc) : null,
        personalRewardList: state.personalRewardList ? [
          ...state.personalRewardList.filter((e) => e.id !== action.payload.id),
          action.payload
        ].sort(sortFunc) : null,
        loading: false
      };

    case REWARD_TYPE.DELETE_REWARD_SUCCESS:
      return {
        ...state,
        totalRewardList: state.personalRewardList.filter((e) => e.id !== action.id).sort(sortFunc),
        personalRewardList: state.personalRewardList.filter((e) => e.id !== action.id).sort(sortFunc),
        loading: false
      };

    case REWARD_TYPE.UPDATE_REWARD_ACCEPTED_SUCCESS:
      return {
        ...state,
        // totalRewardList: state.totalRewardList ? [
        //   ...state.totalRewardList?.filter((e) => e.id !== action.payload.id),
        //   action.payload
        // ].sort(sortFunc) : null,
        // personalRewardList: state.personalRewardList ? [
        //   ...state.personalRewardList?.filter((e) => e.id !== action.payload.id),
        //   action.payload
        // ].sort(sortFunc) : null,
        loading: false
      };

    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        personalRewardList: action.payload?.rewards
      }


    default:
      return state;
  }
}
