import { apiCall } from '../utils';
import { VOTE_API, VOTE_TYPE } from '../constants';

import { userActions } from '.';
const { notification } = userActions;

export const voteActions = {
  vote,
  getVoteInfo,
  getVoteCount,
  setVoteInfo,
  reloadVoteInfo,
};

function vote(payload, callBack) {
  return async(dispatch) => {
    dispatch(loading());
    
    const api = VOTE_API.vote();
    const { response, error } = await apiCall({ ...api, payload });
    
    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      callBack();
    } else {
      dispatch(failure(error));
      if (error?.response?.includes('pls wait 60s')) {
        dispatch(notification({ message: 'Vui lòng bình chọn tiếp sau 60s nữa', type: 'error' }));
      } else if (error?.response?.includes('email invalid')) {
        dispatch(notification({ message: 'Email không tồn tại', type: 'error' }));
      } else if (error?.response?.includes('banned')) {
        dispatch(notification({ message: 'IP bị cấm bình chọn 1 ngày do có dấu hiệu spam', type: 'error' }));
      } else {
        dispatch(notification({ message: 'Bình chọn thất bại', type: 'error' }));
      }
    }
  };
  function loading() { return { type: VOTE_TYPE.VOTE_LOADING } }
  function success(payload) { return { type: VOTE_TYPE.VOTE_SUCCESS, payload } }
  function failure(error) { return { type: VOTE_TYPE.VOTE_FAILURE, error } }
}

function getVoteInfo(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = VOTE_API.getVoteInfo();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(error));
    }
  };
  function loading() { return { type: VOTE_TYPE.GET_VOTE_INFO_LOADING } }
  function success(payload) { return { type: VOTE_TYPE.GET_VOTE_INFO_SUCCESS, payload } }
  function failure(error) { return { type: VOTE_TYPE.GET_VOTE_INFO_FAILURE, error } }
}

function getVoteCount() {
  return async(dispatch) => {
    dispatch(loading());

    const api = VOTE_API.getVoteCount();
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(error));
    }
  };
  function loading() { return { type: VOTE_TYPE.GET_VOTE_COUNT_LOADING } }
  function success(payload) { return { type: VOTE_TYPE.GET_VOTE_COUNT_SUCCESS, payload } }
  function failure(error) { return { type: VOTE_TYPE.GET_VOTE_COUNT_FAILURE, error } }
}

function setVoteInfo(info) {
  return { type: 'SET_VOTE_INFO', info }
}

function reloadVoteInfo() {
  return { type: 'RELOAD_VOTE_INFO' }
}

// function filterForm(payload) {
//   return async(dispatch) => {
//     dispatch(loading());

//     const api = VOTE_API.filterForm();
//     const { response, error } = await apiCall({ ...api, payload });

//     if (!error && response.status === 200 && response.data.success) {
//       dispatch(success(response.data.data));
//     } else {
//       dispatch(failure(error));
//     }
//   };
//   function loading() { return { type: VOTE_TYPE.VOTEFILTER_FORM_VOTE_LOADING_LOADING } }
//   function success(payload) { return { type: VOTE_TYPE.FILTER_FORM_VOTE_SUCCESS, payload } }
//   function failure(error) { return { type: VOTE_TYPE.FILTER_FORM_VOTE_FAILURE, error } }
// }
