import React from 'react';

import DuAnButton from '../../../containers/QCV/DuAnNghienCuu/DuAnButton';
import DuAnTable from '../../../containers/QCV/DuAnNghienCuu/DuAnTable';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';

function DuAn() {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  return (
    <>
      <DuAnButton filterType={filterType}/>
      <ThanhTichAlert />
      <DuAnTable filterType={filterType} type='update' />
    </>
  );
}

export default DuAn;
