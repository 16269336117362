import { apiCall } from '../utils';
import { MONOGRAPHS_API, MONOGRAPHS_TYPE } from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const monographActions = {
	getAllMonograph,
	getMonographsByProfileId,
	insertMonograph,
	updateMonograph,
	deleteMonograph,
	updateMonographAccepted
}

function getAllMonograph() {
	return async (dispatch) => {
		dispatch(loading());

		const api = MONOGRAPHS_API.getMonographAll();
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy sách chuyên khảo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy sách chuyên khảo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: MONOGRAPHS_TYPE.GET_ALL_MONOGRAPH_LOADING } }
	function success(payload) { return { type: MONOGRAPHS_TYPE.GET_ALL_MONOGRAPH_SUCCESS, payload } }
	function failure(error) { return { type: MONOGRAPHS_TYPE.GET_ALL_MONOGRAPH_FAILURE, error } }
}

function getMonographsByProfileId(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = MONOGRAPHS_API.getMonographByProfileId(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy bảng sách chuyên khảo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy bảng sách chuyên khảo thất bại', type: 'error' }));
		}
	}

	function loading() { return { type: MONOGRAPHS_TYPE.GET_MONOGRAPH_BY_PROFILE_ID_LOADING } }
	function success(payload) { return { type: MONOGRAPHS_TYPE.GET_MONOGRAPH_BY_PROFILE_ID_SUCCESS, payload } }
	function failure(error) { return { type: MONOGRAPHS_TYPE.GET_MONOGRAPH_BY_PROFILE_ID_FAILURE, error } }
}

function insertMonograph(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = MONOGRAPHS_API.insertMonograph();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data[0]));
			dispatch(notification({ message: 'Thêm bảng sách chuyên khảo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Thêm bảng sách chuyên khảo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: MONOGRAPHS_TYPE.INSERT_MONOGRAPH_LOADING } }
	function success(payload) { return { type: MONOGRAPHS_TYPE.INSERT_MONOGRAPH_SUCCESS, payload } }
	function failure(error) { return { type: MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_FAILURE, error } }
}

function updateMonograph(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = MONOGRAPHS_API.updateMonograph();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusFormByData('formMonograph', 'monograph', response.data.data));
			dispatch(notification({ message: 'Chỉnh sửa bảng sách chuyên khảo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Chỉnh sửa bảng sách chuyên khảo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_LOADING } }
	function success(payload) { return { type: MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_SUCCESS, payload } }
	function failure(error) { return { type: MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_FAILURE, error } }
}

function updateMonographAccepted(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = MONOGRAPHS_API.updateMonographAccepted();
		const { response, error } = await apiCall({ ...api, payload });

		const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusForm('formMonograph', response.data.data));
			dispatch(notification({ message: `${noti} bảng sách chuyên khảo thành công`, type: 'success' }));
			return true;
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: `${noti} bảng sách chuyên khảo thất bại`, type: 'error' }));
		}
	};

	function loading() { return { type: MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_ACCEPTED_LOADING } }
	function success(payload) { return { type: MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_ACCEPTED_SUCCESS, payload } }
	function failure(error) { return { type: MONOGRAPHS_TYPE.UPDATE_MONOGRAPH_ACCEPTED_FAILURE, error } }
}

function deleteMonograph(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = MONOGRAPHS_API.deleteMonograph(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200) {
			dispatch(success(id));
			dispatch(notification({ message: 'Xoá bảng sách chuyên khảo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Xoá bảng sách chuyên khảo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: MONOGRAPHS_TYPE.DELETE_MONOGRAPH_LOADING } }
	function success(id) { return { type: MONOGRAPHS_TYPE.DELETE_MONOGRAPH_SUCCESS, id } }
	function failure(error) { return { type: MONOGRAPHS_TYPE.DELETE_MONOGRAPH_FAILURE, error } }
}