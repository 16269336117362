import { apiCall } from '../utils';
import { TECH_COMPANY_API, TECH_COMPANY_TYPE } from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const techCompanyActions = {
	getAllTechCompany,
	getTechCompanysByProfileId,
	insertTechCompany,
	updateTechCompany,
	deleteTechCompany,
	updateTechCompanyAccepted
}

function getAllTechCompany() {
	return async (dispatch) => {
		dispatch(loading());

		const api = TECH_COMPANY_API.getAllTechCompanyList();
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy doanh nghiệp công nghệ thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy doanh nghiệp công nghệ thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: TECH_COMPANY_TYPE.GET_ALL_TECH_COMPANY_LOADING } }
	function success(payload) { return { type: TECH_COMPANY_TYPE.GET_ALL_TECH_COMPANY_SUCCESS, payload } }
	function failure(error) { return { type: TECH_COMPANY_TYPE.GET_ALL_TECH_COMPANY_FAILURE, error } }
}

function getTechCompanysByProfileId(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = TECH_COMPANY_API.getTechCompanyByProfileId(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy doanh nghiệp công nghệ thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy doanh nghiệp công nghệ thất bại', type: 'error' }));
		}
	}

	function loading() { return { type: TECH_COMPANY_TYPE.GET_TECH_COMPANY_BY_PROFILE_ID_LOADING } }
	function success(payload) { return { type: TECH_COMPANY_TYPE.GET_TECH_COMPANY_BY_PROFILE_ID_SUCCESS, payload } }
	function failure(error) { return { type: TECH_COMPANY_TYPE.GET_TECH_COMPANY_BY_PROFILE_ID_FAILURE, error } }
}

function insertTechCompany(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = TECH_COMPANY_API.insertTechCompany();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data[0]));
			dispatch(notification({ message: 'Thêm doanh nghiệp công nghệ thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Thêm doanh nghiệp công nghệ thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: TECH_COMPANY_TYPE.INSERT_TECH_COMPANY_LOADING } }
	function success(payload) { return { type: TECH_COMPANY_TYPE.INSERT_TECH_COMPANY_SUCCESS, payload } }
	function failure(error) { return { type: TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_FAILURE, error } }
}

function updateTechCompany(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = TECH_COMPANY_API.updateTechCompany();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusFormByData('formTechCompany', 'techCompany', response.data.data));
			dispatch(notification({ message: 'Chỉnh sửa doanh nghiệp công nghệ thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Chỉnh sửa doanh nghiệp công nghệ thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_LOADING } }
	function success(payload) { return { type: TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_SUCCESS, payload } }
	function failure(error) { return { type: TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_FAILURE, error } }
}

function updateTechCompanyAccepted(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = TECH_COMPANY_API.updateTechCompanyAccepted();
		const { response, error } = await apiCall({ ...api, payload });

		const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusForm('formTechCompany', response.data.data));
			dispatch(notification({ message: `${noti} doanh nghiệp công nghệ thành công`, type: 'success' }));
			return true;
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: `${noti} doanh nghiệp công nghệ thất bại`, type: 'error' }));
		}
	};

	function loading() { return { type: TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_ACCEPTED_LOADING } }
	function success(payload) { return { type: TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_ACCEPTED_SUCCESS, payload } }
	function failure(error) { return { type: TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_ACCEPTED_FAILURE, error } }
}

function deleteTechCompany(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = TECH_COMPANY_API.deleteTechCompany(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200) {
			dispatch(success(id));
			dispatch(notification({ message: 'Xoá doanh nghiệp công nghệ thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Xoá doanh nghiệp công nghệ thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: TECH_COMPANY_TYPE.DELETE_TECH_COMPANY_LOADING } }
	function success(id) { return { type: TECH_COMPANY_TYPE.DELETE_TECH_COMPANY_SUCCESS, id } }
	function failure(error) { return { type: TECH_COMPANY_TYPE.DELETE_TECH_COMPANY_FAILURE, error } }
}