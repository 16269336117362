import React from 'react';
import { Typography } from '@material-ui/core';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { FORM_CONSTANTS, HR_DEVELOPMENT_CONSTANTS } from '../../../../constants';

export default function DaoTaoForm(props) {
  const isMobileMode = useScreenSize();

  const { values, disabled } = props;
  const { roleOptions, levelOptions } = HR_DEVELOPMENT_CONSTANTS;

  const textFormDaoTao = [
    { key: 'traineeName', width: 12, height: 1, label: 'Tên học viên', value: values?.traineeName, disabled, required: true },
    { key: 'unit', width: 12, height: 1, label: 'Cơ sở đào tạo', value: values?.unit, disabled, required: true },
    { key: 'academicLevel', width: isMobileMode ? 12: 4, label: 'Chương trình đào tạo', value: values?.academicLevel, disabled, options: levelOptions, required: true },
    { key: 'role', width: isMobileMode ? 12: 5, label: 'Vai trò', value: values?.role, disabled, options: roleOptions, required: true },
		{ key: 'year', width: isMobileMode ? 12: 3, label: 'Năm bảo vệ', type: 'int',value: values?.year, disabled, required: true },
    {
      key: 'previewFile',
      width: 12,
      value: values?.previewFile,
      type: 'file',
      label: (
        <>
          <Typography variant='body1' style={{ fontSize: 17 }}>
            <b>Upload file minh chứng tại đây</b>
          </Typography>
          <Typography variant='body2' color='secondary'>
            (Quyết định hướng dẫn, Quyết định (Biên bản) đã bảo vệ thành công)
          </Typography>
        </>
      ),
      disabled, required: true
    },
  ];

  const textFormBoiDuong = [
    { key: 'programName', width: 12, height: 1, label: 'Tên chương trình (tập huấn, hội thảo...)', value: values?.programName, disabled, required: true },
    { key: 'unit', width: 12, label: 'Đơn vị tổ chức', value: values?.unit, disabled, required: true },
		{ key: 'year', width: isMobileMode ? 12: 4 , label: 'Năm tổ chức', type: 'int',value: values?.year, disabled, required: true },
		{ key: 'traineeNum', width: isMobileMode ? 12: 8 , label: 'Số lượng nhân lực được bồi dưỡng', type: 'int',value: values?.traineeNum, disabled, required: true },
		{ key: 'field', width: 12 , label: 'Lĩnh vực', value: values?.field, disabled, required: true, options: FORM_CONSTANTS.FIELD_QCV_OPTIONS },
    { key: 'previewFile', width: 12, value: values?.previewFile, type: 'file', disabled, required: true },
  ];

  return (
    <KPTextform {...props} textForm={props?.type === 'daoTao' ? textFormDaoTao : textFormBoiDuong} />
  );
}