import { USER_TYPE } from '../constants';

const initialState = {
  user: null,
  loading: false,
  notification: null,
  loadingReset: false,
  userList: null,
  loadingUser: false,
}

const sortFunc = (a, b) => a.id - b.id;

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_TYPE.LOGIN_LOADING:
    case USER_TYPE.REGISTER_LOADING:
    case USER_TYPE.CHANGE_PASSWORD_LOADING:
      return { ...state, loading: true };

    case USER_TYPE.GET_ALL_USER_LOADING:
    case USER_TYPE.MODIFY_ROLE_LOADING:
      return { ...state, loadingUser: true };

    case USER_TYPE.LOGIN_FAILURE:
    case USER_TYPE.REGISTER_FAILURE:
    case USER_TYPE.REGISTER_SUCCESS:
    case USER_TYPE.CHANGE_PASSWORD_FAILURE:
    case USER_TYPE.CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false };

    case USER_TYPE.GET_ALL_USER_FAILURE:
    case USER_TYPE.MODIFY_ROLE_FAILURE:
      return { ...state, loadingUser: false };

    case USER_TYPE.LOGIN_SUCCESS:
      return { ...state, loading: false, user: {...action.payload} };
      
    case USER_TYPE.LOGIN_WITH_TOKEN:
      return { ...state, loading: false, user: {...action.payload} };

    case USER_TYPE.LOGOUT:
      return { ...state, loading: false, user: null };

    case USER_TYPE.NOTIFICATION:
      return { ...state, notification: action.payload };
      
    case USER_TYPE.RESET_BY_EMAIL_LOADING:
      return { ...state, loadingReset: true };

    case USER_TYPE.RESET_BY_EMAIL_SUCCESS:
    case USER_TYPE.RESET_BY_EMAIL_FAILURE:
      return { ...state, loadingReset: false };

    case USER_TYPE.GET_ALL_USER_SUCCESS:
      return { ...state, userList: [...action.payload].sort(sortFunc), loadingUser: false };

    case USER_TYPE.MODIFY_ROLE_SUCCESS:
      return {
        ...state,
        userList: [
          ...state.userList.filter((e) => e?.id !== action.payload?.id),
          {
            ...state.userList.find((e) => e?.id === action.payload?.id),
            role: action.payload?.role
          }
        ].sort(sortFunc),
        loadingUser: false
      } 

    default:
      return state
  }
}
