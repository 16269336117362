import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,

  Box,
  Card,
  Button,
  Checkbox,
  Typography,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import {
  Drafts,
  Search,
  FilterList,
} from '@material-ui/icons';

import styles from './KPTable.module.css';

import PopupMenu from '../../CustomComponents/PopupMenu';

function KPTable(props) {
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const [activeFilterKey, setActiveFilterKey] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const [realFilter, setRealFilter] = useState(null);

  const findColumnWithKey = (key) => props.columns.find((e) => e.dataIndex === key);

  // Click vào title thì sort cột đó
  // Nếu cột ấy đã được click thì sort theo chiều ngược lại
  // không thì sort để data cột đó tăng dần
  const handleSort = (key) => {
    if (!findColumnWithKey(key).sorter) return;
    if (sort?.direction === 1) setSort(null);
    else if (sort?.key !== key) setSort({ key, direction: 0 });
    else setSort({ ...sort, direction: (sort?.direction + 1) % 2 });
  }

  // Kiểm tra xem một cột có được click để sort không
  const checkSortedColumn = (key) => {
    if (sort?.key === key) return sort?.direction;
  }

  const handleOpenFilter = (e, key) => {
    setActiveFilterKey(key);
    setAnchorElFilter(e.currentTarget);
    if ((filter && !filter[key]) || !filter) {
      setFilter({
        ...filter,
        [key]: findColumnWithKey(key).filters?.map((item) => ({ ...item, checked: false })),
      });
    }
  }

  const handleResetFilter = () => {
    setFilter({
      ...filter,
      [activeFilterKey]: findColumnWithKey(activeFilterKey).filters.map((item) => ({ ...item, checked: false })),
    });
    setRealFilter({
      ...filter,
      [activeFilterKey]: findColumnWithKey(activeFilterKey).filters.map((item) => ({ ...item, checked: false })),
    });
    setAnchorElFilter(null);
    setActiveFilterKey(null);
  }

  const handleFilter = (e) => {
    e.preventDefault();
    setRealFilter(filter);
    setAnchorElFilter(null);
    setActiveFilterKey(null);
  }

  const columns = props?.columns;
  var rows = [...props?.rows || []];

  if (sort) {
    if (sort.direction === 0) rows = rows.sort(findColumnWithKey(sort.key).sorter);
    else rows = rows.sort(findColumnWithKey(sort.key).sorter).reverse();
  }

  for (var item in realFilter) {
    // eslint-disable-next-line no-loop-func
    realFilter[item].forEach((e) => {
      if (e.checked) {
        rows = rows.filter((row) => findColumnWithKey(item)?.onFilter(e.value, row));
      }
    })
  }

  return (
    <>
      <TableContainer
        className={styles.tableContainer}
        component={props?.nonBorder ? Box : Card}
        style={{ maxHeight: props?.maxHeight, width: props?.nonBorder ? '100%' : 'calc(100% - 3px)' }}
      >
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead style={{ backgroundColor: props?.headerColor || '#f2f2f2' }}>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column?.dataIndex}
                  className={styles.header}
                  style={{
                    width: column.width,
                    minWidth: column.width,
                    maxWidth: column.width,
                    textAlign: column.align || 'left'
                  }}
                >
                  <div className={styles.headerContainer}>
                    <div
                      className={!props?.nonHover ? styles.headerTitleContainer : styles.headerTitleContainerCantHover}
                      onClick={() => handleSort(column.dataIndex)}
                    >
                      <div className={styles.headerTitle}>
                        <div className={styles.title}>{column?.title}</div>
                        {column?.sorter && (
                          <div className={styles.icon}>
                            <svg viewBox='0 9 24 6' style={{ fill: checkSortedColumn(column.dataIndex) === 0 ? '#1976d2' : '#7D7D7D' }}><path d='M7 14l5-5 5 5z'></path></svg>
                            <svg viewBox='0 9 24 6' style={{ fill: checkSortedColumn(column.dataIndex) === 1 ? '#1976d2' : '#7D7D7D' }}><path d='M7 10l5 5 5-5z'></path></svg>
                          </div>
                        )}
                      </div>
                    </div>

                    {column?.searchable && (
                      <div className={`${styles.icon} ${styles.searchIcon}`}>
                        <Search fontSize='small' />
                      </div>
                    )}

                    {column?.filters && (
                      <div className={`${styles.icon} ${styles.searchIcon}`} onClick={(e) => handleOpenFilter(e, column.dataIndex)}>
                        <FilterList fontSize='small' />
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length ? rows.map((row, rowIndex) => (
              <TableRow className={styles.tableRow} key={`row-${rowIndex}`} sx={{ '&:last-child td, &:last-child th': { border: 'none' } }}>
                {columns.map((column, columnIndex) => (
                  <TableCell
                    className={styles.bodyCell}
                    key={`column-${columnIndex}`}
                    style={{ textAlign: column.align || 'left' }}
                  >
                    {!column.render ? (
                      row[column.dataIndex]
                    ) : (
                      column.render(row[column.dataIndex], row, rowIndex)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={props.columns?.length} style={{ textAlign: 'center', border: 'none' }}>
                  <Drafts color='disabled' style={{ fontSize: 128 }} />
                  <Typography variant='h5' style={{ color: 'rgba(0, 0, 0, 0.26)' }}>Không có dữ liệu</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {props.loading && (
          <div className={styles.loadingContainer}>
            <CircularProgress size={24} />
          </div>
        )}
      </TableContainer>


      <PopupMenu
        position='right'
        anchorEl={anchorElFilter}
        handleClose={() => {
          setActiveFilterKey(null);
          setAnchorElFilter(null);
        }}
        render={
          <form id='filter' style={{ padding: '4px 16px' }}>
            {filter && (
              <>
                {filter[activeFilterKey]?.map((e, index) => (
                  <FormControlLabel
                    key={e.value}
                    control={
                      <Checkbox
                        size='small'
                        color='primary'
                        checked={e.checked}
                        onChange={() => {
                          const temp = [...filter[activeFilterKey]];
                          const newItem = temp[index];
                          temp.splice(index, 1, { ...newItem, checked: !newItem.checked });

                          setFilter({
                            ...filter,
                            [activeFilterKey]: [...temp],
                          });
                        }}
                      />
                    }
                    label={e.text}
                    style={{ display: 'block' }}
                  />
                ))}
                {/* <Divider style={{ marginBottom: 4 }} /> */}
                <div>
                  <Button className={styles.resetFilterBtn} fullWidth variant='outlined' onClick={handleResetFilter} >Reset</Button>
                  <Button className={styles.submitFilterBtn} fullWidth variant='contained' color='secondary' onClick={handleFilter}>Lọc</Button>
                </div>
              </>
            )}
          </form>
        }
      />
    </>
  );
}

export default KPTable;
