/* eslint-disable */
import dayjs from 'dayjs';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';

import {
  formActions,
  profileActions,
  reportsActions,
  researchActions,
  rewardActions,
  scoreInformationActions,
  solutionActions,
} from '../../../../actions';
import { photoRender } from '../../../../utils/photoRender';
import { APP_SCENE, ACHIEVEMENT_TYPE, CATEGORY, KEYS } from '../../../../constants';

import styles from './HoSoDetail.module.css'
import KPTable from '../../../../components/KPTComponents/KPTable';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
import ThanhTichInsert from '../../../KVC/ThanhTichInsert/ThanhTichInsert';

import HosoAbout from '../components/about';
import HosoProfile from '../components/profile';

import { capitalizeFirstLetter } from '../../../../utils';
import { hoSoColumnMap } from '../../../../utils/qcv/hoSo.column_table';
import { columnRender } from '../../../../utils';
import MultiPreviewModal from '../../../../components/CustomComponents/MultiPreviewModal/MultiPreviewModal';
import DuyetThanhTichModalV2 from '../../../../components/CustomComponents/DuyetThanhTichModalV2/DuyetThanhTichModalV2';

export default function HoSoDetail(props) {
  const dispatch = useDispatch();

  const [dialog, setDialog] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [duyetThanhTich, setDuyetThanhTich] = useState(null);

  const user = useSelector(state => state.userReducer.user);
  const profile = useSelector(state => state.profileReducer.profile);
	const focusForm = useSelector(state => state.formReducer.focusForm);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);
  const applicationList = useSelector((state) => state.formReducer.applicationList);
  const scoreInformationList = useSelector((state) => state.scoreInformationReducer.scoreInformationList);

  const urlId = useMemo(() => {
    const arrayPath = window.location.href.split('/');
    return parseInt(arrayPath.slice(arrayPath.length - 1));
  }, []);

  useEffect(() => {
    if (applicationList) {
      const applicationDetail = applicationList.find((e) => e.id === urlId);
      dispatch(formActions.setFocusForm(applicationDetail));
    }
  }, [applicationList]);

  useEffect(() => {
    if (applicationList.length === 0) dispatch(profileActions.getProfileList());
  }, []);

  const applicationDetail = useMemo(() => {
    const detail = { ...focusForm };
    if (focusForm) {
      Object.keys(ACHIEVEMENT_TYPE).forEach((key) => {
        const _key = 'form' + capitalizeFirstLetter(ACHIEVEMENT_TYPE[key]);
        detail[ACHIEVEMENT_TYPE[key]] = detail[_key].map((e) => ({
          ...e[ACHIEVEMENT_TYPE[key]],
          note: e.note,
          score: e.score,
          accepted: e.accepted,

          [`${_key}Id`]: e.id,
          thanhTichAccepted: e.accepted,
        }));
      });

      detail[ACHIEVEMENT_TYPE.SOLUTION + CATEGORY.GTNS] = detail[ACHIEVEMENT_TYPE.SOLUTION].filter((e) => ['ptns-sc', 'ptns-scqg', 'ptns-gp'].find((type) => type === e.type));
      detail[ACHIEVEMENT_TYPE.SOLUTION] = detail[ACHIEVEMENT_TYPE.SOLUTION].filter((e) => ['sc', 'scqg', 'gp'].find((type) => type === e.type));

      detail[ACHIEVEMENT_TYPE.REWARD] = detail[ACHIEVEMENT_TYPE.REWARD].map((e) => ({ ...e, issueAt: dayjs(e?.issueAt).format('DD/MM/YYYY') }));
      detail[ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT] = detail[ACHIEVEMENT_TYPE.REWARD].filter((e) => ['g1', 'g2', 'g3-1'].find((type) => type === e.type)),
      detail[ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG] = detail[ACHIEVEMENT_TYPE.REWARD].filter((e) => ['g3-2', 'g4', 'g5'].find((type) => type === e.type)),
      detail[ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS] = detail[ACHIEVEMENT_TYPE.REWARD].filter((e) => ['nstb-g1', 'nstb-g2', 'nstb-g3'].find((type) => type === e.type)),
      detail[ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB] = detail[ACHIEVEMENT_TYPE.REWARD].filter((e) => ['nstb-n1', 'nstb-n2', 'nstb-n3', 'nstb-n4'].find((type) => type === e.type)),
      detail[ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT] = detail[ACHIEVEMENT_TYPE.REWARD].filter((e) => ['nstb-n5', 'nstb-n6', 'nstb-n7'].find((type) => type === e.type)),
      
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.DOMESTIC);
      detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL] = detail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH].filter((e) => e.filterType === KEYS.INTERNATIONAL);

      detail.reward = JSON.parse(focusForm.reward || '[]');
      detail.socialActivity = JSON.parse(detail.socialActivity || '[]');
      detail.majorAchievement = JSON.parse(detail.majorAchievement || '[]');

      const fileList = JSON.parse(detail.previewFile || '[]');
      detail.uploadGpa = fileList?.find((file) => file?.includes(('gpa')));
      detail.uploadNominator = fileList?.find((file) => file?.includes(('nominator')));
      detail.uploadCurriculum = fileList?.find((file) => file?.includes(('curriculum')));
      detail.uploadSocialActivity = fileList?.filter((file) => file?.includes(('fiveSocialActivity')));
    }
    return detail;
  }, [focusForm]);

  const actions = useMemo(() => {
    const role = user?.role;
    if (props.isSuperAdmin || role.includes('gtns_admin') || role.includes('ptns_admin')) return ['link', 'edit', 'score'];
    else if (applicationDetail.profile?.id === profile?.id) return ['link', 'detail'];
    else return ['link'];
  }, [user, props.isSuperAdmin]);

  const achievementFields = [
    {
      index: '1',
      actions,
      type: ACHIEVEMENT_TYPE.SOLUTION,
      title: 'Có bằng độc quyền sáng chế, giải pháp hữu ích thuộc các nhóm ngành xét chọn',
      data: applicationDetail[ACHIEVEMENT_TYPE.SOLUTION]?.map((e) => ({ ...e, issueAt: dayjs(e?.issueAt).format('DD/MM/YYYY') })),
    },
    {
      index: '2',
      actions,
      type: ACHIEVEMENT_TYPE.SOLUTION + CATEGORY.GTNS,
      title: 'Có sáng chế/ giải pháp hữu ích đã được chấp nhận đơn hợp lệ thuộc các nhóm ngành xét chọn',
      data: applicationDetail[ACHIEVEMENT_TYPE.SOLUTION + CATEGORY.GTNS]?.map((e) => ({ ...e, issueAt: dayjs(e?.issueAt).format('DD/MM/YYYY') })),
    },
    {
      index: '3',
      title: 'Có bài báo khoa học đăng trên tạp chí quốc tế/quốc gia thuộc các ngành xét giải',
      children: [
        {
          index: '3.1',
          actions,
          type: ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL,
          title: 'Bài báo khoa học trên tạp chí uy tín quốc tế ',
          data: applicationDetail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
        },
        {
          index: '3.2',
          actions,
          type: ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC,
          title: 'Bài báo khoa học đăng trên tạp chí trong nước (các tạp chí thuộc danh mục tính điểm của Hội đồng chức danh GSNN)',
          data: applicationDetail[ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
          defaultData: { type: 'q6' }
        },
      ],
    },
    {
      index: '4',
      actions,
      title: 'Có bài báo khoa học đăng trên hội thảo quốc tế/quốc gia thuộc các ngành xét giải',
      type: ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT,
      data: applicationDetail[ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT],
    },
    {
      index: '5',
      title: 'Tham gia nghiên cứu khoa học thuộc các ngành xét giải',
      children: [
        {
          index: '5.1',
          actions,
          type: ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB,
          title: 'Đề tài nghiên cứu khoa học cấp Bộ đã nghiệm thu đạt',
          data: applicationDetail[ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB],
        },
        {
          index: '5.2',
          actions,
          type: ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT,
          title: 'Đề tài nghiên cứu khoa học cấp Trường đã nghiệm thu đạt',
          data: applicationDetail[ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT],
        },
      ],
    },
    {
      index: '6',
      title: 'Đạt Huy chương/Giải thưởng thuộc các ngành xét giải',
      children: [
        {
          index: '6.1',
          actions,
          type: ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT,
          title: 'Huy chương/Giải thưởng cấp quốc tế',
          data: applicationDetail[ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT],
        },
        {
          index: '6.2',
          actions,
          type: ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG,
          title: 'Huy chương/Giải thưởng cấp quốc gia',
          data: applicationDetail[ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG],
        },
        {
          index: '6.3',
          actions,
          type: ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS,
          title: 'Huy chương/Giải thưởng cấp tỉnh, thành, ngành',
          data: applicationDetail[ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS],
        },
      ],
    },
  ];

  useEffect(() => {
    if (openSeasonId) dispatch(scoreInformationActions.getScoreInformationBySeasonId(openSeasonId));
  }, [openSeasonId]);

  return (
    <Grid container style={{ padding: 32 }}>
      <HosoProfile applicationDetail={applicationDetail} />
      <HosoAbout applicationDetail={applicationDetail} isSuperAdmin={props.isSuperAdmin} />
      <Typography style={{ paddingTop: 4, paddingBottom: 4 }}>
        <b>05 ảnh ứng viên tham gia các hoạt động học tập, nghiên cứu khoa học, hoạt động vì cộng đồng: </b>
        <a href='#' onClick={() => setPreviewFile(applicationDetail.uploadSocialActivity)}>
          Xem tại đây
        </a>
      </Typography>
 
      <Grid item xs={5} style={{ marginTop: 64, marginBottom: 8 }}>
        <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>THÀNH TÍCH KHOA HỌC CÔNG NGHỆ</Box>
      </Grid>
      {achievementFields.map((achivement, id) => {
        return (
          <Grid item xs={12} key={achivement.index}>
            <Box display='flex' margin='8px 0'>
              <Box className={styles.circle}>{achivement.index}</Box>
              <Typography className={styles['thanh-tich-title']} variant='h6'>{achivement.title}</Typography>
            </Box>
            {achivement.children?.map((child) => (
              <>
                <Box display='flex' margin='8px 0'>
                  <Typography variant='h6'><b>{child.index}. {child.title}</b></Typography>
                </Box>
                {child.data && (
                  <KPTable nonBorder={false} rows={child.data} columns={
                    columnRender(child, setDialog, setPreviewFile, props.isSuperAdmin ? setDuyetThanhTich : null)
                  } />
                )}
              </>
            ))}
            {achivement.data && (
              <KPTable nonBorder={false} rows={achivement.data} columns={
                columnRender(achivement, setDialog, setPreviewFile, props.isSuperAdmin ? setDuyetThanhTich : null)
              } />
            )}
          </Grid>
        );
      })}
      <Grid item xs={5} style={{ marginTop: 64, marginBottom: 8 }}>
        <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>THÀNH TÍCH TRONG LĨNH VỰC CHUYÊN NGÀNH</Box>
      </Grid>
      <Grid item xs={12}>
        <KPTable
          columns={hoSoColumnMap[APP_SCENE].thanhTichChuyenNganh(setPreviewFile)}
          rows={applicationDetail.majorAchievement || []}
        />
      </Grid>
      <Grid item xs={5} style={{ marginTop: 64, marginBottom: 8 }}>
        <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>THÀNH TÍCH KHEN THƯỞNG</Box>
      </Grid>
      <Grid item xs={12}>
        <KPTable
          columns={hoSoColumnMap[APP_SCENE].thanhTichBangKhen(setPreviewFile)}
          rows={applicationDetail.reward || []}
        />
      </Grid>
      <Grid item xs={5} style={{ marginTop: 64, marginBottom: 8 }}>
        <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>THÀNH TÍCH HOẠT ĐỘNG ĐOÀN, ĐỘI, HỘI</Box>
      </Grid>
      <Grid item xs={12}>
        <KPTable
          columns={hoSoColumnMap[APP_SCENE].hoatDongCongDong(setPreviewFile)}
          rows={applicationDetail.socialActivity || []}
        />
      </Grid>
      <ThanhTichInsert dialog={dialog} setDialog={setDialog} />
      <PreviewModal open={!!previewFile && typeof(previewFile) === 'string'} url={previewFile} handleClose={() => setPreviewFile(null)} />
      <MultiPreviewModal open={!!previewFile && typeof(previewFile) !== 'string'} url={previewFile} handleClose={() => setPreviewFile(null)} />
      <DuyetThanhTichModalV2
        info={duyetThanhTich}
        scoreInformationList={scoreInformationList}
        handleClose={() => setDuyetThanhTich(null)}
      />
    </Grid>
  );
}
