export * from './api';
export * from './doi';
export * from './autoScore';
export * from './columnRender';
export * from './dateFormatter';
export * from './acceptAchivement';

export * from './qcv.exportExcel';

export * from './kvc.exportExcel';
export * from './kvc.achievements';

export * from './gtns.exportExcel';

export const lowercaseFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getWordCount(htmlData) {
    if (htmlData === '' || htmlData === ' ' || !htmlData) return 0;
    return htmlData.replace(/<(?:.|\s)*?>/g, '').split(' ').length;    
}
