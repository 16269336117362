import { REPORTS_TYPE, FORM_TYPE } from "../constants";

const initialState = {
  loading: false,
  totalReportList: [],
  personalReportList: [],
};

const sortFunc = (a, b) => a.id - b.id;

export function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case REPORTS_TYPE.GET_ALL_REPORT_LOADING:
    case REPORTS_TYPE.GET_REPORTS_BY_PROFILE_ID_LOADING:
    case REPORTS_TYPE.INSERT_REPORTS_LOADING:
    case REPORTS_TYPE.UPDATE_REPORT_LOADING:
    case REPORTS_TYPE.DELETE_REPORT_LOADING:
    case REPORTS_TYPE.UPDATE_REPORT_ACCEPTED_LOADING:
      return { ...state, loading: true };

    case REPORTS_TYPE.GET_ALL_REPORT_FAILURE:
    case REPORTS_TYPE.GET_REPORTS_BY_PROFILE_ID_FAILURE:
    case REPORTS_TYPE.INSERT_REPORTS_FAILURE:
    case REPORTS_TYPE.UPDATE_REPORT_FAILURE:
    case REPORTS_TYPE.DELETE_REPORT_FAILURE:
    case REPORTS_TYPE.UPDATE_REPORT_ACCEPTED_FAILURE:
      return { ...state, loading: false };

    case REPORTS_TYPE.GET_ALL_REPORT_SUCCESS:
      return { ...state, totalReportList: action.payload.sort(sortFunc), loading: false };

    case REPORTS_TYPE.GET_REPORTS_BY_PROFILE_ID_SUCCESS:
      return { ...state, personalReportList: action.payload.sort(sortFunc), loading: false };

    case REPORTS_TYPE.INSERT_REPORTS_SUCCESS:
      return {
        ...state,
        totalReportList: [...state.personalReportList, action.payload].sort(sortFunc),
        personalReportList: [...state.personalReportList, action.payload].sort(sortFunc),
        loading: false,
      };

    case REPORTS_TYPE.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        totalReportList: state.totalReportList
          ? [...state.totalReportList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        personalReportList: state.personalReportList
          ? [...state.personalReportList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        loading: false,
      };

    case REPORTS_TYPE.DELETE_REPORT_SUCCESS:
      return {
        ...state,
        personalReportList: state.personalReportList.filter((e) => e.id !== action.id).sort(sortFunc),
        loading: false,
      };

    case REPORTS_TYPE.UPDATE_REPORT_ACCEPTED_SUCCESS:
      return {
        ...state,
        // totalReportList: state.totalReportList ? [
        //   ...state.totalReportList?.filter((e) => e.id !== action.payload.id),
        //   action.payload
        // ].sort(sortFunc) : null,
        // personalReportList: state.personalReportList ? [
        //   ...state.personalReportList?.filter((e) => e.id !== action.payload.id),
        //   action.payload
        // ].sort(sortFunc) : null,
        loading: false,
      };

    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        personalReportList: action.payload.scientificReports.sort(sortFunc),
      };
    default:
      return state;
  }
}
