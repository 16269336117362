import { HEADERS } from '.';

export const SEASON_API = {
  getSeasonList: () => ({
    endPoint: '/api/v1/season/list',
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  updateSeason: () => ({
    endPoint:'/api/v1/season/update',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  })
}

export const SEASON_TYPE = {
  GET_SEASON_LIST_LOADING: 'GET_SEASON_LIST_LOADING',
  GET_SEASON_LIST_SUCCESS: 'GET_SEASON_LIST_SUCCESS',
  GET_SEASON_LIST_FAILURE: 'GET_SEASON_LIST_FAILURE',

  UPDATE_SEASON_LOADING: 'UPDATE_SEASON_LOADING',
  UPDATE_SEASON_SUCCESS: 'UPDATE_SEASON_SUCCESS',
  UPDATE_SEASON_FAILURE: 'UPDATE_SEASON_FAILURE'
};