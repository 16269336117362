import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { ROUTER } from '../../../constants';
import { userActions } from '../../../actions';
import Loading from '../../../components/CustomComponents/Loading';
import KPTextform from '../../../components/KPTComponents/KPTextform';

function DangNhap() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState({ showPassword: false });
  const [openReset, setOpenReset] = useState(false);
  const [apiCalled, setApiCalled] = useState(null);
  const [resetEmail, setResetEmail] = useState(null);

  const loading = useSelector(state => state.userReducer.loading);
  const loadingReset = useSelector(state => state.userReducer.loadingReset);

  const refreshToken = localStorage.getItem('refresh_token');

  useEffect(() => {
    if (refreshToken) navigate(ROUTER.HOME);
  }, [refreshToken]);

  const handleCloseDialog = () => {
    setOpenReset(false);
    setApiCalled(null);
    setResetEmail(null);
  }

  const textForm = [
    { key: 'email', width: 12, label: 'Email', required: true },
    { key: 'password', width: 12, label: 'Mật khẩu', type: values.showPassword ? 'passwordText' : 'password', required: true },
    { key: 'submit', width: 12, label: 'Đăng nhập', type: 'submit' },
  ];

  const resetForm = [
    { key: 'email', width: 12, label: 'Email cần khôi phục', value: resetEmail, required: true },
    { key: 'submit', width: 12, label: 'Khôi phục mật khẩu', type: 'submit' },
  ];

  const handleChangeForm = (name, event) => {
    if (name === 'visible') {
      setValues({ ...values, showPassword: !values.showPassword });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  }

  const handleSubmit = () => dispatch(userActions.login(navigate, values.email, values.password));

  return (
    <>
      <Grid container spacing={1} alignContent='center' justifyContent='center'>
        <Grid item xs={12} md={6} lg={5}>
          <Card style={{ padding: 16, backgroundColor: '#E8F0FD' }}>
            <Typography variant='h4' style={{ textAlign: 'center', fontFamily: 'SFProDisplay-bold' }}>
              <b>Đăng nhập</b>
            </Typography>
            <KPTextform
              values={values}
              textForm={textForm}
              setValues={setValues}
              handleSubmit={handleSubmit}
              handleChangeForm={handleChangeForm}
            />
          </Card>
          <Grid container>
            <Grid item xs={6} style={{ marginTop: 8 }}>
              <NavLink to={ROUTER.DANG_KY} style={{ color: 'blue' }}>Đăng ký tài khoản mới</NavLink>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 8 }}>
              <Typography style={{
                color: 'blue',
                textAlign: 'end',
                cursor: 'pointer',
                textDecoration: 'underline',
              }} onClick={() => setOpenReset(true)}>Quên mật khẩu?</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={openReset} maxWidth='xs' fullWidth>
        <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>Khôi phục mật khẩu</Typography>
            <IconButton onClick={handleCloseDialog}><Close /></IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 16 }}>
          {loadingReset ? (
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
              <CircularProgress />
              <Typography style={{ textAlign: 'center' }}>
                Hãy đợi trong giây lát
              </Typography>
            </Box>
          ) : (
            apiCalled ? (
              <Box display='flex' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
                <Typography style={{ textAlign: 'center' }}>
                  {`Mật khẩu mới đã được gửi vào email: ${resetEmail}!`}
                </Typography>
              </Box>
            ) : (
              apiCalled === null ? (
                <Box display='flex' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
                  <Typography style={{ textAlign: 'center' }}>
                    Nhập email của bạn tại đây và hệ thống sẽ gửi mật khẩu mới vào hòm thư của bạn!
                  </Typography>
                </Box>
              ) : (
                <Box display='flex' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
                  <Typography style={{ textAlign: 'center' }}>
                    Khôi phục mật khẩu thất bại!
                  </Typography>
                </Box>
              )
            )
          )}
          <KPTextform
            textForm={resetForm}
            setValues={setValues}
            handleSubmit={() => dispatch(userActions.resetByEmail(resetEmail)).then((res) => setApiCalled(res))}
            handleChangeForm={(_, event) => setResetEmail(event.target.value)}
          />
        </DialogContent>
      </Dialog>

      <Loading open={loading} />
    </>
  );
}

export default DangNhap;
