import { apiCall } from '../utils';
import { FORM_API, FORM_TYPE } from '../constants';

import { userActions } from '.';
const { notification } = userActions;

export const formActions = {
  insertApplicationForm,
  updateApplicationForm,
  filterApplicationForm,
  filterApplicationFormSearch,
  approveApplicationForm,
  getApplicationByProfileId,
  setFocusForm,
  updateFocusForm,
  updateFocusFormByData,
  thongKe,
  updateBCD,
  getApplicationFormv2,
};

function getApplicationByProfileId(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.getApplicationByProfileId(id);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy thông tin hồ sơ thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy thông tin hồ sơ thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: FORM_TYPE.GET_APPLICATION_BY_PROFILE_ID_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.GET_APPLICATION_BY_PROFILE_ID_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.GET_APPLICATION_BY_PROFILE_ID_FAILURE, error };
  }
}

function getApplicationFormv2(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.getApplicationFormv2(id);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy thông tin hồ sơ thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy thông tin hồ sơ thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: FORM_TYPE.GET_APPLICATION_FORM_V2_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.GET_APPLICATION_FORM_V2_FAILURE, error };
  }
}

function filterApplicationForm(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.filterApplicationForm();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      if (response.data.data.length > 0) {
        dispatch(success(response.data.data));
      } else {
        dispatch(success([]));
      }
      // dispatch(notification({ message: 'Lấy thông tin hồ sơ thành công', type: 'success' }));
      return response.data.data;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy thông tin hồ sơ thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() {
    return { type: FORM_TYPE.FILTER_APPLICATION_FORM_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.FILTER_APPLICATION_FORM_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.FILTER_APPLICATION_FORM_FAILURE, error };
  }
}

function filterApplicationFormSearch(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.filterApplicationForm();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      if (response.data.data.length > 0) {
        dispatch(success(response.data.data));
      } else {
        dispatch(success(null));
      }
      return response.data.data;
    } else {
      dispatch(failure(error));
      return false;
    }
  };

  function loading() {
    return { type: FORM_TYPE.FILTER_APPLICATION_FORM_SEARCH_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.FILTER_APPLICATION_FORM_SEARCH_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.FILTER_APPLICATION_FORM_SEARCH_FAILURE, error };
  }
}

function insertApplicationForm(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.insertApplicationForm();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() {
    return { type: FORM_TYPE.INSERT_APPLICATION_FORM_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.INSERT_APPLICATION_FORM_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.INSERT_APPLICATION_FORM_FAILURE, error };
  }
}

function updateApplicationForm(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.updateApplicationForm();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() {
    return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_FAILURE, error };
  }
}

function approveApplicationForm(id, payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.approveApplicationForm(id);
    const { response, error } = await apiCall({ ...api, payload });

    const message = payload.status === 'need_update' ? 'Ghi chú hồ sơ' : 'Xác nhận hồ sơ';

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));

      dispatch(notification({ message: `${message} thành công`, type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: `${message} thất bại`, type: 'error' }));
    }
  };

  function loading() {
    return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_FAILURE, error };
  }
}

function thongKe(seasonId) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.thongKe(seasonId);
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(error));
    }
  };

  function loading() {
    return { type: FORM_TYPE.THONG_KE_TAI_KHOAN_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.THONG_KE_TAI_KHOAN_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.THONG_KE_TAI_KHOAN_FAILURE, error };
  }
}

function updateBCD(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.updateBCD();
    const { response, error } = await apiCall({ ...api, payload });
    if (!error && response.status === 200 && response.data.success) {
      dispatch(notification({ message: 'Duyệt hồ sơ thành công', type: 'success' }));
      setTimeout(() => window.location.reload(), 1000);
    } else {
      dispatch(notification({ message: 'Duyệt hồ sơ thất bại', type: 'error' }));
    }
  };

  function loading() {
    return { type: FORM_TYPE.UPDATE_BCD_APPLICATION_FORM_LOADING };
  }
}

function setFocusForm(form) {
  return { type: FORM_TYPE.SET_FOCUS_FORM, payload: form };
}

function updateFocusForm(name, payload) {
  return { type: FORM_TYPE.UPDATE_FOCUS_FORM, name, payload };
}

function updateFocusFormByData(name, child, payload) {
  return { type: 'UPDATE_FOCUS_FORM_BY_DATA', name, child, payload };
}
