import dayjs from 'dayjs';
import { Parser } from 'htmlparser2';

export const dateFormatter = (value) => {
  const dateForm = value?.replaceAll('/', '');
  const date = dateForm?.substr(0, 2);
  const month = dateForm?.substr(2, 2);
  const year = dateForm?.substr(-4);
  return(value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ssZ') : null);
}

export const numRender = (num) => {
  if (num >= 10) return num;
  return `0${num}`;
}

export function convertHtmlToRichText(_html) {
  const html = _html
    .replaceAll('<ul>', '')
    .replaceAll('</ul>', '')
    .replaceAll('<ol>', '')
    .replaceAll('</ol>', '')
    .replaceAll('</p>', '\n</p>')
    .replaceAll('<li>', '<p>• ')
    .replaceAll('</li>', '\n</p>')
  const richText = [];
  let currentText = '';
  let currentStyle = {};

  const parser = new Parser({
    onopentag(name) {
      if (['strong', 'b', 'i', 'u', 'p'].includes(name)) {
        let item = { text: currentText }
        if (Object.keys(currentStyle).length > 0) item.font = currentStyle;
        if (item.text !== '') richText.push(item);
        currentText = '';
        currentStyle = {};
      }
      switch (name) {
        case 'b':
        case 'strong':
          currentStyle.bold = true;
          break;
        case 'i':
          currentStyle.italic = true;
          break;
        case 'u':
          currentStyle.underline = true;
          break;
        default:
          break;
      }
    },
    ontext(text) {
      // console.log('text', text);
      currentText += text;
    },
    onclosetag(tagname) {
      if (['strong', 'b', 'i', 'u', 'p'].includes(tagname)) {
        let item = { text: currentText }
        if (Object.keys(currentStyle).length > 0) item.font = currentStyle;
        if (item.text !== '') richText.push(item);
        currentText = '';
        currentStyle = {};
      }
    },
  }, { decodeEntities: true });

  parser.write(html);
  parser.end();

  return richText;
}
