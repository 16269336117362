import { HEADERS } from '.';

export const FILE_API = {
  importFile: (id) => ({
    endPoint: `/api/v1/file/storage/${id}?type=avatar`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'POST',
  }),
  importMultiFile: (id) => ({
    endPoint: `/api/v1/file/storage/multi/${id}?type=avatar`,
    headers: HEADERS.UPLOAD_FILE_HEADER,
    method: 'POST',
  }),
}

export const FILE_TYPE = {
  IMPORT_FILE_LOADING: 'IMPORT_FILE_LOADING',
  IMPORT_FILE_SUCCESS: 'IMPORT_FILE_SUCCESS',
  IMPORT_FILE_FAILURE: 'IMPORT_FILE_FAILURE',
};
