import React from 'react';

import BaiBaoTable from '../../../containers/QCV/BaiBao/BaiBaoTable';
import BaiBaoButton from '../../../containers/QCV/BaiBao/BaiBaoButton';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';
import { Typography } from '@material-ui/core';

function BaiBao(props) {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  return (
    <>
      <BaiBaoButton filterType={filterType} form={props.form} />
      <ThanhTichAlert extraBefore={
        (props.form === "domestic") && (
          <Typography variant="body1">
            Chỉ tính bài báo thuộc danh mục tính điểm của Hội đồng GSNN
          </Typography>
        )
      } />
      <BaiBaoTable filterType={filterType} form={props.form} type='update' />
    </>
  );
}

export default BaiBao;
