import React from 'react';

import BangSachButton from '../../../containers/QCV/SachChuyenKhao/BangSachButton';
import BangSachTable from '../../../containers/QCV/SachChuyenKhao/BangSachTable';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';

export default function SachChuyenKhao() {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  return (
    <>
      <BangSachButton filterType={filterType}/>
      <ThanhTichAlert />
      <BangSachTable filterType={filterType} type='update'/>
    </>
  );
}


