import { apiCall } from '../utils';
import { PROFILE_API, PROFILE_TYPE } from '../constants';

import { userActions } from '.';
const { notification } = userActions;

export const profileActions = {
  getProfileByUserId,
  upsertProfile,
  getProfileList,
  getProfileFilterForm,
};

function getProfileByUserId(id) {
  return async(dispatch) => {
    dispatch(loading());

    const api = PROFILE_API.getProfileByUserId(id);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(error));
    }
  };

  function loading() { return { type: PROFILE_TYPE.GET_PROFILE_BY_USER_ID_LOADING } }
  function success(profile) { return { type: PROFILE_TYPE.GET_PROFILE_BY_USER_ID_SUCCESS, profile } }
  function failure(error) { return { type: PROFILE_TYPE.GET_PROFILE_BY_USER_ID_FAILURE, error } }
}

function upsertProfile(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = PROFILE_API.upsertProfile();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Cập nhật thông tin thành công', type: 'success' }));
      return response.data.data;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật thông tin thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: PROFILE_TYPE.UPSERT_PROFILE_LOADING } }
  function success(profile) { return { type: PROFILE_TYPE.UPSERT_PROFILE_SUCCESS, profile } }
  function failure(error) { return { type: PROFILE_TYPE.UPSERT_PROFILE_FAILURE, error } }
}

function getProfileList(){
  return async(dispatch) => {
    dispatch(loading());
  
    const api = PROFILE_API.getProfileList();
    const { response, error} = await apiCall({...api});

    if(!error && response.status === 200 && response.data.success){
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Danh sách hồ sơ không thể hiển thị', type: 'error' }));
    }

  }; 
  function loading() { return { type: PROFILE_TYPE.GET_PROFILE_LIST_LOADING } }
  function success(profile) { return { type: PROFILE_TYPE.GET_PROFILE_LIST_SUCCESS, profile } }
  function failure(error) { return { type: PROFILE_TYPE.GET_PROFILE_LIST_FAILURE, error } }
}

function getProfileFilterForm(payload){
  return async(dispatch) => {
    dispatch(loading());
  
    const api = PROFILE_API.getProfileFilterForm();
    const { response, error} = await apiCall({...api,payload});

    if(!error && response.status === 200 && response.data.success){
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Danh sách hồ sơ không thể hiển thị', type: 'error' }));
    }

  }; 
  function loading() { return { type: PROFILE_TYPE.GET_PROFILE_FILTER_FORM_LOADING}}
  function success(profile) { return { type: PROFILE_TYPE.GET_PROFILE_FILTER_FORM_SUCCESS, profile}}
  function failure (error) { return { type: PROFILE_TYPE.GET_PROFILE_FILTER_FORM_FAILURE, error}}
}

