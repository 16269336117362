import React from 'react';

import { photoRender } from '../../../utils/photoRender';

function AnhChanDung(props) {
  return (
    <img
      alt='anh-chan-dung'
      src={photoRender(props.src)}
      style={{
        objectFit: 'cover',
        width: props.width,
        height: `calc(${props.width} / 2 * 3)`,
      }}
    />
  );
}

export default AnhChanDung;
