import React from "react";
import { Add, Delete, Visibility, Edit, Close } from "@material-ui/icons";
import {
  Grid,
  Button,
  Typography,
  Card,
  OutlinedInput,
  IconButton,
  CardActions,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@material-ui/core";

import KPTextfield from "../../../components/KPTComponents/KPTextfield";
import KPTimepicker from "../../../components/KPTComponents/KPTimepicker";
import { useDispatch } from "react-redux";
import { userActions } from "../../../actions";


export default function InputActive(props) {
  const { notification } = userActions;

  const dispatch = useDispatch();

  const [ind, setInd] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [removeFileValue, setRemoveFileValue] = React.useState([]);

  const handleOpenDelete = () => setOpenDelete(!openDelete);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <b>{props?.label}</b>
        </Typography>
      </Grid>
      {props?.values?.map((value, index) => (
        <React.Fragment key={`value-${index}`}>
          {props.fields?.map((field) => (
            <React.Fragment key={`${field.key}-${index}`}>
              {field?.type == "file" ? (
                <Grid item xs={field.width} style={{ margin: "4px 0" }}>
                  <Typography className="kpt-textfield-label">
                    {field.label}
                  </Typography>
                  {(() => {
                    //sua noi dung theo them bang khen giay khen
                    const file = field.key;
                    var value = props.values[index][file];
                    if (typeof value !== 'string') value = "";

                    return (
                      <>
                        {value ? (
                          <>
                            <OutlinedInput
                              id={file}
                              fullWidth
                              size="small"
                              value={value.replace(" ", "_").slice(value.indexOf("_") + 1)}
                              variant="outlined"
                              style={{
                                paddingRight: "0",
                                backgroundColor: "white",
                                height: "38px",
                              }}
                              endAdornment={
                                <Grid
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    padding: "0 12px",
                                  }}
                                >
                                  <IconButton
                                    color="primary"
                                    title="Chi tiết minh chứng"
                                    edge="end"
                                    onClick={() => {
                                      props.setPreview(value);
                                    }}
                                  >
                                    <Visibility />
                                  </IconButton>
                                  <IconButton
                                    title="Chỉnh sửa minh chứng"
                                    edge="end"
                                    onClick={() => {
                                      // props.handleFileChange(null, `${props.id}.${index}.${field.key}`);
                                      props.handleChangeForm(
                                        `${props.id}.${index}.${file}`, null, "removeFile", props.values, props.setValues
                                      );
                                      setRemoveFileValue([
                                        ...removeFileValue,
                                        {
                                          index,
                                          [file]: props.values[index][file],
                                        },
                                      ]);
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Grid>
                              }
                            />
                          </>
                        ) : (
                          <Card
                            style={{
                              height: 22,
                              borderColor: "#b3b9c3",
                              padding: "6.5px 8px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <input
                              type="file"
                              // style={{ width: "70%" }}
                              value={props?.values?.[`${props.id}.${index}.${field.key}`]}
                              onChange={(e) => {
                                
                                if (e.target.files[0].size > 10485760) {
                                  console.log(props);
                                  e.target.value = null
                                  dispatch(notification({ message: 'Dung lượng vượt quá hơn 10MB', type: 'error' }));
                                } else {
                                  props.handleFileChange(
                                    e.target.files[0],
                                    `${props.id}.${index}.${field.key}`,
                                  );
                                }
                              }}
                            />
                            <CardActions>
                              {removeFileValue.find(
                                (elm) => elm?.index === index
                              ) && (
                                  <Tooltip title="Hủy chọn">
                                    <IconButton
                                      edge="end"
                                      onClick={(e) => {
                                        e.target.value = removeFileValue.find(
                                          (elm) => elm?.index === index
                                        )[file];

                                        props.handleChangeForm(
                                          `${props.id}.${index}.${file}`, e, "", props.values, props.setValues
                                        );
                                        // props.handleFileChange(null, `${props.id}.${index}.${field.key}`);
                                        // console.log(e.target.file)
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </CardActions>
                          </Card>
                        )}
                      </>
                    );
                  })()}
                </Grid>
              ) : (
                <>
                  {field.type === "date" ? (
                    <Grid item xs={field.width} style={{ margin: "4px 0" }}>
                      <KPTimepicker
                        {...field}
                        id={`${props.id}.${index}.${field.key}`}
                        value={value[field.key] || 'dd/mm/yyyy'}
                        setValue={(e) => props.handleChangeForm(
                          `${props.id}.${index}.${field.key}`, e, 'date', props.values, props.setValues,
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={field.width} style={{ margin: "4px 0" }}>
                      <KPTextfield
                        {...field}
                        id={`${props.id}.${index}.${field.key}`}
                        value={value[field.key]}
                        label={field.label}
                        options={field.options}
                        handleChangeForm={(name, event, type) => props.handleChangeForm(
                          name, event, type, props.values, props.setValues
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={1}>
            <Button
              fullWidth
              color="secondary"
              variant="outlined"
              style={{ marginTop: 33, backgroundColor: "#fff" }}
              onClick={() => {
                setInd(index);
                handleOpenDelete();
              }}
            >
              <Delete />
            </Button>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            console.log(props.setValues([...props.values, {}]));
            props.setValues([...props.values, {}]);
          }}
        >
          <Add />
        </Button>
      </Grid>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={openDelete}
      >
        <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
          <Typography variant='h5'>Bạn chắc chắn muốn xóa nội dung thành tích tích này?</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography><b>Lưu ý: </b>Sau khi lưu hồ sơ hoặc nộp hồ sơ nội dung thành tích này sẽ không còn.</Typography>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button variant='outlined' color='secondary' onClick={handleOpenDelete}>
            Huỷ
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              props.setValues(
                props.values.filter((_, elmId) => ind !== elmId)
              )
              handleOpenDelete()
            }}
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
