import React, { useEffect, useState } from 'react';
import {
  Box,
	Button,
	Divider,
	IconButton,
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import DoanhNghiepForm from '../DoanhNghiepForm';

import { fileActions, techCompanyActions } from '../../../../actions';

export default function DoanhNghiepUpdate(props) {
	const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);

	const [values, setValues] = useState(null);
	const [disabled, setDisabled] = useState(true);

	const handleSubmit = () => {
		if (values?.previewFile?.[0]) {
			dispatch(fileActions.importPreviewFile(profileId, values?.previewFile[0]))
			.then((response) => {
				dispatch(techCompanyActions.updateTechCompany({
					...values,
					link: response,
          value: parseInt(values?.value?.toString()?.replaceAll('.', '')) || null,
				}));
			});
    } else {
			dispatch(techCompanyActions.updateTechCompany({
				...values,
				value: parseInt(values?.value?.toString()?.replaceAll('.', '')) || null,
			}));
    }
		props.onClose();
	}

	const handleEdit = () => {setDisabled(false)};

	const handleClose = () => {
		if (disabled) props.onClose();
		else setDisabled(true);
	}

	useEffect(() => {
		setDisabled(true);
		setValues(props.detail);
	}, [props.detail]);

	return (
		<Dialog
			fullWidth
			maxWidth='sm'
			open={!!(props.detail)}
		>
			<DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography variant='h5'>Chi tiết giải thưởng</Typography>
					<IconButton onClick={props.onClose}><Close /></IconButton>
				</Box>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<DoanhNghiepForm
					id='giai-thuong'
					values={values}
					disabled={disabled}
					setValues={setValues}
					handleSubmit={handleSubmit}
				/>
			</DialogContent>
			<DialogActions style={{ padding: '8px 24px' }}>
				<Button variant='outlined' onClick={handleClose}>Huỷ</Button>
				{disabled ? (
					<Button variant='contained' color='secondary' onClick={handleEdit}>Chỉnh sửa</Button>
				) : (
					<Button variant='contained' color='secondary' onClick={handleSubmit}>Xác nhận</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}