import React from 'react';
import { Chip } from '@material-ui/core';

function StatusChip({ status }) {
  var label = 'Đang hoàn thiện', color = 'default', variant = 'outlined';
  if (status === 'in_review') {
    label = 'Đã nộp';
    color = 'primary';
  } else if (status === 'need_update') {
    label = 'Đã bị loại';
    color = 'secondary';
  } else if (status === 'approved') {
    label = 'Đã được duyệt';
    color = 'primary';
  }

  if (status === 'need_update' || status === 'approved') {
    return <Chip size='small' color={color} label={label} style={{ width: '100%' }} />
  }

  return <Chip size='small' variant={variant} color={color} label={label}style={{ width: '100%' }} />
}

export default StatusChip;
