import React from "react";
import { ACHIEVEMENT_TYPE, CATEGORY, KEYS, RESEARCH_CONSTANTS } from "../../../constants";

const autoScoreKVC = (info, scoreInformationList, defaultMaxPoint=null) => {
  const dict = {
    [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: {
      q1: '1.1-a',
      q2: '1.1-b',
      q3: '1.1-c',
      q4: '1.1-d',
    },
    [ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL]: {
      q1: '1.2-a',
      q2: '1.2-b',
      q3: '1.2-c',
      q4: '1.2-d',
    },
    [ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL]: {
      s1: '1.3-a',
      s2: '1.3-b',
      s3: '1.3-c',
      s4: '1.3-d',
    },
    [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: '2.1',
    [ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC]: '2.2',
    [ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC]: {
      s5: '2.3-a',
      s6: '2.3-b',
      s7: '2.3-c',
      s8: '2.3-d',
    },
    [ACHIEVEMENT_TYPE.RESEARCH_PROJECT]: {
      domestic: '2.4-a',
      ministry: '2.4-b',
    },
  };

  var code;

  switch (info.renderType) {
    case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
      code = dict?.[info.renderType]?.[info.type];
      break;
    case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
      code = dict?.[info.renderType];
      break;
    case ACHIEVEMENT_TYPE.RESEARCH_PROJECT:
      if (info.type === 'd1') {
        // Chỉ chủ trì mới được điểm
        code = dict?.[info.renderType]?.[info.level];
      }
      break
    default:
      break;
  }

  // B1: Tìm xem thành tích thuộc hạng mục gì
  var text = [];
  var found = {...scoreInformationList.find((e) => e.code === code)};

  if (found && found.value) {
    found.defaultMaxPoint = found.value;
    found.maxScore = found.value;

    console.log(defaultMaxPoint);

    if (defaultMaxPoint != null) {
      found = { ...found, value: defaultMaxPoint, maxScore: defaultMaxPoint };
    }

    text = [
      <React.Fragment key='found'>
        {'Thành tích thuộc hạng mục '}
        <b>{found.description}</b>
        {', với số điểm: '}
        <b>{found.defaultMaxPoint}. </b>
      </React.Fragment>
    ];
  } else {
    const p = defaultMaxPoint;
    found = { value: p, score: p, maxScore: p, defaultMaxPoint };
    text.push(<span>Thành tích không thuộc hạng mục nào, hội đồng thư ký tự cho điểm.</span>);
  }

  if (defaultMaxPoint != null && (defaultMaxPoint != found.defaultMaxPoint)) {
    text.push(
      <React.Fragment>
        (Hội đồng đã sửa khung điểm thành <b>{defaultMaxPoint}</b>)
      </React.Fragment>
    );
  }

  // B2: Chia điểm theo số tác giả
  switch (info.renderType) {
    case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL: {
      // 1/2 tổng điểm chia đều cho số tác giả, 1/2 chia đều cho số tác giả chính
      const scoreForEveryone = (found.value / 2) / info.numberAuthor;
      const ScoreForEachMain = (info.role === 'main') ? (found.value / 2) / info.numberMain : 0;
      found.value = Math.round((scoreForEveryone + ScoreForEachMain) * 100) / 100;
      text.push(
        <React.Fragment>
          <br /><br />
          <span>Thành tích có </span><b>{info.numberAuthor}</b><span> tác giả tham gia, với </span>
          <span><b>{info.numberMain}</b> </span>
          <span>{RESEARCH_CONSTANTS.ROLE_OPTIONS[0].title}. Trong đó, ứng viên là </span>
          <span><b>{RESEARCH_CONSTANTS.ROLE_OPTIONS.find((role) => role.value === info.role)?.title}</b></span>
          <span> nên được hưởng </span>
          <span>
            <b>
              {info.role === 'main' ? (
                `1/2 x 1/${info.numberMain} + 1/2 x 1/${info.numberAuthor} = ${
                  Math.round((((1/2) / info.numberMain + (1/2) / info.numberAuthor)) * 100) / 100
                }`
              ) : (
                `1/2 x 1/${info.numberAuthor} = ${Math.round((1/2 * 1 / info.numberAuthor) * 100) / 100}`
              )}
            </b>
          </span>
          <span> tổng số điểm.</span>
        </React.Fragment>
      );
      break;
    }
    default:
      break;
  }

  text.push(
    <React.Fragment>
      <br /><br />
      <span>Vậy ứng viên được tối đa: <b>{found.value} điểm.</b></span>
    </React.Fragment>
  );

  return { ...found, text }
}

const autoScoreGTNS = (info, scoreInformationList, defaultMaxPoint=null) => {
  const dict = {
    [ACHIEVEMENT_TYPE.SOLUTION]: {
      sc: '3.1',
      scqg: '3.2',
      gp: '3.3',
    },
    [ACHIEVEMENT_TYPE.SOLUTION + CATEGORY.GTNS]: {
      'ptns-sc': '4.1',
      'ptns-scqg': '4.2',
      'ptns-gp': '4.3',
    },
    [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: {
      main: {
        q1: '5.1.1.a',
        q2: '5.1.2.a',
        q3: '5.1.3.a',
        q4: '5.1.4.a',
        scopus: '5.1.4.a',
      },
      member: {
        q1: '5.1.1.b',
        q2: '5.1.2.b',
        q3: '5.1.3.b',
        q4: '5.1.4.b',
        scopus: '5.1.4.b',
      },
    },
    [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: {
      main: { q6: '5.2.1' },
      member: { q6: '5.2.2' },
    },
    [ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT]: {
      main: {
        b1: '6.1.1',
        q1: '6.1.1',
        q2: '6.1.1',
        q3: '6.1.1',
        q4: '6.1.1',
        'b2-1': '6.3.1',
        b3: '6.2.1',
      },
      member: {
        b1: '6.1.2',
        q1: '6.1.2',
        q2: '6.1.2',
        q3: '6.1.2',
        q4: '6.1.2',
        'b2-1': '6.3.2',
        b3: '6.2.2',
      },
    },
    [ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB]: {
      main: { 'nstb-n1': '7.1.1.a', 'nstb-n2': '7.1.2.a', 'nstb-n3': '7.1.3.a' },
      member: { 'nstb-n1': '7.1.1.b', 'nstb-n2': '7.1.2.b', 'nstb-n3': '7.1.3.b' }
    },
    [ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT]: {
      main: { 'nstb-n5': '7.2.1.a', 'nstb-n6': '7.2.2.a', 'nstb-n7': '7.2.3.a' },
      member: { 'nstb-n5': '7.2.1.b', 'nstb-n6': '7.2.2.b', 'nstb-n7': '7.2.3.b' }
    },
    [ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT]: { g1: '9.1.1', g2: '9.1.2', 'g3-1': '9.1.3' },
    [ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG]: { 'g3-2': '9.2.1', g4: '9.2.2', g5: '9.2.3' },
    [ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS]: { 'nstb-g1': '9.3.1', 'nstb-g2': '9.3.2', 'nstb-g3': '9.3.3' },
  };

  var code;

  switch (info.renderType) {
    case ACHIEVEMENT_TYPE.SOLUTION:
    case ACHIEVEMENT_TYPE.SOLUTION + CATEGORY.GTNS:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQT:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.HTQG:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.HTCS:
      code = dict?.[info.renderType]?.[info.type];
      break;
    case ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCB:
    case ACHIEVEMENT_TYPE.REWARD + KEYS.NCKHCT:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
    case ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
      code = dict?.[info.renderType]?.[info.role]?.[info.type];
      break;
    default:
      break;
  }

  // B1: Tìm xem thành tích thuộc hạng mục gì
  var text = [];
  var found = scoreInformationList.find((e) => e.code === code);

  if (found) {
    found = { ...found };
    found.defaultMaxPoint = found.value;
    found.maxScore = found.value;

    if (defaultMaxPoint != null) {
      found = { ...found, value: defaultMaxPoint, maxScore: defaultMaxPoint };
    }

    text = [
      <React.Fragment key='found'>
        {'Thành tích thuộc hạng mục '}
        <b>{found.description}</b>
        {', với số điểm: '}
        <b>{found.defaultMaxPoint}. </b>
      </React.Fragment>
    ];

    if (defaultMaxPoint != null && (defaultMaxPoint != found.defaultMaxPoint)) {
      text.push(
        <React.Fragment>
          (Hội đồng đã sửa khung điểm thành <b>{defaultMaxPoint}</b>)
        </React.Fragment>
      );
    }

    text.push(
      <React.Fragment>
        <br /><br />
        <span>Vậy ứng viên được tối đa: <b>{found.value} điểm.</b></span>
      </React.Fragment>
    );
  } else {
    const p = defaultMaxPoint;
    found = { value: p, score: p, maxScore: p, defaultMaxPoint };
    text.push(<span>Thành tích không thuộc hạng mục nào, hội đồng thư ký tự cho điểm.</span>);
  }

  return { ...found, text }
}

export const autoScore = (scene, info, scoreInformationList, defaultMaxPoint=null) => {
  if (scene.isKhueVanCac) {
    return autoScoreKVC(info, scoreInformationList, defaultMaxPoint);
  } else if (scene.isGiaiThuongNuSinh) {
    return autoScoreGTNS(info, scoreInformationList, defaultMaxPoint);
  }
}