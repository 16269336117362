import { apiCall } from '../utils';
import { SEASON_API, SEASON_TYPE } from '../constants';

export const seasonActions = {
  getSeasonList,
  updateSeason
};

function getSeasonList() {
  return async(dispatch) => {
    dispatch(loading());

    const api = SEASON_API.getSeasonList();
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure());
    }
  };

  function loading() { return { type: SEASON_TYPE.GET_SEASON_LIST_LOADING } }
  function success(payload) { return { type: SEASON_TYPE.GET_SEASON_LIST_SUCCESS, payload } }
  function failure() { return { type: SEASON_TYPE.GET_SEASON_LIST_FAILURE } }
}

function updateSeason(payload){
  return async(dispatch) => {
    dispatch(loading());

    const api = SEASON_API.updateSeason();
    const {response, error} = await apiCall({ ...api, payload});

    if(!error && response.status === 200 && response.data.success){
      dispatch(success(response.data.data));
    }else {
      dispatch(failure());
    }
  };

  function loading() { return {type: SEASON_TYPE.UPDATE_SEASON_LOADING }}
  function success(payload) { return { type:SEASON_TYPE.UPDATE_SEASON_SUCCESS, payload }}
  function failure() { return { type: SEASON_TYPE.UPDATE_SEASON_FAILURE }}
}