import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import BaoCaoInsert from '../BaoCaoInsert';
import { reportsActions } from '../../../../actions';

export default function BaoCaoButton(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);
  const totalReportList = useSelector(state => state.reportsReducer.totalReportList);
  const personalReportList = useSelector(state => state.reportsReducer?.personalReportList);

  const [inserting, setInserting] = useState(false);

  const handleClick = () => setInserting(true);
  const handleClose = () => setInserting(false);
  const handleReload = () => {
    if (props.filterType === 'person') {
      dispatch(reportsActions.getReportsByProfileId(profileId));
    } else {
      dispatch(reportsActions.getAllReport());
    }
  }

  useEffect(() => {
    if (props.filterType === 'person' && !isNaN(profileId) && personalReportList.length === 0) handleReload();
  }, [profileId]);

  useEffect(() => {
    if (props.filterType === 'all' && totalReportList.length === 0) handleReload();
  }, []);

  useEffect(() => {
    if (props.filterType === 'all' && totalReportList.length === 0) handleReload();
  }, []);


  return (
    <>
      <Grid item xs={12} md={3}>
        <div style={{ float: 'right' }}>
          <Button variant='outlined' startIcon={<Replay />} style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleReload} >Tải lại</Button>
          {props.filterType === 'person' && (
            <Button variant='contained' color='secondary' startIcon={<Add />} onClick={handleClick}>Thêm</Button>
          )}
        </div>
      </Grid>


      <BaoCaoInsert open={inserting} onClose={handleClose} />
    </>
  );
}
