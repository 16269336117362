import { SCORE_INFORMATION_TYPE } from "../constants";

const initialState = {
  loading: false,
  scoreInformationList: [],
}

const sortFunc = (a, b) => a.stt - b.stt;

export function scoreInformationReducer(state = initialState, action) {
  switch(action.type) {
    case SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_LOADING:
    case SCORE_INFORMATION_TYPE.UPDATE_SCORE_INFORMATION_LOADING:
      return { ...state, loading: true };

    case SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_FAILURE:
    case SCORE_INFORMATION_TYPE.UPDATE_SCORE_INFORMATION_FAILURE:
      return { ...state, loading: false };

    case SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_SUCCESS:
      return {
        ...state,
        scoreInformationList: [...action.payload].sort(sortFunc),
        loading: false,
      };

    case SCORE_INFORMATION_TYPE.UPDATE_SCORE_INFORMATION_SUCCESS:
      return {
        ...state,
        scoreInformationList: [
          ...state.scoreInformationList.filter((e) => e.id !== action.payload.id),
          action.payload
        ].sort(sortFunc),
        loading: false,
      };

    default:
      return state;
  }
}
