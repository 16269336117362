import { apiCall } from '../utils';
import { SCORE_INFORMATION_API, SCORE_INFORMATION_TYPE } from '../constants';

import { userActions } from '.';
const { notification } = userActions;

export const scoreInformationActions = {
	getAllScoreInformation,
	getScoreInformationBySeasonId,
	updateScoreInformation,
}

function getAllScoreInformation() {
	return async (dispatch) => {
		dispatch(loading());

		const api = SCORE_INFORMATION_API.getAllScoreInformation();
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy dữ liệu thất bại!', type: 'error' }));
		}
	};

	function loading() { return { type: SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_LOADING } }
	function success(payload) { return { type: SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_SUCCESS, payload } }
	function failure(error) { return { type: SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_FAILURE, error } }
}

function getScoreInformationBySeasonId(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = SCORE_INFORMATION_API.getScoreInformationBySeasonId(id);
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy dữ liệu thất bại!', type: 'error' }));
		}
	};

	function loading() { return { type: SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_LOADING } }
	function success(payload) { return { type: SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_SUCCESS, payload } }
	function failure(error) { return { type: SCORE_INFORMATION_TYPE.GET_ALL_SCORE_INFORMATION_FAILURE, error } }
}

function updateScoreInformation(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = SCORE_INFORMATION_API.updateScoreInformation();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Cập nhật dữ liệu thất bại!', type: 'error' }));
		}
	};

	function loading() { return { type: SCORE_INFORMATION_TYPE.UPDATE_SCORE_INFORMATION_LOADING } }
	function success(payload) { return { type: SCORE_INFORMATION_TYPE.UPDATE_SCORE_INFORMATION_SUCCESS, payload } }
	function failure(error) { return { type: SCORE_INFORMATION_TYPE.UPDATE_SCORE_INFORMATION_FAILURE, error } }
}
