import { HEADERS } from '.';

export const VOTE_API = {
  vote: () => ({
    endPoint: '/api/v1/vote',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  getVoteInfo: () => ({
    endPoint: '/api/v1/vote/get_cre',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
  // getAllVoteInfo: () => ({
  //   endPoint: '/api/v1/vote/get_info',
  //   headers: HEADERS.TOKEN_HEADER,
  //   method: 'GET',
  // }),
  getVoteCount: () => ({
    endPoint: '/api/v1/vote/count',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'GET',
  }),
  filterForm: () => ({
    endPoint: '/api/v1/form/filter/votes',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
}

export const VOTE_TYPE = {
  VOTE_LOADING: 'VOTE_LOADING',
  VOTE_SUCCESS: 'VOTE_SUCCESS',
  VOTE_FAILURE: 'VOTE_FAILURE',

  GET_VOTE_INFO_LOADING: 'GET_VOTE_INFO_LOADING',
  GET_VOTE_INFO_SUCCESS: 'GET_VOTE_INFO_SUCCESS',
  GET_VOTE_INFO_FAILURE: 'GET_VOTE_INFO_FAILURE',

  GET_VOTE_COUNT_LOADING: 'GET_VOTE_COUNT_LOADING',
  GET_VOTE_COUNT_SUCCESS: 'GET_VOTE_COUNT_SUCCESS',
  GET_VOTE_COUNT_FAILURE: 'GET_VOTE_COUNT_FAILURE',

  FILTER_FORM_VOTE_LOADING: 'FILTER_FORM_VOTE_LOADING',
  FILTER_FORM_VOTE_SUCCESS: 'FILTER_FORM_VOTE_SUCCESS',
  FILTER_FORM_VOTE_FAILURE: 'FILTER_FORM_VOTE_FAILURE',
}

export const VOTE_CONSTANTS = {
  academicLevelOptions : [
    { key: 'master', title: 'Th.S ', value: 'master' },
    { key: 'doctor', title: 'TS. ', value: 'doctor' },
  ],
}