import React from 'react';

import GiaiThuongTable from '../../../containers/QCV/GiaiThuong/GiaiThuongTable/GiaiThuongTable'
import GiaiThuongButton from '../../../containers/QCV/GiaiThuong/GiaiThuongButton/GiaiThuongButton';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';

export default function GiaiThuong() {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  return (
    <>
      <GiaiThuongButton filterType={filterType} />
      <ThanhTichAlert />
      <GiaiThuongTable filterType={filterType} type='update'/>
    </>
  );
}