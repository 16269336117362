import React from "react";
import {
  Box,
  Grid,
  Divider,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Container,
  Hidden,
} from "@material-ui/core";

import QuyChe from "../QuyChe";
import SuccessfulFace from "../../../containers/QCV/SuccessfulFace/successfulFace";

import { ROUTER } from "../../../constants";
import { useStyles } from "./styled";
import { useScreenSize } from "../../../hooks/useScreenSize";

import tut from "../../../assets/image/home/tutorial-icon.png";
// import video30 from "../../../assets/image/home/Comp 1.mp4";
import cytast30 from "../../../assets/image/home/cytast30.jpg";
import frame30 from "../../../assets/image/home/frame-30.png";
import logoDoan from "../../../assets/image/home/logoDoan.png";
import backdrop from "../../../assets/image/home/backdrop.jpg";
import paperIcon from "../../../assets/image/tnv/qcv-icon.png";

import logoBoKHCN from "../../../assets/image/home/logoBoKHCN.png";
import bannerTitle from "../../../assets/image/home/qcv-banner-title.png";
import registerIcon from "../../../assets/image/home/register-icon.png";

const topicList = [
  {
    key: "thanh-tích",
    title: "Quy chế xét thưởng",
    containerColor: "#F5F1EE",
    imgSource: paperIcon,
    to: "#quy-che",
    description:
      "Quy chế Giải thưởng ban hành theo Quyết định số 1186-QĐ/TWĐTN-KHCN ngày 19/8/2021 của Ban Bí thư Trung ương Đoàn.",
  },
  {
    key: "dang-ky",
    title: "Đăng ký",
    containerColor: "#EFEEF5",
    imgSource: registerIcon,
    to: ROUTER.DANG_KY_HO_SO,
    description:
      "Đăng ký tham gia hệ thống bằng việc hoàn thiện hồ sơ đăng ký, khai các thành tích cá nhân và chờ hệ thống xét duyệt.",
  },
  {
    key: "binh-chọn",
    title: "Hướng dẫn đăng ký",
    containerColor: "#F4F5EE",
    imgSource: tut,
    to: ROUTER.QCV.HUONG_DAN_SU_DUNG,
    description: "Vui lòng ấn vào Chi tiết bên dưới để xem hướng dẫn sử dụng",
  },
];

const LOGO_30_WIDTH = 200;
const LOGO_30_HEIGHT = 519 / 549 * 200;
const LOGO_30_SCALE = LOGO_30_WIDTH / 549;

function GioiThieuQCV() {
  const classes = useStyles(LOGO_30_WIDTH);
  const isMobileMode = useScreenSize();

  return (
    <>
      <Box style={{
        backgroundImage: `url(${backdrop})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <Container maxWidth="lg">
          <Box
            paddingY={6}
            display="flex"
            alignItems="center"
            flexDirection={isMobileMode ? "column" : "row"}
          >
            <Box display="flex">
              <img
                src={logoDoan}
                alt="logo-doan"
                style={{ minWidth: 64, width: "calc(100vw / 20)" }}
              />
              <img
                src={logoBoKHCN}
                alt="logo-bo-khcn"
                style={{
                  minWidth: 64,
                  width: "calc(100vw / 20)",
                  marginTop: 10,
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{ flexGrow: 1 }}
            >
              <img
                src={bannerTitle}
                alt="title"
                style={{
                  marginLeft: isMobileMode ? 0 : 8,
                  width: isMobileMode ? "100%" : "calc(100% - 100vw / 30)",
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box className="formal-text">
          <p>
            Thực hiện chủ trương của Đảng, Nhà nước về phát triển nguồn nhân lực
            trẻ tài năng Quốc gia, được sự đồng ý của Thủ tướng Chính phủ, từ năm
            2003 Trung ương Đoàn TNCS Hồ Chí Minh chủ trì phối hợp với Bộ Khoa học
            và Công nghệ tổ chức Giải thưởng Khoa học công nghệ Quả Cầu Vàng nhằm
            phát hiện, tôn vinh tài năng trẻ Việt Nam (không quá 35 tuổi tính đến
            năm xét trao giải) đang học tập, nghiên cứu và làm việc ở trong hoặc
            ngoài nước có thành tích xuất sắc trong lĩnh vực khoa học công nghệ
            nhằm thúc đẩy phong trào học tập, nghiên cứu, ứng dụng khoa học công
            nghệ, xây dựng và phát triển đội ngũ nhân tài trong lĩnh vực khoa học
            công nghệ, góp phần phát triển đất nước Việt Nam phồn vinh, hạnh phúc.
            Giải thưởng được trao hằng năm, mỗi năm có tối đa 10 cá nhân được nhận
            Giải thưởng.
          </p>
          <ul>
            <li>
              Từ năm 2003-2010: Giải thưởng được Trung ương Đoàn sáng lập vào năm
              2003 với tên gọi “Giải thưởng Công nghệ thông tin Thanh niên mang
              tên Quả Cầu Vàng” vinh danh 10 tài năng trẻ có thành tích xuất sắc
              nhất toàn quốc trong lĩnh vực CNTT nhằm phát triển nguồn nhân lực
              CNTT và phát huy vai trò xung kích, tiên phong của thanh niên Việt
              Nam trong đẩy mạnh ứng dụng CNTT vào các lĩnh vực của đời sống xã
              hội.
            </li>
            <li>
              Từ năm 2011-2015: đổi tên là{" "}
              <i>
                Giải thưởng Khoa học Kỹ thuật Thanh niên mang tên Quả Cầu Vàng
              </i>{" "}
              và mở rộng thành 4 lĩnh vực xét giải thưởng: Công nghệ thông tin và
              truyền thông, Công nghệ Y- dược, Công nghệ Sinh học và Công nghệ Môi
              trường.
            </li>
            <li>
              Từ năm 2016-2020, đổi tên là{" "}
              <i>Giải thưởng Khoa học công nghệ Thanh niên Quả Cầu Vàng </i>và mở
              rộng thành 5 lĩnh vực xét giải thưởng: Công nghệ thông tin và truyền
              thông, Công nghệ Y- dược, Công nghệ Sinh học, Công nghệ Môi trường
              và Công nghệ Vật liệu mới.
            </li>
            <li>
              Từ năm 2021, đổi tên gọi thành{" "}
              <i>Giải thưởng Khoa học công nghệ Quả Cầu Vàng </i>và sửa đổi, bổ
              sung, mở rộng các ngành trong 5 lĩnh vực xét giải thưởng: Công nghệ
              thông tin, chuyển đổi số và tự động hóa; Công nghệ Y- dược; Công
              nghệ Sinh học; Công nghệ Môi trường và Công nghệ Vật liệu mới.
            </li>
          </ul>
          <p>
            Đến năm 2023, đã có 204 cá nhân xuất sắc trong và ngoài nước được nhận
            Giải thưởng, trong đó: Công nghệ thông tin, chuyển đổi Số và tự động
            hóa (109 cá nhân); Công nghệ Y- dược (31 cá nhân); Công nghệ Sinh học
            (19 cá nhân); Công nghệ Môi trường (30 cá nhân) và Công nghệ Vật liệu
            mới (15 cá nhân)
          </p>
          <p>
            <i>
              Trung tâm Nghiên cứu và chuyển giao công nghệ Trí tuệ nhân tạo và Khoa 
              học dữ liệu (Centre for Applied Data Science and Artficial Intelligence - CADA), ĐHKTQD là đơn vị đồng hành của Giải thưởng.
            </i>
          </p>
        </Box>
        <Grid container spacing={4}>
          {topicList.map((e) => (
            <Grid key={e.key} item xs={12} md={4} style={{ marginBottom: 32 }}>
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  display="flex"
                  paddingY={4}
                  justifyContent="center"
                  alignItems="center"
                  bgcolor={e.containerColor}
                >
                  <CardMedia
                    src={e.imgSource}
                    component="img"
                    style={{ width: '45%' }}
                  />
                </Box>
                <CardContent style={{ flexGrow: 1, textAlign: "justify" }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginTop: 24,
                      marginBottom: 8,
                      textAlign: "center",
                      fontFamily: "WorkSans-ExtraBold"
                    }}
                  >
                    <b>{e.title}</b>
                  </Typography>
                  <Box style={{ color: "#55626a", lineHeight: "1.68421053em" }}>
                    {e.description}
                  </Box>
                </CardContent>
                <CardActions>
                  <a href={e.to} style={{ margin: "auto", color: "#ee6064" }}>
                    <b>Chi tiết</b>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Divider
          style={{
            width: 64,
            height: 4,
            margin: "64px auto 16px",
            backgroundColor: "#ee6064",
          }}
        />
        <Typography variant="h4" style={{ textAlign: "center", fontFamily: "WorkSans-ExtraBold", marginBottom: 8 }}>
          <b>Một số gương mặt Quả Cầu Vàng tiêu biểu</b>
        </Typography>
        <SuccessfulFace />
        <Divider
          id="quy-che"
          style={{
            width: 64,
            height: 4,
            backgroundColor: "#ee6064",
            margin: "64px auto 16px",
          }}
        />
        <Typography variant="h4" style={{ textAlign: "center", fontFamily: "WorkSans-ExtraBold" }}>
          <strong>QUY CHẾ XÉT THƯỞNG</strong>
        </Typography>
        <Typography
          variant="h6"
          style={{ textAlign: "center", color: "#55626a" }}
        >
          <i>
            (Theo Quy chế Giải thưởng ban hành theo Quyết định số 1186
            -QĐ/TWĐTN-KHCN ngày 19/8/2021 của Ban Bí thư Trung ương Đoàn)
          </i>
        </Typography>
        <QuyChe />
      </Container>
      <Hidden smDown>
        <Box
          className={classes.logo30}
          position='sticky'
          height={0}
          bottom={128}
        >
          <Box position='relative' width={549 * LOGO_30_SCALE} height={519 * LOGO_30_SCALE}>
            {/* <video loop muted autoPlay src={video30} style={{
              width: `calc(100% - ${96 * LOGO_30_SCALE}px)`,
              height: `calc(100% - ${56 * LOGO_30_SCALE}px)`,
              top: -LOGO_30_HEIGHT,
              bottom: 0,
              left: 48 * LOGO_30_SCALE,
              right: 48 * LOGO_30_SCALE,
              position: 'absolute',
            }} /> */}
            <img src={cytast30} draggable={false} style={{
              width: `calc(100% - ${96 * LOGO_30_SCALE + 2}px)`,
              height: `calc(100% - ${56 * LOGO_30_SCALE + 4}px)`,
              top: -LOGO_30_HEIGHT,
              bottom: 0,
              left: 48 * LOGO_30_SCALE,
              right: 48 * LOGO_30_SCALE,
              position: 'absolute',
            }} />
            <img className={classes.frame30} src={frame30} draggable={false} style={{ top: -LOGO_30_HEIGHT }} />
          </Box>
        </Box>
      </Hidden>
    </>
  );
}

export default GioiThieuQCV;
