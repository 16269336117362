import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  accordionSummaryRoot: {
    marginBottom: -1,
    minHeight: '56px !important',
  },
  accordionSummaryContent: {
    margin: '12px 0 !important',
  },
  accordionSummaryExpanded: {},
}));