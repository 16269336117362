import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { matchSorter } from "match-sorter";

import {
  Box,
  Card,
  Grid,
  Step,
  Stepper,
  StepLabel,
  Typography,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import svg from '../../../assets/image/danhsachhoso/svg.png';
import styles from './index.module.css';
import DanhSachHoSoGrid from "../../../containers/QCV/DanhSachHoSo/DanhSachHoSoGrid";
import DanhSachHoSoFind from "../../../containers/QCV/DanhSachHoSo/DanhSachHoSoFind";
import DanhSachHoSoTable from "../../../containers/QCV/DanhSachHoSo/DanhSachHoSoTable";

import { useScene } from "../../../hooks/useScene";
import { FORM_CONSTANTS } from "../../../constants";
import { APP_SCENE, ROLE } from "../../../constants/enums";

function DanhSachHoSo() {
  const scene = useScene();

  const [name, setName] = useState(null);
  const [field, setField] = useState(null);

  const role = useSelector((state) => state.userReducer.user?.role);
  const applicationList = useSelector((state) => state.formReducer.applicationList);

  useEffect(() => {
    const admin_field = sessionStorage.getItem("admin_field");

    if (admin_field) {
      setField(admin_field);
    } else {
      const listField = FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE];
      if (role) {
        setField(role === ROLE.SUPER_ADMIN ? listField[0].value : role.replaceAll("qcv_admin_", ""));
        if (role === ROLE.SUPER_ADMIN) sessionStorage.setItem("admin_field", listField[0].value);
      }
    }
  }, [role]);

  const rows = useMemo(() => {
    var fitleredRows = matchSorter(applicationList, name || "", { keys: ["profile.name"] });

    if (field) {
      fitleredRows = (field !== "all") ? fitleredRows.filter((e) => e.field === field) : fitleredRows;
    }

    return fitleredRows;
  }, [applicationList, field]);

  const status = useMemo(() => {
    if (scene.isQuaCauVang) {
      return ['in_review', 'need_update', 'approved'];
    } else if (scene.isKhueVanCac) {
      return ['in_review', 'need_update', 'approved'];
    } else if (scene.isGiaiThuongNuSinh) {
      return ['unsubmit', 'in_review', 'need_update', 'approved'];
    }
  }, [scene]);

  return (
    <Grid container justifyContent="center" spacing={2} style={{ marginTop: 32, marginBottom: 32 }}>
      {role?.includes("admin") ? (
        <>
          <Grid item xs={4}>
            <Card style={{ backgroundColor: "#ececec", height: 164 }}>
              <CardContent style={{ padding: 0 }}>
                <Box width='100%' display='flex' justifyContent='space-between'>
                  <Box style={{ padding: 16, paddingRight: 4 }}>
                    <Typography variant="h6">
                      <b>Tổng số lượng hồ sơ</b>
                    </Typography>
                    <Typography variant="h2" style={{ textAlign: 'center' }}>
                      <b>{rows?.length}</b>
                    </Typography>
                  </Box>
                  <img alt="svg" height={164} src={svg} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8} style={{ height: "100%" }}>
            <Card style={{ backgroundColor: "#ececec", height: 164 }}>
              <CardHeader style={{ paddingRight: 4 }} title={
                <Typography variant="h6">
                  <b>Hướng dẫn hội đồng chấm</b>
                </Typography>
                }
                />
              <CardContent style={{ padding: 0 }}>
                <Stepper
                  color="secondary"
                  style={{ paddingTop: 0, backgroundColor: "transparent" }}
                >
                  <Step active>
                    <StepLabel StepIconProps={{ classes: { root: styles.icon } }} optional="Duyệt và cho điểm từng thành tích của ứng viên">
                      <b>Duyệt thành tích</b>
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel active StepIconProps={{ classes: { root: styles.icon } }} optional="Duyệt hoặc loại hồ sơ của ứng viên">
                      <b>Duyệt hồ sơ</b>
                    </StepLabel>
                  </Step>
                  <Step active>
                    <StepLabel StepIconProps={{ classes: { root: styles.icon } }}>
                      <b>Trích xuất báo cáo</b>
                    </StepLabel>
                  </Step>
                </Stepper>
              </CardContent>
            </Card>
          </Grid>
          <DanhSachHoSoFind
            name={name}
            field={field}
            setName={setName}
            setField={setField}
            rows={rows}
            role={role}
          />
          <Grid item xs={12}>
            <Card style={{ border: "none" }}>
              {scene.isGiaiThuongNuSinh ? (
                <DanhSachHoSoTable field={field} name={name} rows={rows} status={status} />
              ) : (
                <DanhSachHoSoTable field={field} name={name} rows={rows} status={status} />
              )}
            </Card>
          </Grid>
        </>
      ) : (
        <DanhSachHoSoGrid />
      )}
    </Grid>
  );
}

export default DanhSachHoSo;
