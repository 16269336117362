import React, { useEffect, useMemo } from 'react';
import { Helmet } from "react-helmet";
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import { Box, CircularProgress, Container, IconButton, Typography } from '@material-ui/core';
import { createBrowserRouter, NavLink, Outlet, RouterProvider } from 'react-router-dom';

import { ROUTER } from '../../constants/router';
import { useScene } from '../../hooks/useScene';
import { profileActions, seasonActions, userActions } from '../../actions';

import AppHeader from '../../containers/AppLayout/AppHeader';
import AppFooter from '../../containers/AppLayout/AppFooter/AppFooter';
import ROUTER_CONFIG from './routerConfig';
import ThanhTichCaNhan, { ROUTER_CONFIG_THANH_TICH_CA_NHAN } from '../QCV/ThanhTichCaNhan/ThanhTichCaNhan';
import DuyetThanhTichCaNhan, { ROUTER_CONFIG_DUYET_THANH_TICH_CA_NHAN } from '../QCV/DuyetThanhTichCaNhan/DuyetThanhTichCaNhan';
import { APP_SCENE, ROLE } from '../../constants/enums';


const NavLayout = ({
  maxWidth = false,
  disableHeader = false,
  loading,
  loggedIn,
  bgColor = '#FFF',
  authRequired = true,
  adminRoleRequired = false,
  isSuperAdmin = false,
}) => {
  document.body.style.backgroundColor = bgColor;

  const outletComponent = () => {
    if (loading) {
      return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
          <CircularProgress />
          <Typography variant='body1'>
            Vui lòng đợi trong giây lát...
          </Typography>
        </Box>
      );
    } else if (authRequired && !loggedIn) {
      return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
          <NavLink to={ROUTER.DANG_NHAP}>Đăng nhập để truy cập tính năng này</NavLink>
        </Box>
      );
    } else if (adminRoleRequired && loggedIn && !isSuperAdmin) {
      return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
          <NavLink to={ROUTER.HOME}>Bạn không có quyền truy cập tính năng này</NavLink>
        </Box>
      );
    }
    return <Outlet />
  };

  const style = useMemo(() => {
    if (disableHeader) {
      return {
        flexGrow: 1,
        alignContent: 'center',
        paddingTop: 32,
        paddingBottom: 32,
      }
    } else {
      return {
        minHeight: '100vh',
        paddingTop: 64,
        paddingBottom: 32,
      }
    }
  }, [disableHeader]);

  useEffect(() => {
    if (disableHeader) {
      // Nhìn thấy footer
      const rootDiv = document.getElementById('root');
      if (rootDiv) {
        rootDiv.style.display = 'flex';
        rootDiv.style.minHeight = '100vh';
        rootDiv.style.flexDirection = 'column';
      }
    }
  }, [disableHeader])

  return (
    <>
      {!disableHeader && <AppHeader />}
      <Container
        style={style}
        maxWidth={maxWidth}
        disableGutters={!maxWidth}
      >
        {outletComponent()}
      </Container>
      <AppFooter />
    </>
  );
};


function AppLayout() {
  const scene = useScene();
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, profile, notification } = useSelector(state => ({
    user: state.userReducer.user,
    profile: state.profileReducer.profile,
    notification: state.userReducer.notification,
  }));

  const loading = useSelector((state) => {
    const loadingUser = state.userReducer.loading;
    const loadingProfile = state.profileReducer.loading;
    return loadingUser || loadingProfile;
  })

  // 1. Lấy danh sách các season
  // 2. Đăng nhập lại bằng refresh token
  useEffect(() => {
    if (refreshToken && !user) dispatch(userActions.loginWithToken(refreshToken));
  }, []);

  // Có user-id thì lấy luôn profile luôn
  useEffect(() => {
    if (!isNaN(user?.id) && accessToken && !profile) {
      dispatch(seasonActions.getSeasonList());
      dispatch(profileActions.getProfileByUserId(user?.id));
    }
  }, [user, accessToken]);

  // 1. Trong store có thông báo
  // 2. Hiển thị thông báo
  useEffect(() => {
    if (notification) {
      enqueueSnackbar(notification?.message, {
        variant: notification?.type || 'default',
        action: (key) => (
          <IconButton size='small' onClick={() => closeSnackbar(key)}>
            <Close size='small' style={{ color: 'white' }} />
          </IconButton>
        ),
      });
      closeSnackbar(1);
      dispatch(userActions.notification(null));
    }
  }, [notification]);

  const loggedIn = (!loading) && (!isNaN(user?.id)) && (!!refreshToken);
  const isSuperAdmin = user?.role === ROLE.SUPER_ADMIN;

  const router = useMemo(() => {
    var routerConfig = [...ROUTER_CONFIG.BASE];
    if (isSuperAdmin) routerConfig = [...routerConfig, ...ROUTER_CONFIG.ADMIN];

    routerConfig = [...routerConfig, ...ROUTER_CONFIG[APP_SCENE]];
    
    routerConfig = routerConfig.map((route) => ({
      exact: true,
      children: [route],
      element: <NavLayout {...route} loggedIn={loggedIn} isSuperAdmin={isSuperAdmin} loading={loading} />
    }));

    if (scene.isQuaCauVang) {
      routerConfig.push({ element: <ThanhTichCaNhan />, children: ROUTER_CONFIG_THANH_TICH_CA_NHAN });
      if (isSuperAdmin) {
        routerConfig.push({ element: <DuyetThanhTichCaNhan />, children: ROUTER_CONFIG_DUYET_THANH_TICH_CA_NHAN });
      }
    }
    
    const router = createBrowserRouter(routerConfig);
    return router;
  }, [loggedIn, scene]);

  if (!scene.isGiaiThuongNuSinh && !scene.isQuaCauVang && !scene.isKhueVanCac) return null;

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap" rel="stylesheet"></link>
        <meta name="description" content="Giải thưởng khoa học công nghệ Quả Cầu Vàng là phần thưởng cao quý của Trung ương Đoàn TNCS Hồ Chí Minh và Bộ Khoa học & Công nghệ dành cho các tài năng trẻ xuất sắc trong lĩnh vực khoa học công nghệ hàng năm."></meta>
        <title>
          {scene.isQuaCauVang
            ? 'Cổng đăng ký trực tuyến Giải thưởng KHCN Quả Cầu Vàng'
            : scene.isGiaiThuongNuSinh
              ? 'Cổng đăng ký trực tuyến Phần thưởng Nữ sinh viên tiêu biểu trong lĩnh vực KHCN'
              : ''}
        </title>
      </Helmet>
      <RouterProvider router={router} />
    </>
  )
}

export default AppLayout;
