import { HEADERS } from ".";

export const RESEARCHPROJECTS_API = {
  getAllResearchProjectList: () => ({
    endPoint: `/api/v1/project/list`,
    headers: HEADERS.TOKEN_HEADER,
    method: "GET",
  }),
  getResearchProjectByProfileId: (id) => ({
    endPoint: `/api/v1/project/profile/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: "GET",
  }),
  insertResearchProject: () => ({
    endPoint: "/api/v1/project/create",
    headers: HEADERS.TOKEN_HEADER,
    method: "POST",
  }),
  updateResearchProject: () => ({
    endPoint: "/api/v1/project/update",
    headers: HEADERS.TOKEN_HEADER,
    method: "PUT",
  }),
  deleteResearchProject: (id) => ({
    endPoint: `/api/v1/project/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: "DELETE",
  }),
  updateResearchProjectAccepted: () => ({
    endPoint: "/api/v1/formResearch/updateScore_Accepted",
    headers: HEADERS.TOKEN_HEADER,
    method: "PUT",
  }),
};

export const RESEARCHPROJECTS_TYPE = {
  GET_ALL_RESEARCH_PROJECT_LOADING: "GET_ALL_RESEARCH_PROJECT_LOADING",
  GET_ALL_RESEARCH_PROJECT_SUCCESS: "GET_ALL_RESEARCH_PROJECT_SUCCESS",
  GET_ALL_RESEARCH_PROJECT_FAILURE: "GET_ALL_RESEARCH_PROJECT_FAILURE",

  GET_RESEARCH_PROJECT_BY_PROFILE_ID_LOADING: "GET_RESEARCH_PROJECT_BY_PROFILE_ID_LOADING",
  GET_RESEARCH_PROJECT_BY_PROFILE_ID_SUCCESS: "GET_RESEARCH_PROJECT_BY_PROFILE_ID_SUCCESS",
  GET_RESEARCH_PROJECT_BY_PROFILE_ID_FAILURE: "GET_RESEARCH_PROJECT_BY_PROFILE_ID_FAILURE",

  INSERT_RESEARCHPROJECT_LOADING: "INSERT_RESEARCHPROJECT_LOADING",
  INSERT_RESEARCHPROJECT_SUCCESS: "INSERT_RESEARCHPROJECT_SUCCESS",
  INSERT_RESEARCHPROJECT_FAILURE: "INSERT_RESEARCHPROJECT_FAILURE",

  UPDATE_RESEARCHPROJECT_LOADING: "UPDATE_RESEARCHPROJECT_LOADING",
  UPDATE_RESEARCHPROJECT_SUCCESS: "UPDATE_RESEARCHPROJECT_SUCCESS",
  UPDATE_RESEARCHPROJECT_FAILURE: "UPDATE_RESEARCHPROJECT_FAILURE",

  DELETE_RESEARCHPROJECT_LOADING: "DELETE_RESEARCHPROJECT_LOADING",
  DELETE_RESEARCHPROJECT_SUCCESS: "DELETE_RESEARCHPROJECT_SUCCESS",
  DELETE_RESEARCHPROJECT_FAILURE: "DELETE_RESEARCHPROJECT_FAILURE",

  UPDATE_RESEARCHPROJECT_ACCEPTED_LOADING: "UPDATE_RESEARCHPROJECT_ACCEPTED_LOADING",
  UPDATE_RESEARCHPROJECT_ACCEPTED_SUCCESS: "UPDATE_RESEARCHPROJECT_ACCEPTED_SUCCESS",
  UPDATE_RESEARCHPROJECT_ACCEPTED_FAILURE: "UPDATE_RESEARCHPROJECT_ACCEPTED_FAILURE",
};

export const RESEARCHPROJECTS_CONSTANTS = {
  TYPO_OPTIONS: [
    { key: "d1", title: "Chủ nhiệm", value: "d1" },
    { key: "d2", title: "Phó Chủ nhiệm", value: "d2" },
    { key: "d3", title: "Thư ký", value: "d3" },
  ],
  LEVEL_OPTIONS: [
    { key: "domestic", title: "Cấp Nhà nước và tương đương", value: "domestic" },
    { key: "ministry", title: "Cấp Bộ và tương đương", value: "ministry" },
    { key: "school", title: "Cơ sở", value: "school" },
    { key: "vn", title: "Đề tài quốc tế giải quyết các vấn đề của Việt Nam", value: "vn" },
  ],
};
