import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import DaoTaoTable from '../../../containers/QCV/DaoTao/DaoTaoTable'
import DaoTaoInsert from '../../../containers/QCV/DaoTao/DaoTaoInsert';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';

import { hrDevelopmentActions} from '../../../actions';

export default function DaoTao() {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  const dispatch = useDispatch();
  const profileId = useSelector(state => state.profileReducer.profile?.id);
  const totalTechCompanyList = useSelector(state => state.techCompanyReducer.totalTechCompanyList);
  const personalTechCompanyList = useSelector(state => state.techCompanyReducer?.personalTechCompanyList);
  const mobileMode = window.innerWidth <= 768;

  const [inserting, setInserting] = useState(null);

  useEffect(() => {
    if (filterType === 'person' && !isNaN(profileId) && personalTechCompanyList.length === 0) handleReload();
  }, [profileId]);

  useEffect(() => {
    if (filterType === 'all' && totalTechCompanyList.length === 0) handleReload();
  }, []);

  const handleClick = (type) => setInserting(type);
  const handleClose = () => setInserting(null);

  const handleReload = () => {
    if (filterType === 'person') {
      dispatch(hrDevelopmentActions.getHrDevelopmentByProfileId(profileId));
    } else {
      dispatch(hrDevelopmentActions.getAllHrDevelopment());
    }
  }

  return (
    <>
      <Grid item xs={3} />
      <ThanhTichAlert />
      <Grid item xs={12}>
        <Box display='flex'>
          <Box flexGrow={1}>
            <Typography variant='h6'>Đào tạo nhân lực (Thạc sĩ, tiến sĩ, cử nhân)</Typography>
          </Box>
          <Box style={{ float: 'right' }}>
            <Button variant='outlined' startIcon={<Replay />} style={{ backgroundColor: 'white' }} onClick={handleReload}>Tải lại</Button>
            {filterType === 'person' && (
              <Button
                variant='contained'
                color='secondary'
                startIcon={<Add />}
                style={{ marginLeft: 8 }}
                onClick={() => handleClick('daoTao')}
              >
                Thêm
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DaoTaoTable filterType={filterType} type='update' tableType='daoTao' />
      </Grid>

      <Grid item xs={12} style={{ marginTop: 32 }}>
        <Box display='flex'>
          <Box width='50%'>
            <Typography variant='h6'>{mobileMode?'Bồi dưỡng nhân lực ':'Bồi dưỡng nhân lực (Chia sẻ tri thức KHCN...)'}</Typography>
          </Box>
          <Box width='50%'>
            <Box style={{ float: 'right' }}>
              <Button variant='outlined' startIcon={<Replay />} style={{ backgroundColor: 'white' }} onClick={handleReload}>Tải lại</Button>
              {filterType === 'person' && (
                <Button
                variant='contained'
                color='secondary'
                startIcon={<Add />}
                style={{ marginLeft: 8 }}
                onClick={() => handleClick('boiDuong')}
              >
                Thêm
              </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DaoTaoTable filterType={filterType} type='update' tableType='boiDuong' />
      </Grid>


      <DaoTaoInsert open={!!inserting} type={inserting} onClose={handleClose} />
    </>
  );
}