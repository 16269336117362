import React from 'react';
import { TextField, Typography, InputAdornment } from '@material-ui/core';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { TECH_COMPANY_CONSTANTS } from '../../../../constants';
import theme from '../../../../theme';

export default function DoanhNghiepForm(props) {
  const { values, setValues, disabled } = props;
  const { productionOptions } = TECH_COMPANY_CONSTANTS;

  const mobileMode = window.innerWidth <= 768;

  const valueFormat = (value) => {
    const temp = value.replace(/\D/g, '').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.');
    return temp;
  };

  const textForm = [
    // { key: 'name', width: 12, height: 1, label: 'Tên sản phẩm', value: values?.name, disabled, required: true },
    // { key: 'role', width: 12, height: 1, label: 'Vị trí trong doanh nghiệp', value: values?.role, options: roleOptions, disabled, required: true },
    {
      key: 'publicYear',
      width: mobileMode ? 12 : 6,
      label: 'Năm hoàn thành sản phẩm',
      type: 'int',
      value: values?.publicYear,
      disabled,
      required: true,
    },
    {
      key: 'applicationRange',
      width: mobileMode ? 12 : 6,
      label: 'Phạm vi đã ứng dụng trong thực tế',
      value: values?.applicationRange,
      options: productionOptions,
      disabled,
      required: true,
    },
    {
      key: 'production',
      width: 12,
      height: 1,
      label: 'Sản phẩm ứng dụng thực tế',
      value: values?.production,
      disabled,
      required: true,
    },
    {
      key: 'value',
      width: 12,
      label: 'Giá trị của sản phẩm ứng dụng thực tế',
      required: true,
      render: (
        <>
          <Typography className='kpt-textfield-label'>
            Giá trị của sản phẩm ứng dụng thực tế:
            <span style={{ color: theme.palette.secondary.main }}>*</span>
          </Typography>

          <TextField
            autoFocus
            fullWidth
            // error={error}
            // required
            disabled={disabled}
            size='small'
            variant='outlined'
            value={valueFormat(values?.value?.toString() || '')}
            InputProps={{ endAdornment: <InputAdornment position='end'>VNĐ</InputAdornment> }}
            onChange={(event) => {
              const input = event.target.value;
              const inputNum = parseInt(input.replaceAll('.', ''))
              setValues({ ...values, value: inputNum });
            }}
          />
        </>
      ),
    },
    {
      key: 'website',
      width: 12,
      height: 1,
      label: 'Website giới thiệu sản phẩm',
      value: values?.website,
      disabled,
    },
    {
      key: 'note',
      width: 12,
      label: 'Ghi chú',
      value: values?.note,
      disabled,
    },
    {
      key: 'previewFile',
      width: 12,
      value: values?.previewFile,
      label: (
        <>
          <Typography variant='body1' style={{ fontSize: 17 }}>
            <b>Upload file minh chứng tại đây</b>
          </Typography>
          <Typography variant='body2' color='secondary'>
            (Quyết định công nhận/ văn bản thể hiện địa điểm/ phạm vi đã ứng dụng)
          </Typography>
        </>
      ),
      type: 'file',
      disabled,
      required: true,
    },
  ];

  return <KPTextform {...props} textForm={textForm} />;
}
