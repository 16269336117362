import { FORM_TYPE, RESEARCHPROJECTS_TYPE} from "../constants";

const initialState = {
    loading: false,
    researchProjectList: [],
    totalResearchProjectList: [],
    personalResearchProjectList: [],
}

const sortFunc = (a, b) => a.id - b.id;

export function researchProjectReducer(state = initialState, action) {
    switch(action.type) {
        case RESEARCHPROJECTS_TYPE.GET_ALL_RESEARCH_PROJECT_LOADING:
        case RESEARCHPROJECTS_TYPE.GET_RESEARCH_PROJECT_BY_PROFILE_ID_LOADING:
        case RESEARCHPROJECTS_TYPE.INSERT_RESEARCHPROJECT_LOADING:
        case RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_LOADING:
        case RESEARCHPROJECTS_TYPE.DELETE_RESEARCHPROJECT_LOADING:
            return {...state, loading: true};


        case RESEARCHPROJECTS_TYPE.GET_ALL_RESEARCH_PROJECT_FAILURE:
        case RESEARCHPROJECTS_TYPE.GET_RESEARCH_PROJECT_BY_PROFILE_ID_FAILURE:
        case RESEARCHPROJECTS_TYPE.INSERT_RESEARCHPROJECT_FAILURE:
        case RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_FAILURE:
        case RESEARCHPROJECTS_TYPE.DELETE_RESEARCHPROJECT_FAILURE:
            return { ...state,loading: false};


        case RESEARCHPROJECTS_TYPE.GET_ALL_RESEARCH_PROJECT_SUCCESS:
            return { 
                ...state, 
                totalResearchProjectList: action.payload.sort(sortFunc), 
                loading: false
            };
        
        case RESEARCHPROJECTS_TYPE.GET_RESEARCH_PROJECT_BY_PROFILE_ID_SUCCESS:
            return {
                ...state,
                researchProjectList: action.payload.sort(sortFunc),
                loading: false
            }

        case RESEARCHPROJECTS_TYPE.INSERT_RESEARCHPROJECT_SUCCESS:
            return {
                ...state,
                totalResearchProjectList: [...state.researchProjectList, action.payload].sort(sortFunc),
                researchProjectList: [...state.researchProjectList, action.payload].sort(sortFunc),
                loading: false
            };

        case RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_SUCCESS:
            return {
                ...state,
                totalResearchProjectList: state.totalResearchProjectList ? [
                    ...state.totalResearchProjectList.filter((e) => e.id !== action.payload.id),
                    action.payload
                  ].sort(sortFunc) : null,
                researchProjectList: [
                    ...state.researchProjectList.filter((e) => e.id !== action.payload.id),
                    action.payload].sort(sortFunc),
                personalResearchProjectList: [
                  ...state.personalResearchProjectList.filter((e) => e.id !== action.payload.id),
                  action.payload
                ].sort(sortFunc),
                loading: false
            };
        
        case RESEARCHPROJECTS_TYPE.DELETE_RESEARCHPROJECT_SUCCESS:
            return {
                ...state,
                researchProjectList: state.researchProjectList.filter((e) => e.id !== action.id).sort(sortFunc),
                totalResearchProjectList: state.totalResearchProjectList.filter((e) => e.id !== action.id).sort(sortFunc),
                personalResearchProjectList: state.personalResearchProjectList.filter((e) => e.id !== action.id).sort(sortFunc),
                loading: false
            };

        case RESEARCHPROJECTS_TYPE.UPDATE_RESEARCHPROJECT_ACCEPTED_SUCCESS:
            return {
                ...state,
                totalResearchProjectList: state.totalResearchProjectList ? [
                    ...state.totalResearchProjectList.filter((e) => e.id !== action.payload.id),
                    action.payload
                ].sort(sortFunc) : null,
                researchProjectList: state.researchProjectList ? [
                  ...state.researchProjectList.filter((e) => e.id !== action.payload.id),
                  action.payload
                ].sort(sortFunc) : null,
                personalResearchProjectList: state.personalResearchProjectList
                  ? [...state.personalResearchProjectList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
                  : null,
                loading: false
            };
        
        case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
        return {
            ...state,
            loading: false,
            personalResearchProjectList: action.payload.researchProjects.sort(sortFunc)
        }

        default:
            return state;
    }
}
