import { HEADERS } from ".";

export const REPORTS_API = {
  getAllReport: () => ({
    endPoint: '/api/v1/report/all',
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  getReportsByProfileId: (id) => ({
    endPoint: `/api/v1/report/${id}/list`,
    headers: HEADERS.TOKEN_HEADER,
    method: "GET",
  }),
  insertReport: () => ({
    endPoint: "/api/v1/report/create",
    headers: HEADERS.TOKEN_HEADER,
    method: "POST",
  }),
  updateReport: () => ({
    endPoint: "/api/v1/report/update",
    headers: HEADERS.TOKEN_HEADER,
    method: "PUT",
  }),
  deleteReport: (id) => ({
    endPoint: `/api/v1/report/delete/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: "DELETE",
  }),
  updateReportAccepted: () => ({
		endPoint: '/api/v1/formSciReport/updateScore_Accepted',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
};

export const REPORTS_TYPE = {
  GET_ALL_REPORT_LOADING: 'GET_ALL_REPORT_LOADING',
  GET_ALL_REPORT_SUCCESS: 'GET_ALL_REPORT_SUCCESS',
  GET_ALL_REPORT_FAILURE: 'GET_ALL_REPORT_FAILURE',

  GET_REPORTS_BY_PROFILE_ID_LOADING: "GET_REPORTS_BY_PROFILE_ID_LOADING",
  GET_REPORTS_BY_PROFILE_ID_SUCCESS: "GET_REPORTS_BY_PROFILE_ID_SUCCESS",
  GET_REPORTS_BY_PROFILE_ID_FAILURE: "GET_REPORTS_BY_PROFILE_ID_FAILURE",

  INSERT_REPORTS_LOADING: "INSERT_REPORTS_LOADING",
  INSERT_REPORTS_SUCCESS: "INSERT_REPORTS_SUCCESS",
  INSERT_REPORTS_FAILURE: "INSERT_REPORTS_FAILURE",

  UPDATE_REPORT_LOADING: "UPDATE_REPORT_LOADING",
  UPDATE_REPORT_SUCCESS: "UPDATE_REPORT_SUCCESS",
  UPDATE_REPORT_FAILURE: "UPDATE_REPORT_FAILURE",

  DELETE_REPORT_LOADING: "DELETE_REPORT_LOADING",
  DELETE_REPORT_SUCCESS: "DELETE_REPORT_SUCCESS",
  DELETE_REPORT_FAILURE: "DELETE_REPORT_FAILURE",

  UPDATE_REPORT_ACCEPTED_LOADING: 'UPDATE_REPORT_ACCEPTED_LOADING',
  UPDATE_REPORT_ACCEPTED_SUCCESS: 'UPDATE_REPORT_ACCEPTED_SUCCESS',
  UPDATE_REPORT_ACCEPTED_FAILURE: 'UPDATE_REPORT_ACCEPTED_FAILURE',
};

const typeOptions = {
  international: [
    { key: 'b1', title: 'Báo cáo xuất sắc', value: 'b1' },
		{ key: 'b2-1', title: 'Poster xuất sắc', value: 'b2-1' },
		{ key: 'b2-2', title: 'Báo cáo trên hội thảo, không được giải', value: 'b2-2' },
		{ key: 'q1', title: 'Q1', value: 'q1' },
		{ key: 'q2', title: 'Q2', value: 'q2' },
		{ key: 'q3', title: 'Q3', value: 'q3' },
		{ key: 'q4', title: 'Q4/Scopus', value: 'q4' },
  ],
  domestic: [
		{ key: 'b3', title: 'Hội thảo quốc gia', value: 'b3' },
  ],
}

export const REPORTS_CONSTANTS = {
  TYPE_OPTIONS : {
    ALL: [...typeOptions.international, ...typeOptions.domestic],
    DOMESTIC: typeOptions.domestic,
    INTERNATIONAL: typeOptions.international,
  },
  ROLE_OPTIONS :[
		{ key: 'main', title: 'Tác giả chính (Tác giả đầu, tác giả liên hệ)', value: 'main' },
    { key: 'member', title: 'Đồng tác giả', value: 'member' },
	]
}