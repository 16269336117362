import React from "react";
import { Grid, Box } from "@material-ui/core"

import { APP_SCENE, FORM_CONSTANTS } from "../../../../constants";
import { fileRender } from "../../../../utils/photoRender";

const HosoAbout = ({ applicationDetail, isSuperAdmin }) => {
  const { POLITICAL_UNION_OPTIONS } = FORM_CONSTANTS;

  const aboutMeList = [
    { key: 'nominator', label: 'Đơn vị đề cử', value: `${applicationDetail?.nominator} (SĐT: ${applicationDetail?.nominatorPhone})` },
    { key: 'faculty', label: 'Tên Khoa/Viện', value: `${applicationDetail?.faculty} (SĐT: ${applicationDetail?.facultyPhone})` },
    {
      key: 'politicalUnion',
      label: 'Đoàn thể chính trị',
      value: POLITICAL_UNION_OPTIONS.find((e) => e.value === applicationDetail?.politicalUnion)?.title,
    },
    { key: 'gifted', label: 'Năng khiếu', value: applicationDetail?.gifted },
    { key: 'languague', label: 'Ngoại ngữ', value: applicationDetail?.languague },
    { key: 'break' },
    { key: 'break' },
    { key: 'break' },
    { key: 'studentYear', label: 'Sinh viên năm thứ', value: applicationDetail?.studentYear },
    { key: 'major', label: 'Ngành học', value: applicationDetail?.major },
    {
      key: 'field',
      label: 'Nhóm ngành đăng ký',
      value: FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE].find((e) => e.value === applicationDetail?.field)?.title,
    },
    { key: 'gpa', label: 'Điểm TB chung học tập (theo thang điểm 4)', value: applicationDetail?.gpa },
    { key: 'break' },
  ];

  if (isSuperAdmin) {
    aboutMeList.push({
      key: 'link-1',
      label: 'File công văn giới thiệu của đơn vị đề cử',
      value:  <a target='_blank' rel='noreferrer' href={fileRender(applicationDetail?.uploadNominator)}>Xem tại đây</a>,
    });
    aboutMeList.push({
      key: 'link-2',
      label: 'Link video giới thiệu bản thân',
      value: <a target='_blank' rel='noreferrer' href={applicationDetail?.videoUrl}>Xem tại đây</a>,
    });
    aboutMeList.push({
      key: 'link-3',
      label: 'File bảng điểm học tập',
      value:  <a target='_blank' rel='noreferrer' href={fileRender(applicationDetail?.uploadGpa)}>Xem tại đây</a>,
    });
  }

  return (
    <>
      <Grid item xs={5} style={{ marginTop: 64, marginBottom: 8 }}>
        <Box bgcolor='#c8c7cc' padding={1} fontSize={21}>GIỚI THIỆU CHUNG</Box>
      </Grid>
      <Grid item xs={7} />
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {aboutMeList.map((e, index) => (
            <Grid key={index} item xs={e.key.includes('link') ? 12 : 6}>
              {e.key !== 'break' && <span><b>{e.label}: </b>{e.value}</span>}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default HosoAbout;