/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import {
	Grid,
	Select,
	Button,
	MenuItem,
	Typography,
	Divider,
	Badge,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ACHIEVEMENT_TYPE, CATEGORY, KEYS } from '../../../../constants/enums';
import { autoScore } from '../../../../utils';
import { formActions, scoreInformationActions } from '../../../../actions';

import './DanhSachThanhTichButton.css';

import PopupMenu from '../../../../components/CustomComponents/PopupMenu';

function DanhSachThanhTichButton(props) {
  const dispatch = useDispatch();

  const { value, setValue } = props;
  const [scoreMenuAnchor, setScoreMenuAnchor] = useState(false);

	const form = useSelector(state => state.formReducer.focusForm);
	const role = useSelector(state => state.userReducer.user?.role);
	const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);
	const applicationList = useSelector(state => state.formReducer.applicationList);
	const scoreInformationList = useSelector(state => state.scoreInformationReducer.scoreInformationList);

	const handleReload = () => dispatch(scoreInformationActions.getScoreInformationBySeasonId(openSeasonId));
	const openScoreMenu = (e) => setScoreMenuAnchor(e.currentTarget);
	const closeScoreMenu = () => setScoreMenuAnchor(null);

  const unacceptedCounter = useMemo(() => {
    if (!form) return null;
    return ({
      [ACHIEVEMENT_TYPE.SOLUTION]: form.formSolution.filter((e) => !e.accepted).length,
      [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: form.formScientificResearch.filter((e) => e.scientificResearch.filterType === KEYS.INTERNATIONAL).filter((e) => !e.accepted).length,
      [ACHIEVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: form.formScientificResearch.filter((e) => e.scientificResearch.filterType === KEYS.DOMESTIC).filter((e) => !e.accepted).length,
      [ACHIEVEMENT_TYPE.SCIENTIFIC_REPORT]: form.formScientificReport.filter((e) => !e.accepted).length,
      [ACHIEVEMENT_TYPE.MONOGRAPH]: form.formMonograph.filter((e) => !e.accepted).length,
      [ACHIEVEMENT_TYPE.RESEARCH_PROJECT]: form.formResearchProject.filter((e) => !e.accepted).length,
      [ACHIEVEMENT_TYPE.REWARD]: form.formReward.filter((e) => !e.accepted).length,
    //   [ACHIEVEMENT_TYPE.HR_DEVELOPMENT]: form.formHrDevelopment.filter((e) => (!e.accepted && e.hrDevelopment.type === 'daoTao')).length,
      [ACHIEVEMENT_TYPE.TECH_COMPANY]: form.formTechCompany.filter((e) => !e.accepted).length,

    });
  }, [form]);

  useEffect(() => {
    if (openSeasonId) handleReload();

    // Lọc lấy đơn
		if (!applicationList || applicationList.length === 0) {
			dispatch(formActions.filterApplicationForm({
				seasonId: openSeasonId,
				category: CATEGORY.QCV,
				status: ['unsubmit', 'in_review', 'need_update', 'approved']
			}));
		}
	}, [openSeasonId]);

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12} md={3}>
					<Typography variant='h5' style={{ lineHeight: '36px' }}><b>Thành tích</b></Typography>
				</Grid>
				<Grid item xs={12} md={6}>
          <Select
            fullWidth
            size='small'
            value={value || ''}
            variant='outlined'
            SelectDisplayProps={{ style: { padding: 8, paddingRight: 32 } }}
            MenuProps={{
              style: { left: window.innerWidth <= 768 ? -8 : 0 },
              getContentAnchorEl: null,
              MenuListProps: { style: { padding: 0 } },
              PaperProps: { style: { left: 8 } },
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
            }}
            InputProps={{ style: { display: 'flex', flexDirection: 'row' } }}
            onChange={(e) => setValue(e.target.value)}
          >
            {props.thanhTichList.map((opt, id) => <MenuItem key={opt.key} value={opt.key} style={{ width: '100%' }}>
							<Badge
								className='srBadge'
								color="secondary"
								badgeContent={unacceptedCounter?.[opt?.key]}
							>
              {`${id + 1}. ${opt.title}`}
							</Badge>
            </MenuItem>)}
          </Select>
        </Grid>
				<Grid item xs={12} md={3}>
					<Button
						color='secondary'
						variant='contained'
						disabled={role !== 'super_admin'}
						style={{ float: 'right' }}
						onClick={(e) => openScoreMenu(e)}
					>
						Chấm điểm tự động
					</Button>
					<PopupMenu
						position='center'
						anchorEl={scoreMenuAnchor}
						handleClose={closeScoreMenu}
						render={
							<div style={{ padding: 8 }}>
								<Typography style={{ paddingBottom: 16 }}>
									Bắt đầu chấm tự động? (Lưu ý rằng kết quả chấm cũ sẽ bị loại bỏ)
								</Typography>
								<Button variant='outlined' style={{ width: 'calc(50% - 4px)' }} onClick={closeScoreMenu}>Huỷ</Button>
								<Button
									variant='contained'
									color='secondary'
									style={{ marginLeft: 8, width: 'calc(50% - 4px)' }}
									onClick={() => {
										closeScoreMenu();
										autoScore(form, scoreInformationList, dispatch);
									}}
								>
									Xác nhận
								</Button>
							</div>
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<Divider style={{ height: '0.2px', marginBottom: 8 }} />
				</Grid>
			</Grid>
		</>
	)
}

export default DanhSachThanhTichButton;
