import React from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Grid, Paper } from '@material-ui/core';

import HoSoNote from '../../../containers/GTNS/HoSo/HoSoNote';
import HoSoDetail from '../../../containers/GTNS/HoSo/HoSoDetail';

export default function HoSo() {
  const role = useSelector(state => state.userReducer.user?.role);
	const loading = useSelector(state => state.formReducer.loading);
	const isSuperAdmin = role === 'super_admin' || role.includes('gtns_admin') || role.includes('ptns_admin')

	if (!role || loading) return <CircularProgress style={{ marginTop: 128 }} />;

	return (
    <Grid item xs={12}>
			<Grid container justifyContent='center'>
				<Grid item xs={12} lg={10} xl={8}>
					<Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
						<Paper className='paper' style={{ marginTop: 32, marginBottom: 16 }}>
							<HoSoDetail isSuperAdmin={isSuperAdmin} />
						</Paper>
            {isSuperAdmin && (
              <HoSoNote />
            )}
					</Box>
				</Grid>
			</Grid>
		</Grid>
	)
}