import { apiCall } from '../utils';
import { REWARD_API,REWARD_TYPE} from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const rewardActions = {
  getAllRewards,
  getRewardByProfileId,
  insertReward,
  updateReward,
  deleteReward,
  updateRewardAccepted,
};

function getAllRewards() {
  return async (dispatch) => {
    dispatch(loading());

    const api = REWARD_API.getAllRewards();
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy thông tin giải thưởng thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy thông tin giải thưởng thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: REWARD_TYPE.GET_ALL_REWARD_LOADING } }
  function success(payload) { return { type: REWARD_TYPE.GET_ALL_REWARD_SUCCESS, payload } }
  function failure(error) { return { type: REWARD_TYPE.GET_ALL_REWARD_FAILURE, error } }
}

function getRewardByProfileId(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = REWARD_API.getRewardByProfileId(id);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
      // dispatch(notification({ message: 'Lấy thông tin giải thưởng thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy thông tin giải thưởng thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: REWARD_TYPE.GET_REWARD_BY_PROFILE_ID_LOADING } }
  function success(payload) { return { type: REWARD_TYPE.GET_REWARD_BY_PROFILE_ID_SUCCESS, payload } }
  function failure(error) { return { type: REWARD_TYPE.GET_REWARD_BY_PROFILE_ID_FAILURE, error } }
}

function insertReward(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = REWARD_API.insertReward();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data[0]));
      dispatch(notification({ message: 'Thêm thông tin giải thưởng thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm thông tin giải thưởng thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: REWARD_TYPE.INSERT_REWARD_LOADING } }
  function success(payload) { return { type: REWARD_TYPE.INSERT_REWARD_SUCCESS, payload } }
  function failure(error) { return { type: REWARD_TYPE.INSERT_REWARD_FAILURE, error } }
}

function updateReward(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = REWARD_API.updateReward();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
			// dispatch(formActions.updateFocusFormByData('formReward', 'reward', response.data.data));
      dispatch(notification({ message: 'Chỉnh sửa thông tin giải thưởng thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Chỉnh sửa thông tin giải thưởng thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: REWARD_TYPE.UPDATE_REWARD_LOADING} }
  function success(payload) { return { type: REWARD_TYPE.UPDATE_REWARD_SUCCESS, payload } }
  function failure(error) { return { type: REWARD_TYPE.UPDATE_REWARD_FAILURE, error } }
}

function deleteReward(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = REWARD_API.deleteReward(id);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200) {
      dispatch(success(id));
      dispatch(notification({ message: 'Xoá thông tin giải thưởng thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Xoá thông tin giải thưởng thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: REWARD_TYPE.DELETE_REWARD_LOADING } }
  function success(id) { return { type: REWARD_TYPE.DELETE_REWARD_SUCCESS, id } }
  function failure(error) { return { type: REWARD_TYPE.DELETE_REWARD_FAILURE, error } }
}

function updateRewardAccepted(payload){
  return async(dispatch) => {
    dispatch(loading());

    const api = REWARD_API.updateRewardAccepted();
    const { response, error } = await apiCall({ ...api, payload });

      
    const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
			dispatch(formActions.updateFocusForm('formReward', response.data.data));
      dispatch(notification({ message: `${noti} thông tin giải thưởng thành công`, type: 'success' }));
			return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: `${noti} thông tin giải thưởng thất bại`, type: 'error' }));
    }
  };

  function loading() { return { type: REWARD_TYPE.UPDATE_REWARD_ACCEPTED_LOADING } }
  function success(payload) { return { type: REWARD_TYPE.UPDATE_REWARD_ACCEPTED_SUCCESS , payload } }
  function failure(error) { return { type: REWARD_TYPE.UPDATE_REWARD_ACCEPTED_FAILURE , error } }
}
