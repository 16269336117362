import { apiCall } from '../utils';
import { HR_DEVELOPMENT_API, HR_DEVELOPMENT_TYPE } from '../constants';

import { userActions, formActions } from '.';
const { notification } = userActions;

export const hrDevelopmentActions = {
	getAllHrDevelopment,
	getHrDevelopmentByProfileId,
	insertHrDevelopment,
	updateHrDevelopment,
	deleteHrDevelopment,
	updateHrDevelopmentAccepted
}

function getAllHrDevelopment() {
	return async (dispatch) => {
		dispatch(loading());

		const api = HR_DEVELOPMENT_API.getAllHrDevelopmentList();
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy kinh nghiệm đào tạo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy kinh nghiệm đào tạo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: HR_DEVELOPMENT_TYPE.GET_ALL_HR_DEVELOPMENT_LOADING } }
	function success(payload) { return { type: HR_DEVELOPMENT_TYPE.GET_ALL_HR_DEVELOPMENT_SUCCESS, payload } }
	function failure(error) { return { type: HR_DEVELOPMENT_TYPE.GET_ALL_HR_DEVELOPMENT_FAILURE, error } }
}

function getHrDevelopmentByProfileId(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = HR_DEVELOPMENT_API.getHrDevelopmentByProfileId(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy kinh nghiệm đào tạo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy kinh nghiệm đào tạo thất bại', type: 'error' }));
		}
	}

	function loading() { return { type: HR_DEVELOPMENT_TYPE.GET_HR_DEVELOPMENT_BY_PROFILE_ID_LOADING } }
	function success(payload) { return { type: HR_DEVELOPMENT_TYPE.GET_HR_DEVELOPMENT_BY_PROFILE_ID_SUCCESS, payload } }
	function failure(error) { return { type: HR_DEVELOPMENT_TYPE.GET_HR_DEVELOPMENT_BY_PROFILE_ID_FAILURE, error } }
}

function insertHrDevelopment(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = HR_DEVELOPMENT_API.insertHrDevelopment();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(notification({ message: 'Thêm kinh nghiệm đào tạo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Thêm kinh nghiệm đào tạo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: HR_DEVELOPMENT_TYPE.INSERT_HR_DEVELOPMENT_LOADING } }
	function success(payload) { return { type: HR_DEVELOPMENT_TYPE.INSERT_HR_DEVELOPMENT_SUCCESS, payload } }
	function failure(error) { return { type: HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_FAILURE, error } }
}

function updateHrDevelopment(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = HR_DEVELOPMENT_API.updateHrDevelopment();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusFormByData('formHrDevelopment', 'hrDevelopment', response.data.data));
			dispatch(notification({ message: 'Chỉnh sửa kinh nghiệm đào tạo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Chỉnh sửa kinh nghiệm đào tạo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_LOADING } }
	function success(payload) { return { type: HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_SUCCESS, payload } }
	function failure(error) { return { type: HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_FAILURE, error } }
}

function updateHrDevelopmentAccepted(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = HR_DEVELOPMENT_API.updateHrDevelopmentAccepted();
		const { response, error } = await apiCall({ ...api, payload });

		const noti = payload[0]?.accepted ? 'Duyệt ' : 'Huỷ duyệt ';

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			dispatch(formActions.updateFocusForm('formHrDevelopment', response.data.data));
			dispatch(notification({ message: `${noti} kinh nghiệm đào tạo thành công`, type: 'success' }));
			return true;
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: `${noti} kinh nghiệm đào tạo thất bại`, type: 'error' }));
		}
	};

	function loading() { return { type: HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_ACCEPTED_LOADING } }
	function success(payload) { return { type: HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_ACCEPTED_SUCCESS, payload } }
	function failure(error) { return { type: HR_DEVELOPMENT_TYPE.UPDATE_HR_DEVELOPMENT_ACCEPTED_FAILURE, error } }
}

function deleteHrDevelopment(id) {
	return async (dispatch) => {
		dispatch(loading());

		const api = HR_DEVELOPMENT_API.deleteHrDevelopment(id);
		const { response, error } = await apiCall({ ...api });
		if (!error && response.status === 200) {
			dispatch(success(id));
			dispatch(notification({ message: 'Xoá kinh nghiệm đào tạo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Xoá kinh nghiệm đào tạo thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: HR_DEVELOPMENT_TYPE.DELETE_HR_DEVELOPMENT_LOADING } }
	function success(id) { return { type: HR_DEVELOPMENT_TYPE.DELETE_HR_DEVELOPMENT_SUCCESS, id } }
	function failure(error) { return { type: HR_DEVELOPMENT_TYPE.DELETE_HR_DEVELOPMENT_FAILURE, error } }
}