import React from "react";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import thaotac11 from "../../../assets/image/huongdansudung/thaotac11.png";
import thaotac12 from "../../../assets/image/huongdansudung/thaotac12.png";
import thaotac13 from "../../../assets/image/huongdansudung/thaotac13.png";
import thaotac14 from "../../../assets/image/huongdansudung/thaotac14.png";
import thaotac21 from "../../../assets/image/huongdansudung/thaotac21.png";
import thaotac22 from "../../../assets/image/huongdansudung/thaotac22.png";
import thaotac23 from "../../../assets/image/huongdansudung/thaotac23.png";
import thaotac24 from "../../../assets/image/huongdansudung/thaotac24.png";
import thaotac25 from "../../../assets/image/huongdansudung/thaotac25.png";
import thaotac26 from "../../../assets/image/huongdansudung/thaotac26.png";
import thaotac27 from "../../../assets/image/huongdansudung/thaotac27.png";
import thaotac31 from "../../../assets/image/huongdansudung/thaotac31.png";
import thaotac32 from "../../../assets/image/huongdansudung/thaotac32.png";
import thaotac33 from "../../../assets/image/huongdansudung/thaotac33.png";
import thaotac34 from "../../../assets/image/huongdansudung/thaotac34.png";
import thaotac35 from "../../../assets/image/huongdansudung/thaotac35.png";
import thaotac41 from "../../../assets/image/huongdansudung/thaotac41.png";
import thaotac42 from "../../../assets/image/huongdansudung/thaotac42.png";

export default function HuongDanSuDung() {
  const isMobileMode = window.innerWidth <= 768;
  const content = [
    {
      nodeId: "dkdn",
      label: "I. Đăng ký và đăng nhập",
      thaotac: [
        {
          content:
            "Để đăng ký tài khoản tham gia Giải thưởng KHCN Quả Cầu Vàng năm 2022, chọn vào phần đăng ký trên trang chủ.",
          img1: thaotac11,
        },
        {
          content:
            "Hoàn thành đầy đủ thông tin đăng ký tài khoản và click vào nút ĐĂNG KÝ TÀI KHOẢN (Lưu ý: Khuyến khích ứng viên điền thông tin cá nhân một cách chính xác.)",
          img1: thaotac12,
        },
        {
          content:
            "Sau khi đăng ký tài khoản hệ thống sẽ gửi thông báo đăng ký cùng mật khẩu của tài khoản về địa chỉ email đã đăng ký (tin nhắn gửi về có thể trong mục spam)",
          img1: thaotac13,
        },
        {
          content:
            " Đăng nhập vào cổng đăng ký bằng email và mật khẩu được được gửi về. Sau khi đăng nhập thành công, chọn giải thưởng mà ứng viên muốn tham dự.",
          img1: thaotac14,
        },
      ],
    },
    {
      nodeId: "khaibaothongtin",
      label: "II. Khai báo thành tích cá nhân",
      thaotac: [
        {
          content: "Click vào mục Thành tích cá nhân trên thanh công cụ",
          img1: thaotac21,
        },
        {
          content:
            "Tại ô xổ xuống ở giữa màn hình, ứng viên có thể chọn loại thành tích cá nhân muốn khai báo",
          img1: thaotac22,
        },
        {
          content:
            "Sau khi chọn loại thành tích cá nhân để khai báo, ứng viên click vào nút thêm ở góc trên bên phải để thêm thành tích mới.",
          img1: thaotac23,
        },
        {
          content:
            "Với những thành tích cá nhân cho phép nhập từ link DOI, ứng viên chỉ cần paste đường link DOI vào và click vào nút NHẬP TỪ DOI là hệ thống tự động điền các thông tin của thành tích đó. Sau khi hệ thống tự động điền một số trường thông tin, ứng viên kiểm tra chỉnh sửa (nếu thông tin chưa đúng) hoặc bổ sung thêm thông tin (nếu thiếu).",
          img1: thaotac24,
          img2: thaotac25,
        },
        {
          content:
            "Với những thành tích cá nhân không thể nhập từ link DOI, ứng viên click vào ô Upload file minh chứng và chọn file (ảnh hoặc pdf) đưa lên hệ thống làm minh chứng.",
          img1: thaotac26,
        },
        {
          content:
            "Sau khi đã điền đầy đủ các thông tin, ứng viên click vào nút XÁC NHẬN ở dưới cùng để lưu lại thành tích.",
        },
        {
          content:
            "Ứng viên sẽ thấy các thành tích cá nhân được hiển thị trên màn hình. Tại đây, ứng viên có thể click vào nút Chi tiết để xem lại thông tin của thành tích cũng như chỉnh sửa các thông tin.",
          img1: thaotac27,
        },
      ],
    },
    {
      nodeId: "dkhs",
      label: "III. Đăng ký hồ sơ tham dự giải thưởng",
      thaotac: [
        {
          content: "Click vào mục Đăng ký hồ sơ trên thanh công cụ",
          img1: thaotac31,
        },
        {
          content:
            "Hệ thống sẽ nhắc nhở ứng viên hoàn thành những Thành tích cá nhân trước khi tiến hành đăng ký hồ sơ. Nếu chưa hoàn thành, ứng viên ấn vào nút Chỉnh sửa thành tích, hoặc ấn vào nút Đóng để đăng ký hồ sơ.",
          img1: thaotac32,
        },
        {
          content:
            "Ứng viên tiến hành khai báo những trường thông tin vào từng mục. Lưu ý với những trường thông tin cơ bản (như trong hình), ứng viên đã khai báo trong quá trình đăng ký tài khoản nên sẽ không cần khai báo lại.",
          img1: thaotac33,
        },
        {
          content:
            "Với phần Thành tích cá nhân, ứng viên đã khai báo trước khi tiến hành đăng ký hồ sơ nên không cần khai báo lại",
          img1: thaotac34,
        },
        {
          content:
            "Sau khi hoàn thành các trường thông tin, ứng viên click chọn ô Tôi xin hoàn toàn chịu trách nhiệm trước pháp luật, Ban tổ chức Giải thưởng về tính chính xác và các thông tin đã khai trong hồ sơ đăng ký trực tuyến tham gia xét giải thưởng. Phía dưới cùng, ứng viên có hai nút bấm, với nút LƯU BẢN NHÁP, ứng viên sẽ lưu tạm thời hồ sơ vào hệ thống, hội đồng sẽ chưa chấm điểm hồ sơ này và ứng viên có thể chỉnh sửa lại hồ sơ. Còn đối với nút NỘP HỒ SƠ, sau khi click, ứng viên sẽ nộp hồ sơ lên hệ thống, và hồ sơ sẽ được hội đồng xét duyệt và chấm điểm.",
          img1: thaotac35,
        },
      ],
    },
    {
      nodeId: "kiemtrahs",
      label: "IV. Kiểm tra hồ sơ cá nhân",
      thaotac: [
        {
          content:
            "Ứng viên click tên đăng nhập (email) phía trên phải màn hình, chọn mục Danh sách hồ sơ",
          img1: thaotac41,
        },
        {
          content:
            "Ứng viên click vào ô Chi tiết để kiểm tra lại hồ sơ đã đăng ký",
          img1: thaotac42,
        },
      ],
    },
  ];

  return (
    <Box paddingY={3}>
      <Typography
        variant={isMobileMode ? 'h5' : 'h3'}
        style={{ textAlign: 'center' }}
      >
        <b>HƯỚNG DẪN ĐĂNG KÝ</b>
      </Typography>

      {content.map((e) => (
        <Accordion key={e.nodeId} style={{ margin: '16px 0' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><b>{e.label}</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} justifyContent='center'>
              {e.thaotac.map((tt, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12}><b>Thao tác {index + 1}:</b></Grid>
                  <Grid item xs={12}>{tt.content}</Grid>
                  {!tt?.img2 ? (
                    <Grid item xs={10}><img src={tt?.img1} width='100%' style={{ border: 'solid 1px #cecece', borderRadius: 4 }} /></Grid>
                  ) : (
                    <>
                      <Grid item xs={5}><img src={tt?.img1} width='100%' style={{ border: 'solid 1px #cecece', borderRadius: 4 }} /></Grid>
                      <Grid item xs={5}><img src={tt?.img2} width='100%' style={{ border: 'solid 1px #cecece', borderRadius: 4 }} /></Grid>
                    </>
                  )}
                </React.Fragment>
              ))}

            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
