import { HEADERS } from ".";

export const HR_DEVELOPMENT_API = {
    getAllHrDevelopmentList: () => ({
        endPoint: '/api/v1/development/all',
        headers: HEADERS.TOKEN_HEADER,
        method: 'GET',
    }),
    getHrDevelopmentByProfileId: (id) => ({
        endPoint: `/api/v1/development/profile/${id}`,
        headers: HEADERS.TOKEN_HEADER,
        method: 'GET',
    }),
    insertHrDevelopment: () => ({
        endPoint: '/api/v1/development/create',
        headers: HEADERS.TOKEN_HEADER,
        method: 'POST',
    }),
    updateHrDevelopment: () => ({
        endPoint: '/api/v1/development/update',
        headers: HEADERS.TOKEN_HEADER,
        method: 'PUT',
    }),
    deleteHrDevelopment: (id) => ({
        endPoint: `/api/v1/development/delete/${id}`,
        headers: HEADERS.TOKEN_HEADER,
        method: 'DELETE',
    }),
    updateHrDevelopmentAccepted: () => ({
        endPoint: '/api/v1/formHRDevelopment/updateScore_Accepted',
        headers: HEADERS.TOKEN_HEADER,
        method: 'PUT',
    })
};

export const HR_DEVELOPMENT_TYPE = {
    GET_ALL_HR_DEVELOPMENT_LOADING: 'GET_ALL_HR_DEVELOPMENT_LOADING',
    GET_ALL_HR_DEVELOPMENT_SUCCESS: 'GET_ALL_HR_DEVELOPMENT_SUCCESS',
    GET_ALL_HR_DEVELOPMENT_FAILURE: 'GET_ALL_HR_DEVELOPMENT_FAILURE',

    GET_HR_DEVELOPMENT_BY_PROFILE_ID_LOADING: 'GET_HR_DEVELOPMENT_BY_PROFILE_ID_LOADING',
    GET_HR_DEVELOPMENT_BY_PROFILE_ID_SUCCESS: 'GET_HR_DEVELOPMENT_BY_PROFILE_ID_SUCCESS',
    GET_HR_DEVELOPMENT_BY_PROFILE_ID_FAILURE: 'GET_HR_DEVELOPMENT_BY_PROFILE_ID_FAILURE',
  
    INSERT_HR_DEVELOPMENT_LOADING: 'INSERT_HR_DEVELOPMENT_LOADING',
    INSERT_HR_DEVELOPMENT_SUCCESS: 'INSERT_HR_DEVELOPMENT_SUCCESS',
    INSERT_HR_DEVELOPMENT_FAILURE: 'INSERT_HR_DEVELOPMENT_FAILURE',

    UPDATE_HR_DEVELOPMENT_LOADING: 'UPDATE_HR_DEVELOPMENT_LOADING',
    UPDATE_HR_DEVELOPMENT_SUCCESS: 'UPDATE_HR_DEVELOPMENT_SUCCESS',
    UPDATE_HR_DEVELOPMENT_FAILURE: 'UPDATE_HR_DEVELOPMENT_FAILURE',

    DELETE_HR_DEVELOPMENT_LOADING: 'DELETE_HR_DEVELOPMENT_LOADING',
    DELETE_HR_DEVELOPMENT_SUCCESS: 'DELETE_HR_DEVELOPMENT_SUCCESS',
    DELETE_HR_DEVELOPMENT_FAILURE: 'DELETE_HR_DEVELOPMENT_FAILURE',

    UPDATE_HR_DEVELOPMENT_ACCEPTED_LOADING: 'UPDATE_HR_DEVELOPMENT_ACCEPTED_LOADING',
    UPDATE_HR_DEVELOPMENT_ACCEPTED_SUCCESS: 'UPDATE_HR_DEVELOPMENT_ACCEPTED_SUCCESS',
    UPDATE_HR_DEVELOPMENT_ACCEPTED_FAILURE: 'UPDATE_HR_DEVELOPMENT_ACCEPTED_FAILURE',
}

export const HR_DEVELOPMENT_CONSTANTS = {
    levelOptions : [
        {key: 'doctor', title: 'Tiến sĩ', value: 'doctor'},
        {key: 'master', title: 'Thạc sĩ', value: 'master'},
        {key: 'bachelor', title: 'Cử nhân', value: 'bachelor'},
    ],
    roleOptions : [
        {key: 'main', title: 'Hướng dẫn chính', value: 'main'},
        {key: 'other', title: 'Hướng dẫn phụ', value: 'other'},
    ],
}