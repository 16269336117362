import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';

import KPTable from '../../../../components/KPTComponents/KPTable';
import StatusChip from '../../../../components/CustomComponents/StatusChip/StatusChip';

import { APP_SCENE } from '../../../../constants/enums';
import { formActions } from '../../../../actions';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { ROUTER, FORM_CONSTANTS } from '../../../../constants';

export default function DanhSachHoSoTable(props) {
	const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

	const loading = useSelector(state => state.formReducer.loading);
	const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);

	useEffect(() => {
		if (openSeasonId && props.status) {
			dispatch(formActions.filterApplicationForm({
				seasonId: openSeasonId,
				category: APP_SCENE,
				status: props.status,
			}));
		}
	}, [props.status, openSeasonId]);

	var columns = [
		{ title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
		{ title: <b>Tên ứng viên</b>, dataIndex: 'profile', width: '20%', render: (value) => value?.name },
		{
			title: <b>Lĩnh vực</b>,
			dataIndex: 'field',
			width: '30%',
			render: (value) => FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE].find((e) => e.value === value)?.title,
		},
		{
			title: <b>Trạng thái hồ sơ</b>,
			dataIndex: 'status',
			width: '20%',
			filters: [
				{ text: 'Đang hoàn thiện', value: 'unsubmit' },
				{ text: 'Đã nộp', value: 'in_review' },
				{ text: 'Đã được duyệt', value: 'approved' },
			],
			onFilter: (value, row) => row.status.includes(value),
			render: (value) => <StatusChip status={value} />,
    },
    // {
		// 	title: <b>Trạng thái bình chọn</b>,
		// 	dataIndex: 'voteStatus',
		// 	width: '20%',
		// 	filters: [
		// 		{ text: 'Top 20', value: 'first_round' },
		// 	],
		// 	render: (value, row) => (
    //     <Select
    //       fullWidth
    //       size='small'
    //       value={value || ''}
    //       variant='outlined'
    //       SelectDisplayProps={{ style: { padding: '4px 16px', borderRadius: 16 } }}
    //       MenuProps={{
    //         style: { left: window.innerWidth <= 768 ? -8 : 0 },
    //         getContentAnchorEl: null,
    //         MenuListProps: { style: { padding: 0, borderRadius: 16 } },
    //         PaperProps: { style: { left: 8 } },
    //         anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    //         transformOrigin: { vertical: 'top', horizontal: 'center' },
    //       }}
    //       onChange={(e) => {
    //         dispatch(formActions.updateApplicationForm({
    //           ...row,
    //           voteStatus: e.target.value
    //         }))
    //       }}
    //       style={{ borderRadius: 16 }}
    //      >
    //       <MenuItem key='' value=''>Không</MenuItem>
    //       {VOTE_STATUS.map((opt) => <MenuItem key={opt.value} value={opt.value}>{opt.title}</MenuItem>)}
    //     </Select>
    //   ),
    // },
		{
		title: <b>Tác vụ</b>,
		dataIndex: 'actions',
    align: 'right',
		width: '25%',
		render: (_, row) => (
			<Box>
        <NavLink to={`${ROUTER.HO_SO}/${row.id}`} style={{ float: 'right' }}>Xem hồ sơ</NavLink>
			</Box>
		)
		}
	];

	if (isMobileMode) columns = [
    {
			...columns[1],
			width: '38%',
			render: (value, row, id) => (
				<>
					<b>{id + 1}. {value?.name}</b>
					<div>{`(Lĩnh vực: ${FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE].find((e) => e.value === row.field)?.title})`}</div>
				</>
			)
		},
    {...columns[3], width: '35%'},
    {...columns[4], width: '20%'},
  ];

	return <KPTable loading={loading} columns={columns} rows={props.rows} />
}
