import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { useScreenSize } from '../../../../hooks/useScreenSize';
import { MONOGRAPHS_CONSTANTS } from '../../../../constants';

const { TYPE_OPTIONS: { INTERNATIONAL } } = MONOGRAPHS_CONSTANTS;

export const monographForm = (values, mobileMode, disabled, typeOptions = INTERNATIONAL) => ([
  { key: 'title', width: 12, height: 1, label: 'Tên sách', value: values?.title, disabled, required: true },
  { key: 'publisher', width: 12, label: 'Nhà xuất bản', value: values?.publisher, disabled, required: true },
  { key: 'numberMain', width: 12, label: 'Số lượng tác giả chính (Tác giả đầu, tác giả liên hệ)', value: values?.numberMain, disabled, type: 'int', required: true },
  { key: 'publishYear', width: mobileMode ? 7 : 3, label: 'Năm xuất bản', value: values?.publishYear, disabled, required: true },
  { key: 'page', width: mobileMode ? 5 : 2, label: 'Trang',type:'int', value: values?.page, disabled, required: true },
  { key: 'issn', width: mobileMode ? 5 : 4, label: 'ISSN/ISBN', value: values?.issn, disabled, required: true },
  { key: 'numberAuthor', width: mobileMode ? 7 : 3, label: 'Số tác giả', value: values?.numberAuthor, disabled, required: true },
  { key: 'author', width: 12, height: 1, label: 'Danh sách tác giả', value: values?.author, disabled, required: true },
  { key: 'role', width: mobileMode ? 12 : 6, label: 'Vai trò', value: values?.role, options: MONOGRAPHS_CONSTANTS.ROLE_OPTIONS, disabled, required: true },
  { key: 'type', width: mobileMode ? 12 : 6, label: 'Phân loại', value: values?.type, options: typeOptions, disabled, required: true },
  { key: 'previewFile', width: 12, value: values?.previewFile, type: 'file', disabled, required: true },
]);

export default function BangSachForm(props) {
  const isMobileMode = useScreenSize();
  const textForm = monographForm(props.values, isMobileMode, props.disabled);
  return <KPTextform {...props} textForm={textForm} />
}