export const HEADERS = {
  DOI_HEADER: {
    'Accept': 'application/json; charset=UTF-8',
  },
  DEFAULT_HEADER: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
  TOKEN_HEADER: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Authorization': localStorage.getItem('access_token'),
  },
  UPLOAD_FILE_HEADER: {
    'Content-Type': 'Content-Type: multipart/form-data;', // ?
    'Authorization': localStorage.getItem('access_token'),
  },
}