import { FORM_TYPE, TECH_COMPANY_TYPE } from '../constants';

const initialState = {
  loading: false,
  personalTechCompanyList: [],
  totalTechCompanyList: [],
};

const sortFunc = (a, b) => a.id - b.id;

export function techCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case TECH_COMPANY_TYPE.GET_ALL_TECH_COMPANY_LOADING:
    case TECH_COMPANY_TYPE.GET_TECH_COMPANY_BY_PROFILE_ID_LOADING:
    case TECH_COMPANY_TYPE.INSERT_TECH_COMPANY_LOADING:
    case TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_LOADING:
    case TECH_COMPANY_TYPE.DELETE_TECH_COMPANY_LOADING:
    case TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_ACCEPTED_LOADING:
      return { ...state, loading: true };

    case TECH_COMPANY_TYPE.GET_ALL_TECH_COMPANY_FAILURE:
    case TECH_COMPANY_TYPE.GET_TECH_COMPANY_BY_PROFILE_ID_FAILURE:
    case TECH_COMPANY_TYPE.INSERT_TECH_COMPANY_FAILURE:
    case TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_FAILURE:
    case TECH_COMPANY_TYPE.DELETE_TECH_COMPANY_FAILURE:
    case TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_ACCEPTED_FAILURE:
      return { ...state, loading: false };

    case TECH_COMPANY_TYPE.GET_ALL_TECH_COMPANY_SUCCESS:
      return { ...state, totalTechCompanyList: action.payload.sort(sortFunc), loading: false };

    case TECH_COMPANY_TYPE.GET_TECH_COMPANY_BY_PROFILE_ID_SUCCESS:
      return { ...state, personalTechCompanyList: action.payload.sort(sortFunc), loading: false };

    case TECH_COMPANY_TYPE.INSERT_TECH_COMPANY_SUCCESS:
      return {
        ...state,
        totalTechCompanyList: [...state.personalTechCompanyList, action.payload].sort(sortFunc),
        personalTechCompanyList: [...state.personalTechCompanyList, action.payload].sort(sortFunc),
        loading: false,
      };

    case TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_SUCCESS:
      return {
        ...state,
        totalTechCompanyList: state.totalTechCompanyList
          ? [...state.totalTechCompanyList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        personalTechCompanyList: [
          ...state.personalTechCompanyList.filter((e) => e.id !== action.payload.id),
          action.payload,
        ].sort(sortFunc),
        loading: false,
      };

    case TECH_COMPANY_TYPE.DELETE_TECH_COMPANY_SUCCESS:
      return {
        ...state,
        totalTechCompanyList: state.totalTechCompanyList?.filter((e) => e.id !== action.id).sort(sortFunc),
        personalTechCompanyList: state.personalTechCompanyList.filter((e) => e.id !== action.id).sort(sortFunc),
        loading: false,
      };

    case TECH_COMPANY_TYPE.UPDATE_TECH_COMPANY_ACCEPTED_SUCCESS:
      return {
        ...state,
        totalTechCompanyList: state.totalTechCompanyList
          ? [...state.totalTechCompanyList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        personalTechCompanyList: state.personalTechCompanyList
          ? [...state.personalTechCompanyList.filter((e) => e.id !== action.payload.id), action.payload].sort(sortFunc)
          : null,
        loading: false,
      };
    
    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        personalTechCompanyList: action.payload.techCompanies.sort(sortFunc)
      }

    default:
      return state;
  }
}
