const FULL_BORDER = {
  top: { style:'medium', color: { argb:'FF000000' }},
  left: { style:'medium', color: { argb:'FF000000' }},
  bottom: { style:'medium', color: { argb:'FF000000' }},
  right: { style:'medium', color: { argb:'FF000000' }}
}

const EXPORT_COLUMNS = [
  { key: 'name', title: 'THÔNG TIN CÁ NHÂN', width: 50 },
  { key: 'summary', title: 'CÔNG TRÌNH TIÊU BIỂU THUỘC LĨNH VỰC XÉT THƯỞNG', width: 50 },
  { key: 'title', title: 'THÀNH TÍCH NỔI BẬT', width: 70 },
  { key: 'type', title: 'PL', width: 10 },
  { key: 'numberAuthor', title: 'SỐ TG', width: 5 },
  { key: 'isMain', title: 'TG CHÍNH', width: 7 },
  { key: 'score', title: 'ĐIỂM', width: 7 },
  { key: 'linkMinhChung', title: 'MINH CHỨNG (link file, website,…)', width: 20 },
  { key: 'note', title: 'GHI CHÚ', width: 30 },
];

const THANH_TICH_LIST = [
	'formSolution',
	'formScientificResearch',
	'formScientificReport',
	'formResearchProject',
	'formMonograph',
	'formTechCompany',
	'formReward',
	'formHrDevelopment',
];

const TYPE_OPTIONS = {
  'sc': 'Bằng sáng chế quốc tế',
  'scqg': 'Bằng độc quyền sáng chế quốc gia',
  'gp': 'Giải pháp hữu ích',
  'g1': 'HCV/Giải Nhất Quốc tế',
  'g2': 'HCB/Giải Nhì Quốc tế',
  'g3-1': 'HCĐ/Giải ba Quốc tế',
  'g3-2': 'HCV/Giải nhất Quốc gia',
  'g4': 'HCB/Giải Nhì Quốc gia',
  'g5': 'HCĐ/Giải Ba Quốc gia',
  'nstb-g6': 'HCV/Giải nhất cấp tỉnh',
  'nstb-g7': 'HCB/Giải Nhì cấp tỉnh',
  'nstb-g8': 'HCĐ/Giải Ba cấp tỉnh',
  'nstb-g1': 'HCV/Giải Nhất Quốc tế',
  'nstb-g2': 'HCB/Giải Nhì Quốc tế',
  'nstb-g3-1': 'HCĐ/Giải ba Quốc tế',
  'nstb-g3-2': 'HCV/Giải nhất Quốc gia',
  'nstb-g4': 'HCB/Giải Nhì Quốc gia',
  'nstb-g5': 'HCĐ/Giải Ba Quốc gia',
  'nstb-n1': 'Giải Nhất cấp Bộ Giáo dục',
  'nstb-n2': 'Giải Nhì cấp Bộ Giáo dục',
  'nstb-n3': 'Giải Ba cấp Bộ Giáo dục',
  'nstb-n4': 'Giải Khuyến khích cấp Bộ Giáo dục',
  'nstb-n5': 'Giải Nhất cấp Trường',
  'nstb-n6': 'Giải Nhì cấp Trường',
  'nstb-n7': 'Giải Ba cấp Trường',
  'nstb-o1': 'Giải Nhất',
  'nstb-o2': 'Giải Nhì',
  'nstb-o3': 'Giải Ba',
  'nstb-o4': 'Giải Khuyến khích',
  'b1': 'Báo cáo xuất sắc',
  'b2-1': 'Poster xuất sắc',
  'b2-2': 'Báo cáo trên hội thảo, không được giải',
  'b3': 'Hội thảo quốc gia',
  'q1': 'Q1',
  'q2': 'Q2',
  'q3': 'Q3',
  'q4': 'Q4/Scopus',
  'scopus': 'Q4/Scopus',
  'q5': 'Q5 ',
  'q6': 'Bài báo trong nước',
  'doctor': 'Tiến sĩ',
  'master': 'Thạc sĩ',
  'bachelor': 'Cử nhân',
}

export const qcvEnums = {
  FULL_BORDER,
  TYPE_OPTIONS,
  EXPORT_COLUMNS,
  THANH_TICH_LIST,
}
