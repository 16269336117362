import React from 'react';

import BaoCaoTable from '../../../containers/QCV/BaoCao/BaoCaoTable';
import BaoCaoButton from '../../../containers/QCV/BaoCao/BaoCaoButton';
import ThanhTichAlert from '../../../containers/AppLayout/ThanhTichAlert';

function BaiBao() {
  var filterType = 'person';
  const url = window.location.href;
  if (url.includes('duyet-thanh-tich-ca-nhan')) filterType = 'all';

  return (
    <>
      <BaoCaoButton filterType={filterType}/>
      <ThanhTichAlert />
      <BaoCaoTable filterType={filterType} type='update' />
    </>
  );
}

export default BaiBao;
