import { VOTE_TYPE } from '../constants';

const initialState = {
  loading: false,
  loadingCount: false,
  votedList: [],
  voteCount: 0,
  voteInfo: null,
}

export function voteReducer(state = initialState, action) {
  switch (action.type) {
    case VOTE_TYPE.VOTE_LOADING:
    case VOTE_TYPE.GET_VOTE_INFO_LOADING:
      return { ...state, loading: true };

    case VOTE_TYPE.VOTE_SUCCESS:
    case VOTE_TYPE.VOTE_FAILURE:
    case VOTE_TYPE.GET_VOTE_INFO_FAILURE:
      return { ...state, loading: false };

    case VOTE_TYPE.GET_VOTE_COUNT_LOADING: return { ...state, loadingCount: true };
    case VOTE_TYPE.GET_VOTE_COUNT_FAILURE: return { ...state, loadingCount: false };

    case VOTE_TYPE.GET_VOTE_INFO_SUCCESS:
      return { ...state, votedList: action.payload, loading: false };

    case VOTE_TYPE.GET_VOTE_COUNT_SUCCESS:
      return { ...state, voteCount: action.payload, loadingCount: false };

    case 'RELOAD_VOTE_INFO':
      return { ...state, voteInfo: null, votedList: [] };

    case 'SET_VOTE_INFO':
      return { ...state, voteInfo: action.info };

    default:
      return state
  }
}
